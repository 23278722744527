

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';
import FilterListIcon from '@mui/icons-material/FilterList';
// import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  getAllBlinds,
  getRecommendedPick,
  getReviewSummary,
} from '../actions/productActions';
import Product from '../components/Product';
import Recommended from '../components/Recommended';
import { setFilter } from '../actions/filterActions';
import { fetchReviewSummary } from '../actions/userReviewActions';
import Menu3 from '../components/Menu3';
import LoadingOverlay from 'react-loading-overlay';
import { useParams } from 'react-router-dom';

const styles = {
  heavyText: {
    fontWeight: 400,
  },
  lightText: {
    fontWeight: 300,
  },
  divider: {
    width: '100%',
    borderTop: '1px solid black',
    marginTop: '1rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  accordianCleaner: {
    background: 'transparent',
    boxShadow: 'none',
  },
  radioSizer: {
    padding: '0.2rem 0.2rem',
    marginLeft: '1rem',
  },
  accordianSummarySizer: {
    minHeight: 'auto',
    margin: '0',
  },
  accordianSummarySizerexpanded: {
    margin: '0',
    minHeight: 'auto',
  },
  accordianDetailsSizer: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  accordianHeader: {
    fontWeight: 300,
    fontSize: '1.5rem',
  },
  widthCorrector: {
    maxWidth: '100%',
    marginTop: '2px',
  },
  spacerTop: {
    marginTop: '2rem',
  },
  top: {
    marginTop: '6rem',
    background: 'black',
  },
  filtersWidth: {
    display: 'block',
  },
};

const StyledAccordion = withStyles((theme) => ({
  root: {
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '0',
      marginLeft: 0,
      width: '100%',
    },
  },
  expanded: {},
}))(Accordion);

const StyledAccordionSummary = withStyles((theme) => ({
  root: {
    minHeight: 10,
    '&$expanded': {
      minHeight: 10,
    },
  },
  content: {
    '&$expanded': {
      margin: '2px 0',
    },
  },
  expanded: {},
}))(AccordionSummary);

const AllShadeScreen = () => {
  const dispatch = useDispatch();
  const { products, loading } = useSelector((state) => state.products);
  const filterRedux = useSelector((state) => state.filter.value);

  const [filteredData, setFilteredData] = useState([]);
  const [colorFilters, setColorFilters] = useState([]);
  const [lightFilters, setLightFilters] = useState([]);
  const [priceFilters, setPriceFilters] = useState([]);
  const [typeFilters, setTypeFilters] = useState([]);
  const [operationFilters, setOperationFilters] = useState([]);
  const [collectionFilters, setCollectionFilters] = useState([]);

  const [colorChecked, setColorChecked] = useState([]);
  const [lightChecked, setLightChecked] = useState([]);
  const [priceChecked, setPriceChecked] = useState([]);
  const [typeChecked, setTypeChecked] = useState([]);
  const [operationChecked, setOperationChecked] = useState([]);
  const [collectionChecked, setCollectionChecked] = useState([]);

  // const [searchInput, setSearchInput] = useState('');
  const [header, setHeader] = useState();
  const [showHeader, setShowHeader] = useState(true);
  const [filterChanged, setFilterChanged] = useState(false);
  const [filtering, setFiltering] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const { query } = useParams();

  useEffect(() => {
    if (query) {
      if(query.toLowerCase() == 'blackout') filterRedux.light[0] = 'Blackout';
      else if(query.toLowerCase() == 'privacy') filterRedux.light[0] = 'Privacy';
      else if(query.toLowerCase() == 'sunscreen') filterRedux.light[0] = 'Sun Screen';
      else if(query.toLowerCase() == 'transparent') filterRedux.light[0] = 'Transparent';
      else if(query.toLowerCase() == 'translucent') filterRedux.light[0] = 'Translucent';
    }

    if (
      filterRedux.color.length +
      filterRedux.light.length +
      filterRedux.price.length +
      filterRedux.type.length +
      filterRedux.operation.length +
      filterRedux.collection.length !=
      1
    ) {
      setHeader(false);
      //Needs to force reload
      dispatch(getAllBlinds());
      //getReviewSummary(products?.Program_id)
    } else {
      dispatch(getAllBlinds());

      if (filterRedux.color.length == 1) {
        handlePageQuery(filterRedux.color[0]);
      }
      if (filterRedux.light.length == 1) {
        handlePageQuery(filterRedux.light[0]);
      }
      if (filterRedux.price.length == 1) {
        handlePageQuery(filterRedux.price[0]);
      }
      if (filterRedux.type.length == 1) {
        handlePageQuery(filterRedux.type[0]);
      }
      if (filterRedux.operation.length == 1) {
        handlePageQuery(filterRedux.operation[0]);
      }
      if (filterRedux.collection.length == 1) {
        handlePageQuery(filterRedux.collection[0]);
      }
      //handlePageQuery()
    }
  }, [filterRedux, dispatch]);

  useEffect(() => {
    buildFilters();
  }, [products]);

  useEffect(() => {
    dispatch(getAllBlinds());
  }, [dispatch, filterRedux]);

  useEffect(() => {
    applyFilter();
  }, [
    filterRedux,
    colorFilters,
    lightFilters,
    priceFilters,
    priceFilters,
    typeFilters,
    operationFilters,
    collectionFilters,
  ]);

  var productArr = useSelector((state) => state.products.products);

  useEffect(() => {
    fetchReviewSummary(dispatch, productArr);
  }, [dispatch, productArr]);

  const showProducts = () => {
    // Group colour and image to their corresponding series
    const uniqueProducts = [
      ...new Set(filteredData.map((item) => item.Program)),
    ];
    const productsColors = uniqueProducts.map((item) => {
      let SG_Color = filteredData
        .filter((item2) => item2.Program === item)
        .map((item3) => item3.SG_Color);
      let SG_MinPrice = filteredData
        .filter((item2) => item2.Program === item)
        .map((item3) => item3.Min_Price);
      let shade_class = filteredData
        .filter((item2) => item2.Program === item)
        .map((item3) => item3.shade_class)[0];

      return { Program: item, SG_Color, SG_MinPrice, shade_class:  shade_class};
    });

    let ret = productsColors.map((item) => {
      item.SG_MinPrice = Math.min(...item.SG_MinPrice);
      return (
        <Grid item xs={10} sm={4} key={item.Program}>
          <Product product={item} />
        </Grid>
      );
    });
    return ret;
  };

  const applyFilter = () => {
    // let tempFilteredData = filteredData.length === 0 ? products : filteredData;
    let tempFilteredData = products;

    if (
      filterRedux.color.length +
      filterRedux.light.length +
      filterRedux.price.length +
      filterRedux.type.length +
      filterRedux.operation.length +
      filterRedux.collection.length ===
      0
    ) {
      setFilteredData(tempFilteredData);
      return;
    }

    if (filterRedux.color.length > 0) {
      tempFilteredData = tempFilteredData.filter((product) =>
        filterRedux.color.includes(product.Colour_Family),
      );
    }

    if (filterRedux.light.length > 0) {
      tempFilteredData = tempFilteredData.filter((product) =>
        filterRedux.light.includes(product.Fabric_Type),
      );
    }

    if (filterRedux.price.length > 0) {
      tempFilteredData = tempFilteredData.filter((product) =>
        filterRedux.price.includes(product.Price_Range),
      );
    }

    if (filterRedux.type.length > 0) {
      tempFilteredData = tempFilteredData.filter((product) =>
        filterRedux.type.includes(product.Type),
      );
    }

    if (filterRedux.operation.length > 0) {
      tempFilteredData = tempFilteredData.filter((product) =>
        filterRedux.operation.includes(product.Operation),
      );
    }

    if (filterRedux.collection.length > 0) {
      tempFilteredData = tempFilteredData.filter((product) =>
        filterRedux.collection.includes(product.Collection),
      );
    }

    setFilteredData(tempFilteredData);
  };

  const buildFilters = () => {
    const colorsList = new Set(
      products.map((product) => product.Colour_Family),
    );
    const lightList = new Set(products.map((product) => product.Fabric_Type));
    const priceList = new Set(products.map((product) => product.Price_Range));
    const typeList = new Set(products.map((product) => product.Type));
    const operationList = new Set(products.map((product) => product.Operation));
    const collectionList = new Set(
      products.map((product) => product.Collection),
    );

    setColorFilters(Array.from(colorsList));
    setLightFilters(Array.from(lightList));
    setPriceFilters(Array.from(priceList));
    setTypeFilters(Array.from(typeList));
    setOperationFilters(Array.from(operationList));
    setCollectionFilters(Array.from(collectionList));
  };

  const handlePageQuery = (headerVal) => {
    if (filterRedux !== undefined) {
      setFilterChanged(true);
      // Dont set header for All

      setHeader(headerVal);
      dispatch(getRecommendedPick(headerVal));
      setShowHeader(true);
    }

    setFirstLoad(false);
  };

  const handleColorCheck = (name) => {
    //let tempList = [...colorChecked];
    let filterReduxTemp = JSON.parse(JSON.stringify(filterRedux));
    if (!filterReduxTemp.color.includes(name)) {
      filterReduxTemp.color.push(name);
    } else {
      filterReduxTemp.color.splice(filterReduxTemp.color.indexOf(name), 1);
    }
    //setColorChecked([...tempList]);
    setFilter(dispatch, filterReduxTemp);
    setFilterChanged(true);
  };

  const handleLightFilter = (name) => {
    let filterReduxTemp = JSON.parse(JSON.stringify(filterRedux));
    if (!filterReduxTemp.light.includes(name)) {
      filterReduxTemp.light.push(name);
    } else {
      filterReduxTemp.light.splice(filterReduxTemp.light.indexOf(name), 1);
    }
    setFilter(dispatch, filterReduxTemp);
    setFilterChanged(true);
  };

  const handlePriceCheck = (name) => {
    let filterReduxTemp = JSON.parse(JSON.stringify(filterRedux));

    if (!filterReduxTemp.price.includes(name)) {
      filterReduxTemp.price.push(name);
    } else {
      filterReduxTemp.price.splice(filterReduxTemp.price.indexOf(name), 1);
    }
    setFilter(dispatch, filterReduxTemp);
    setFilterChanged(true);
  };

  const handleTypeCheck = (name) => {
    let filterReduxTemp = JSON.parse(JSON.stringify(filterRedux));

    if (!filterReduxTemp.type.includes(name)) {
      filterReduxTemp.type.push(name);
    } else {
      filterReduxTemp.type.splice(filterReduxTemp.type.indexOf(name), 1);
    }
    setFilter(dispatch, filterReduxTemp);
    setFilterChanged(true);
  };

  const handleOperationCheck = (name) => {
    let filterReduxTemp = JSON.parse(JSON.stringify(filterRedux));

    if (!filterReduxTemp.operation.includes(name)) {
      filterReduxTemp.operation.push(name);
    } else {
      filterReduxTemp.operation.splice(
        filterReduxTemp.operation.indexOf(name),
        1,
      );
    }
    setFilter(dispatch, filterReduxTemp);
    setFilterChanged(true);
  };

  const handleCollectionCheck = (name) => {
    let filterReduxTemp = JSON.parse(JSON.stringify(filterRedux));

    if (!filterReduxTemp.collection.includes(name)) {
      filterReduxTemp.collection.push(name);
    } else {
      filterReduxTemp.collection.splice(
        filterReduxTemp.collection.indexOf(name),
        1,
      );
    }
    setFilter(dispatch, filterReduxTemp);
    setFilterChanged(true);
  };

  return (
    <>
      <Menu3 />
      <Container>
        <LoadingOverlay
          active={loading}
          spinner
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'none',
            }),
            spinner: (base) => ({
              ...base,
              width: '100px',
              '& svg circle': {
                stroke: '#ef4036',
              },
            }),
          }}>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-start'>
          {/* Left - Filters */}
          <Grid className='mobileList2'>
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'>
                  <Typography style={styles.accordianHeader}>
                    {' '}
                    <FilterListIcon />
                    {'   '}Filter By
                  </Typography>
                </AccordionSummary>

                <AccordionDetails style={styles.filtersWidth}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel2a-content'
                      id='panel2a-header'>
                      <Typography>Colour</Typography>
                    </AccordionSummary>
                    <div className='gridList'>
                      {colorFilters.map((color, index) => {
                        return (
                          <FormControlLabel
                            checked={filterRedux.color.includes(color)}
                            value={color}
                            key={index}
                            control={<Checkbox style={styles.radioSizer} />}
                            label={color}
                            onChange={() => handleColorCheck(color)}
                          />
                        );
                      })}
                    </div>
                  </Accordion>
                </AccordionDetails>

                <AccordionDetails style={styles.filtersWidth}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel2a-content'
                      id='panel2a-header'>
                      <Typography>Light</Typography>
                    </AccordionSummary>
                    <div className='gridList'>
                      {lightFilters.map((light, index) => {
                        return (
                          <FormControlLabel
                            checked={filterRedux.light.includes(light)}
                            value={light}
                            key={index}
                            control={<Checkbox style={styles.radioSizer} />}
                            label={light}
                            onChange={() => handleLightFilter(light)}
                          />
                        );
                      })}
                    </div>
                  </Accordion>
                </AccordionDetails>

                <AccordionDetails style={styles.filtersWidth}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel2a-content'
                      id='panel2a-header'>
                      <Typography>Price</Typography>
                    </AccordionSummary>
                    <div className='gridList'>
                      {priceFilters.map((price, index) => {
                        return (
                          <FormControlLabel
                            checked={filterRedux.price.includes(price)}
                            value={price}
                            key={index}
                            control={<Checkbox style={styles.radioSizer} />}
                            label={price}
                            onChange={() => handlePriceCheck(price)}
                          />
                        );
                      })}
                    </div>
                  </Accordion>
                </AccordionDetails>

                <AccordionDetails style={styles.filtersWidth}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel2a-content'
                      id='panel2a-header'>
                      <Typography>Shade Type</Typography>
                    </AccordionSummary>
                    <div className='gridList'>
                      {typeFilters.map((type, index) => {
                        return (
                          <FormControlLabel
                            checked={filterRedux.type.includes(type)}
                            value={type}
                            key={index}
                            control={<Checkbox style={styles.radioSizer} />}
                            label={type}
                            onChange={() => handleTypeCheck(type)}
                          />
                        );
                      })}
                    </div>
                  </Accordion>
                </AccordionDetails>

                <AccordionDetails style={styles.filtersWidth}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel2a-content'
                      id='panel2a-header'>
                      <Typography>Operation</Typography>
                    </AccordionSummary>
                    <div className='gridList'>
                      {operationFilters.map((operation, index) => {
                        return (
                          <FormControlLabel
                            checked={filterRedux.operation.includes(operation)}
                            value={operation}
                            key={index}
                            control={<Checkbox style={styles.radioSizer} />}
                            label={operation}
                            onChange={() => handleOperationCheck(operation)}
                          />
                        );
                      })}
                    </div>
                  </Accordion>
                </AccordionDetails>

                <AccordionDetails style={styles.filtersWidth}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel2a-content'
                      id='panel2a-header'>
                      <Typography>Collections</Typography>
                    </AccordionSummary>
                    <div className='gridList'>
                      {collectionFilters.map((collection, index) => {
                        return (
                          <FormControlLabel
                            checked={filterRedux.collection.includes(
                              collection,
                            )}
                            value={collection}
                            key={index}
                            control={<Checkbox style={styles.radioSizer} />}
                            label={collection}
                            onChange={() => handleCollectionCheck(collection)}
                          />
                        );
                      })}
                    </div>
                  </Accordion>
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid>

          <Grid item xs={3} className='mobileList'>
            {/* <TextField
            // style={styles.margin}
            // id="input-with-icon-textfield"
            label='Search'
            variant='outlined'
            style={styles.spacerTop}
            // label="TextField"
            value={searchInput}
            changeInput={(e) => setSearchInput(e.target.value)}
          ><InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          </TextField> */}
            <div>
              {/* Filter Accordians */}
              {/* New Color */}
              <StyledAccordion style={styles.accordianCleaner}>
                <StyledAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='color-filter'
                  aria-label='Expand'>
                  <span className='programName'>Colour</span>
                </StyledAccordionSummary>
                <AccordionDetails style={styles.accordianDetailsSizer}>
                  <FormControl component='fieldset' style={styles.formControl}>
                    {/* <FormLabel component="legend">Assign responsibility</FormLabel> */}
                    <FormGroup>
                      {colorFilters.map((color, index) => {
                        return (
                          <FormControlLabel
                            checked={filterRedux.color.includes(color)}
                            value={color}
                            key={index}
                            control={<Checkbox style={styles.radioSizer} />}
                            label={color}
                            onChange={() => handleColorCheck(color)}
                          />
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                </AccordionDetails>
              </StyledAccordion>
              {/* Light */}
              {/* defaultExpanded */}
              <StyledAccordion style={styles.accordianCleaner}>
                <StyledAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='color-filter'
                  id='color-filter'>
                  <span className='programName'>Light Filtering</span>
                </StyledAccordionSummary>
                <AccordionDetails style={styles.accordianDetailsSizer}>
                  <FormControl component='fieldset'>
                    {lightFilters.map((light, index) => {
                      return (
                        <FormControlLabel
                          checked={filterRedux.light.includes(light)}
                          value={light}
                          key={index}
                          control={<Checkbox style={styles.radioSizer} />}
                          label={light}
                          onChange={() => handleLightFilter(light)}
                        />
                      );
                    })}
                  </FormControl>
                </AccordionDetails>
              </StyledAccordion>
              {/* Price */}
              <StyledAccordion style={styles.accordianCleaner}>
                <StyledAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='color-filter'
                  id='color-filter'>
                  <span className='programName'>Price</span>
                </StyledAccordionSummary>
                <AccordionDetails style={styles.accordianDetailsSizer}>
                  <FormControl component='fieldset'>
                    {priceFilters.map((price, index) => {
                      return (
                        <FormControlLabel
                          checked={filterRedux.price.includes(price)}
                          value={price}
                          key={index}
                          control={<Checkbox style={styles.radioSizer} />}
                          label={price}
                          onChange={() => handlePriceCheck(price)}
                        />
                      );
                    })}
                  </FormControl>
                </AccordionDetails>
              </StyledAccordion>
              {/* Shade Type */}
              <StyledAccordion style={styles.accordianCleaner}>
                <StyledAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='color-filter'
                  id='color-filter'>
                  <span className='programName'> Shade Type</span>
                </StyledAccordionSummary>
                <AccordionDetails style={styles.accordianDetailsSizer}>
                  <FormControl component='fieldset'>
                    {typeFilters.map((type, index) => {
                      return (
                        <FormControlLabel
                          checked={filterRedux.type.includes(type)}
                          value={type}
                          key={index}
                          control={<Checkbox style={styles.radioSizer} />}
                          label={type}
                          onChange={() => handleTypeCheck(type)}
                        />
                      );
                    })}
                  </FormControl>
                </AccordionDetails>
              </StyledAccordion>
              {/* Operation */}
              <StyledAccordion style={styles.accordianCleaner}>
                <StyledAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='color-filter'
                  id='color-filter'>
                  <span className='programName'> Operation</span>
                </StyledAccordionSummary>
                <AccordionDetails style={styles.accordianDetailsSizer}>
                  <FormControl component='fieldset'>
                    {operationFilters.map((operation, index) => {
                      return (
                        <FormControlLabel
                          checked={filterRedux.operation.includes(operation)}
                          value={operation}
                          key={index}
                          control={<Checkbox style={styles.radioSizer} />}
                          label={operation}
                          onChange={() => handleOperationCheck(operation)}
                        />
                      );
                    })}
                  </FormControl>
                </AccordionDetails>
              </StyledAccordion>
              <StyledAccordion style={styles.accordianCleaner}>
                <StyledAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='color-filter'
                  id='color-filter'>
                  <span className='programName'> Collection</span>
                </StyledAccordionSummary>
                <AccordionDetails style={styles.accordianDetailsSizer}>
                  <FormControl component='fieldset'>
                    {collectionFilters.map((collection, index) => {
                      return (
                        <FormControlLabel
                          checked={filterRedux.collection.includes(collection)}
                          value={collection}
                          key={index}
                          control={<Checkbox style={styles.radioSizer} />}
                          label={collection}
                          onChange={() => handleCollectionCheck(collection)}
                        />
                      );
                    })}
                  </FormControl>
                </AccordionDetails>
              </StyledAccordion>
              {/* End Accordian Section */}
            </div>
          </Grid>
          {/* Blinds - Right Side */}

          {/* Home Screen */}
          <Grid item xs={9}>
            {header ? <Recommended program={header} /> : ''}
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              style={styles.widthCorrector}
              spacing={3}>
              {!loading && showProducts()}
            </Grid>
          </Grid>
        </Grid>

      </LoadingOverlay>
      {/* <hr style={styles.divider} /> */}
    </Container>
      </>
  );
};

export default AllShadeScreen;
