/** @format */

import { React, useEffect, useRef, useState } from 'react';
import { Grid, Button, Typography, Radio, Snackbar } from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Alert, AlertTitle } from '@material-ui/lab';

import {
  filterFabricsByGroup,
  getDeflection,
  getPriceDifference,
  groupFabric,
} from '../actions/blindActions';

import { useDispatch, useSelector } from 'react-redux';
import { customizeProduct } from '../actions/blindActions';
import { Loader } from './Loader';
import { calculateTotalPrice } from '../actions/blindActions';
import { addToSwatchCart } from '../actions/cartActions';
import LoadingOverlay from 'react-loading-overlay';

const CustomizeFabric = ({
  page,
  productInfo,
  optionInfo,
  totalPrice,
  setTotalPrice,
  switchPage,
  setSwitchPage,
  fabricPrice,
  renderFabric,
  setRenderFabric,
  setDisabled,
}) => {
  const dispatch = useDispatch();

  const shadeTitle = productInfo.Type + ' Shade';

  var shadeWidthFrac = optionInfo.widthFraction;
  var shadeLengthFrac = optionInfo.lengthFraction;
  var shadeLength = optionInfo.lengthWhole;
  var shadeWidth = optionInfo.widthWhole;
  var measure = optionInfo.measure;

  var [fabricType, setFabricType] = useState(productInfo.Fabric_Type);
  var { swatchCartItems } = useSelector((state) => state.swatchCart);

  var [colour, setColour] = useState(productInfo.Colour_Family);
  var [mWidth, setMWidth] = useState(0);
  var [δ, setδ] = useState(productInfo.δ);
  var [invalid, setInvalid] = useState(false);
  var [sampleToast, setSampleToast] = useState(false);
  const cancelToken = new AbortController();

  var [selectedRadio, setSelectedRadio] = useState(
    productInfo.fabricColorRadio,
  );

  var [data, setData] = useState(null);
  var [data1, setData1] = useState(null);
  var [data2, setData2] = useState(null);
  var priceGroup = productInfo.Price_Group;
  var [loading, setLoading] = useState(true);

  useEffect(() => {
    setDisabled(loading);
  }, [loading]);

  // Handlers
  const handleFabricType = (e) => {
    colour = 'All';
    setColour('All');
    setFabricType(e.target.value);
  };

  const handleColour = (e) => {
    colour = e.target.value;
    setColour(e.target.value);
  };

  const dispatchDetails = () => {
    console.log('dispatching');
    let spl = selectedRadio.split('||');
    productInfo.Fabric = spl[0];
    productInfo.fabricPrice = fabricPrice.current;
    productInfo.SG_Color = spl[1];
    productInfo.Fabric_Code = spl[2];
    productInfo.fabricColorRadio = selectedRadio;
    productInfo.Fabric_Type = fabricType;
    optionInfo.OUR_PRICE = totalPrice;
    optionInfo.selectedColor = spl[1];
    productInfo.Price_Group = priceGroup;
    console.log(productInfo);
    dispatch(
      customizeProduct({
        productInfo: {
          ...productInfo,
        },
        optionInfo: {
          ...optionInfo,
        },
      }),
    );
  };

  const handleClose = () => {
    setSampleToast(false);
  };

  const forwardPage = () => {
    page.current = 3;
    dispatchDetails();
    var toChange = document.getElementById('fabric');
    toChange.style.display = 'none';
    var t1 = document.getElementById('t2');

    t1.style.color = '#9b9b9b';
    t1.style.fontSize = '0.8rem';

    var t3 = document.getElementById('t3');
    t3.style = styles.darkText;

    toChange = document.getElementById('bottombars');
    toChange.style.display = 'block';
  };

  const previousPage = () => {
    page.current = 1;
    dispatchDetails();
    var toChange = document.getElementById('fabric');
    toChange.style.display = 'none';
    var t2 = document.getElementById('t2');

    t2.style.color = '#9b9b9b';
    t2.style.fontSize = '0.8rem';

    var t1 = document.getElementById('t1');
    t1.style = styles.darkText;

    toChange = document.getElementById('details');
    toChange.style.display = 'block';
  };

  const handleRadio = (e) => {
    let temp = e;
    console.log(temp);
    temp = temp.split('||');
    priceGroup = temp[3];
    console.log('PRice Group is ' + priceGroup);

    setRenderFabric(temp[0] + ' ' + temp[1]);
    temp = temp[0] + '||' + temp[1] + '||' + temp[2];
    setSelectedRadio(temp);
    getPrice();
  };

  const getPrice = () => {
    console.log('Getting price for page ', page.current);
    if (page.current != 2) return;
    let width = optionInfo.widthWholeFrac
      ? parseFloat(optionInfo.widthWhole) +
        parseFloat(optionInfo.widthWholeFrac)
      : optionInfo.widthWhole;
    let length = optionInfo.lengthWholeFrac
      ? parseFloat(optionInfo.lengthWhole) +
        parseFloat(optionInfo.lengthWholeFrac)
      : optionInfo.lengthWhole;

    if (length % 1 !== 0) {
      length = eval(length);
    }
    if (width % 1 !== 0) {
      width = eval(width);
    }

    let shade = productInfo.Type + ' Shade';
    let drive = productInfo.Operation;
    let valance = productInfo.Valance;
    let channel = productInfo.L_Channel;
    let quantity = optionInfo.quantity;
    console.log('Confirming price group is ' + priceGroup);

    calculateTotalPrice(
      length,
      width,
      shade,
      priceGroup,
      drive,
      valance,
      channel,
    ).then((tp) => {
      if (tp.hasOwnProperty('type')) {
        console.log(tp);
        return;
      }
      console.log(quantity);
      let temp = quantity * tp.totalPrice;

      temp = Math.round(temp * 100) / 100;
      totalPrice = temp;
      // if (fabricPrice.current && productInfo.Type != 'Sheer')
      //   temp += `${fabricPrice.current}`;
      // console.log('Total price for drive', drive, ' ' + tp.drivePrice);
      // if (drive == 'Motor') temp += `${tp.drivePrice}`;
      // if (drive == 'Zero Gravity')
      //   temp += `${tp.drivePrice}`;
      // if (tp.valancePrice) temp += `${tp.valancePrice} `;
      // if (channel != 'None')
      //   temp += `${tp.channelPrice} `;
      setTotalPrice(temp);
      console.log(totalPrice);
    });
  };

  useEffect(() => {
    if (switchPage && page.current == 2) {
      console.log('Switching page to ', page.current);

      var t2 = document.getElementById('t2');
      t2.style = styles.darkText;

      var toHide = document.getElementById('t' + switchPage);

      toHide.style.color = '#9b9b9b';
      toHide.style.fontSize = '0.8rem';
      setSwitchPage(0);
    }
  }, [switchPage]);

  const styles = {
    fabricTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '1rem 0',
    },
    fabricContainer: {
      width: '100%',
      height: '100%',
    },
    items: {
      margin: '1rem',
      display: 'inline-flex',
    },
    image: {
      width: '150px',
      height: '150px',
      objectFit: 'cover',
    },
    divider: {
      width: '100%',
      margin: '1rem auto',
    },
    leftHeader: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    atcButton: {
      // background: '#ebebeb',
      minWidth: '10rem',
      marginRight: '1rem',
      marginTop: '1rem',
    },
    fields: {
      width: '100%',
      margin: '.2rem',
      padding: '.2rem',
    },
  };

  useEffect(() => {
    if (shadeTitle.includes('Banded')) {
      if (
        productInfo.Fabric.includes('Vintage') &&
        fabricType.includes('Translucent')
      )
        fabricType = 'Translucent transparent';
      else if (
        productInfo.Program.includes('Tokyo Black Out') ||
        productInfo.Program.includes('Rome Black Out Banded') ||
        (productInfo.Type.includes('Banded') &&
          productInfo.Program.includes('Black Out Shade') &&
          fabricType == 'Blackout')
      )
        fabricType = 'Blackout transparent';
      else if (
        productInfo.Program.includes('Privacy Banded') &&
        (productInfo.Fabric == 'Terra' || productInfo.Fabric == 'Gossamer')
      )
        fabricType = 'Privacy transparent';

      productInfo.Fabric_Type = fabricType;
      console.log('Fabric Type: ' + fabricType);

      setFabricType(fabricType);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    console.log(fabricType);
    showFabricOptions();
  }, [
    shadeWidth,
    shadeLength,
    shadeWidthFrac,
    shadeLengthFrac,
    measure,
    colour,
    fabricType,
    productInfo.Operation,
    productInfo.Valance,
    productInfo.L_Channel,
    productInfo.Fabric,
    selectedRadio,
    swatchCartItems,
  ]);

  const showFabricOptions = () => {
    var width = shadeWidthFrac
      ? parseFloat(shadeWidth) + parseFloat(shadeWidthFrac)
      : shadeWidth;
    var length = shadeLengthFrac
      ? parseFloat(shadeLength) + parseFloat(shadeLengthFrac)
      : shadeLength;

    // Check if width or length is in fraction, if yes, convert to decimal
    if (width % 1 !== 0) {
      width = eval(width);
    }
    if (length % 1 !== 0) {
      length = eval(length);
    }

    getDeflection(length).then((temp) => {
      console.log(temp);

      if (temp.hasOwnProperty('type')) {
        if (invalid == false) {
          setInvalid(true);
          invalid = true;
          productInfo.invalid = true;
          dispatch(
            customizeProduct({
              productInfo: {
                ...productInfo,
              },
              optionInfo: {
                ...optionInfo,
              },
            }),
          );
        }
        return;
      } else {
        if (invalid == true) {
          console.log('Reached 3');
          setInvalid(false);
          invalid = false;
          productInfo.invalid = false;
          dispatch(
            customizeProduct({
              productInfo: {
                ...productInfo,
              },
              optionInfo: optionInfo,
            }),
          );
        }
        δ = temp;
        let newWidth = 0;
        switch (measure) {
          case 'Tight':
            newWidth = parseFloat(width) - parseFloat(0.0125);
            break;
          case 'Finish':
            newWidth = parseFloat(width);
            break;
          case 'Cloth':
            newWidth = parseFloat(width) + parseFloat(1);
            break;
          default:
            newWidth = parseFloat(width);
            break;
        }
        setMWidth(newWidth);

        mWidth = newWidth;
        productInfo.δ = temp;

        setδ(temp);

        console.log(
          'New Width ' +
            newWidth +
            ' mWidth: ' +
            mWidth +
            ' Width: ' +
            width +
            ' Length: ' +
            length +
            ' Deflection: ' +
            δ,
        );
        dispatch(
          customizeProduct({
            productInfo: {
              ...productInfo,
            },
            optionInfo: {
              ...optionInfo,
            },
          }),
        );

        // Get the fabric
        groupFabric(mWidth, shadeTitle.toLowerCase(), cancelToken).then(
          (fabrics) => {
            console.log(fabrics);

            if (fabrics.hasOwnProperty('type')) {
              console.log('End');
              if (invalid == false) {
                setInvalid(true);
                invalid = true;
                productInfo.invalid = true;
                dispatch(
                  customizeProduct({
                    productInfo: {
                      ...productInfo,
                    },
                    optionInfo: optionInfo,
                  }),
                );
              }
              setData(null);
            } else {
              if (invalid == true) {
                console.log('Reached 3');
                setInvalid(false);
                invalid = false;
                productInfo.invalid = false;
                dispatch(
                  customizeProduct({
                    productInfo: {
                      ...productInfo,
                    },
                    optionInfo: optionInfo,
                  }),
                );
              }

              // Map fabricArray
              if (
                shadeTitle.includes('Banded') &&
                (productInfo.Fabric != 'Republic' ||
                  productInfo.Fabric != 'Blackout')
              ) {
                // Remove Blackout from the list
                fabrics = fabrics.filter((fabric) => fabric != 'Blackout');
              }
              fabrics = fabrics.sort();
              console.log(fabrics);
              let ret = fabrics.map((fabric) => {
                // Make first letter capital
                fabric = fabric.charAt(0).toUpperCase() + fabric.slice(1);
                if (fabric == 'Sunscreen') fabric = 'Sun Screen';

                return (
                  <MenuItem key={fabric} value={fabric}>
                    {fabric}
                  </MenuItem>
                );
              });
              console.log(ret);
              setData(ret);
              showFilteredOptions(fabricType);
            }
          },
        );
      }
    });
  };

  const handleSample = (index, img, color, code) => {
    if (swatchCartItems.length >= 5) {
      alert("You can't add more than 5 fabrics to cart");
      return;
    }
    if (existsInCart(index, img, color, code)) {
      return;
    }
    let item = {
      shade: shadeTitle,
      name: index + ' ' + color,
      image: img,
      code: code,
      type: index,
      series: color,
    };

    dispatch(addToSwatchCart({ item }));

    setSampleToast(true);
  };

  const existsInCart = (index, img, color, code) => {
    let itemToCheck = {
      shade: shadeTitle,
      name: index + ' ' + color,
      image: img,
      code: code,
      type: index,
      series: color,
    };
    let exists = false;
    swatchCartItems.filter((item) => {
      if (
        item.item.name == itemToCheck.name &&
        item.item.shade == itemToCheck.shade &&
        item.item.code == itemToCheck.code &&
        item.item.type == itemToCheck.type &&
        item.item.series == itemToCheck.series
      ) {
        exists = true;
      }
    });
    console.log(exists);
    return exists;
  };

  const showFilteredOptions = (group) => {
    filterFabricsByGroup(
      mWidth,
      shadeTitle.toLowerCase(),
      group,
      'All',
      cancelToken,
    ).then((filters) => {
      console.log(filters);

      let width = optionInfo.widthWholeFrac
        ? parseFloat(optionInfo.widthWhole) +
          parseFloat(optionInfo.widthWholeFrac)
        : optionInfo.widthWhole;
      let length = optionInfo.lengthWholeFrac
        ? parseFloat(optionInfo.lengthWhole) +
          parseFloat(optionInfo.lengthWholeFrac)
        : optionInfo.lengthWhole;

      if (length % 1 !== 0) {
        length = eval(length);
      }
      if (width % 1 !== 0) {
        width = eval(width);
      }

      // function checkIfImageExists(url, callback) {
      //   const img = new Image();
      //   img.src = url;

      //   if (img.complete) {
      //     callback(true);
      //   } else {
      //     img.onload = () => {
      //       callback(true);
      //     };

      //     img.onerror = () => {
      //       callback(false);
      //     };
      //   }
      // }

      getPriceDifference(shadeTitle, length, width).then((prices) => {
        if (filters.length != 0 && !filters.hasOwnProperty('type')) {
          if (invalid == true) {
            setInvalid(false);
            invalid = false;
            productInfo.invalid = false;
            dispatch(
              customizeProduct({
                productInfo: {
                  ...productInfo,
                },
                optionInfo: optionInfo,
              }),
            );
          }
          for (let i = 0; i < filters.length; i++) {
            for (let j = 0; j < filters[i].series.length; j++) {
              // If series tarts with Cs, make it CS
              if (
                filters[i].series[j].charAt(0) === 'C' &&
                filters[i].series[j].charAt(1) === 's'
              )
                filters[i].series[j] = 'CS' + filters[i].series[j].substring(2);
            }

            for (let j = 0; j < filters[i].image.length; j++) {
              filters[i].image[j] = filters[i].image[j].replace(
                'image/',
                'images/',
              );
              // Check if jpg does not exist in the image name
              if (filters[i].image[j].indexOf('.jpg') === -1)
                filters[i].image[j] += filters[i].colour[j] + '.jpg';
              if (filters[i].colour[j] == 'Berkeley')
                filters[i].colour[j] = 'Berkley';
            }
          }
          console.log(filters);

          let cols = filters.map((index) => {
            return (
              <MenuItem key={index.colourFamily} value={index.colourFamily}>
                {index.colourFamily}
              </MenuItem>
            );
          });
          setData1(cols);

          // If colour != "All" then filter the filters array to only show the selected colour
          if (colour != 'All') {
            filters = filters.filter((index, i) => {
              if (index.colourFamily == colour) {
                return index;
              }
            });
          }

          // If colour != "All" then filter the filters array to only show the selected colour
          // if (colour != 'All') {
          //   filters = filters.filter((index) => {
          //     if (index.colourFamily == colour) {
          //       for (let i = 0; i < index.colour.length; i++) {
          //         if (index.colourFamily == colour) {
          //           index.image = [index.image[i]];
          //           index.colour = [index.colour[i]];
          //           index.code = [index.code[i]];
          //           index.series = [index.series[i]];
          //           return index;
          //         }
          //       }
          //     }
          //   });
          // }

          let newFilters = [];
          filters.map((index) => {
            index.priceGroup.map((priceGroup, i) => {
              newFilters.push({
                colourFamily: [index.colourFamily],
                priceGroup: priceGroup,
                image: [index.image[i]],
                colour: [index.colour[i]],
                code: [index.code[i]],
                series: [index.series[i]],
              });
            });
          });

          filters = newFilters;

          // Sort the filters array by priceGroup
          filters = filters.sort((a, b) => {
            return a.priceGroup - b.priceGroup;
          });

          console.log(filters);

          // Group all the indexes with the same priceGroup
          filters = filters.reduce((r, a) => {
            r[a.priceGroup] = [...(r[a.priceGroup] || []), a];
            return r;
          }, {});

          // Convert the array to an object
          filters = Object.values(filters);

          // Now sort them alphabetically by series
          for (let i = 0; i < Object.keys(filters).length; i++) {
            filters[i] = filters[i].sort((a, b) => {
              return a.series[0].localeCompare(b.series[0]);
            });
          }

          console.log(filters);

          // Loop in each index, combine all the colourFamily arrays into one, other arrays as well
          for (let i = 0; i < Object.keys(filters).length; i++) {
            let colourFamily = [];
            let image = [];
            let colour = [];
            let code = [];
            let series = [];
            let priceGroup = [];
            for (let j = 0; j < filters[i].length; j++) {
              colourFamily = colourFamily.concat(filters[i][j].colourFamily);
              image = image.concat(filters[i][j].image);
              colour = colour.concat(filters[i][j].colour);
              code = code.concat(filters[i][j].code);
              series = series.concat(filters[i][j].series);
              priceGroup = filters[i][j].priceGroup;
            }
            filters[i] = {
              colourFamily: colourFamily,
              priceGroup: priceGroup,
              image: image,
              colour: colour,
              code: code,
              series: series,
            };
          }

          // Make sure priceGroup is not an array, if it is, make it the first element
          for (let i = 0; i < Object.keys(filters).length; i++) {
            if (Array.isArray(filters[i].priceGroup))
              filters[i].priceGroup = filters[i].priceGroup[0];
          }

          // Convert all the objects inside the filters array to arrays
          filters = Object.values(filters);

          console.log(filters);

          let ret = filters.map((index, i) => {
            return (
              <>
                <Grid item xs={12}>
                  <hr className='divider' />
                  <Typography variant='h6' className='sg-box-title'>
                    Fabric Collection {index.priceGroup} |{' '}
                    {index.priceGroup == 0
                      ? '(Free)'
                      : `+$${prices.priceGroupDifference[index.priceGroup]}`}
                  </Typography>
                </Grid>
                {index.image.map((image, i) => {
                  // Check if the file exists by merging /images/render/{index}/fabric.png
                  // let img = 'http://localhost:3000/images/render/' + index.series + ' '+ index.colour[i] + '/fabric.png';
                  // checkIfImageExists(img, (exists) => {
                  //   if (!exists) {console.log(index.series + ' '+ index.colour[i]);
                  //     }
                  //     else {
                  //      console.log('exists');
                  //     }
                  // });

                  return (
                    <>
                      <div
                        style={{ width: '15%', margin: '5px' }}
                        className='fabricsList'>
                        <div
                          className={
                            selectedRadio ===
                            index.series[i] +
                              '||' +
                              index.colour[i] +
                              '||' +
                              index.code[i]
                              ? ((fabricPrice.current =
                                  prices.priceGroupDifference[
                                    index.priceGroup
                                  ]),
                                'colorWrap')
                              : 'colorWrapHover'
                          }
                          onClick={() => {
                            fabricPrice.current =
                              prices.priceGroupDifference[index.priceGroup];
                            handleRadio(
                              index.series[i] +
                                '||' +
                                index.colour[i] +
                                '||' +
                                index.code[i] +
                                '||' +
                                index.priceGroup,
                            );
                          }}>
                          <div
                            className='colorSwatch'
                            style={{
                              backgroundImage: `url(${encodeURI(
                                image.toLowerCase(),
                              )})`,
                            }}>
                            {selectedRadio ===
                            index.series[i] +
                              '||' +
                              index.colour[i] +
                              '||' +
                              index.code[i] ? (
                              <div className='customCheckbox'>
                                <span
                                  style={{
                                    border: '0px solid #ccc',
                                    backgroundColor: 'transparent',
                                  }}
                                  className='checkbox selected'>
                                  <span
                                    className='checkmark'
                                    style={{
                                      width: 15,
                                      height: 15,
                                      backgroundSize: '9px 9px',
                                    }}></span>
                                </span>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>

                          <div className='colorName'>
                            {index.series[i]}-{index.colour[i]}
                          </div>
                        </div>
                        <a
                          className='addSample'
                          onClick={() => {
                            handleSample(
                              index.series[i],
                              image,
                              index.colour[i],
                              index.code[i],
                            );
                          }}>
                          {existsInCart(
                            index.series[i],
                            image,
                            index.colour[i],
                            index.code[i],
                          )
                            ? 'Added'
                            : 'Free Sample'}
                        </a>
                      </div>
                    </>
                  );
                })}{' '}
              </>
            );
          });

          setData2(ret);
          setLoading(false);
        } else {
          if (invalid == false) {
            setInvalid(true);
            invalid = true;
            productInfo.invalid = true;
            dispatch(
              customizeProduct({
                productInfo: {
                  ...productInfo,
                },
                optionInfo: optionInfo,
              }),
            );
          }
          setData2(null);
        }
      });
    });
  };

  return (
    <>
      <Grid
        id='fabric'
        style={page.current == 2 ? { display: 'block' } : { display: 'none' }}
        spacing={1}
        onHide={switchPage && dispatchDetails()}>
        <p style={styles.leftHeader}>2. Select Fabric</p>
        <hr className='divider' />
        <LoadingOverlay
          active={loading}
          spinner
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'rgba(255, 255, 255, 0.5)',
            }),
            spinner: (base) => ({
              ...base,
              width: '100px',
              '& svg circle': {
                stroke: '#ef4036',
              },
            }),
          }}>
          <Grid>
            {invalid ? null : (
              <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'>
                <Grid item xs={6}>
                  <FormControl variant='outlined' style={styles.fields}>
                    <InputLabel size='small' for='Fabric Group'>
                      Fabric Group
                    </InputLabel>
                    <Select
                      label='Fabric Group'
                      size='small'
                      id='fabric-type'
                      value={fabricType}
                      name='fabricType'
                      onChange={(e) => {
                        handleFabricType(e);
                      }}>
                      <MenuItem value='All'>All</MenuItem>
                      {data}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  {/* Add dropdown with 8 selections*/}
                  <FormControl variant='outlined' style={styles.fields}>
                    <InputLabel size='small' for='Fabric Colour'>
                      Fabric Colour
                    </InputLabel>
                    <Select
                      label='Fabric Colour'
                      size='small'
                      id='light-penetration'
                      value={colour}
                      name='colour'
                      onChange={(e) => {
                        handleColour(e);
                      }}>
                      <MenuItem value='All'>All</MenuItem>
                      {data1}
                    </Select>
                  </FormControl>
                </Grid>

                <Snackbar
                  open={sampleToast}
                  autoHideDuration={2000}
                  onClose={handleClose}>
                  <Alert severity='success'>
                    <AlertTitle>
                      A sample swatch has been added to your cart!
                    </AlertTitle>
                  </Alert>
                </Snackbar>
                {/* A list of images and label under them in a row */}

                {/* <Typography variant='h6'>
                  Fabrics (Listed By Series):
                </Typography> */}

                <Grid
                  container
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='center'>
                  {data2}{' '}
                </Grid>
              </Grid>
            )}{' '}
          </Grid>
          <Grid container direction='row'>
            <Grid item xs={6}>
              <Button id='back' onClick={previousPage} style={styles.atcButton}>
                Back
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button  className='redButton'
              style={{
                margin: '.5rem 0rem',
                width: '10rem',
                backgroundColor: '#ef4036',
                color: '#fff',
                marginTop: '1rem',
              }} onClick={forwardPage}>
                Next
              </Button>
            </Grid>
          </Grid>
        </LoadingOverlay>
      </Grid>
    </>
  );
};

export default CustomizeFabric;
