/** @format */

import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Container, Link } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import OCSApi from '../OCSApi';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBlinds, getRecommendedPick } from '../actions/productActions';
import Message from './Message';
import { Loader } from './Loader';
import { useHistory } from 'react-router-dom';

const styles = {
  heavyText: {
    fontWeight: 400,
  },
  lightText: {
    fontWeight: 300,
  },
  divider: {
    width: '100%',
    // zIndex: 1,
    borderTop: '1px solid black',
    margin: '2rem auto',
    textAlign: 'center',
    // marginLeft: 0,
  },
  widthCorrector: {
    // margin: '1rem 0',
    // overflow: 'None'
    // maxWidth: '100%',
  },
  image: {
    width: '100%',
  },
  imageContainer: {
    // width: '100%',
    // margin: 'auto',
  },
  spacerBot: {
    marginTop: '15px',
    textAlign: 'center',
  },
  dividerSmall: {
    width: '80%',

    borderTop: '1px solid',
    borderColor: 'rgb(96, 96, 96, 0.4)',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  colorIcon: {
    borderRadius: '50%',
    border: '1px solid black',
    height: '4rem',
    width: '4rem',
    minWidth: '2rem',
    margin: '0.25rem',
  },
  programDesc: {
    fontSize: '1rem',
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    lineHeight: '1.5',
    // fontStyle: 'Italic',
    margin: '1rem 0',
  },
  msrpTag: {
    fontFamily: 'Source Sans Pro',
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1.25rem',
    textTransform: 'Capitalize',
    color: 'rgb(96, 96, 96)',
    marginTop: '1rem',
  },
  ourTag: {
    fontFamily: 'Source Sans Pro',
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1.25rem',
    textTransform: 'Capitalize',
    color: '#E61610',
    marginBottom: '1rem',
  },
  viewButton: {
    // background: '#ebebeb',
    minWidth: '12rem',
    margin: '1.2rem',
  },
  colorPanelHeight: {
    minHeight: '2rem',
    margin: '1rem auto',
  },
  recoDesc: {
    margin: '1rem 0',
    fontStyle: 'italic',
    color: 'dimGrey',
  },
};

const Recommended = ({ program, color }) => {
  const dispatch = useDispatch();

  const recommended = useSelector((state) => state.recommended);
  const { recommendedProduct, error, loading, message } = recommended;

  const { product } = useSelector((state) => state.product);

  const history = useHistory();

  let goToRecomended = ({ ev }) => {
  
    let nameArr = recommended.recommendedProduct[0].Program.split(' ');
    let shadeClass = '';
    for (let i = 0; i < nameArr.length; i++) {
      if (i != 0) {
        shadeClass += '%20';
      }

      shadeClass += nameArr[i];
      if (i == 0 && nameArr[0][1] == '%') {
        shadeClass += '25';
      }
    }
    history.push(
      '/products/name/' +
        shadeClass +
        '?color=' +
        recommended.recommendedProduct[0].SG_Color,
    );
  };

  return (
    <Container>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <div>
          {/* <Typography variant='h4' style={styles.spacerTop}>
            {program}
          </Typography> */}

          {/* <hr style={styles.divider} /> */}

          <Typography variant='h5' style={styles.spacerBot}>
            Recommended Pick
          </Typography>
          <hr style={styles.divider} />

          <Grid
            container
            direction='col'
            justifyContent='center'
            // alignItems="center"
            alignItems='flex-start'
            style={styles.widthCorrector}
            spacing={6}>
            {/* Left */}
            <Grid
              item
              xs={12}
              sm={6}
              justifyContent='center'
              // alignItems="center"
              alignItems='center'>
              <div style={styles.imageContainer}>
                <img
                  src={`/images/programs/${recommendedProduct[0]?.Program}/render/${recommendedProduct[0]?.SG_Color}.jpg`
                    .replaceAll('%', '')
                    .replaceAll(' ', '_')
                    .toLowerCase()}
                  style={styles.image}
                  alt='Window Render'
                />
              </div>
            </Grid>
            {/* Right */}
            <Grid item xs={12} sm={6}>
              <Typography variant='h5'>
                {recommendedProduct[0]?.Program
                  ? recommendedProduct[0]?.Program
                  : 'Missing Title'}
              </Typography>

              <Typography style={styles.programDesc}>
                {recommendedProduct[0]?.Description
                  ? recommendedProduct[0]?.Description
                  : 'Missing Description'}
              </Typography>
              {/* //Recommended */}
              {/* {recommendedProduct[0]?.Recommended_Desc === '' ? (
                <></>
              ) : (
                <>
                  <Grid
                    // container
                    direction='row'
                    // justifyContent="space-evenly"
                    // alignItems='center'
                    style={styles.colorPanelHeight}>
                    <img
                      src={`/images/recommended/${recommendedProduct[0]?.Recommended_photo}.jpg`}
                      style={styles.colorIcon}
                      alt='Person'
                    />
                    <Typography style={styles.recoDesc}>
                      {recommendedProduct[0]?.Recommended_Desc
                        ? recommendedProduct[0]?.Recommended_Desc
                        : ''}
                    </Typography>
                  </Grid>
                </>
              )} */}
              <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
                style={styles.colorPanelHeight}>
                <Button style={styles.viewButton} onClick={goToRecomended}>
                  View Shade
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <hr style={styles.divider} />
        </div>
      )}
    </Container>
  );
};

export default Recommended;
