/** @format */

import React from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { addEmailToMailChimp, apiMail } from '../actions/userActions';
import { useState } from 'react';
import '../screens/style.css';

const styles = {
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Source Sans Pro',
    fontWeight: 300,
    fontSize: '1.05rem',
    marginBottom: '1rem',
  },
  logo: {
    // width: '7.5%'
    height: '4rem',
  },

  pay: {
    // width: '18%',
    height: '2rem',
  },
  footerColumn1: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  linksColumn: {
    display: 'inline-flex',
    // flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    // width: '33%',
  },
  middleColumn: {
    display: 'flex',
    flexGrow: 6,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  // footerTitle: {
  //     fontWeight: 400,
  // },
  address: {
    textAlign: 'center',
    width: '50%',
    display: 'block',
    fontWeight: 400,
  },
  divider: {
    width: '100%',
    // zIndex: 1,
    borderTop: '1px solid black',
    // marginTop: '1rem',
    // marginBottom: '1rem',
    textAlign: 'center',
    // marginLeft: 0,
  },
  subButton: {
    // borderRadius: '15px',
    // fontSize: '0.8rem',
    background: 'transparent',
    padding: '0rem 2rem',
    margin: '0.75rem',
    fontWeight: 400,
    // minHeight: '4rem',
    textTransform: 'Capitalize',
  },
  roundButton: {
    background: 'black',
    color: 'white',
    width: '1rem',
    height: '1rem',
    margin: '0.1rem',
  },
  endLine: {
    textAlign: 'left',
  },
  footerTitle: {
    fontWeight: 525,
    // fontFamily: 'Lato',
  },
};

const Footer = () => {
  const [emailState, setEmailState] = useState();
  const [toast, setToast] = useState(false);
  const [email, setEmail] = useState();
  const [emailErrorText, setEmailErrorText] = useState();

  const closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
  };
  return (
    <div style={styles.divider}>
      <footer class='p-3 bg-light text-dark text-center position-relative '>
        <div class=' text-center'>
          <div class='row foot'>
            <div class='col'>
              <div style={styles.linksColumn} className='footPadding'>
                <span class='lead fw-normal'>Main Menu</span>
                <Link to='/'>
                  <Typography variant='body1 fw-light' color='textSecondary'>
                    Home
                  </Typography>
                </Link>
                <Link to='/aboutUs'>
                  <Typography variant='body1 fw-light' color='textSecondary'>
                    About
                  </Typography>
                </Link>
                <Link to={'/products/all'}>
                  <Typography variant='body1 fw-light' color='textSecondary'>
                    All Shopping
                  </Typography>
                </Link>
                <Link to='/wallpaperinit'>
                  <Typography variant='body1 fw-light' color='textSecondary'>
                    Wallpaper
                  </Typography>
                </Link>
                <Link to='/termsandconditions'>
                  <Typography variant='body1 fw-light' color='textSecondary'>
                    Terms And Conditions
                  </Typography>
                </Link>
                <Link to='/privacypolicy'>
                  <Typography variant='body1 fw-light' color='textSecondary'>
                    Privacy Policy
                  </Typography>
                </Link>
                <Link to='/help'>
                  <Typography variant='body1 fw-light' color='textSecondary'>
                    Help
                  </Typography>
                </Link>
                <span class='lead fw-normal'>Guides</span>
                <Link to='/installation'>
                  <Typography variant='body1 fw-light' color='textSecondary'>
                    Installation
                  </Typography>
                </Link>
                <Link to='/measuring'>
                  <Typography variant='body1 fw-light' color='textSecondary'>
                    Measuring
                  </Typography>
                </Link>
                <Link to='/productselection'>
                  <Typography variant='body1 fw-light' color='textSecondary'>
                    Product Selection
                  </Typography>
                </Link>
              </div>
            </div>

            <div class='col'>
              {' '}
              <div style={styles.middleColumn} className='footPadding'>
                <div>
                  <img
                    style={styles.logo}
                    src='/images/logoDark.png'
                    alt='OCS Logo'
                  />
                  <hr style={styles.divider} />
                </div>
                <div class='lead'>
                  50 Hollinger Road <br />
                  Toronto, Ontario
                  <br />
                  M4B 3G5
                </div>
              </div>
            </div>
            <div class='col'>
              <div className='footPadding'>
                <p class='lead fw-normal' gutterBottom={true}>
                  Stay Connected
                </p>
                <Typography
                  variant='body1'
                  color='textSecondary'
                  style={styles.lightText}>
                  Sign up for the Off Cut Shades newsletter
                </Typography>
                {/* <Typography
                  variant='body2'
                  color='textSecondary'
                  style={styles.lightText}>
                  you can, of course, unsubscribe at any time
                </Typography> */}
              </div>
             
              <div id="mc_embed_signup">
              <form
                    action="https://offcutshades.us14.list-manage.com/subscribe/post?u=269c387d04f73bada3a71bcd5&id=2d9f792d6b&f_id=00fc8fe0f0"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className="validate"
                    target="_blank"
                    noValidate=""
                  >
                  <div id="mc_embed_signup_scroll">
                   
                    <div className="mc-field-group">
                     
                      <TextField
                        type="email"
                        defaultValue=""
                        label='Email Address'
                        name="EMAIL"
                        className="required email"
                        id="mce-EMAIL"
                        required={true}
                      />
                      <span id="mce-EMAIL-HELPERTEXT" className="helper_text" />
                    </div>
                    <div hidden="true">
                      <input type="hidden" name="tags" defaultValue={11238843} />
                    </div>
                    <div id="mce-responses" className="clear">
                      <div
                        className="response"
                        id="mce-error-response"
                        style={{ display: "none" }}
                      />
                      <div
                        className="response"
                        id="mce-success-response"
                        style={{ display: "none" }}
                      />
                    </div>{" "}
                    {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                    <div
                      style={{ position: "absolute", left: "-5000px" }}
                      aria-hidden="true"
                    >
                      <input
                        type="text"
                        name="b_269c387d04f73bada3a71bcd5_2d9f792d6b"
                        tabIndex={-1}
                        defaultValue=""
                      />
                    </div>
                    <div className="clear">
                      <Button
                        type="submit"
                        variant='contained'
                        style={styles.subButton}
                        defaultValue="Subscribe"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className="button"
                      >Subscribe</Button>
                    </div>
                  </div>
                </form>
                <div dangerouslySetInnerHTML={{
                  __html:
                    "<script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';}(jQuery));var $mcj = jQuery.noConflict(true);</script>"
                }}></div>
              </div>
              {/* <div className='footPadding'>
                <TextField
                  id='emailLetter'
                  label='Email Address'
                  helperText={emailErrorText}
                  error={emailErrorText}
                  onChange={(e) => {
                    let str = e.target.value;
                    if (
                      str.match(
                        '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$',
                      ) != null
                    ) {
                      setEmailErrorText('');
                      setEmail(str);
                    } else {
                      setEmailErrorText('invalid email');
                    }
                  }}
                />
                <Button
                  variant='contained'
                  style={styles.subButton}
                  onClick={() => {
                    if (email.match('') != null) {
                      addEmailToMailChimp(email);
                      document.getElementById('emailLetter').value = '';
                      setToast(true);
                    }
                    apiMail(
                      email,
                      `Welcome`,
                      `<td class="esd-stripe" align="center">
                      <table bgcolor="#ffffff" class="es-content-body" align="center" cellpadding="0" cellspacing="0" width="600">
                          <tbody>
                              <tr>
                                  <td class="esd-structure es-p15t es-p20r es-p20l" align="left">
                                      <table cellpadding="0" cellspacing="0" width="100%">
                                          <tbody>
                                              <tr>
                                                  <td width="560" class="es-m-p0r esd-container-frame" valign="top" align="center" esd-custom-block-id="744422">
                                                      <table cellpadding="0" cellspacing="0" width="100%">
                                                          <tbody>
                                                              <tr>
                                                                  <td align="center" class="esd-block-image" style="font-size: 0px;"><a target="_blank" href="https://offcutshades.com"><img src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/offcutshadeslogo.png" alt="Logo" style="display: block;" width="200" title="Logo"></a></td>
                                                              </tr>
                                                              <tr>
                                                                  <td align="center" class="esd-block-text es-p10b">
                                                                      <p>Shading Solutions Simplified</p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td width="560" class="esd-container-frame" align="center" valign="top">
                                                      <table cellpadding="0" cellspacing="0" width="100%">
                                                          <tbody>
                                                              <tr>
                                                                  <td align="center" class="esd-block-image" style="font-size: 0px;"><a target="_blank"><img class="adapt-img" src="https://vdpuhr.stripocdn.email/content/guids/CABINET_0a7539c4f46831deabafddc082c2c916/images/black_gold_gradient_we_are_open_instagram_story_4.gif" alt style="display: block;" width="560"></a></td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </td>
                              </tr>
                              <tr>
                                  <td class="esd-structure es-p20t es-p10b es-p20r es-p20l" align="left">
                                      <table cellpadding="0" cellspacing="0" width="100%">
                                          <tbody>
                                              <tr>
                                                  <td width="560" class="esd-container-frame" align="center" valign="top">
                                                      <table cellpadding="0" cellspacing="0" width="100%">
                                                          <tbody>
                                                              <tr>
                                                                <td align="center" class="esd-block-text es-p10b es-m-txt-l">
                                                                  <h3 style="font-size: 20px; font-family: 'open sans', 'helvetica neue', helvetica, arial, sans-serif; color: #444444;">Thanks for subscribing to our community!</h3>
                                                                      <p style="font-size: 14px; font-family: 'open sans', 'helvetica neue', helvetica, arial, sans-serif; color: #444444;"><br />
                                                                        We're excited to have you as part of our growing group of DIY, interior design and art enthusiasts. <br />
                                                                        <br />
                                                                        As a member of our group, you will receive ideas for your interior design project, information about our in-house events, exclusive gifts, art-related content and details about our products. <br />
                                                                        <br />
                                                                      Enjoy our curated selection of high-quality window shades 100% made in Canada, and don't hesitate to contact us if you have any questions. </p></td>
                                                              </tr>
                                                              <tr>
                                                                  <td align="center" class="esd-block-image" style="font-size: 0px;">
                                                                      <a target="_blank">
                                                                          <img class="adapt-img" src="https://vdpuhr.stripocdn.email/content/guids/CABINET_0a7539c4f46831deabafddc082c2c916/images/zachsignature.jpg" alt style="display: block;" width="200">
                                                                      </a>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </td>
                              </tr>
                          </tbody>
                     <td class="esd-stripe" align="center" esd-custom-block-id="746268" bgcolor="#efefef" style="background-color: #efefef;">
                      <table class="es-footer-body" align="center" cellpadding="0" cellspacing="0" width="600" style="background-color: transparent;">
                          <tbody>
                              <tr>
                                  <td class="esd-structure es-p20" align="left" esd-custom-block-id="744444">
                                      <!--[if mso]><table width="560" cellpadding="0" cellspacing="0"><tr><td width="145" valign="top"><![endif]-->
                                      <table cellpadding="0" cellspacing="0" class="es-left" align="left">
                                          <tbody>
                                              <tr>
                                                  <td width="125" class="es-m-p0r es-m-p20b esd-container-frame" align="center">
                                                      <table cellpadding="0" cellspacing="0" width="100%">
                                                          <tbody>
                                                              <tr>
                                                                  <td align="center" class="esd-block-image" style="font-size: 0px;"><a target="_blank"><img class="adapt-img" src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/madetolasticon.png" alt style="display: block;" width="30"></a></td>
                                                              </tr>
                                                              <tr>
                                                                  <td align="center" class="esd-block-text es-p5t">
                                                                      <p>Made to last</p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                  </td>
                                                  <td class="es-hidden" width="20"></td>
                                              </tr>
                                          </tbody>
                                      </table>
                                      <!--[if mso]></td><td width="145" valign="top"><![endif]-->
                                      <table cellpadding="0" cellspacing="0" class="es-left" align="left">
                                          <tbody>
                                              <tr>
                                                  <td width="125" class="es-m-p20b esd-container-frame" align="center">
                                                      <table cellpadding="0" cellspacing="0" width="100%">
                                                          <tbody>
                                                              <tr>
                                                                  <td align="center" class="esd-block-image" style="font-size: 0px;"><a target="_blank"><img class="adapt-img" src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/readytoshipicon.png" alt style="display: block;" width="30"></a></td>
                                                              </tr>
                                                              <tr>
                                                                  <td align="center" class="esd-block-text es-p5t">
                                                                      <p>Ready to ship in 2 days</p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                  </td>
                                                  <td class="es-hidden" width="20"></td>
                                              </tr>
                                          </tbody>
                                      </table>
                                      <!--[if mso]></td><td width="125" valign="top"><![endif]-->
                                      <table cellpadding="0" cellspacing="0" class="es-left" align="left">
                                          <tbody>
                                              <tr>
                                                  <td width="125" class="es-m-p20b esd-container-frame" align="center">
                                                      <table cellpadding="0" cellspacing="0" width="100%">
                                                          <tbody>
                                                              <tr>
                                                                  <td align="center" class="esd-block-image" style="font-size: 0px;"><a target="_blank"><img class="adapt-img" src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/guaranteeiconorange.png" alt style="display: block;" width="30"></a></td>
                                                              </tr>
                                                              <tr>
                                                                  <td align="center" class="esd-block-text es-p5t">
                                                                      <p>Guaranteed Fit</p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                      <!--[if mso]></td><td width="20"></td><td width="125" valign="top"><![endif]-->
                                      <table cellpadding="0" cellspacing="0" class="es-right" align="right">
                                          <tbody>
                                              <tr>
                                                  <td width="125" align="center" class="esd-container-frame">
                                                      <table cellpadding="0" cellspacing="0" width="100%">
                                                          <tbody>
                                                              <tr>
                                                                  <td align="center" class="esd-block-image" style="font-size: 0px;"><a target="_blank"><img class="adapt-img" src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/pricerighticon.png" alt style="display: block;" width="30"></a></td>
                                                              </tr>
                                                              <tr>
                                                                  <td align="center" class="esd-block-text es-p5t">
                                                                      <p>Price Right every day</p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                      <!--[if mso]></td></tr></table><![endif]-->
                                  </td>
                              </tr>
                              <tr>
                                  <td class="esd-structure es-p20t es-p20b es-p20r es-p20l" align="left">
                                      <table cellpadding="0" cellspacing="0" width="100%">
                                          <tbody>
                                              <tr>
                                                  <td width="560" class="esd-container-frame" align="left">
                                                      <table cellpadding="0" cellspacing="0" width="100%">
                                                          <tbody>
                                                              <tr>
                                                                  <td align="left" class="esd-block-text">
                                                                      <p style="text-align: center;"><strong>Follow Us</strong></p>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td align="center" class="esd-block-social es-p15t es-p15b" style="font-size:0">
                                                                      <table cellpadding="0" cellspacing="0" class="es-table-not-adapt es-social">
                                                                          <tbody>
                                                                              <tr>
                                                                                  <td align="center" valign="top" class="es-p40r"><a target="_blank" href="https://www.facebook.com/OffCutShadesOnline/"><img title="Facebook" src="https://vdpuhr.stripocdn.email/content/assets/img/social-icons/logo-black/facebook-logo-black.png" alt="Fb" width="32"></a></td>
                                                                                  <td align="center" valign="top" class="es-p40r"><a target="_blank" href="https://www.instagram.com/offcutshades/"><img title="Instagram" src="https://vdpuhr.stripocdn.email/content/assets/img/social-icons/logo-black/instagram-logo-black.png" alt="Inst" width="32"></a></td>
                                                                                  <td align="center" valign="top" esd-tmp-icon-type="pinterest"><a target="_blank" href="https://www.pinterest.ca/offcutshades/"><img title="Pinterest" src="https://vdpuhr.stripocdn.email/content/assets/img/social-icons/logo-black/pinterest-logo-black.png" alt="P" width="32"></a></td>
                                                                              </tr>
                                                                          </tbody>
                                                                      </table>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td align="center" class="esd-block-text es-p35b">
                                                                      <p>Off Cut Shades&nbsp;</p>
                                                                      <p>50 Hollinger road, Toronto, ON ·<a target="_blank" href="tel:+14162856001"> 416.285.6001</a><br>You are receiving this email because&nbsp;you have subscribed to&nbsp;Off Cut Shades email list.&nbsp;</p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </td>
                    </table>
                  
                    </td>	
                  `,
                    );
                  }}>
                  Subscribe
                </Button>
              </div> */}
              <img
                className='m-4'
                style={styles.pay}
                src='https://www.paypalobjects.com/webstatic/en_US/i/buttons/cc-badges-ppmcvdam.png'
                alt='Buy now with PayPal'
              />
            </div>
          </div>
        </div>
        <p class='lead'>
          All prices are in CAD. &copy;2023 Off Cut Shades.
        </p>
        <a href='#' class='position-absolute bottom-0 end-0 p-5'>
          <i class='bi bi-arrow-up-circle h1'></i>
        </a>
      </footer>
      <Snackbar autoHideDuration={6000} open={toast} close={closeToast}>
        <Alert severity='success' onClose={closeToast}>
          <AlertTitle>Subscribed</AlertTitle>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Footer;
