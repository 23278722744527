/** @format */

//stores photos uploaded by user
import Dexie from 'dexie';
import { useLiveQuery } from 'dexie-react-hooks';

export var dbver = 1;
export var db;

export function DBStart(doc) {
  db = new Dexie(doc);
  dbver = db.verno;
  db.version(++dbver).stores({ usrImages: '++id,used,image' });
}

export function DBReset(doc) {
  db = new Dexie(doc);
  db.version(++dbver).stores({ usrImages: '++id,used,image' });
  var blankFile = new File([], 'blank.png', {
    type: 'image/png',
  });
}

export const addImageToDb = async (image) => {
  await db.usrImages.add({
    used: true,
    image,
  });
};

export const removeImageFromDb = async (id) => {
  await db.usrImages.update(id, { used: false });
};

export const setImageInDb = async (id, usedIn, imageIn) => {
  await db.usrImages.update(id, { used: usedIn, image: imageIn });
};

export const storeDB = async (stamp, readDB) => {
  let imgArr = readDB;
  var StoreDB = new Dexie(stamp);
  var dbverS = 5;
  StoreDB.version(++dbverS).stores({ usrImages: '++id,used,image' });

  for (let i = 0; i < imgArr.length; i++) {
    await StoreDB.usrImages.add({
      used: true,
      image: imgArr[i].image,
    });
  }
};

export const RetrieveDB = (stamp) => {
  var StoreDB = new Dexie(stamp);
  var dbverS = 10;
  StoreDB.version(++dbverS).stores({ usrImages: '++id,used,image' });
  var ret = useLiveQuery(() => StoreDB.usrImages.toArray(), []);
  return ret;
};
