/** @format */

import React, { useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Typography,
  Snackbar,
} from '@material-ui/core';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestRecovery } from '../actions/userActions';
import Message from '../components/Message';
import { Loader } from '../components/Loader';
import { Alert, AlertTitle } from '@material-ui/lab';
import Menu3 from '../components/Menu3';

const styles = {
  heavyText: {
    fontWeight: 400,
    textAlign: 'center',
  },
  divider: {
    width: '100%',
    borderTop: '1px solid black',
    marginTop: '1rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  leftHeader: {
    color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '1.5rem',
    textAlign: 'left',
    margin: '1rem 0',
  },
  button: {
    border: '1px solid black',
    background: 'transparent',
    color: 'black',
    backgroundColor: '#EEEBDD',
    textTransform: 'capitalize',
    minWidth: '10rem',
    hover: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    width: '20rem',
  },
};

const RecoveryScreen = ({ location, history }) => {
  const [email, setEmail] = useState('');
  const [toast, setToast] = useState();

  const closeToast = () => {
    setToast(false);
  };

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const redirect = location.search ? location.search.split('=')[1] : '/';

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
  }, [history, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    //dispatch(login(email, password));
    setToast(true);
    requestRecovery(email);
  };

  return (
    <>
      <Menu3 />
      <Container>
        {loading && <Loader />}
        <p style={styles.leftHeader}> Account Recovery</p>
        <hr style={styles.divider} />
        {error && <Message variant='danger'>{error}</Message>}

        <form onSubmit={submitHandler}>
          <Grid style={{ width: '20rem' }} container direction='column'>
            <Typography>Enter email associated with your account</Typography>
            <TextField
              style={{ marginTop: '1.5rem', width: '20rem' }}
              size='small'
              variant='outlined'
              id='outlined-multiline-flexible'
              label='Email'
              required
              value={email}
              type='email'
              onChange={(e) => setEmail(e.target.value)}
            />

            <Button
              className='redButton'
              style={{
                margin: '1rem 0rem',
                width: '10rem',
                backgroundColor: '#ef4036',
                color: '#fff',
              }}
              type='submit'
              variant='outlined'>
              Submit
            </Button>
          </Grid>
        </form>
        <p>Don't have account?</p>
        <Link to={redirect ? `/register?redirect=${redirect}` : '/register'}>
          <Button
            className='redButton'
            style={{
              margin: '1rem 0rem',
              width: '10rem',
              backgroundColor: '#ef4036',
              color: '#fff',
            }}
            variant='outlined'>
            Register
          </Button>
        </Link>
        <Snackbar autoHideDuration={6000} open={toast} close={closeToast}>
          <Alert severity='success' onClose={closeToast}>
            <AlertTitle>recovery email sent</AlertTitle>
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

export default RecoveryScreen;
