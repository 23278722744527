/** @format */

import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { English } from '../../localization/strings';
import Menu3 from '../../components/Menu3';

const styles = {
  heavyText: {
    // fontWeight: 400,
    textAlign: 'center',
  },
  subText: {
    fontWeight: 300,
    // width: '80%',
    margin: '2rem auto',
  },
  subHead: {
    fontWeight: 400,
    width: '80%',
    margin: '0rem auto',
    marginTop: '1rem',
  },
  subDesc: {
    fontWeight: 300,
    width: '80%',
    margin: '0rem auto',
  },
  listStyle: {
    listStyle: 'none',
    fontSize: '1.5rem',
    fontWeight: '300',
    padding: 0,
    margin: '1.5rem auto',
  },
  itemSpacer: {
    margin: '1.25rem auto',
  },
  divider: {
    width: '100%',
    borderTop: '1px solid black',
    marginTop: '1rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  align: {
    alignSelf: 'center',
    textAlign: 'center',
  },
};

const ShippingPolicyScreen = () => {
  const language = English;
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  let unused = window.onbeforeunload();

  return (
    <div>
     <Menu3/>
      <div class='container '>
        <div class='p-4 ' style={styles.align}>
          <img
            src='/images/newHome/Shipping-Information-80x80.png'
            class='img-fluid rounded-start'
            alt='...'
          />
        </div>
        <h4 class='my-2 text-center fw-bolder'>Shipping Information </h4>
        <div class=' p-4'>
          <p class='my-2 text-center'>
            All our products come directly from the factory located in Toronto,
            Canada. Because there are no intermediaries, we can offer you one of
            the fastest processing time for window shades in Canada. Under
            normal circumstances, your order will be ready for shipment in just
            2 business days.
          </p>
        </div>
        <p class='lead fw-bolder '>Free Shipping</p>
        <p>
          We are pleased to offer{' '}
          <span class='fw-bold '> free standard shipping</span> to most
          places in Canada. However, please note that additional shipping
          charges may apply to remote areas or for large or heavy items. You
          will be advised of any charges before we prepare your order. Sales tax
          is charged according to the province or territory to which the item is
          shipped.
        </p>
        <p>
          For international shipping, shipping costs are based on the weight of
          your order and the delivery method. To find out how much your order
          will cost, simply add the items you would like to purchase to your
          cart and proceed to the checkout page.
        </p>
        <hr style={styles.divider} />
        <p class='lead fw-bolder'>Delivery Location</p>
        <p>
          Items offered on our website are only available for delivery to
          addresses in Canada and the United States of America. For any
          shipments outside of Canada and the United States of America, please
          contact info@offcutshades.com for assistance.
        </p>

        <hr style={styles.divider} />

        <p class='lead fw-bolder'>Delivery Time</p>
        <p>
          Delivery times are estimates and commence from the date of shipping,
          rather than the date of order. Delivery times are to be used as a
          guide only and are subject to the acceptance and approval of your
          order. Please note we do not ship on Saturdays or Sundays. The date of
          delivery may vary due to carrier shipping practices, delivery
          location, method of delivery, and the items ordered. Products may also
          be delivered in separate shipments.
        </p>
        <hr style={styles.divider} />

        <p class='lead fw-bolder'>Damaged Items in Transport</p>
        <p>
          If there is any damage to the product or packaging on delivery,
          contact us at info@offcutshades.com within 7 calendar days after the
          date you receive your order. After this period, we will not replace or
          refund the damaged merchandise. When submitting your claim, a picture
          is worth a thousand words.
        </p>
        <hr style={styles.divider} />

        <p class='text-center'>
          If special delivery instructions are required or If you have any
          questions about the delivery and shipment or your order, please
          contact us at   <span class='fw-bold '> info@offcutshades.com</span>.
        </p>
      </div>
    </div>
  );
};

export default ShippingPolicyScreen;
