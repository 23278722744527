/** @format */

import { db, addImageToDb, DBReset, removeImageFromDb } from '../DexieDB';
import React, { useCallback } from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { previews as framePreviews } from './importFramePreviws';
import { patternObject } from './importPatterns';
import { TextField } from '@mui/material';
import { fontObject } from './importFonts';
import { useLiveQuery } from 'dexie-react-hooks';
import { ColorPicker, useColor } from 'react-color-palette';
import { RadioButton, RadioGroup } from 'react-radio-buttons';
import 'react-color-palette/lib/css/styles.css';
import '../../../wallpaperimg/main.css';
import { newPaperBool } from '../startWallPaper';
import {
  setWallPaperFrames,
  setWallPaperDims,
} from '../../../actions/wallpaperActions';
import { useDropzone } from 'react-dropzone';
import uploadIcon from '../../../wallpaperimg/uploadIcon.png';
import uploadIcon2 from '../../../wallpaperimg/uploadIcon2.png';
import { addToCartHandler } from '../sendWallToCart';

import { Button, Container, Grid, Typography,Tooltip } from '@material-ui/core';
import InfoIcon from '@mui/icons-material/Info'
import { useHistory } from 'react-router-dom';

const styles = {
  image: {
    width: '100%',
  },
  imageContainer: {
    width: '50%',
    marginTop:'1rem'
    // margin: 'auto',
  },
  pickerContainer: {
    width: '50%',
    // margin: 'auto',
    //background: 'blue',
    marginTop:'1rem',
    left: '0',
    position: 'relative',
  },
  leftHeader: {
    color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '1.5rem',
    // textAlign: 'left',
    //fontFamily: 'bebas neue',


    margin: '1rem 0 0 0',
  },
  smallText: {
    //color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '1rem',
    // textAlign: 'left',
    //margin: '1rem 0 0 0',
  },
  disabledText: {
    color: '#9b9b9b',
    borderBottom: '1px solid #9b9b9b;',
    fontSize: '1.1rem',
    fontFamily: 'Helvetica W01 Cn, Helvetica, Arial, sans-serif',
  },
  formControl: {
    minWidth: '12rem',
    minHeight: '2rem',
    margin: '1rem',
    border: '.05rem solid darkgrey',
  },
  heavyText: {
    fontWeight: 400,
  },
  topSpacer: {
    marginTop: '2rem',
  },
  panel: {
    background: '#e3e3e3e3',
    marginTop: '-1rem',
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
  },
  couponCode: {
    color: '#000',
    fontSize: '1.5rem',
    textAlign: 'left',
    width: '100%',
  },
  priceText: {
    fontSize: '1rem',
    margin: '.5rem 0',
  },
  priceTextSave: {
    fontSize: '1rem',
    margin: '.5rem 0',
    color: '#E61610',
  },
  priceTotal: {
    fontSize: '1.2rem',
    margin: '.5rem 0',
    borderTop: '1px solid #000;',
  },
  priceSaving: {
    fontSize: '1.2rem',
    margin: '.5rem 0',
    color: '#E61610',
  },
  atcButton: {
    background: '#ebebeb',
    minWidth: '12rem',
    margin: '1rem 1rem',
    // backgroundColor:'Red'
  },
  atcSelect: {
    background: '#red',
    borderRadius: '50px',
    minWidth: '12rem',
    margin: '1rem 1rem',
    border: '.05rem solid ',
    height: '2.5rem',
    textAlign: 'center',
    // backgroundColor:'Red'
  },
  divider: {
    width: '100%',
    margin: '2rem auto',
  },
  darkText: {
    color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '1rem',
    fontFamily: 'Helvetica W01 Cn, Helvetica, Arial, sans-serif',
  },

  headText: {
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'uppercase',
    padding: '.25rem 0',
    textAlign: 'left',
  },
  subText: {
    fontWeight: 300,
    fontSize: '.9rem',
    padding: '.25rem 0',
  },

  cartItem: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  imgContainer: {
    width: '100%',
  },
  msrpTag: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'Capitalize',
  },
  ourTag: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'Capitalize',
    color: '#E61610',
  },
  msrPrice: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '2rem',
    textTransform: 'Capitalize',
  },
  ourPrice: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '2rem',
    textTransform: 'Capitalize',
    color: '#E61610',
  },
  removeButton: {
    border: 'none',
  },
  quantityField: {
    maxWidth: '35%',
    margin: '1rem 0 0 1rem',
  },
};

export var b64Map = new Map();
export var picIDMapFinal = new Map();
var gallPicId = 0;

var earlyVals = {
  landscapePer: 25,
  tbMargin: 0,
};
export function getEarlyVals() {
  //Values
  //early vals are vals that only need to be present for random placments. after initialization they are irelevent
  return earlyVals;
}

export var landscapePer = 25;
export var tbMargin = 0;

export default function MyOptions(
  picIDMap,
  startTab,
  setPicIDMap,
  doc,
  colorFilterState,
  setColorFilterState,
  tabState,
  setTabState,
  iterationState,
  setIterationState,
  setFrameVisibilityState
) {
  const [input, setInput] = useState({
    width: 0,
    height: 0,
    frameSet: null,
    colour: '#FFFFFF',
  });
  const [backgroundWordMenuState, setBackgroundWordMenuState] =
    useState('custom');
  const [backgroundPatternState, setBackgroundPatternState] = useState('bumpy');
  const [colourState, setColourState] = useState('#f0f0e9');
  const [backPickerState, setBackPickerState] = useColor('hex', '#f0f0e9');
  const [patColourState, setPatColourState] = useState('#000000');
  const [patPickerState, setPatPickerState] = useColor('hex', '#000000');
  const [fontState, setFontState] = useState('Helvetica W01 Cn, Helvetica, Arial, sans-serif');
  const [frameState, setFrameState] = useState('rustic');
  const [patternOrTextState, setPatternOrTextState] = useState('pattern');
  const [scriptState, setScriptState] = useState('');
  const [tab0GenState,setTab0GenState] = useState(false)
  // if (!newPaperBool && tabState == 0) {
  //   setTabState(0);
  // }
  const frameArr = useSelector((state) => state.wallpaperFrame.value);
  const dispatch = useDispatch();


//width input
  function handleWidthChange(e) {
    e.preventDefault();
    let update = input;
    let newI = e.target.value;
    if (newI < 10) {
      newI = 10;
    }
    update['width'] = newI;
    setInput(update);
  }

  //height input
  function handleHeightChange(e) {
    e.preventDefault();
    let update = input;
    let newI = e.target.value;
    if (newI < 10) {
      newI = 10;
    }
    update['height'] = newI;
    setInput(update);
  }

  const oldDims = useSelector((state) => state.wallpaperDims.value);
  const oldFrames = useSelector((state) => state.wallpaperFrame.value);


  //next button
  function handleTab0(e) {
    e.preventDefault();

    let update = JSON.parse(JSON.stringify(oldDims));

    earlyVals.tbMarginT = document.getElementById('tbMarginT').value;
    earlyVals.tbMarginB = document.getElementById('tbMarginB').value;
    earlyVals.landscapePer = document.getElementById('landscapePIN').value;

    setTabState(1);
    
  }

  //generate step 1 button
  function handleTab0Gen(e) {
    e.preventDefault();

    let update = JSON.parse(JSON.stringify(oldDims));

    update['width'] = input.width;
    update['height'] = input.height;

    earlyVals.tbMarginT = document.getElementById('tbMarginT').value;
    earlyVals.tbMarginB = document.getElementById('tbMarginB').value;
    earlyVals.landscapePer = document.getElementById('landscapePIN').value;
    console.log(iterationState)
    setWallPaperDims(dispatch, update);
    setTab0GenState(true)
    setFrameVisibilityState('hidden')
    setIterationState(iterationState+1)
    //newPaperBool = true
    setFrameVisibilityState('hidden')
    console.log(iterationState)
  }

  //  //generate step 2 button
  function handleTab1(e) {
    e.preventDefault();

    let update = JSON.parse(JSON.stringify(oldDims));

    update['frameSet'] = frameState;
    setIterationState(iterationState+1)
    update['colour'] = colourState;
    setFrameVisibilityState('visible')
    setWallPaperDims(dispatch, update);
  }

  //next button on step 2-style
  function handleTab1Next(ev) {
    ev.preventDefault();
    setTabState(2);
  }

  function handleTab2Pat(e) {
    e.preventDefault();

    let update = JSON.parse(JSON.stringify(oldDims));

    update['strBack'] = '';
    update['patBack'] = backgroundPatternState;

    update['patColour'] = patColourState;

    setWallPaperDims(dispatch, update);
  }

  //next button for step 3
  function handleTab2Tex(e) {
    e.preventDefault();

    let update = JSON.parse(JSON.stringify(oldDims));

    update['strBack'] = document.getElementById('wordEntry').value;
    update['patBack'] = 'none';

    update['patColour'] = patColourState;
    update['font'] = fontState;
    setWallPaperDims(dispatch, update);
  }

  //selects thecolor for background
  function handleColorSelect(color) {
    let update = input;
    update['colour'] = color.hex;
    setColourState(color.hex);
  }

//select the patterns at step 3
  function handlePatColorSelect(color) {
    let update = input;
    update['patColour'] = color.hex;
    setPatColourState(color.hex);
  }

//write script for step 3
  function backWordChange(e) {
    e.preventDefault();
    setBackgroundWordMenuState(e.target.value);
  }

  //at step 2 changing the frame set on right side
  function framePackChange(e) {
    e.preventDefault();
    setFrameState(document.getElementById('frameSelect').value);
  }

  //reading images uploaded by user
  var getImages = useLiveQuery(() => db.usrImages.toArray(), []);
  //auto place button place images randomly
  function handleAutoPlace(ev) {
    ev.preventDefault();
    var autoMap = new Map();
    for (let i = 0; i < frameArr.length; i++) {
      autoMap.set(i, i % getImages.length);
    }
    setPicIDMap(autoMap);
  }

  //image upload read function
  var readDB = useLiveQuery(() => db.usrImages.toArray(), []);
  const history = useHistory();

  //finish button at last step
  function finishWallPaper(ev) {

    //prepares wallpaper to send to cart
    setWallPaperDims(dispatch, oldDims);

    var newFrameArr = [...oldFrames];
    let mapEntries = Array.from(picIDMap.keys());

    for (let i = 0; i < mapEntries.length; i++) {
      newFrameArr[mapEntries[i]] = {
        x: oldFrames[i]['x'],
        y: oldFrames[i]['y'],
        land: oldFrames[i]['land'],
        size: oldFrames[i]['size'],
        frame: 0, //never actually ended up needing this whoops
        id: oldFrames[i]['id'],
        picture: picIDMap.get(i),
      };
    }


    setWallPaperFrames(dispatch, newFrameArr);

    var myDate = new Date();
    const myStamp = myDate.getTime();

    var cartWall = {
      dims: oldDims,
      frames: newFrameArr,
      indexDB: myStamp,
      //numberOfImg:
    };
    ev.preventDefault();
    //add all info into cart
    addToCartHandler(dispatch, cartWall, myStamp, readDB);
    history.push('/cart');
  }

  var tabStyle = {
    height: '80vh',
    position: 'relative',
    left: 0,
    top: '0vh',
    width: '30vw',
    backgroundColor: 'white',
    textAlign: 'center',
    overflowY: 'auto',
    overflowX: 'auto',
    minHeight: '640px',
    minWidth: '450px',
  };
  var tabArr = [];
  tabArr.push(
    <form onSubmit={() => {}} style={tabStyle} key={'0'}>
      <Grid item sm={12} md={12}>
        <p style={styles.leftHeader}>Step 1</p>
        <p style={styles.smallText}>Enter the size of the area you want to cover in inches</p>
        <hr style={styles.divider} />
      </Grid>
      <Grid item sm={12} md={12}>
        <p style={styles.smallText}>
          {(iterationState != 0) ? "Altering this page will erase any previous progress":""}
        </p>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'>
          <Grid item xs={6}>
            <Typography variant='h6' color='textSecondary'>
              Width (Inches)*
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'>
              <input
                type='number'
                name='widthIN'
                id='widthIN'
                min='50'
                onChange={handleWidthChange}
                style={styles.formControl}></input>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'>
          <Grid item xs={6}>
            <Typography variant='h6' color='textSecondary'>
              Height (Inches)*
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'>
              <input
                type='number'
                name='heightIN'
                id='heightIN'
                min='50'
                onChange={handleHeightChange}
                style={styles.formControl}></input>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'>
          <Grid item xs={6}>
          <Typography variant='h6' color='textSecondary' style={styles.smallText}>
          % of frame in portrait vs. landscape
              <Tooltip
                        title={
                          <>
                            <span style={styles.spanInfo}>
                            frames are generated as to be as close as possible to this value while still filling your wall. (optional)
                            </span>
                            <br></br>

                          </>
                        }
                        color='disabled'>
                        <InfoIcon />
                      </Tooltip>
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'>
              <input
                type='number'
                id='landscapePIN'
                min='10'
                max='90'
                style={styles.formControl}></input>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'>
          <Grid item xs={6}>
            <Typography variant='h6' color='textSecondary' style={styles.smallText}>
              Top margin (Inches)
              <Tooltip
                        title={
                          <>
                            <span style={styles.spanInfo}>
                            To cover your whole wall but to leave space at the top and bottom where it may be difficult to see your photos, but keep a congruent complete look. (optional)
                            </span>
                            <br></br>

                          </>
                        }
                        color='disabled'>
                        <InfoIcon />
                      </Tooltip>
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'>
              <input
                type='number'
                id='tbMarginT'
                min='0'
                max='100'
                style={styles.formControl}></input>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'>
          <Grid item xs={6}>
            
            <Typography variant='h6' color='textSecondary' style={styles.smallText}>
              Bottom margin (Inches)
              <Tooltip
                        title={
                          <>
                            <span style={styles.spanInfo}>
                            To cover your whole wall but to leave space at the top and bottom where it may be difficult to see your photos, but keep a congruent complete look. (optional)
                            </span>
                            <br></br>

                          </>
                        }
                        color='disabled'>
                        <InfoIcon />
                      </Tooltip>
            </Typography>
            
          </Grid>

          <Grid item xs={6}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'>
              <input
                style={styles.formControl}
                type='number'
                id='tbMarginB'
                min='0'
                max='100'></input>
            </Grid>
          </Grid>

          <Grid item xs={6} style={styles.lineSpacer}>
            <Button style={styles.atcButton} onClick={handleTab0Gen}>
            1. GENERATE PREVIEW
            </Button>
          </Grid>
         
          <Grid item xs={6} style={styles.lineSpacer}>
            <Button style={styles.atcButton} onClick={handleTab0} disabled={!tab0GenState}>
            2. READY FOR NEXT STEP
            </Button>
          </Grid>
          <Grid item xs={12} style={styles.lineSpacer}>
           <Typography style={styles.smallText}>
           generate as many times as you like until you like the configuration
           </Typography>
          </Grid>
        </Grid>
      </Grid>
    </form>,
  );

  tabArr.push(
    <form onSubmit={() => {}} style={tabStyle} key={'1'}>
      <Grid item sm={12} md={12}>
        <p style={styles.leftHeader}>Step 2</p>
        <p style={styles.smallText}>Select the style of frames and background colour</p>
        <hr style={styles.divider} />
      </Grid>

      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='stretch'

        >
        <Grid item xs={6} sm={6}>
          <div style={styles.pickerContainer}>
          <Typography variant='h6' color='textSecondary' style={{width:240}}>
              background colour
            </Typography>
            <ColorPicker
              width={240}
              height={150}
              color={backPickerState}
              onChange={(ev) => {
                setBackPickerState(ev);
                handleColorSelect(ev);
              }}
              hideHSV
              hideRGB
              dark
            />
          </div>
        </Grid>

        {/* Right Pricing */}
        <Grid item xs={6} sm={6} style={{ maxHeight: '320px' , marginTop:'1rem',}}>
        <Typography variant='h6' color='textSecondary' style={{width:'100%',marginLeft:'3rem'}}>
              frame set
            </Typography>
          {FrameSelect(setFrameState, frameState, colourState)}
        </Grid>
        <Grid item xs={6} style={styles.lineSpacer}>
          <Button style={styles.atcButton} onClick={handleTab1}>
            1. GENERATE PREVIEW
          </Button>
        </Grid>

        <Grid item xs={6} style={styles.lineSpacer}>
          <Button
            style={styles.atcButton}
            onClick={(ev) => {
              ev.preventDefault();
              setTabState(2);
            }}>
            2. READY FOR NEXT STEP
          </Button>
        </Grid>
        <Grid item xs={12} style={styles.lineSpacer}>
        <Typography>
            NOTE: If you would like to go back to a previous step you can click on the corresponding step in the grey bar above; However you will lose any information entered.
          </Typography>
        </Grid>
        {/* <Grid item xs={6}>
          <Typography>
            NOTE: If you would like to go back to a previous step you can click on the corresponding step in the grey bar above; However you will lose any information entered.
          </Typography>
        </Grid> */}
      </Grid>
    </form>,
  );

  function handleRadio(ev) {
    setPatternOrTextState(ev);
  }

  var patternTab = (
    <form onSubmit={() => {}} style={tabStyle} key={'2'}>
      {/* <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              style={styles.panel}
              spacing={0}
            >
              <Grid item sm={3} style={styles.disabledText}>
                <p onClick={(e)=>{e.preventDefault()}}>1. Size</p>
              </Grid>
              <Hidden smDown>
                <Grid item md={3} style={styles.disabledText}>
                  <p onClick={(e)=>{e.preventDefault()
                  setTabState(1)}}>2.Background</p>
                </Grid>
              </Hidden>
              <Hidden smDown>
                <Grid item md={3} style={styles.darkText}>
                  <p onClick={(e)=>{e.preventDefault()}}>3. Decal</p>
                </Grid>
              </Hidden>
              <Hidden smDown>
                <Grid item md={3} style={styles.disabledText}>
                  <p onClick={(e)=>{e.preventDefault()
                setTabState(3)}}>4. Photos </p>
                </Grid>
              </Hidden>
            </Grid> */}
      <Grid item sm={12} md={12}>
        <p style={styles.leftHeader}>Step 3</p>
        <p style={styles.smallText}>Add pattern or script to your background</p>
        <hr style={styles.divider} />
      </Grid>

      <RadioGroup horizontal value={patternOrTextState} onChange={handleRadio}>
        <RadioButton value='pattern'>pattern</RadioButton>
        <RadioButton value='script'>script</RadioButton>
      </RadioGroup>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'>
        <Grid item xs={12} sm={6}>
          <div style={styles.pickerContainer}>
            <ColorPicker
              width={240}
              height={150}
              color={patPickerState}
              onChange={(ev) => {
                setPatPickerState(ev);
                handlePatColorSelect(ev);
              }}
              hideHSV
              hideRGB
              dark
            />
          </div>
        </Grid>

        {/* Right Pricing */}
        <Grid item xs={12} sm={6} style={{ maxHeight: '320px' ,marginTop:'1rem'}}>
          {PatternSelect(
            setBackgroundPatternState,
            backgroundPatternState,
            patColourState,
            colourState,
          )}
        </Grid>

        <Grid item xs={6} style={styles.lineSpacer}>
          <Button style={styles.atcButton} onClick={handleTab2Pat}>
          1. GENERATE PREVIEW
          </Button>
        </Grid>

        <Grid item xs={6} style={styles.lineSpacer}>
          <Button
            style={styles.atcButton}
            onClick={(ev) => {
              ev.preventDefault();
              setTabState(3);
            }}>
            2. READY FOR NEXT STEP
          </Button>
        </Grid>
      </Grid>
    </form>
  );

  var scriptTab = (
    <form onSubmit={() => {}} style={tabStyle} key={'2s'}>
      {/* <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              style={styles.panel}
              spacing={0}
            >
             <Grid item sm={3} style={styles.disabledText}>
                <p onClick={(e)=>{e.preventDefault()}}>1. Size</p>
              </Grid>
              <Hidden smDown>
                <Grid item md={3} style={styles.disabledText}>
                  <p onClick={(e)=>{e.preventDefault()}}>2.Background</p>
                </Grid>
              </Hidden>
              <Hidden smDown>
                <Grid item md={3} style={styles.darkText}>
                  <p onClick={(e)=>{e.preventDefault()}}>3. Decal</p>
                </Grid>
              </Hidden>
              <Hidden smDown>
                <Grid item md={3} style={styles.disabledText}>
                  <p onClick={(e)=>{e.preventDefault()
                setTabState(3)}}>4. Photos </p>
                </Grid>
              </Hidden>
            </Grid> */}
      <Grid item sm={12} md={12}>
        <p style={styles.leftHeader}>Step 3</p>
        <p style={styles.smallText}>Add pattern or script to your background</p>
        <hr style={styles.divider} />
      </Grid>

      <RadioGroup horizontal value={patternOrTextState} onChange={handleRadio}>
        <RadioButton value='pattern' rootColor='#414042' pointColor='#ef4136'>
          Pattern
        </RadioButton>
        <RadioButton value='script' rootColor='#414042' pointColor='#ef4136'>
          Script
        </RadioButton>
      </RadioGroup>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'>
        <Grid item xs={12} sm={6}>
          <div style={styles.imageContainer}>
            <ColorPicker
              width={262.656}
              height={133.056}
              color={patPickerState}
              onChange={(ev) => {
                setPatPickerState(ev);
                handlePatColorSelect(ev);
              }}
              hideHSV
              hideRGB
              dark
            />
          </div>
        </Grid>

        {/* Right Pricing */}
       
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'>
          <Grid item xs={6}>
            <Typography variant='h6' color='textSecondary'>
              Select font:
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'>
              {FontOptions(
                setFontState,
                fontState,
                patColourState,
                colourState,
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'>
       



        </Grid>

        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'>
          <Grid item xs={6}>
            <Typography variant='h6' color='textSecondary'>
              Your script:
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'>
              {BackroundContentPreviewWord(
                backgroundWordMenuState,
                fontState,
                patColourState,
                colourState,
                scriptState,
                setScriptState,
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant='h6' color='textSecondary' style={styles.smallText}>
              Note: script will be 1 approximately inch tall
          </Typography>
        </Grid>
        <Grid item xs={6} style={styles.lineSpacer}>
          <Button style={styles.atcButton} onClick={handleTab2Tex}>
          1. GENERATE PREVIEW
          </Button>
        </Grid>

        <Grid item xs={6} style={styles.lineSpacer}>
          <Button
            style={styles.atcButton}
            onClick={(ev) => {
              ev.preventDefault();
              setTabState(3);
            }}>
            2. READY FOR NEXT STEP
          </Button>
        </Grid>
      </Grid>
    </form>
  );

  var tab2OBJ = { pattern: patternTab, script: scriptTab };
  tabArr.push(tab2OBJ[patternOrTextState]);

  tabArr.push(
    <form onSubmit={() => {}} style={tabStyle} key={'3'}>
      {/* <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              style={styles.panel}
              spacing={0}
            >
              <Grid item sm={3} style={styles.disabledText}>
                <p onClick={(e)=>{e.preventDefault()}}>1. Size</p>
              </Grid>
              <Hidden smDown>
                <Grid item md={3} style={styles.disabledText}>
                  <p onClick={(e)=>{e.preventDefault()
                   setTabState(1)}}>2.Background</p>
                </Grid>
              </Hidden>
              <Hidden smDown>
                <Grid item md={3} style={styles.disabledText}>
                  <p onClick={(e)=>{e.preventDefault()
                   setTabState(2)}}>3. Decal</p>
                </Grid>
              </Hidden>
              <Hidden smDown>
                <Grid item md={3} style={styles.darkText}>
                  <p onClick={(e)=>{e.preventDefault()
               }}>4. Photos </p>
                </Grid>
              </Hidden>
            </Grid> */}
      <Grid item sm={12} md={12}>
        <p style={styles.leftHeader}>Step 4</p>
        <p style={styles.smallText}>Import your photos</p>
        <p style={styles.leftHeader}>
          {'There are ' + frameArr.length + ' frames to fill'}
        </p>
        <hr style={styles.divider} />
      </Grid>

      <div style={{ position: 'relative', height: '45%' }}>
        {/* <label style={{fontFamily:'Helvetica W01 Cn, Helvetica, Arial, sans-serif',fontSize:'2.5vh',color:'#414042',position:'relative',top:'0',width:'50%'}}> </label> */}

        <div
          style={{ position: 'relative', height: '80%', overflowY: 'scroll' }}>
          {PictureGrid(picIDMap, setPicIDMap, doc)}
        </div>
        <div style={{ position: 'relative', height: '10%', width: '100%' }}>
          {MyDropzone(doc)}
        </div>
      </div>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'>
        <Grid item xs={12} sm={6}>
          <Button style={styles.atcButton} onClick={handleAutoPlace}>
            Auto-place photos
          </Button>
        </Grid>

        {/* Right Pricing */}
        <Grid item xs={12} sm={6}>
          <select
            style={styles.atcSelect}
            id='filterSelect'
            onChange={(ev) => {
              ev.preventDefault();
              setColorFilterState(
                document.getElementById('filterSelect').value,
              );
            }}>
            <option value={'grayscale(0%)'}>No Re-colour</option>
            <option value={'grayscale(100%)'}>Black and White</option>
            <option value={'sepia(70%)'}>Sepia</option>
          </select>
        </Grid>

        <Grid item xs={12} >
          <Button style={styles.atcButton} onClick={finishWallPaper}>
            Finish
          </Button>
        </Grid>
      </Grid>
    </form>,
  );

  return tabArr[tabState];

  function PanelRelPosTest(picIDMap, setPicIDMap, doc) {
    //just for experimenting with css relative positioning
    var myStyle = { position: 'absolute', top: '0vh', left: 0 };

    return (
      // <div style={myStyle}>
      //     <h1>SunGlow WallPaper forge</h1>
      // </div>
      <div style={{ position: 'absolute', top: 0, height: '100%' }}>
        <div style={{ height: '10%' }}>
          <Container>
            <h1>place your pictures!</h1>
          </Container>
        </div>
        <div style={{ height: '58%', overflowY: 'scroll' }}>
          {PictureGrid(picIDMap, setPicIDMap, doc)}
        </div>

        {GalleryMenu(picIDMap, doc)}
      </div>
    );
  }

  function MyDropzone(doc) {
    async function getBase64Image(img) {
      let base64_result = await new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = (e) => resolve(reader.result);
      });

      return base64_result;
    }

    const onDrop = useCallback((acceptedFiles) => {
      //('zone '+doc)

      if (Array.isArray(acceptedFiles)) {
        for (var i = 0; i < acceptedFiles.length; i++) {
          addImageToDb(acceptedFiles[i]);
          let test = getBase64Image(acceptedFiles[i]);
        }
      } else {
        addImageToDb(acceptedFiles);
        //addImageToDoc(doc,acceptedFiles[i])
      }

      // Do something with the files
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
    });

    return (
      <div
        {...getRootProps()}
        style={{ height: '100%', width: '100%' }}
        draggable='false'>
        <input {...getInputProps()} draggable='false' />
        {isDragActive ? (
          <img
            src={uploadIcon2}
            alt='error'
            draggable='false'
            style={{ height: '100%', width: '100%' }}
          />
        ) : (
          <img
            src={uploadIcon}
            alt='error'
            draggable='false'
            style={{ height: '100%', width: '100%' }}
          />
        )}
      </div>
    );
  }

  function PictureGrid(picIDMap, setPicIDMap, doc) {
    var allImages = useLiveQuery(() => db.usrImages.toArray(), []);
    //var allImages = getDocImgArray(doc)

    if (!allImages) {
      //if empty/undefined
      // return(
      // <div>/////////////////////
      //   <Container style={{height:'70vh', overflowY: "scroll", background:"#8a3a08"}}></Container>
      // </div>)
    } else {
      var galleryArr = []; //hi shivm lol

      for (var i = 0; i < allImages.length; i++) {
        if (allImages[i].used) {
          galleryArr.push(
            GalleryPicture(allImages[i], i, picIDMap, setPicIDMap),
          );
        }
      }

      return (
        <div>
          <Container>
            <Grid
              container
              spacing={0}
              style={{
                background: 'white',
                width: '25vw',
                borderStyle: '1px',
              }}>
              {galleryArr}
            </Grid>
          </Container>
        </div>
      );
    }
  }

  function GalleryPicture(pic, id, picIDMap, setPicIDMap) {
    function dragS(ev) {
      ev.dataTransfer.setData('text', ev.target.id);
    }

    function remove(ev) {
      ev.preventDefault();
      var keyArr = Array.from(picIDMap.keys());
      var valArr = Array.from(picIDMap.values());
      var myMap = new Map(picIDMap);

      for (var i = 0; i < valArr.length; i++) {
        if (valArr[i] == id) {
          myMap.delete(keyArr[i]);
        }
      }

      setPicIDMap(myMap);
      removeImageFromDb(pic['id']);
      ////DBReset()//temp
    }

    var keyArr = Array.from(picIDMap.keys());
    var valArr = Array.from(picIDMap.values());
    const occurrenceMap = valArr.reduce(
      (acc, e) => acc.set(e, (acc.get(e) || 0) + 1),
      new Map(),
    );

    var display = URL.createObjectURL(pic['image']);
    return (
      <Grid item xs={6} key={gallPicId++} style={{ textAlign: 'center' }}>
        {/* <Container > */}

        <img
          src={display}
          alt='error'
          onContextMenu={remove}
          onDragStart={dragS}
          id={pic['id']}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
          draggable='true'
        />
        <div>
          <label
            style={{
              fontFamily: 'Helvetica W01 Cn, Helvetica, Arial, sans-serif',
              backgroundColor: '#414042',
              color: 'white',
            }}>
            {occurrenceMap.get(id)}
          </label>
        </div>

        {/* </Container> */}
      </Grid>
    );
  }

  var ldbVer = 2;

  function GalleryMenu(picIDMap, doc) {
    const allImages = useLiveQuery(() => db.usrImages.toArray(), []);
    //const gotoOrder = () => nav('/Order')
    const dispatch = useDispatch();
    const frameArr = useSelector((state) => state.frame.value);

    function handleClear(e) {
      // db.delete()
      // db.version(ldbVer++)
      DBReset();
    }
    //const nav = useNavigate();
    //const navToOrder = () => nav('/Order')

    function handlePlaceOrder(e) {
      var keyArr = Array.from(picIDMap.keys());
      var valArr = Array.from(picIDMap.values());

      for (let i = 0; i < keyArr.length; i++) {
        let reader = new FileReader();
        reader.readAsDataURL(allImages[valArr[i]]['image']);
        reader.onload = () => {
          b64Map.set(keyArr[i], reader.result);
        };
        reader.onerror = function () {};
      }
      picIDMapFinal = picIDMap;
    }

    return (
      <div
        style={{
          display: 'flex',
          height: '20%',
          position: 'relative',
          bottom: 0,
        }}>
        <div style={{ height: '100%', width: '50%' }}>{MyDropzone(doc)}</div>

        <div style={{ height: '100%', width: '25%' }}>
          <button
            style={{
              height: '100%',
              width: '100%',
              backgroundColor: 'white',
              fontFamily: 'Helvetica W01 Cn, Helvetica, Arial, sans-serif',
              fontSize: '150%',
              borderColor: 'black',
              borderWidth: '1px',
              fontWeight: 'bold',
              color: '#ef4136',
            }}
            onClick={handlePlaceOrder}>
            Place order
          </button>
        </div>
      </div>
    );
  }
}

function BackroundContentPreviewWord(
  selection,
  fontState,
  patColourState,
  colourState,
  scriptState,
  setScriptState,
) {
  var wordMap;
  var boolDisable;
  var strTemplate;
  if (selection == 'custom') {
    strTemplate = '';
    boolDisable = false;
  } else if (selection[0] == 'w') {
    switch (selection) {
      case 'wlocations':
        strTemplate =
          'Canada America France Italy Russia China Taiwan Germany Ukraine Japan Greenland';
        break;
      default:
        strTemplate = '';
    }

    boolDisable = false;
  }

  let alt = (
    <textarea
      type='text'
      hiddenLabel
      id='wordEntry'
      defaultValue={strTemplate}
      variant='filled'
      multiline={true}
      className='largeText'
      disabled={boolDisable}
      style={{
        maxHeight: '100px',
        overflowY: 'scroll',
        width: '70%',
        fontFamily: fontState,
        color: patColourState,
        backgroundColor: colourState,
      }}
    />
  );

  let original = (
    <TextField
      hiddenLabel
      id='wordEntry'
      defaultValue={strTemplate}
      variant='filled'
      multiline={true}
      className='largeText'
      disabled={boolDisable}
      style={{
        maxHeight: '100px',
        overflowY: 'scroll',
        width: '100%',
        fontFamily: fontState,
        color: patColourState,
        backgroundColor: colourState,
      }}
    />
  );

  return alt;
}

function FrameSelect(setFrameState, frameState, colourState) {
  var imgOBJ = framePreviews;

  function onClickEv(ev) {
    ev.preventDefault();

    setFrameState(ev.target.id);
  }

  var ret = [];
  let keys = Object.keys(framePreviews);
  for (var i = 0; i < keys.length; i++) {
    var bw = 0;

    if (frameState == keys[i]) {
      bw = '5px';
    }
    var myImage = imgOBJ[keys[i]];
    var myStyle = {
      aspectRatio: '1 / 1',
      width: '90%',
      borderWidth: bw,
      borderStyle: 'solid',
      borderColor: '#ef4136',
      backgroundColor: colourState,
      textAlign: 'center',
    };
    ret.push(
      <div style={myStyle} key={i}>
        <img
          style={{
            borderColor: '#ef4136',
            borderWidth: 0,
            borderStyle: 'solid',
            position: 'relative',
            top: '10%',
            left: '3%',
            height: '80%',
            width: '80%',
          }}
          src={myImage}
          alt='e'
          key={i}
          id={keys[i]}
          onClick={onClickEv}></img>
      </div>,
    );
  }

  return (
    <div
      style={{
        height: '90%',
        width: '60%',
        borderColor: 'black',
        borderWidth: '2px',
        borderStyle: 'solid',
        position: 'relative',
        left: '40%',
        overflowY: 'scroll',
        textAlign: 'center',
        backgroundColor: colourState,
      }}>
      {ret}
    </div>
  );
}

function PatternSelect(
  setBackgroundPatternState,
  backgroundPatternState,
  patColourState,
  colourState,
) {
  var imgOBJ = patternObject;

  function onClickEv(ev) {
    ev.preventDefault();

    setBackgroundPatternState(ev.target.id);
  }

  var ret = [];
  let keys = Object.keys(imgOBJ);
  for (var i = 0; i < keys.length; i++) {
    var bw = 0;

    if (backgroundPatternState == keys[i]) {
      bw = '5px';
    }
    var myImage = imgOBJ[keys[i]];
    var myStyle = {
      aspectRatio: '1 / 1',
      width: '90%',
      borderWidth: bw,
      borderStyle: 'solid',
      borderColor: '#ef4136',
      backgroundColor: colourState,
      textAlign: 'center',
    };
    ret.push(
      <div style={myStyle} key={i}>
        {/* <img style = {{borderColor:'#ef4136',borderWidth: bw, borderStyle:'solid',position:'relative',top:'10%',left:'10%',height:'80%',width:'80%'}} src ={myImage} alt = 'e'  key = {i} id={keys[i]} onClick={onClickEv}></img> */}
        <img
          alt=''
          onClick={onClickEv}
          id={keys[i]}
          style={{
            position: 'relative',
            top: '10%',
            left: '3%',
            height: '80%',
            width: '80%',
            backgroundColor: patColourState,
            maskImage: 'url(' + myImage + ')',
            WebkitMaskImage: 'url(' + myImage + ')',
            maskSize: '100%',
            maskSize: '100%',
            WebkitMaskSize: '100%',
            WebkitMaskSize: '100%',
            maskRepeat: 'repeat-xy',
            WebkitMaskRepeat: 'repeat-xy',
          }}></img>
      </div>,
    );
  }

  return (
    <div
      style={{
        height: '90%',
        width: '60%',
        borderColor: 'black',
        borderWidth: '2px',
        borderStyle: 'solid',
        position: 'relative',
        left: '40%',
        overflowY: 'scroll',
        textAlign: 'center',
        backgroundColor: colourState,
      }}>
      {ret}
    </div>
  );
}


//step 3 font options
function FontOptions(setFontState, fontState, patColourState, backColState) {
  function fontChange(ev) {
    ev.preventDefault();
    setFontState(ev.target.value);
  }

  var ret = [];
  let keys = Object.keys(fontObject);
  for (var i = 0; i < keys.length; i++) {
    ret.push(
      <option
        style={{
          fontFamily: fontObject[keys[i]],
          color: patColourState,
          fontSize: '2.5vh',
          backgroundColor: backColState,
        }}
        value={fontObject[keys[i]]}
        key={i}>
        {keys[i]}
      </option>,
    );
  }

  return (
    <select
      id='fontSelect'
      onChange={fontChange}
      style={{
        fontFamily: fontState,
        width: '40%',
        height: '100%',
        top: '20%',
        borderRadius: '5px',
        borderStyle: 'solid',
        fontsize: '2.5vh',
        color: patColourState,
        backgroundColor: backColState,
      }}>
      {ret}
    </select>
  );
}
