/** @format */

import React, { useEffect } from 'react';
import { Button, Grid, Tooltip } from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useState } from 'react';
import { customizeProduct } from '../actions/blindActions';
import { calculateTotalPrice } from '../actions/blindActions';
import InfoIcon from '@mui/icons-material/Info';
import './menu.css';
import ImageZoom from './ImageZoom';

const CustomizeDetails = ({
  page,
  productInfo,
  optionInfo,
  totalPrice,
  setTotalPrice,
  switchPage,
  setSwitchPage,
  fabricPrice,
  disabled,
}) => {
  var dispatch = useDispatch();

  var [quantity, setQuantity] = useState(optionInfo.quantity);
  var [shadeWidthFrac, setShadeWidthFrac] = useState(optionInfo.widthFraction);
  var [shadeLengthFrac, setShadeLengthFrac] = useState(
    optionInfo.lengthFraction,
  );
  var [shadeLength, setShadeLength] = useState(optionInfo.lengthWhole);
  var [shadeWidth, setShadeWidth] = useState(optionInfo.widthWhole);
  var [measure, setMeasure] = useState(optionInfo.measure);
  var invalid = productInfo.invalid;
  var locked = useRef(false);

  // add Handler to update state
  const setQuantityHandler = (e) => {
    setQuantity(e.target.value);
  };

  const setShadeWidthFracHandler = (e) => {
    setShadeWidthFrac(e.target.value);
  };

  const setShadeLengthFracHandler = (e) => {
    setShadeLengthFrac(e.target.value);
  };

  const setShadeLengthHandler = (e) => {
    setShadeLength(e.target.value);
  };

  const setShadeWidthHandler = (e) => {
    setShadeWidth(e.target.value);
  };

  const forwardPage = () => {
    if (locked == true) return;
    locked = true;
    dispatchDetails();
    setTimeout(() => {
      locked = false;
    }, 2000);

    if (invalid) return;

    page.current = 2;
    var toChange = document.getElementById('details');
    toChange.style.display = 'none';
    var t1 = document.getElementById('t1');

    t1.style.color = '#9b9b9b';
    t1.style.fontSize = '0.8rem';

    var t2 = document.getElementById('t2');
    t2.style = styles.darkText;

    toChange = document.getElementById('fabric');
    toChange.style.display = 'block';
  };

  const shadeTitle = productInfo.Type + ' Shade';

  const styles = {
    leftHeader: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    atcButton: {
      // background: '#ebebeb',
      minWidth: '10rem',
      marginRight: '1rem',
      marginTop: '1rem',
    },
    fields: {
      // width: '100%',
      margin: '.5rem',
      // padding: '.2rem',
    },
    smallImage: {
      width: '100%',
      // margin: 'auto',
      // margin: '.5rem',
      // padding: '.2rem',
    },
  };

  const getPrice = () => {
    if (page.current != 1) return;
    let width = shadeWidthFrac
      ? parseFloat(shadeWidth) + parseFloat(shadeWidthFrac)
      : shadeWidth;
    let length = shadeLengthFrac
      ? parseFloat(shadeLength) + parseFloat(shadeLengthFrac)
      : shadeLength;

    if (length % 1 !== 0) {
      length = eval(length);
    }
    if (width % 1 !== 0) {
      width = eval(width);
    }

    let shade = productInfo.Type + ' Shade';
    let priceGroup = productInfo.Price_Group;
    let drive = productInfo.Operation;
    let valance = productInfo.Valance;
    let channel = productInfo.L_Channel;

    calculateTotalPrice(
      length,
      width,
      shade,
      priceGroup,
      drive,
      valance,
      channel,
    ).then((tp) => {
      if (tp.hasOwnProperty('type')) {
        console.log(tp);
        return;
      }
      console.log(quantity);
      let temp = quantity * tp.totalPrice;

      // temp = temp - (temp * (productInfo.Discount));
      temp = Math.round(temp * 100) / 100;
      totalPrice = temp;

      // if (fabricPrice.current && productInfo.Type != 'Sheer')
      //   temp += ` ${fabricPrice.current}`;
      // if (drive == 'Motor') temp += `${tp.drivePrice}`;
      // if (drive == 'Zero Gravity') temp += `${tp.drivePrice} `;
      // if (channel != 'None') temp += `${tp.channelPrice} `;
      // if (tp.valancePrice) temp += ` ${tp.valancePrice}`;

      setTotalPrice(temp);
      console.log(totalPrice);
    });
  };

  const fraction = [
    '0',
    // '0/16',
    '1/16',
    '1/8',
    '3/16',
    '1/4',
    '5/16',
    '3/8',
    '7/16',
    '1/2',
    '9/16',
    '5/8',
    '11/16',
    '3/4',
    '13/16',
    '7/8',
    '15/16',
  ];

  const widthOptionsFrac = () => {
    let ret = fraction.map((number) => {
      return (
        <MenuItem key={number} value={number}>
          {number}
        </MenuItem>
      );
    });
    return ret;
  };

  const lengthOptionsFrac = () => {
    let ret = fraction.map((number) => {
      return (
        <MenuItem key={number} value={number}>
          {number}
        </MenuItem>
      );
    });
    return ret;
  };

  useEffect(() => {
    if (optionInfo.customize || fabricPrice.current == 0) {
      setTimeout(() => {
        getPrice();
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (switchPage && page.current == 1) {
      var t1 = document.getElementById('t1');
      t1.style = styles.darkText;

      console.log('t' + switchPage);

      var toHide = document.getElementById('t' + switchPage);

      toHide.style.color = '#9b9b9b';
      toHide.style.fontSize = '0.8rem';
      setSwitchPage(0);
    }
  }, [switchPage]);

  const dispatchDetails = () => {
    optionInfo.quantity = quantity;
    optionInfo.lengthFraction = shadeLengthFrac;
    optionInfo.lengthWhole = shadeLength;
    optionInfo.measure = measure;
    optionInfo.widthWhole = shadeWidth;
    optionInfo.widthFraction = shadeWidthFrac;
    optionInfo.OUR_PRICE = totalPrice;
    dispatch(
      customizeProduct({
        productInfo: {
          ...productInfo,
        },
        optionInfo: {
          ...optionInfo,
        },
      }),
    );
  };

  return (
    <>
      <Grid
        id='details'
        visible='none'
        style={page.current == 1 ? { display: 'block' } : { display: 'none' }}
        spacing={1}
        onHide={switchPage && dispatchDetails()}>
        <Grid disabled>
          <p style={styles.leftHeader}>1. Shade Details</p>
          <hr className='divider' />
          <Grid container direction='row'>
            <Grid item xs={6}>
              <TextField
                id='shade'
                label='Shade Name'
                disabled
                size='small'
                value={shadeTitle}
                variant='outlined'
                style={styles.fields}
              />
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                direction='row'
                // justifyContent='center'
              >
                <TextField
                  id='quantity'
                  label='Quantity'
                  value={quantity}
                  size='small'
                  onBlur={getPrice}
                  variant='outlined'
                  onChange={(e) => {
                    setQuantityHandler(e);
                  }}
                  style={styles.fields}
                />
              </Grid>
            </Grid>
          </Grid>
          <hr className='divider' />

          <Grid container direction='row'>
            <Grid item xs={3}>
              <img
                style={styles.smallImage}
                src='/images/customizeToolRenders/width.png'
                alt='#'
              />
            </Grid>
            <Grid xs={3}>
              <FormControl component='fieldset'>
                <TextField
                  id='width'
                  size='small'
                  label='Width'
                  value={shadeWidth}
                  onBlur={getPrice}
                  variant='outlined'
                  onChange={(e) => setShadeWidthHandler(e)}
                  style={styles.fields}
                />
                <FormControl variant='outlined' style={styles.fields}>
                  <InputLabel size='small' for='Width Fraction'>
                    Width Fraction
                  </InputLabel>
                  <Select
                    label='Width Fraction'
                    size='small'
                    id='Width Fraction'
                    value={shadeWidthFrac}
                    onBlur={getPrice}
                    name='widthFraction'
                    onChange={(e) => setShadeWidthFracHandler(e)}>
                    {widthOptionsFrac()}{' '}
                  </Select>
                </FormControl>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <img
                style={styles.smallImage}
                src='/images/customizeToolRenders/Height.png'
                alt='#'
              />
            </Grid>
            <Grid xs={3}>
              <FormControl
                component='fieldset'
                style={{
                  // width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  // justifyContent: 'space-between',
                }}>
                <TextField
                  id='length'
                  value={shadeLength}
                  label='Length'
                  size='small'
                  onBlur={getPrice}
                  variant='outlined'
                  onChange={(e) => setShadeLengthHandler(e)}
                  style={styles.fields}
                />{' '}
                <FormControl variant='outlined' style={styles.fields}>
                  <InputLabel size='small' for='Length Fraction'>
                    Length Fraction
                  </InputLabel>
                  <Select
                    label='Length Fraction'
                    id='Length Fraction'
                    value={shadeLengthFrac}
                    size='small'
                    onBlur={getPrice}
                    name='lengthFraction'
                    onChange={(e) => setShadeLengthFracHandler(e)}>
                    {lengthOptionsFrac()}{' '}
                  </Select>
                </FormControl>
              </FormControl>
            </Grid>
          </Grid>

          {/* <Grid container direction='row' justifyContent='center'> */}

          <hr className='divider' />
          <div
            className={
              measure == 'Tight'
                ? 'featureOption selected optionsBoxMain'
                : 'featureOption optionsBoxMain'
            }
            onClick={() => {
              setMeasure('Tight');
            }}>
            <span
              className={measure == 'Tight' ? 'featureOptioncheckmark' : ''}
              style={{ display: 'block' }}></span>

            <div
              className='option feature optionFeatures'
              style={{ height: '132.406px' }}>
              <div
                className='image optionsFeatures'
                style={{
                  backgroundImage: `url(/images/customizeToolRenders/Inside.png)`,
                }}>
                <ImageZoom imageUrl={'/images/customizeToolRenders/Inside.png'} />
                <div className='overlay'></div>
              </div>
            </div>

            <div className='featureText withMediaIcons'>
              <div className='featureoptionradio' style={{}}>
                <div
                  className='customRadio radioFeatureButton'
                  style={{ float: 'left', left: '-2px' }}>
                  <input
                    defaultChecked='checked'
                    groupname='Feature1320'
                    id='SelectedProductFeatureOptions3743'
                    name='AvailableFeatures[9].FeatureOptions[2].Selected'
                    type='checkbox'
                    defaultValue='true'
                  />
                  <input
                    name='AvailableFeatures[9].FeatureOptions[2].Selected'
                    type='hidden'
                    defaultValue='false'
                  />
                  <input
                    id='AvailableFeatures_9__FeatureOptions_2__OriginallySelected'
                    name='AvailableFeatures[9].FeatureOptions[2].OriginallySelected'
                    type='hidden'
                    defaultValue='True'
                  />
                </div>
                <div style={{}} className='featureOptionNamelbl'>
                  <label
                    htmlFor='SelectedProductFeatureOptions3743'
                    className='featureOptionName'
                    data-featuretitle='Curved Cassette Valance'>
                    Inside Mount
                    <br />
                    <b>Free</b>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              measure == 'Cloth'
                ? 'featureOption selected optionsBoxMain'
                : 'featureOption optionsBoxMain'
            }
            onClick={() => {
              setMeasure('Cloth');
            }}>
            <span
              className={measure == 'Cloth' ? 'featureOptioncheckmark' : ''}
              style={{ display: 'block' }}></span>

            <div
              className='option feature optionFeatures'
              style={{ height: '132.406px' }}>
              <div
                className='image optionsFeatures'
                style={{
                  backgroundImage: `url(/images/customizeToolRenders/Outside.png)`,
                }}>
                 <ImageZoom imageUrl={'/images/customizeToolRenders/Outside.png'} />
                <div className='overlay'></div>
              </div>
            </div>

            <div className='featureText withMediaIcons'>
              <div className='featureoptionradio' style={{}}>
                <div
                  className='customRadio radioFeatureButton'
                  style={{ float: 'left', left: '-2px' }}>
                  <span className='radio selected'>
                    <span className='checked'></span>
                  </span>
                  <input
                    defaultChecked='checked'
                    groupname='Feature1320'
                    id='SelectedProductFeatureOptions3743'
                    name='AvailableFeatures[9].FeatureOptions[2].Selected'
                    type='checkbox'
                    defaultValue='true'
                  />
                  <input
                    name='AvailableFeatures[9].FeatureOptions[2].Selected'
                    type='hidden'
                    defaultValue='false'
                  />
                  <input
                    id='AvailableFeatures_9__FeatureOptions_2__OriginallySelected'
                    name='AvailableFeatures[9].FeatureOptions[2].OriginallySelected'
                    type='hidden'
                    defaultValue='True'
                  />
                </div>
                <div style={{}} className='featureOptionNamelbl'>
                  <label
                    htmlFor='SelectedProductFeatureOptions3743'
                    className='featureOptionName'
                    data-featuretitle='Curved Cassette Valance'>
                    Outside Mount
                    <br />
                    <b>Free</b>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Grid>

        <Grid container direction='row'>
          <Grid item xs={6}>
            <Button id='back' disabled style={styles.atcButton}>
              Back
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className='redButton'
              style={{
                margin: '.5rem 0rem',
                width: '10rem',
                backgroundColor: '#ef4036',
                color: '#fff',
                marginTop: '1rem',
              }}
              onClick={forwardPage}>
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomizeDetails;
