/** @format */

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import React, { Component } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { setCoords } from './logical_functions/setCoords';
//import { setOccupied } from "../../../slices/occupiedSlice";
//import { newId} from "../../../slices/frameSlice"
import { db, addImageToDb, DBReset, DBStart } from '../DexieDB';

import { useLiveQuery } from 'dexie-react-hooks';
import { frameMap } from './frameImportFile';
import defimg from '../../../wallpaperimg/blank.png';

import { addListener, removeListener } from 'resize-detector';

let idM = 0;

function Frame(
  frameList,
  dim1,
  picID,
  hasPic,
  setIDmap,
  IDmap,
  imgDatabase,
  w,
  h,
  frameGraphic,
  scalerPXstate,
  setScalerPXstate,
  colorFilterState,
  frameVisibilityState
) {
  let frameImage;

  if (hasPic == false) {
    frameImage = defimg;
  } else {
    if (
      typeof imgDatabase == 'undefined' ||
      typeof imgDatabase[picID] == 'undefined'
    ) {
      //let myMap = new Map()
      //setIDmap(myMap)
      frameImage = defimg;
    } else {
      frameImage = URL.createObjectURL(imgDatabase[picID]['image']);
    }
  }

  function clear(ev) {
    ev.preventDefault();
    let myMap = new Map(IDmap);
    myMap.delete(frameData['id']);
    setIDmap(myMap);
  }

  //const dims = useSelector((state) => state.dimensions.value)
  const frameData = frameList;
  let scaler = 100 / dim1;

  //var w = 6
  //var h = 8
  if (frameData['land']) {
    var temp = w;
    w = h;
    h = temp;
  }

  const newStyle = setCoords(frameData['x'], frameData['y'], w, h);
  newStyle['overflow'] = 'hidden';
  function handleDrop(ev) {
    ev.preventDefault();
    var dropID = parseInt(ev.dataTransfer.getData('text')) - 1; //why -2 i really have no idea but it works \('_')/
    let myMap = new Map(IDmap);
    myMap.set(frameData['id'], dropID);
    setIDmap(myMap);
  }

  function allowDrop(ev) {
    ev.preventDefault();
  }

  var wallViewHeight = document.getElementById('wallView');
  if (wallViewHeight == null) {
    wallViewHeight = document.body.parentElement;
  } else {
    addListener(wallViewHeight, () => {
      setScalerPXstate(wallViewHeight.clientHeight / dim1);
    });
  }

  const graphicID = frameData['id'] % frameGraphic.length; //use id to determine what frame from set to use
  const myGraphic = frameGraphic[graphicID]['frameFile'];
  const myTranslate = frameGraphic[graphicID]['translate'];
  const myDiolate = frameGraphic[graphicID]['diolate'];

  return (
    <div
      style={{
        position: 'absolute',
        //background: '#9e9e9e',
        top: frameData['y'] * scalerPXstate + 'px',
        left: frameData['x'] * scalerPXstate + 'px',
        width: w * scalerPXstate + 'px',
        height: h * scalerPXstate + 'px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowX: 'hidden',
        overflowY: 'hidden',
        visibility: frameVisibilityState
      }}
      key={frameData['id']}
      id={frameData['id']}
      onDragOver={allowDrop}
      onDrop={handleDrop}
      draggable='false'
      onContextMenu={clear}>
      <img
        src={frameImage}
        alt='error'
        style={{
          position: 'relative',
          top: 0,
          left: 0,
          minHeight: '100%',
          minWidth: '100%',
          objectFit: 'cover',
          filter: colorFilterState,
          WebkitFilter: colorFilterState,
          visibility: frameVisibilityState
        }}
        draggable='false'></img>

      <img
        src={myGraphic}
        alt='error'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: (100).toString() + '%',
          width: '100%',
          filter: colorFilterState,
          WebkitFilter: colorFilterState,
          visibility: frameVisibilityState
        }}
        draggable='false'></img>
    </div>
  );
}

export function MainFrame(
  picIDMap,
  setPicIDMap,
  scalerPXstate,
  setScalerPXstate,
  colorFilterState,
  frameVisibilityState,
) {
  // <------ hahaha pun that doesn't even make sense in this context

  //function will read redux frame data and turn it into visable/functional
  //frame components. These frame components will update redux when given a picture

  var imgDatabase = useLiveQuery(() => db.usrImages.toArray(), []);

  const frameArr = useSelector((state) => state.wallpaperFrame.value);

  const dim1 = useSelector((state) => state.wallpaperDims.value)['height'];
  const frameTitle = useSelector((state) => state.wallpaperDims.value)[
    'frameSet'
  ];
  const frameGraphic = frameMap(frameTitle);

  var ret = [];
  for (var i = 0; i < frameArr.length; i++) {
    if (frameArr[i]['size'] === 3) {
      ret.push(
        Frame(
          frameArr[i],
          dim1,
          picIDMap.get(i),
          picIDMap.has(i),
          setPicIDMap,
          picIDMap,
          imgDatabase,
          10,
          12,
          frameGraphic,
          scalerPXstate,
          setScalerPXstate,
          colorFilterState,
          frameVisibilityState
        ),
      );
    } else if (frameArr[i]['size'] === 2) {
      ret.push(
        Frame(
          frameArr[i],
          dim1,
          picIDMap.get(i),
          picIDMap.has(i),
          setPicIDMap,
          picIDMap,
          imgDatabase,
          8,
          10,
          frameGraphic,
          scalerPXstate,
          setScalerPXstate,
          colorFilterState,
          frameVisibilityState
        ),
      );
    } else {
      ret.push(
        Frame(
          frameArr[i],
          dim1,
          picIDMap.get(i),
          picIDMap.has(i),
          setPicIDMap,
          picIDMap,
          imgDatabase,
          6,
          8,
          frameGraphic,
          scalerPXstate,
          setScalerPXstate,
          colorFilterState,
          frameVisibilityState
        ),
      );
    }
  }
  return ret;
}
