/** @format */

export const ls = localStorage;
export function createDoc(name, imgMap, redux) {
  var jsonMAP;
  if (typeof imgMap != 'undefined') {
    jsonMAP = Object.fromEntries(imgMap);
  }
  ls.setItem('WALL' + name, JSON.stringify({ idMap: jsonMAP, redux: redux }));
}

export function printDoc() {}

export function checkDoc(name) {
  if (ls.getItem('WALL'+name)) {
    return true;
  } else {
    return false;
  }
}

export function getDoc(name) {
  return JSON.parse(ls.getItem('WALL' + name));
}

export function storeToken(user, token) {
  ls.setItem('userToken', JSON.stringify({ user: user, token: token }));
}
export function getToken() {
  return JSON.parse(ls.getItem('userToken'));
}
