/** @format */

import axios from 'axios';
import {
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_PAY_FAIL,
  ORDER_PAY_REQUEST,
  ORDER_PAY_SUCCESS,
  ORDER_RATES_FAIL,
  ORDER_RATES_REQUEST,
  ORDER_RATES_SUCCESS,
} from '../constants/orderConstants';
import { tokenRedirectCheck } from '../utils/tokenRedirectCheck';
import { useHistory } from 'react-router-dom';

const API = process.env.REACT_APP_OCS_API;

export const createOrder = (order, history) => async (dispatch, getState) => {
  for (let i = 0; i < order.cartItems.length; i++) {
    if (order.cartItems[i].optionInfo.widthFraction != '') {
      //console.log(((order.cartItems[i].optionInfo.widthFraction.split('/')[0])/(order.cartItems[i].optionInfo.widthFraction.split('/')[1])))
      order.cartItems[i].optionInfo.widthWhole = (
        parseFloat(order.cartItems[i].optionInfo.widthWhole) +
        parseFloat(
          order.cartItems[i].optionInfo.widthFraction.split('/')[0] /
            order.cartItems[i].optionInfo.widthFraction.split('/')[1],
        )
      ).toString();
    }
    if (order.cartItems[i].optionInfo.lengthFraction != '') {
      order.cartItems[i].optionInfo.lengthWhole = (
        parseFloat(order.cartItems[i].optionInfo.lengthWhole) +
        parseFloat(
          order.cartItems[i].optionInfo.lengthFraction.split('/')[0] /
            order.cartItems[i].optionInfo.lengthFraction.split('/')[1],
        )
      ).toString();
    }
  }

  try {
    dispatch({
      type: ORDER_CREATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    order.email = userInfo.email;

    console.log("Email is " + order.email + "");
    // get isGuest from userLogin
    const isGuest = getState().userLogin.isGuest;
    
    var config;
    if(isGuest) {
      config  = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer Guest`,
        },
      }
    }
    else {
        config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
    }
    const { data } = await axios.post(`${API}api/orders`, order, config);
    dispatch(tokenRedirectCheck(data, history)); //checks if token is valid
    dispatch({
      type: ORDER_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const createSwatchOrder = (order, swatchShippingAddress) => async (dispatch, getState) => {
 
  try {
    dispatch({
      type: ORDER_CREATE_REQUEST,
    });
   
    const { data } = await axios.post(`${API}api/orders/swatch`, order, swatchShippingAddress);
  
    dispatch({
      type: ORDER_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

//Order Details
export const getOrderDetails =
  (userInfo, history) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_DETAILS_REQUEST,
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },

        //Token for private user
      };
      const { data } = await axios.post(
        `${API}api/orders/myorders`,
        userInfo,
        config,
      );
      dispatch(tokenRedirectCheck(data, history)); //checks if token is valid

      dispatch({
        type: ORDER_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORDER_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

//Pay Order actions
export const payOrder =
  (order, paymentResult, history) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_PAY_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
        //Token for private user
      };
      //Change the API
      const { data } = await axios.put(`${API}api/orders`, order, config);
      dispatch(tokenRedirectCheck(data, history)); //checks if token is valid

      dispatch({
        type: ORDER_PAY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORDER_PAY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

//Pay Order actions

export const getRates =
  (shipment_details, history) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_RATES_REQUEST,
      });

      console.log(shipment_details);

      //Change the API
      const { data } = await axios.post(
        `${API}api/orders/rates`,
        shipment_details,
      );

      if (data.message == 'error getting rates') {
        history.push('/shipping');
      }

      //const [ground, select, overnight] = data.rates;
      console.log(data);

      const ground = { total_charge: { amount: data.rates } };
      const overnight = { total_charge: { amount: 9999999 } };
      const select = { total_charge: { amount: 9999999 } };

      dispatch({
        type: ORDER_RATES_SUCCESS,
        payload: { ground, select, overnight },
      });
      return;
    } catch (error) {
      dispatch({
        type: ORDER_RATES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
