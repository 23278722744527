import {
  CART_ADD_ITEM,
  SWATCH_CART_ADD_ITEM,
  SWATCH_CART_REMOVE_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_PAYMENT_RESULT,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_UPDATE_QTY,
  CART_SAVE_SUMMARY,
  CART_RESET,
  PROMO_CODE_SUMBMITED_REQUEST,
  PROMO_CODE_SUMBMITED_FAIL,
  PROMO_CODE_SUMBMITED_SUCCESS,
  SWATCH_CART_SAVE_SHIPPING_ADDRESS,
  SWATCH_CART_RESET
} from '../constants/cartConstants';
import axios from 'axios';
const API = process.env.REACT_APP_OCS_API;


//Adding items to Cart
export const addToCart = (data) => async (dispatch, getState) => {
  dispatch({
    type: CART_ADD_ITEM,
    payload: data,
  });
  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

//Removing item from cart
export const removeFromCart = (item) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: item,
  });
  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

//Adding items to Swatch Cart
export const addToSwatchCart = (data) => async (dispatch, getState) => {
  // Check if the item is already in the cart
  if (getState().swatchCart.swatchCartItems.length > 0) {
    var item = getState().swatchCart.swatchCartItems.find(
      (x) => x.item.code == data.item.code
    );
    console.log(item);
    // If it is, don't add it again
    if (item) {
      console.log('Item already in cart');
      return;
    }
  }
  
  dispatch({
    type: SWATCH_CART_ADD_ITEM,
    payload: data,
  });
  localStorage.setItem('swatchCartItems', JSON.stringify(getState().swatchCart.swatchCartItems));
};

//Removing item from Swatch cart
export const removeFromSwatchCart = (item) => (dispatch, getState) => {
  dispatch({
    type: SWATCH_CART_REMOVE_ITEM,
    payload: item,
  });
  localStorage.setItem('swatchCartItems', JSON.stringify(getState().swatchCart.swatchCartItems));
};

export const updateProductQuantity =
  (index, quantity) => (dispatch, getState) => {
    dispatch({
      type: CART_UPDATE_QTY,
      payload: { index, quantity },
    });
    localStorage.setItem(
      'cartItems',
      JSON.stringify(getState().cart.cartItems)
    );
  };

//Saving Shipping address to Local storage
export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  });
  localStorage.setItem('shippingAddress', JSON.stringify(data));
};

export const saveSwatchShippingAddress = (data) => async (dispatch) => {
  dispatch({
    type: SWATCH_CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  });
  localStorage.setItem('swatchShippingAddress', JSON.stringify(data));
};


//Saving CartSummery
export const saveCartSummary = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SUMMARY,
    payload: data,
  });
  localStorage.setItem('cartSummary', JSON.stringify(data));
};

//Saving Paypal method to Local storage
export const savePaymentResult = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_RESULT,
    payload: data,
  });
  localStorage.setItem('paymentResult', JSON.stringify(data));
};


//Saving Payment method to Local storage
export const resetCart = (getState) => (dispatch) => {
  dispatch({
    type: CART_RESET,
    payload: 'No Items Found',
  });
  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

export const resetSwatchCart = () => (dispatch, getState) => {
  dispatch({
    type: SWATCH_CART_RESET,
    payload: 'No Items Found',
  });
  localStorage.setItem('swatchCartItems', JSON.stringify(getState().swatchCart.swatchCartItems));
};

export const readPromo = async(code, userId)=>{
  const data = await axios.get(`${API}api/promo/readCode?code=${code}&userId=${userId}`);
  return data
}

export const cencelPromoCode =  (usersId, code) => async (dispatch, getState) => {

  try {
    dispatch({
      type: PROMO_CODE_SUMBMITED_REQUEST,
    });
  
    const { data } = await axios.put(`${API}api/promo/code`, {
      usersId,
      code
    });
    console.log(code)
    console.log(usersId)
    console.log(data)

    dispatch({
      type:PROMO_CODE_SUMBMITED_SUCCESS,
      payload: data,
    });

  } catch (error) {
    dispatch({
      type: PROMO_CODE_SUMBMITED_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}