/** @format */

import React, { useState } from 'react';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addEmailToCartMailChimp,
  login,
  register,
} from '../actions/userActions';
import Message from '../components/Message';
import { Loader } from '../components/Loader';
import Menu3 from '../components/Menu3';
import { Checkbox } from '@mui/material';
import { apiMail } from '../actions/userActions';
const styles = {
  heavyText: {
    fontWeight: 400,
    textAlign: 'center',
  },
  leftHeader: {
    color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '1.5rem',
    textAlign: 'left',
    margin: '1rem 0',
  },
  divider: {
    width: '100%',
    borderTop: '1px solid black',
    marginTop: '1rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  button: {
    border: '1px solid black',
    background: 'transparent',
    color: 'black',
    backgroundColor: '#EEEBDD',
    textTransform: 'capitalize',
    minWidth: '10rem',
    hover: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    width: '20rem',
  },
};

const LoginScreen = ({ location, history }) => {
  const [email, setEmail] = useState('');
  const [guestEmail, setGuestEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const redirect = location.search ? location.search.split('=')[1] : '/';

  useEffect(() => {
    if (userInfo || userLogin?.isGuest) {
      if (!userLogin?.isGuest) history.push(redirect);
      else if (redirect != '/') history.push(redirect);
    }
  }, [history, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  const guestCheckoutHandler = (e) => {
    e.preventDefault();
    if (document.getElementById('createAccount').checked) {
      // Generate a random password
      const password = Math.random().toString(36).slice(-8);
      console.log(password);
      const name = guestEmail.split('@')[0];
      dispatch(register(name, guestEmail, password)).then((res) => {
        console.log(res);

        // Email the user their password
        if (res.includes('Error')) return;
        apiMail(
          guestEmail,
          'Your account has been created!',
          '<b>Your account details are as follows:</b><br/><br/> <b>Email:</b> ' +
            guestEmail +
            '<br/> <b>Password:</b> ' +
            password +
            '<br/><br/> You can change your password by logging in and going to your profile.',
        );
      });
    } else {
      dispatch(login(guestEmail, null));
    }
    addEmailToCartMailChimp(guestEmail);
  };

  return (
    <>
      <Menu3 />
      <Container>
        {/* {redirect == 'shipping' && (
        <>
        <Grid
              // style={{ width: '2rem' }}
              direction='column'
              justifyContent='center'
              alignItems='center'>
              <p className='leftHeader'>Shipping Details</p>
            {error && <Message variant='danger'>{error}</Message>}
            <form onSubmit={guestCheckoutHandler}>
              <Grid
                style={{ width: '20rem' }}
                container
                direction='column'
                justifyContent='center'
                alignItems='center'>
                <TextField
                  style={{ marginTop: '2rem', width: '20rem' }}
                  variant='outlined'
                  id='outlined-multiline-flexible'
                  label='Email'
                  required
                  value={guestEmail}
                  type='email'
                  onChange={(e) => setGuestEmail(e.target.value)}
                />
                <br />
                <input
                  type='checkbox'
                  id='createAccount'
                  name='createAccount'
                  value='createAccount'
                />
                <label for='createAccount'> Create an account?</label>
                <br />

                <Button type='submit' style={styles.button}>
                  Guest Checkout
                </Button>
              </Grid>
            </form>
          </Grid>

          <br />
          <br />
          <br />
          <br />
          <hr style={styles.divider} />
        </>
      )} */}
        {loading && <Loader />}
        <p style={styles.leftHeader}>Log In</p>
        <hr style={styles.divider} />
        {error && <Message variant='danger'>{error}</Message>}
        <form onSubmit={submitHandler}>
          <Grid style={{ width: '20rem' }} container direction='column'>
            <TextField
              style={{ marginTop: '.5rem', width: '20rem' }}
              size='small'
              variant='outlined'
              id='outlined-multiline-flexible'
              label='Email'
              required
              value={email}
              type='email'
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              style={{ marginTop: '1.5rem', width: '20rem' }}
              size='small'
              variant='outlined'
              id='outlined-multiline-flexible'
              label='Password'
              required
              value={password}
              type='password'
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              className='redButton'
              style={{
                margin: '1rem 0rem',
                width: '10rem',
                backgroundColor: '#ef4036',
                color: '#fff',
              }}
              type='submit'
              variant='outlined'>
              Log In
            </Button>
          </Grid>
        </form>
        <p>Don't have account?</p>
        <Link to={redirect ? `/register?redirect=${redirect}` : '/register'}>
          <Button
            className='redButton'
            style={{
              margin: '1rem 0rem',
              width: '10rem',
              backgroundColor: '#ef4036',
              color: '#fff',
            }}
            variant='outlined'>
            Register
          </Button>
        </Link>

        <Link to={'/accountRecovery'}>
          <p>Forgot your password?</p>
        </Link>
      </Container>
    </>
  );
};

export default LoginScreen;
