/** @format */

import { Button, Container, Grid } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    resetSwatchCart,
    saveSwatchShippingAddress,
} from '../actions/cartActions';
import { createSwatchOrder, getRates } from '../actions/orderActions';
import Menu3 from '../components/Menu3';

const styles = {
    divider: {
        width: '100%',
        // margin: '2rem auto',
    },
    darkText: {
        color: '#000',
        // borderBottom: '1px solid #000',
        fontSize: '1.1rem',
        // fontFamily: 'Lato',
    },
    disabledText: {
        color: '#9b9b9b',
        borderBottom: '1px solid #9b9b9b;',
        fontSize: '1.1rem',
        // fontFamily: 'Lato',
    },
    leftHeader: {
        color: '#000',
        borderBottom: '1px solid #000;',
        fontSize: '1.5rem',
        textAlign: 'left',
        margin: '2rem 0 0 0',
    },
    heavyText: {
        fontWeight: 400,
    },
    topSpacer: {
        marginTop: '2rem',
    },
    panel: {
        background: '#e3e3e3d9',
        paddingTop: '.5rem',
        paddingBottom: '.5rem',
        textAlign: 'center',
    },
    couponCode: {
        color: '#000',
        fontSize: '1.5rem',
        textAlign: 'left',
        width: '100%',
    },
    priceText: {
        fontSize: '1rem',
        margin: '.5rem 0',
    },
    msrpTag: {
        textAlign: 'Left',
        fontWeight: 400,
        fontSize: '1rem',
        textTransform: 'Capitalize',
        textDecoration: 'line-through',
    },
    priceTextSave: {
        fontSize: '1rem',
        margin: '.5rem 0',
        color: '#E61610',
    },
    hide: {
        visibility: 'hidden',
    },
    priceTotal: {
        fontSize: '1.2rem',
        margin: '.5rem 0',
        // borderTop: '1px solid #000',
    },
    priceSaving: {
        fontSize: '1.2rem',
        margin: '.5rem 0',
        color: '#E61610',
    },
    atcButton: {
        background: '#ebebeb',
        // borderRadius: '50px',
        minWidth: '12rem',
        marginRight: '1rem',
    },
    heavyText: {
        fontWeight: 400,
        textAlign: 'center',
    },
    subText: {
        textAlign: 'center',
        fontWeight: 300,
        width: '80%',
        margin: '1rem auto',
    },
    listStyle: {
        listStyle: 'none',
        fontSize: '1.5rem',
        fontWeight: '300',
        padding: 0,
        // margin: '1.5rem auto',
    },
    itemSpacer: {
        // margin: '1.25rem',
        textAlign: 'center',
        fontSize: '1.2rem',
    },
    divider: {
        width: '100%',
        borderTop: '1px solid black',
        marginTop: '1rem',
        marginBottom: '1rem',
        textAlign: 'center',
    },
    leftHeader: {
        color: '#000',
        borderBottom: '1px solid #000;',
        fontSize: '1.5rem',
        textAlign: 'left',
        margin: '1rem 0',
    },

    leftHeader2: {
        color: '#000',
        borderBottom: '1px solid #000;',
        fontSize: '1.2rem',
        textAlign: 'left',
        margin: '1rem 0',
    },
    button: {
        background: '#ebebeb',
        // minWidth: '5rem',
        // margin: '1rem',
    },

    card: {
        maxWidth: 300,
        // margin: 'auto',
        marginBottom: '2rem',
        transition: '0.3s',
        boxShadow: '0 8px 40px -12px rgba(0,0,0,0.5)',
        '&:hover': {
            boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
            // backgroundColor: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
        },
    },

    media: {
        width: '6rem',
        height: '6rem',
        alignItems: 'center',
        // margin: '1rem',
    },
    content: {
        textAlign: 'center',
    },
    heading: {
        fontWeight: 'bold',
    },
    subheading: {
        lineHeight: 1.8,
    },
    avatar: {
        display: 'inline-block',
        border: '2px solid white',
    },
    imageContainer: {
        width: '70%',
        // margin: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        // marginLeft: '6rem',
    },
    video: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        border: '1px solid grey',
    },
    heavyText1: {
        fontWeight: 400,
        textAlign: 'center',
        // marginTop: '2rem',
    },
    align: {
        alignSelf: 'center',
        textAlign: 'center',
    },
    colorIcon: {
        zIndex: 9,
        height: '3rem',
        margin: '0.25rem',
        width: '3rem',
    },
    removeButton: {
        border: 'none',
    },
    dropDown: {
        backgroundColor: '#ddd',
        color: 'black',
        margin: '.1rem',
        border: 'none',
    },
    programName: {
        textAlign: 'Left',
        fontWeight: 400,
        fontSize: '1.5rem',
        textTransform: 'Capitalize',
    },
    checkoutButton: {
        backgroundColor: '#EF4036',
        color: 'white',
        border: '1px solid grey',
        width: '90%',
        margin: '1rem',
    },
};

const SwatchCheckout = () => {
    const cart = useSelector((state) => state.swatchCart);

    const { swatchShippingAddress, swatchCartItems } = cart;
    const fabricCart = swatchCartItems;
    const [initialAddress1, setInitialAddress1] = useState();
    const [initialAddress2, setInitialAddress2] = useState();
    const [initialZip, setInitialZip] = useState();

    useEffect(() => {
        setInitialAddress1(swatchShippingAddress?.addressLine1);
        setInitialAddress2(swatchShippingAddress?.addressLine2);
        setInitialZip(swatchShippingAddress?.postalCode);
    }, [swatchShippingAddress?.addressLine1]);

    const [firstName, setFirstName] = useState(swatchShippingAddress?.firstName);
    const [lastName, setLastName] = useState(swatchShippingAddress?.lastName);
    const [phoneNumber, setPhoneNumber] = useState(
        swatchShippingAddress?.phoneNumber,
    );
    const [email, setEmail] = useState(swatchShippingAddress?.email);

    const [addressLine1, setAddressLine1] = useState(
        swatchShippingAddress?.addressLine1,
    );
    const [addressLine2, setAddressLine2] = useState(
        swatchShippingAddress?.addressLine2,
    );
    const [province, setProvince] = useState(swatchShippingAddress?.province);
    const [city, setCity] = useState(swatchShippingAddress?.city);
    const [country, setCountry] = useState(swatchShippingAddress?.country);
    const [postalCode, setPostalCode] = useState(
        swatchShippingAddress?.postalCode,
    );

    const [addressErrorText, setAddressErrorText] = useState();
    const [zipErrorText, setZipErrorText] = useState();
    const [phoneErrorText, setPhoneErrorText] = useState();

    const dispatch = useDispatch();

    const taxForProvince = () => {
        var provinceL = province.toLowerCase();
        var taxRate = 0;
        switch (provinceL) {
            case 'on' || 'ontario':
                taxRate = 0.13;
                break;
            case 'ab' || 'alberta':
                taxRate = 0.05;
                break;
            case 'bc' || 'british colombia':
                taxRate = 0.12;
                break;
            case 'mb' || 'manitoba':
                taxRate = 0.12;
                break;
            case 'qc' || 'quebec':
                taxRate = 0.14975;
            default:
                taxRate = 0.15;
        }
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        await dispatch(
            saveSwatchShippingAddress({
                firstName,
                lastName,
                phoneNumber,
                addressLine1,
                addressLine2,
                city,
                province,
                country,
                postalCode,
                email,
            }),
        ).then(async () => {
            console.log(swatchShippingAddress);
            await dispatch(
                createSwatchOrder({
                    swatchCartItems,
                    swatchShippingAddress,
                }),
            ).then(async () => {
                alert('Your order has been submitted successfully.');
                await dispatch(resetSwatchCart());
                window.location.href = '/';
            });

        });
    };

    return (
        <>
            <Menu3 />
            <Container>
                <p style={styles.leftHeader}> Shipping Details</p>
                <hr style={styles.divider} />
                <Grid container>
                    <div className='fixedOnTop'>

                        <div id='sideboxContent' class='sideboxContent'>
                            <div style={{ maxHeight: '800px' }}>
                                <ul id='product_1523' class='cartBlock'>
                                    <li>
                                        {/* Cart Items */}
                                        {fabricCart.length != 0 &&
                                            fabricCart.map((item, index) => {
                                                return (
                                                    <>
                                                        <Grid
                                                            container
                                                            direction='row'
                                                            alignItems='center'
                                                            style={styles.cartItem}
                                                            key={index}>
                                                            <Grid item xs={3}>
                                                                {/* <span style={styles.headText}>
                                  {item.shade}
                                </span> */}
                                                                {
                                                                    <img
                                                                        src={item.item.image.toLowerCase()}
                                                                        alt='sample'
                                                                        style={styles.colorIcon}
                                                                    />
                                                                }
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <span style={styles.headText}>
                                                                    {item.item.name}
                                                                </span>
                                                            </Grid>


                                                        </Grid>
                                                    </>
                                                );
                                            })}
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    spacing={0}>

                    <Grid item sm={12} md={4}>

                        <form
                            onSubmit={submitHandler}
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '20rem' },
                            }}
                            autoComplete='off'
                            justifyContent='space-between'>
                            <TextField
                                style={{ margin: '.5rem 0 0 0', width: '18rem' }}
                                size='small'
                                variant='outlined'
                                id='outlined-multiline-flexible'
                                label='First Name'
                                required
                                value={firstName}
                                type='text'
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            <TextField
                                style={{ margin: '1rem 0 0 0', width: '18rem' }}
                                size='small'
                                variant='outlined'
                                id='outlined-multiline-flexible'
                                label='Last Name'
                                required
                                value={lastName}
                                type='text'
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            <TextField
                                style={{ margin: '1rem 0 0 0', width: '18rem' }}
                                size='small'
                                variant='outlined'
                                id='outlined-multiline-flexible'
                                label='Phone Number'
                                type='text'
                                min='10'
                                max='10'
                                required
                                helperText={phoneErrorText}
                                error={phoneErrorText}
                                onChange={(e) => {
                                    let str = e.target.value;
                                    str = str.replace(/\s/g, '');
                                    str = str.replaceAll('-', '');

                                    if (str.match('^[0-9]{10}$') != null) {
                                        setPhoneErrorText('');
                                        setPhoneNumber(str);
                                    } else {
                                        setPhoneErrorText('invalid phone #');
                                    }
                                }}
                            />
                            <TextField
                                style={{ margin: '1rem 0 0 0', width: '18rem' }}
                                size='small'
                                variant='outlined'
                                id='outlined-multiline-flexible'
                                label='Address Line 1'
                                required
                                //value={addressLine1}
                                defaultValue={initialAddress1}
                                helperText={addressErrorText}
                                error={addressErrorText}
                                type='text'
                                onChange={(e) => {
                                    if (
                                        e.target.value
                                            .replace(/\s/g, '')
                                            .match("^[a-zA-Z0-9s,'-]*$")
                                    ) {
                                        setAddressErrorText('');
                                        setAddressLine1(e.target.value);
                                    } else {
                                        setAddressErrorText('invalid address');
                                    }
                                }}
                                pattern="^[a-zA-Z0-9\s,'-]*$"
                            />
                            <TextField
                                style={{ margin: '1rem 0 0 0', width: '18rem' }}
                                size='small'
                                variant='outlined'
                                id='outlined-multiline-flexible'
                                label='Address Line 2'
                                helperText={addressErrorText}
                                error={addressErrorText}
                                defaultValue={initialAddress2}
                                type='text'
                                onChange={(e) => {
                                    if (
                                        e.target.value
                                            .replace(/\s/g, '')
                                            .match("^[a-zA-Z0-9s,'-]*$")
                                    ) {
                                        setAddressErrorText('');
                                        setAddressLine2(e.target.value);
                                    } else {
                                        setAddressErrorText('invalid address');
                                    }
                                }}
                            />
                            <TextField
                                style={{ margin: '1rem 0 0 0', width: '18rem' }}
                                size='small'
                                variant='outlined'
                                id='outlined-multiline-flexible'
                                label='City'
                                required
                                value={city}
                                type='text'
                                onChange={(e) => setCity(e.target.value)}
                            />
                            <TextField
                                style={{ margin: '1rem 0 0 0', width: '18rem' }}
                                size='small'
                                variant='outlined'
                                id='outlined-multiline-flexible'
                                label='Province'
                                required
                                value={province}
                                type='text'
                                onChange={(e) => setProvince(e.target.value)}
                            />{' '}
                            <TextField
                                style={{ margin: '1rem 0 0 0', width: '18rem' }}
                                size='small'
                                variant='outlined'
                                id='outlined-multiline-flexible'
                                label='Country'
                                required
                                value={country}
                                type='text'
                                onChange={(e) => setCountry(e.target.value)}
                            />
                            <TextField
                                style={{ margin: '1rem 0 0 0', width: '18rem' }}
                                size='small'
                                variant='outlined'
                                id='outlined-multiline-flexible'
                                label='Email'
                                required
                                value={email}
                                type='email'
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                style={{ margin: '1rem 0 0 0', width: '18rem' }}
                                size='small'
                                variant='outlined'
                                id='outlined-multiline-flexible'
                                label='Postal Code'
                                required
                                helperText={zipErrorText}
                                error={zipErrorText}
                                defaultValue={initialZip}
                                //value={postalCode}
                                type='text'
                                onChange={(e) => {
                                    let str = e.target.value.replace(/\s/g, '');

                                    if (
                                        str.match(
                                            '[A-Za-z]{1}[0-9]{1}[A-Za-z]{1}[0-9]{1}[A-Za-z]{1}[0-9]{1}',
                                        )
                                    ) {
                                        setZipErrorText('');
                                        setPostalCode(str.toUpperCase());
                                    } else {
                                        setZipErrorText(
                                            'Postal Code must be in the format A1A 1A1',
                                        );
                                    }
                                }}
                            />
                            <br></br>
                            <Button
                                className='redButton'
                                style={{
                                    margin: '1rem 0rem',
                                    width: '10rem',
                                    backgroundColor: '#ef4036',
                                    color: '#fff',
                                }}
                                type='submit'>
                                Submit
                            </Button>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default SwatchCheckout;
