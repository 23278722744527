/** @format */

import axios from 'axios';
import Dexie from 'dexie';
import { useLiveQuery } from 'dexie-react-hooks';
import { RetrieveDB } from '../components/wallPaperComponents/DexieDB';

const API = process.env.REACT_APP_OCS_WALLPAPER_API;

export function setWallPaperDims(dispatch, value) {
  dispatch({
    type: 'WALL_DIM_SET',
    payload: value,
  });
}

export function setWallPaperFrames(dispatch, value) {
  dispatch({
    type: 'WALL_FRAME_SET',
    payload: value,
  });
}

export async function CallWallpaperAPI(wallpaperArr, user) {
  for (var i = 0; i < wallpaperArr.length; i++) {
    axios.post(
      `${API}` + 'orders', //needs env var
      {
        dims: wallpaperArr[i].dims,
        frames: wallpaperArr[i].frames,
        customer: { id: user + '@' + wallpaperArr[i].indexDB },
        printed: 0,
      },
    );

    var StoreDB = new Dexie(wallpaperArr[i].indexDB.toString());
    var dbverS = 10;
    StoreDB.version(++dbverS).stores({ usrImages: '++id,used,image' });

    var promiseArr = [];
    await StoreDB.usrImages.each((item, curser) => {
      promiseArr.push(
        new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.readAsDataURL(item.image);
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.onerror = function () {};
        }),
      );
    });

    Promise.all(promiseArr).then((values) => {
      for (var k = 0; k < values.length; k++) {
        axios.post(`${API}` + 'data', {
          orderID: user + '@' + wallpaperArr[i - 1].indexDB,
          imgID: k,
          b64: values[k],
        });
      }
    });
  }
}
