/** @format */

import React, { useState } from 'react';
import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../actions/userActions';
import Message from '../components/Message';
import { Loader } from '../components/Loader';
import Menu3 from '../components/Menu3';

const styles = {
  heavyText: {
    fontWeight: 400,
    textAlign: 'center',
  },
  divider: {
    width: '100%',
    borderTop: '1px solid black',
    marginTop: '1rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  button: {
    border: '1px solid black',
    background: 'transparent',
    color: 'black',
    backgroundColor: '#EEEBDD',
    textTransform: 'capitalize',
    minWidth: '10rem',
    hover: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    width: '20rem',
  },
};

const PasswordResetScreen = () => {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [password1ErrorText,setPassword1ErrorText]=useState()
  const [password2ErrorText,setPassword2ErrorText]=useState()

  const [id, setId] = useState(
    window.location.search.replace('?', '').split(',')[0].split('=')[1],
  ); //location.search returns whole query string
  const [valid, setValid] = useState(
    window.location.search.replace('?', '').split(',')[1].split('=')[1],
  );
  const dispatch = useDispatch();

  const [passwordErrorText, setPasswordErrorText] = useState('');
  let history = useHistory();
  const submitHandler = (e) => {
    e.preventDefault();
    if (password1 == password2) {
      resetPassword(id, password1, valid);
      history.push('/login');
    }
    //dispatch(login(email, password));
  };

  return (
    <Container>
      <Menu3/>
      <Typography variant='h4' style={styles.heavyText}>
        Reset Password
      </Typography>

      <hr style={styles.divider} />
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'>
        <form onSubmit={submitHandler}>
          <Grid
            style={{ width: '20rem' }}
            container
            direction='column'
            justifyContent='center'
            alignItems='center'>
            <TextField
              style={{ marginTop: '2rem', width: '20rem' }}
              variant='outlined'
              id='outlined-multiline-flexible'
              label='Password'
              required
              //value={password}
              error={password1ErrorText}
              helperText={password1ErrorText}
              type='password'
              onChange={(e) => {
                let str = e.target.value
                //console.log(str.match('[0-9]') != null && str.length>=6)
                if ( str.match('[0-9]') != null && str.length>=6)
                 {
                  setPassword1ErrorText('');
                  setPassword1(str);
                } else {
                  setPassword1ErrorText('passwords must be 6 characters long and contain numbers');
                }
                //setPassword(e.target.value)
              }}
            />
            <TextField
              style={{
                marginTop: '2rem',
                width: '20rem',
                marginBottom: '2rem',
              }}
              variant='outlined'
              id='outlined-multiline-flexible'
              label='Confirm Password'
              required
              //value={confirmPassword}
              error={password2ErrorText}
              helperText={password2ErrorText}
              type='password'
              onChange={(e) => {
                let str = e.target.value
                //console.log(str.match('[0-9]') != null && str.length>=6)
                if ( str == password1)
                 {
                  setPassword2ErrorText('');
                  setPassword2(str);
                } else {
                  setPassword2ErrorText('passwords must match');
                }
                //setPassword(e.target.value)
              }}
            />
            <Button type='submit' style={styles.button}>
              reset
            </Button>
          </Grid>
        </form>
        <p>Don't have account?</p>

        <Link to={'/accountRecovery'}>
          <p>Forgot your password?</p>
        </Link>
      </Grid>
      {/* <hr style={styles.divider} /> */}
    </Container>
  );
};

export default PasswordResetScreen;
