/** @format */

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Container,
  Typography,
  FormControl,
  FormGroup,
  Checkbox,
  MenuItem,
  InputLabel,
  Select,
  Radio,
  Snackbar,
  FormControlLabel,
  withStyles,
  Card,
  Button,
  CardContent,
  CardMedia,
  Divider,
  TextField,
} from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
// import { Button } from 'react-bootstrap';
import { filterFabricsByGroup } from '../actions/blindActions';
import Menu3 from '../components/Menu3';
import './style.css';
import { color } from '@mui/system';
import { addToSwatchCart, removeFromSwatchCart } from '../actions/cartActions';
import { useDispatch, useSelector } from 'react-redux';

const styles = {
  heavyText: {
    fontWeight: 400,
    textAlign: 'center',
  },
  subText: {
    textAlign: 'center',
    fontWeight: 300,
    width: '80%',
    margin: '1rem auto',
  },
  listStyle: {
    listStyle: 'none',
    fontSize: '1.5rem',
    fontWeight: '300',
    padding: 0,
    // margin: '1.5rem auto',
  },
  itemSpacer: {
    // margin: '1.25rem',
    textAlign: 'center',
    fontSize: '1.2rem',
  },
  divider: {
    width: '100%',
    borderTop: '1px solid black',
    marginTop: '1rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  leftHeader: {
    color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '1.5rem',
    textAlign: 'left',
    margin: '1rem 0',
  },

  leftHeader2: {
    color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '1.2rem',
    textAlign: 'left',
    margin: '1rem 0',
  },
  button: {
    background: '#ebebeb',
    // minWidth: '5rem',
    // margin: '1rem',
  },

  card: {
    maxWidth: 300,
    // margin: 'auto',
    marginBottom: '2rem',
    transition: '0.3s',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.5)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
      // backgroundColor: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
    },
  },

  media: {
    width: '6rem',
    height: '6rem',
    alignItems: 'center',
    // margin: '1rem',
  },
  content: {
    textAlign: 'center',
  },
  heading: {
    fontWeight: 'bold',
  },
  subheading: {
    lineHeight: 1.8,
  },
  avatar: {
    display: 'inline-block',
    border: '2px solid white',
  },
  imageContainer: {
    width: '70%',
    // margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    // marginLeft: '6rem',
  },
  video: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    border: '1px solid grey',
  },
  heavyText1: {
    fontWeight: 400,
    textAlign: 'center',
    // marginTop: '2rem',
  },
  align: {
    alignSelf: 'center',
    textAlign: 'center',
  },
  colorIcon: {
    zIndex: 9,
    height: '3rem',
    margin: '0.25rem',
    width: '3rem',
  },
  removeButton: {
    border: 'none',
  },
  dropDown: {
    backgroundColor: '#ddd',
    color: 'black',
    margin: '.1rem',
    border: 'none',
  },
  programName: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1.5rem',
    textTransform: 'Capitalize',
  },
  checkoutButton: {
    backgroundColor: '#ef4036',
    color: 'white',
    border: '1px solid grey',
    width: '90%',
    margin: '1rem',
  },
};

const FabricSwatch = () => {
  const dispatch = useDispatch();
  const [series, setSeries] = useState([]);
  const [selectedSeries, setSelectedSeries] = useState('All');
  const [selectedFabric, setSelectedFabric] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('All');
  const [selectedShade, setSelectedShade] = useState('roller');
  const [filteredFabrics, setFilteredFabrics] = useState([]);
  const [invalidToast, setInvalidToast] = useState(false);
  const [cartToast, setCartToast] = useState(false);
  const { swatchCartItems } = useSelector((state) => state.swatchCart);
  const fabricCart = swatchCartItems;
  const [allGroups, setAllGroups] = useState([]);
  const [allSeries, setAllSeries] = useState([]);
  const [allFabrics, setAllFabrics] = useState([]);

  useEffect(() => {
    loadFabrics('roller');
  }, []);

  const handleCheckout = () => {
    if (fabricCart.length > 0) {
      window.location.href = '/swatchcheckout';
    }
  };

  const showFabric = (e) => {
    setSelectedShade(e.currentTarget.id);
    loadFabrics(e.currentTarget.id);
  };

  const handleFabric = (event) => {
    if (existsInCart(event)) return;
    setSelectedFabric(event);
    console.log(event);
    addToCart(event);
  };

  const existsInCart = (fabric) => {
    let img = '';
    filteredFabrics.filter((fab) => {
      fab.image.map((image, i) => {
        let temp = fab.series[i] + '||' + fab.colour[i] + '||' + fab.code[i];
        if (temp.toLowerCase() == fabric.toLowerCase()) {
          img = image;
        }
      });
    });
    let itemToCheck = {
      shade:
        selectedShade.charAt(0).toUpperCase() +
        selectedShade.slice(1) +
        ' Shade',
      name: fabric.split('||')[0] + ' ' + fabric.split('||')[1],
      image: img,
      code: fabric.split('||')[2],
      type: fabric.split('||')[0],
      series: fabric.split('||')[1],
    };
    let exists = false;
    fabricCart.filter((item) => {
      if (
        item.item.name == itemToCheck.name &&
        item.item.shade == itemToCheck.shade &&
        item.item.code == itemToCheck.code &&
        item.item.type == itemToCheck.type &&
        item.item.series == itemToCheck.series
      ) {
        exists = true;
      }
    });
    console.log(exists);
    return exists;
  };

  const addToCart = (fabric) => {
    // Check if length of cart is 5
    if (fabricCart.length >= 5) {
      alert("You can't add more than 5 fabrics to cart");
      return;
    }

    setCartToast(true);

    // Get image of selected fabric from the filteredFabrics array
    let img = '';
    filteredFabrics.filter((fab) => {
      fab.image.map((image, i) => {
        let temp = fab.series[i] + '||' + fab.colour[i] + '||' + fab.code[i];
        if (temp.toLowerCase() == fabric.toLowerCase()) {
          img = image;
        }
      });
    });
    // Make first letter capital of selectedShade

    let item = {
      shade:
        selectedShade.charAt(0).toUpperCase() +
        selectedShade.slice(1) +
        ' Shade',
      name: fabric.split('||')[0] + ' ' + fabric.split('||')[1],
      image: img,
      code: fabric.split('||')[2],
      type: fabric.split('||')[0],
      series: fabric.split('||')[1],
    };
    console.log(item);
    dispatch(addToSwatchCart({ item }));
    // Scroll to top of page
    // window.scrollTo(0, 0);
  };

  const handleInvalidToast = () => {
    setInvalidToast(false);
  };

  const handleCartToast = () => {
    setCartToast(false);
  };

  const loadFabrics = (shade) => {
    filterFabricsByGroup(24, shade + ' shade', 'All', 'All', null).then(
      (res) => {
        let tempSeries = [];
        let tempGroups = [];
        for (let i = 0; i < res.length; i++) {
          // If series tarts with Cs, make it CS

          for (let j = 0; j < res[i].image.length; j++) {
            res[i].image[j] = res[i].image[j].replace('image/', 'images/');
            // Check if jpg does not exist in the image name
            if (res[i].image[j].indexOf('.jpg') === -1)
              res[i].image[j] += res[i].colour[j] + '.jpg';
          }
        }
        res.map((fabric) => {
          for (let i = 0; i < fabric.series.length; i++) {
            // Make each word in series capital
            fabric.series[i] = fabric.series[i]
              .split(' ')
              .map((word) => {
                return word.charAt(0).toUpperCase() + word.slice(1);
              })
              .join(' ');
            // Make each word in group capital
            fabric.group = fabric.group.map((word) => {
              return word.charAt(0).toUpperCase() + word.slice(1);
            });
            tempSeries.push(fabric.series[i]);
            tempGroups.push(fabric.group[0]);
          }
        });
        tempSeries = [...new Set(tempSeries)];
        tempGroups = [...new Set(tempGroups)];
        setAllSeries(tempSeries);
        setAllGroups(tempGroups);
        setAllFabrics(res);
        setSeries(tempSeries);
        setFilteredFabrics(res);
      },
    );
  };

  const removeFabric = (fabric) => {
    // Remove item from fabricCart
    dispatch(removeFromSwatchCart(fabric));
  };

  return (
    <>
      <Menu3 />
      <Container>
        <Grid container>
          <div className='fixedOnTop'>
            <div class='samplesTitle'>Samples Cart</div>
            <div id='sideboxContent' class='sideboxContent'>
              <div style={{ maxHeight: '800px' }}>
                <ul id='product_1523' class='cartBlock'>
                  <li>
                    {/* Cart Items */}
                    {fabricCart.length != 0 &&
                      fabricCart.map((item, index) => {
                        return (
                          <>
                            <Grid
                              container
                              direction='row'
                              alignItems='center'
                              style={styles.cartItem}
                              key={index}>
                              <Grid item xs={3}>
                                {/* <span style={styles.headText}>
                                  {item.shade}
                                </span> */}
                                {
                                  <img
                                    src={item.item.image.toLowerCase()}
                                    alt='sample'
                                    style={styles.colorIcon}
                                  />
                                }
                              </Grid>
                              <Grid item xs={5}>
                                <span style={styles.headText}>
                                  {item.item.name}
                                </span>
                              </Grid>

                              <Grid item xs={3}>
                                <Button
                                  color='primary'
                                  style={styles.removeButton}
                                  onClick={() => removeFabric(item)}>
                                  <HighlightOffIcon />
                                  {/* Remove */}
                                </Button>
                              </Grid>
                            </Grid>
                          </>
                        );
                      })}
                  </li>
                  {/* Checkout button */}
                  <Button
                    variant='contained'
                    color='primary'
                    style={styles.checkoutButton}
                    onClick={handleCheckout}>
                    Checkout
                  </Button>
                </ul>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          container
          item
          lg={8}
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
          >
          <p style={styles.leftHeader}>Select your Swatches</p>
          <hr style={styles.divider} />
          <div class='row text-center g-4'>
            <div class='col-md'>
              <div class='card-body '>
                <img
                  class=' img-fluid'
                  src='/images/newHome/roller-shade-test-1 1.png'
                  alt=''
                />
                <button  class='btn btn-outline-dark my-4 mainPageBtn buttonMainPage'
                  id='roller'
                  // className='sampleCatLink b-loaded'
                  onClick={(e) => {
                    showFabric(e);
                  }}>
                  Roller Shades
                </button>
              </div>
            </div>
            <div class='col-md'>
              <div class='card-body '>
                <img
                  class='img-fluid'
                  src='/images/newHome/banded-shade-test-1 1.png'
                  alt=''
                />
                <button  class='btn btn-outline-dark my-4 mainPageBtn buttonMainPage'
                  id='banded'
                  onClick={(e) => {
                    showFabric(e);
                  }}>
                  Banded Shades
                </button>
              </div>
            </div>
            <div class='col-md'>
              <div class='card-body '>
                <img
                  class=' img-fluid'
                  src='/images/newHome/sheer-shade-test-1 2.png'
                  alt=''
                />
                <button  class='btn btn-outline-dark my-4 mainPageBtn buttonMainPage'
                  id='sheer'
                  onClick={(e) => {
                    showFabric(e);
                  }}>
                  Sheer Shades
                </button>
              </div>
            </div>
          </div>
          {filteredFabrics.length != 0 && (
            <Grid className='samplesCategoryList'>
              {/* A list of images and label under them in a row */}
              <Grid>
                <p style={styles.leftHeader2}>Fabrics by Colours</p>
                <hr style={styles.divider} />
                <Grid
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='center'>
                  {filteredFabrics.map((index) => {
                    return (
                      <Grid>
                        <Accordion>
                          <AccordionSummary
                            style={styles.dropDown}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'>
                            <Typography>{index.colourFamily}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container direction='row'>
                              {index.image.map((image, i) => {
                                return (
                                  <div
                                    style={{ width: '15%', margin: '5px' }}
                                    className='fabricsList'>
                                    <div>
                                      <div
                                        className='colorSwatch'
                                        style={{
                                          backgroundImage: `url(${encodeURI(
                                            image.toLowerCase(),
                                          )})`,
                                        }}></div>

                                      <div className='colorName'>
                                        {index.colour[i]}
                                      </div>
                                    </div>
                                    <a
                                      className='addSample'
                                      target='_blank'
                                      onClick={(e) =>
                                        handleFabric(
                                          index.series[i] +
                                            '||' +
                                            index.colour[i] +
                                            '||' +
                                            index.code[i],
                                        )
                                      }>
                                      {existsInCart(
                                        index.series[i] +
                                          '||' +
                                          index.colour[i] +
                                          '||' +
                                          index.code[i],
                                      )
                                        ? 'Added'
                                        : 'Add to cart'}
                                    </a>
                                  </div>
                                );
                              })}{' '}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    );
                  })}{' '}
                </Grid>
              </Grid>

              <Snackbar
                open={invalidToast}
                autoHideDuration={6000}
                onClose={handleInvalidToast}>
                <Alert severity='error'>
                  <AlertTitle>Please select a fabric first.</AlertTitle>
                </Alert>
              </Snackbar>
              <Snackbar
                open={cartToast}
                autoHideDuration={6000}
                onClose={handleCartToast}>
                <Alert severity='success'>
                  <AlertTitle>
                    The swatch has been added to the cart successfully.
                  </AlertTitle>
                </Alert>
              </Snackbar>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default FabricSwatch;
