/** @format */

import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { Rating } from '@mui/material';
import MobileDetect from 'mobile-detect';

import Product, { buildRenderUrl } from '../components/Product';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { Alert, AlertTitle } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import {
  getProgramsByName,
  getSimilarFabricType,
} from '../actions/productActions';
import { addToCart, addToSwatchCart } from '../actions/cartActions';
import {
  customizeProduct,
  filterFabricsByGroup,
} from '../actions/blindActions';
import { Link } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Magnifier from 'react-magnifier';
import { ReviewDisplay } from '../components/reviewDisplay';
import {
  fetchProductReview,
  fetchReviewSummary,
} from '../actions/userReviewActions';

import InfoIcon from '@mui/icons-material/Info';
import RollerPDF from '../pdf/Open Roll.pdf';
import Fascia from '../pdf/Fascia.pdf';
import Aria from '../pdf/Aria.pdf';
import CcPDF from '../pdf/Curved Cassettes.pdf';
import Menu3 from '../components/Menu3';
import { getDeflection } from '../actions/blindActions';
import { addEmailToCartMailChimp } from '../actions/userActions';

import Carousel from 'react-multi-carousel';
import './sliderStyles.css';
import LoadingOverlay from 'react-loading-overlay';

const styles = {
  heavyText: {
    fontWeight: 300,
  },
  lightText: {
    fontWeight: 300,
  },
  image: {
    width: '100%',
  },
  imageContainer: {
    width: '75%',
    // margin: 'auto',
  },
  programName: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1.5rem',
    textTransform: 'Capitalize',
  },
  collectionName: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'Capitalize',
  },
  msrpTag: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'Capitalize',
  },
  ourTag: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'Capitalize',
    color: '#E61610',
  },
  msrPrice: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1.5rem',
    textTransform: 'Capitalize',
  },
  ourPrice: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1.5rem',
    textTransform: 'Capitalize',
    color: '#E61610',
  },
  divider: {
    width: '100%',
    borderTop: '1px solid',
    borderColor: 'rgb(96, 96, 96, 0.4)',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  dividerSmall: {
    width: '100%',
    borderTop: '1px solid',
    borderColor: 'rgb(96, 96, 96, 0.4)',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  dividerSmallTabs: {
    width: '100%',
    borderTop: '1px solid',
    borderColor: 'rgb(96, 96, 96, 0.4)',
  },
  formControl: {
    margin: ' 2px',
    // minWidth: '50%',
  },
  colorIcon: {
    zIndex: 9,
    // margin: '20px'
    // borderRadius: '50%',
    // border: '1px solid black',
    height: '3rem',
    // minWidth: '3rem',
    margin: '0.25rem',

    width: '3rem',
  },
  recPic: {
    zIndex: 9,
    // margin: '20px'
    borderRadius: '50%',
    // border: '1px solid black',
    height: '3rem',
    // minWidth: '3rem',
    margin: '0.25rem',

    width: '3rem',
  },
  colorIconCon: {
    position: 'relative',
  },
  colorIconBtn: {
    // zIndex: 100,
    // borderRadius: '50%',

    border: '1px solid grey',
    height: '3rem',
    width: '3rem',
    minWidth: '3rem',
    margin: '0.25rem',
    position: 'absolute',
  },
  colorIconActive: {
    // borderRadius: '50%',
    // border: '2px solid #E61610',
    height: '2rem',
    width: '2rem',
    minWidth: '2rem',
    margin: '0.25rem',
  },
  colorSwatch: {
    borderRadius: '0%',
    // border: '1px solid black',
    height: '6rem',
    width: '6rem',
    // minWidth: '2rem',
    margin: '0.5rem',
  },

  aboutShade: {
    textAlign: 'center',
    fontWeight: 300,
    fontSize: '1.5rem',
    textTransform: 'Capitalize',
    margin: '1rem 0',
  },
  smallImage: {
    width: '80%',
    margin: 'auto',
  },
  smallText: {
    // maxWidth: '100%',
    margin: '0 1rem 0 0',
    lineHeight: '1.5rem',
  },
  featuresList: {
    textAlign: 'left',
    lineHeight: '1.5rem',
    width: '100%',
    margin: '1rem auto',
  },
  // containerVertical: {
  //   marginTop: '1rem',
  //   marginBottom: '1rem',
  // },
  lineSpacer: {
    margin: '1rem auto',
  },

  lineSpacerBtn: {
    padding: '2rem 2rem',
    textAlign: 'center',
  },
  atcButton: {
    // background: '#ebebeb',
    margin: '.5rem .5rem',
    minWidth: '10rem',
  },
  helpButtons: {
    background: '#ebebeb',
    minWidth: '15rem',
    margin: '1rem auto',
  },
  infoContainer: {
    textAlign: 'center',
  },
  card: {
    maxWidth: 280,
    // margin: 'auto',
    // marginTop: '2rem',
    // marginBottom: '2rem',
    transition: '0.3s',
  },
  imgContainer: {
    width: '50%',
  },
  recommendedText: {
    // marginTop: '1rem',
    fontStyle: 'italic',
    color: 'dimGrey',
  },
  span: {
    fontWeight: 350,
    fontSize: '.8rem',
    marginLeft: '.2rem',
  },
  spanInfo: {
    fontWeight: 350,
    fontSize: '1rem',
    margin: '1.5rem auto',
  },
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const widthRound = (x) => {
  Math.floor(x);
  switch (true) {
    case x <= 24:
      x = 24;
      return x;
    case x <= 30:
      x = 30;
      return x;
    case x <= 36:
      x = 36;
      return x;
    case x <= 42:
      x = 42;
      return x;
    case x <= 48:
      x = 48;
      return x;
    case x <= 54:
      x = 54;
      return x;
    case x <= 60:
      x = 60;
      return x;
    case x <= 66:
      x = 66;
      return x;
    case x <= 72:
      x = 72;
      return x;
    case x <= 78:
      x = 78;
      return x;
    case x <= 84:
      x = 84;
      return x;
    case x <= 90:
      x = 90;
      return x;
    case x <= 96:
      x = 96;
      return x;
    case x <= 108:
      x = 108;
      return x;
    case x <= 120:
      x = 120;
      return x;
    case x <= 132:
      x = 132;
      return x;
    case x <= 144:
      x = 144;
      return x;
    case x <= 156:
      x = 156;
      return x;
    case x <= 168:
      x = 168;
      return x;
    case x <= 180:
      x = 180;
      return x;
    case x <= 192:
      x = 192;
      return x;
    case x <= 204:
      x = 204;
      return x;
    case x <= 216:
      x = 216;
      return x;
    case x <= 228:
      x = 228;
      return x;
    case x <= 240:
      x = 240;
      return x;
    default:
      x = 240;
      return x;
  }
};

const lengthRound = (x) => {
  Math.floor(x);
  switch (true) {
    case x <= 36:
      x = 36;
      return x;
    case x <= 42:
      x = 42;
      return x;
    case x <= 48:
      x = 48;
      return x;
    case x <= 54:
      x = 54;
      return x;
    case x <= 60:
      x = 60;
      return x;
    case x <= 66:
      x = 66;
      return x;
    case x <= 72:
      x = 72;
      return x;
    case x <= 78:
      x = 78;
      return x;
    case x <= 84:
      x = 84;
      return x;
    case x <= 90:
      x = 90;
      return x;
    case x <= 96:
      x = 96;
      return x;
    case x <= 102:
      x = 102;
      return x;
    case x <= 108:
      x = 108;
      return x;
    case x <= 114:
      x = 114;
      return x;
    case x <= 120:
      x = 120;
      return x;
    case x <= 126:
      x = 126;
      return x;
    case x <= 132:
      x = 132;
      return x;
    case x <= 138:
      x = 138;
      return x;
    case x <= 144:
      x = 144;
      return x;
    case x <= 156:
      x = 156;
      return x;
    case x <= 168:
      x = 168;
      return x;
    case x <= 180:
      x = 180;
      return x;
    case x <= 192:
      x = 192;
      return x;
    case x <= 204:
      x = 204;
      return x;
    case x <= 216:
      x = 216;
      return x;
    case x <= 228:
      x = 228;
      return x;
    case x <= 240:
      x = 240;
      return x;
    default:
      x = 240;
      return x;
  }
};

const whole = [
  // dear ray, Why. Just why man? what reasoning led you to this? <3 max
  24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,
  43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61,
  62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80,
  81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
  100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114,
  115, 116, 117, 118, 119, 120,
];

const fraction = [
  '⠀',
  // '0/16',
  '1/16',
  '1/8',
  '3/16',
  '1/4',
  '5/16',
  '3/8',
  '7/16',
  '1/2',
  '9/16',
  '5/8',
  '11/16',
  '3/4',
  '13/16',
  '7/8',
  '15/16',
];

const useDecodedString = (str) => {
  let decodedValue = null;
  try {
    decodedValue = decodeURI(str);
  } catch (URIError) {
    decodedValue = str;
  }
  return decodedValue;
};

const calculatePrice = (product) => {
  const RETAIL_PRICE =
    (
      product?.Price +
      product?.Valance_Price +
      product?.Operation_Price?.Price
    ).toFixed(2) || 0;

  const DISCOUNT = product.Discount || 0;

  const OUR_PRICE = (RETAIL_PRICE - RETAIL_PRICE * DISCOUNT).toFixed(2);
  const SAVINGS = (RETAIL_PRICE - OUR_PRICE).toFixed(2);
  return { RETAIL_PRICE, OUR_PRICE, SAVINGS };
};

const scrollToReviews = () => {
  const reviews = document.getElementById('reviews');
  reviews.scrollIntoView({ behavior: 'smooth' });
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ProductScreenDetailed = () => {
  const dispatch = useDispatch();
  const { product } = useSelector((state) => state.product);
  const [loading, setLoading] = useState(true);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [stars, setStars] = useState(0);
  const { products } = useSelector((state) => state.products);

  const { swatchCartItems } = useSelector((state) => state.swatchCart);
  const [swatchCartToast, setSwatchCartToast] = useState(false);

  const [selectedColor, setSelectedColor] = useState(product.SG_Color);
  const [widthWhole, setWidthWhole] = useState('24');
  const [widthFraction, setWidthFraction] = useState('');
  const [lengthWhole, setLengthWhole] = useState('36');
  const [lengthFraction, setLengthFraction] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [showToastRev, setShowToastRev] = useState(false);
  const [revDisable, setRevDisable] = useState(false);
  const [measure, setMeasure] = useState('Tight');

  const [image, setImage] = useState('');

  const { name } = useParams();
  const decodedValue = useDecodedString(name);

  let paramColor = useQuery().get('color');

  const { RETAIL_PRICE, OUR_PRICE, SAVINGS } = calculatePrice(product);

  const [newRatingState, setNewRatingState] = useState();
  const history = useHistory();

  const [initialScroll, setInitalScroll] = useState(true);

  const [allFabrics, setAllFabrics] = useState([]);

  //Tabs
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //const scrollToRev = ()=>{
  useLayoutEffect(() => {
    if (window.location.hash == '#reviews') {
      let elem = document.getElementById('reviews');
      if (elem && initialScroll) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [document.getElementById('reviews')]);
  //}
  var reviewSumOBJ = useSelector((state) => state.reviewSummary.value);
  useEffect(() => {
    try {
      setStars(reviewSumOBJ[product?.shade_class].avg);
    } catch {
      setStars(-1);
    }
  }, [product?.Program, product?.SG_Color, reviewSumOBJ]);

  useEffect(() => {
    let fabricType = product?.Fabric_Type;
    if (product?.Type && product?.Type.includes('Banded')) {
      if (
        product?.Fabric.includes('Vintage') &&
        fabricType.includes('Translucent')
      )
        fabricType = 'Translucent transparent';
      else if (
        product?.Program.includes('Tokyo Black Out') ||
        product?.Program.includes('Rome Black Out Banded') ||
        (product?.Type.includes('Banded') &&
          product?.Program.includes('Black Out Shade') &&
          fabricType == 'Blackout')
      )
        fabricType = 'Blackout transparent';
      else if (
        product?.Program.includes('Privacy Banded') &&
        (product?.Fabric == 'Terra' || product?.Fabric == 'Gossamer')
      )
        fabricType = 'Privacy transparent';
    }
    loadFabrics(fabricType);
  }, [product, swatchCartItems]);

  useLayoutEffect(() => {
    dispatch(
      getProgramsByName({
        name: decodedValue,
        color: paramColor,
        width: 24,
        length: 36,
      }),
    );
  }, [decodedValue, paramColor, dispatch]);

  useEffect(() => {
    setImage(
      buildRenderUrl({
        name: product?.Program,
        color: product?.SG_Color,
      }),
    );
    window.scrollTo(0, 0);
    fetchProductReview(dispatch, product);
    fetchReviewSummary(dispatch, products);
  }, [product]);

  var reviewArr = useSelector((state) => state.productReviews);

  let ratingView = {
    false: (
      <Rating
        name='read-only'
        value={stars}
        readOnly
        sx={{ color: '#ef4136' }}
        precision={0.5}
      />
    ),
    true: <p style={styles.lightText}> Be the first to Review!</p>,
  };

  useEffect(() => {
    dispatch(getSimilarFabricType(product.Fabric_Type));
  }, [dispatch, product?.Fabric_Type]);
  //   useHistory

  //color fix --max
  var setCol = (a) => setSelectedColor(a);
  useEffect(() => {
    setCol(product.SG_Color);
  }, [dispatch, product.SG_Color]);

  const addToCartHandler = () => {
    if (userInfo?.email) {
      addEmailToCartMailChimp(userInfo?.email);
    }
    dispatch(
      addToCart({
        productInfo: { ...product },
        optionInfo: {
          widthWhole,
          widthFraction,
          lengthWhole,
          lengthFraction,
          selectedColor,
          measure,
          quantity: 1,
          OUR_PRICE,
          RETAIL_PRICE,
        },
      }),
    );
    setShowToast(true);
    // history.push(`/cart`)
  };

  const loadFabrics = (fabricType) => {
    if (fabricType) {
      console.log('Loading fabrics');
      console.log(product?.Type + ' shade', fabricType, product?.Fabric);
      filterFabricsByGroup(
        24,
        product?.Type.toLowerCase() + ' shade',
        fabricType,
        product?.Fabric,
        null,
      ).then((res) => {
        setLoading(false);
        console.log(res);
        if (res.hasOwnProperty('type')) {
          return;
        }
        for (let i = 0; i < res.length; i++) {
          // If series tarts with Cs, make it CS

          for (let j = 0; j < res[i].image.length; j++) {
            res[i].image[j] = res[i].image[j].replace('image/', '/images/');
            // Check if jpg does not exist in the image name
            if (res[i].image[j].indexOf('.jpg') === -1)
              res[i].image[j] += res[i].colour[j] + '.jpg';
          }
        }
        res.map((fabric) => {
          // Make each word in series capital
          for (let i = 0; i < fabric.series.length; i++) {
            fabric.series[i] = fabric.series[i]
              .split(' ')
              .map((word) => {
                return word.charAt(0).toUpperCase() + word.slice(1);
              })
              .join(' ');
          }
        });

        //

        setAllFabrics(res);
      });
    }
  };

  const showProducts = () => {
    // Group colour and image to their corresponding series
    const uniqueProducts = [...new Set(products.map((item) => item.Program))];
    const productsColors = uniqueProducts.map((item) => {
      let SG_Color = products
        .filter((item2) => item2.Program === item)
        .map((item3) => item3.SG_Color);
      let SG_MinPrice = products
        .filter((item2) => item2.Program === item)
        .map((item3) => item3.Min_Price);

      return { Program: item, SG_Color, SG_MinPrice };
    });
    let ret = productsColors.map((item) => {
      item.SG_MinPrice = Math.min(...item.SG_MinPrice);
      return (
        <Grid item xs={10} sm={4} key={item.Program}>
          <Product product={item} />
        </Grid>
      );
    });
    return ret;
  };

  const customizeHandler = () => {
    product.fabricColorRadio =
      product.Fabric + '||' + product.SG_Color + '||' + product.Fabric_Code;
    product.δ = getDeflection(product.lengthFraction + product.lengthWhole);
    product.invalid = false;
    product.loading = true;
    product.position = 'Right';
    product.valanceArray = null;
    product.spring = null;
    product.originalPrice = OUR_PRICE;
    product.L_Channel = 'None';
    product.defaultBottomBar = product.Bottom_Bar;
    product.Wrap_Type = product.Valance.includes('Wrapped') ? 'Yes' : 'No';
    product.Valance.includes('Reverse Roll')
      ? (product.Roll_Type = 'Reverse')
      : (product.Roll_Type = 'Regular');
    product.Bottom_Bar.includes('Wrapped')
      ? (product.Hem_Wrap_Type = 'Yes')
      : (product.Hem_Wrap_Type = 'No');
    dispatch(
      customizeProduct({
        productInfo: { ...product },
        optionInfo: {
          widthWhole,
          widthFraction,
          lengthWhole,
          lengthFraction,
          selectedColor,
          measure,
          opacity: 1,
          OUR_PRICE,
          RETAIL_PRICE,
          quantity: 1,
        },
      }),
    );
    history.push(`/customize`);
  };

  const handleMeasure = (e) => {
    setMeasure(e.target.value);
  };

  const closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };
  const closeToastRev = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowToastRev(false);
  };

  const widthOptionsWhole = (product) => {
    // var unfiltered = whole.map((number) => {
    //   return <MenuItem value={number}>{number}</MenuItem>;
    // })

    let filtered = whole.filter((x) => {
      return x >= product.Min_Width && x <= product.Max_Width;
    });

    let ret = filtered.map((number) => {
      return <MenuItem value={number}>{number}</MenuItem>;
    });

    return ret;
  };

  const widthOptionsFrac = (product) => {
    if (widthWhole >= product.Max_Width) {
      //setWidthFraction('⠀')
      return <MenuItem value={0}>{'⠀'}</MenuItem>;
    } else {
      let ret = fraction.map((number) => {
        return <MenuItem value={number}>{number}</MenuItem>;
      });
      return ret;
    }
  };

  const lengthOptionsWhole = (product) => {
    let filtered = whole.filter((x) => {
      return x >= product.Min_Height && x <= product.Max_Height;
    });

    let ret = filtered.map((number) => {
      return <MenuItem value={number}>{number}</MenuItem>;
    });

    return ret;
  };

  const lengthOptionsFrac = (product) => {
    if (lengthWhole >= product.Max_Height) {
      //setLengthFraction('⠀')
      return <MenuItem value={0}>{'⠀'}</MenuItem>;
    } else {
      let ret = fraction.map((number) => {
        return <MenuItem value={number}>{number}</MenuItem>;
      });
      return ret;
    }
  };

  const handleFabric = (event) => {
    if (existsInCart(event)) return;
    console.log(event);
    addToSCart(event);
  };

  const existsInCart = (fabric) => {
    let img = '';
    allFabrics.filter((fab) => {
      fab.image.map((image, i) => {
        let temp = fab.series[i] + '||' + fab.colour[i] + '||' + fab.code[i];
        if (temp.toLowerCase() == fabric.toLowerCase()) {
          img = image;
        }
      });
    });
    let selectedShade = product.Type;
    let itemToCheck = {
      shade:
        selectedShade.charAt(0).toUpperCase() +
        selectedShade.slice(1) +
        ' Shade',
      name: fabric.split('||')[0] + ' ' + fabric.split('||')[1],
      image: img,
      code: fabric.split('||')[2],
      type: fabric.split('||')[0],
      series: fabric.split('||')[1],
    };
    let exists = false;

    swatchCartItems.filter((item) => {
      if (
        item.item.name == itemToCheck.name &&
        item.item.shade == itemToCheck.shade &&
        item.item.code == itemToCheck.code &&
        item.item.type == itemToCheck.type &&
        item.item.series == itemToCheck.series
      ) {
        exists = true;
      }
    });
    console.log(exists);
    return exists;
  };

  const addToSCart = (fabric) => {
    if (swatchCartItems.length >= 5) {
      alert("You can't add more than 5 fabrics to cart");
      return;
    }
    setSwatchCartToast(true);

    // Get image of selected fabric from the filteredFabrics array
    let img = '';
    allFabrics.filter((fab) => {
      fab.image.map((image, i) => {
        let temp = fab.series[i] + '||' + fab.colour[i] + '||' + fab.code[i];
        if (temp.toLowerCase() == fabric.toLowerCase()) {
          img = image;
        }
      });
    });
    let selectedShade = product.Type;
    // Make first letter capital of selectedShade

    let item = {
      shade:
        selectedShade.charAt(0).toUpperCase() +
        selectedShade.slice(1) +
        ' Shade',
      name: fabric.split('||')[0] + ' ' + fabric.split('||')[1],
      image: img,
      code: fabric.split('||')[2],
      type: fabric.split('||')[0],
      series: fabric.split('||')[1],
    };
    console.log(item);
    dispatch(addToSwatchCart({ item }));
  };

  const valenceToInstallJson = {
    'Open Roll - Reverse Roll': RollerPDF,
    'Fascia 3inch': Fascia,
    'Fascia 4inch': Fascia,
    'Wrapped Curved Cassette': CcPDF,
    Aria: Aria,
  };
  const installLink = valenceToInstallJson[product.Valance];

  const measureText = `Tight : 1/8' Deduction from width, Finish: Entire shade made to given width, Cloth: Fabric made to given width`;

  var swatchArr = [];

  const [validImgState, setValidImgState] = useState({
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
    7: true,
    8: true,
  });

  var swatchArr = [];
  for (let i = 1; i < 9; i++) {
    if (validImgState[i]) {
      swatchArr.push(
        <Zoom>
          <img
            style={styles.colorSwatch}
            src={`/images/programs/${product?.Program}/swatch/pic${i}.jpg`
              .replaceAll('%', '')
              .replaceAll(' ', '_')
              .toLowerCase()}
            onError={() => {
              let obj = JSON.parse(JSON.stringify(validImgState));
              obj[i] = false;
              setValidImgState(obj);
            }}
          />
        </Zoom>,
      );
    }
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };

  const getDevice = () => {
    let userAgent;
    let deviceType;

    userAgent = navigator.userAgent;

    const md = new MobileDetect(userAgent);
    if (md.tablet()) {
      deviceType = 'tablet';
    } else if (md.mobile()) {
      deviceType = 'mobile';
    } else {
      deviceType = 'desktop';
    }
    return { deviceType };
  };
  <LoadingOverlay
    active={loading}
    spinner
    styles={{
      overlay: (base) => ({
        ...base,
        background: 'none',
      }),
      spinner: (base) => ({
        ...base,
        width: '100px',
        '& svg circle': {
          stroke: '#ef4036',
        },
      }),
    }}
  />;
  return (
    <>
      <Menu3 />
      <Container>
        <Grid container className='widthMarginProduct'>
          {/* Left Image */}
          <Grid item xs={12} sm={5}>
            <Grid className='imageContainer'>
              <img style={styles.image} src={image} alt='shade' />
            </Grid>
            {swatchArr.length > 0 && (
              <Grid className='imageContainer'>
                {/* Slider */}
                <Carousel
                  centerMode={true}
                  responsive={responsive}
                  infinite={true}
                  autoPlay={getDevice() !== 'mobile' ? true : false}
                  deviceType={getDevice()}
                  autoPlaySpeed={3000}>
                  {swatchArr}
                </Carousel>
              </Grid>
            )}
            <Grid className='imageContainer'>
              <div className='productTotalsInnerWrap'>
                <div id='productTotals' className=''>
                  <div className='firstProductSection'>
                    <div className='totalLine'>
                      <label className='productPriceInfo'>Shade Price:</label>
                      <div
                        className='total'
                        style={{ textDecoration: 'line-through' }}>
                        $ {RETAIL_PRICE}
                      </div>
                    </div>
                    <div className='totalLine totalLineRed'>
                      <label className='productPriceInfo'>You Save 30%:</label>
                      <div className='total totalLineRed'>$ {SAVINGS}</div>
                    </div>
                    <div className='totalLine'>
                      <label className='productPriceInfo'>Shipping:</label>
                      <div className='total totalLineRed'>FREE</div>
                    </div>
                    <div
                      className='sale totalLineRed'
                      style={{
                        paddingBottom: 0,
                        marginBottom: 15,
                        borderTop: '1px solid #ccc',
                      }}>
                      <label className='productPriceInfo'>Sale Price:</label>
                      <div className='total totalLineRed'>$ {OUR_PRICE}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          {/* Right Pricing */}
          <Grid item xs={12} sm={7}>
            <Grid className='mobileProductScreen'>
              {/* Line 1 */}
              <Grid container>
                <Grid container item xs={6} className='programName'>
                  <span>{product?.Program}</span>
                </Grid>
                <Grid container justifyContent='space-around' item xs={6}>
                  <img
                    className='badgeMain'
                    src='/images/newHome/Warranty-badge-512x204.png'
                    alt='#'
                  />
                  {/* Warranty-badge-512x204 */}
                </Grid>

                <Grid container item xs={6} className='reviewName'>
                  <span
                    className='reviewNamePading'
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={(e) => scrollToReviews()}>
                    {' '}
                    Reviews
                  </span>
                  <Rating
                    name='read-only'
                    value={stars <= 0 ? 5 : stars}
                    readOnly
                    sx={{ color: '#ef4136' }}
                    precision={0.5}
                  />
                  {stars >= 0 && `(${Math.round(stars * 10) / 10})`}
                </Grid>
              </Grid>

              {/* </Grid> */}

              {/* Line 3 */}
              <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'>
                <Grid item xs={12}>
                  <Typography variant='subtitle1'>
                    {product && product.Description !== null
                      ? product.Description
                      : 'Failed to load Description.'}
                  </Typography>
                </Grid>
              </Grid>

              {/* Dimensions */}
              {/* Width */}

              <Grid container direction='row'>
                <hr style={styles.divider} />
                <Grid item xs={3} className='selfAlign'>
                  {' '}
                  <span className='reviewNamePading'>
                    Width<span style={styles.span}> (in.)</span>
                    <Tooltip
                      title={
                        <>
                          <img
                            style={styles.smallImage}
                            src='/images/customizeToolRenders/width.png'
                            alt='#'
                          />
                        </>
                      }
                      color='disabled'>
                      <InfoIcon />
                    </Tooltip>
                  </span>
                </Grid>
                <Grid xs={3}>
                  {' '}
                  <FormControl
                    size='small'
                    variant='outlined'
                    style={styles.formControl}>
                    <Select
                      id='width-whole'
                      value={widthWhole}
                      name='widthWhole'
                      onChange={(e) => {
                        setWidthWhole(e.target.value);
                        dispatch(
                          getProgramsByName({
                            name: decodedValue,
                            color: paramColor,
                            length: lengthRound(lengthWhole),
                            width: widthRound(e.target.value),
                          }),
                        );
                      }}>
                      {widthOptionsWhole(product)}
                    </Select>
                  </FormControl>
                  <FormControl
                    size='small'
                    variant='outlined'
                    style={styles.formControl}>
                    <Select
                      labelId='demo-simple-select-outlined-label'
                      id='width-frac'
                      value={widthFraction}
                      name='widthFraction'
                      onChange={(e) => setWidthFraction(e.target.value)}>
                      {widthOptionsFrac(product)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3} className='selfAlign'>
                  <span className='reviewNamePading'>
                    Length <span style={styles.span}> (in.)</span>
                    <Tooltip
                      title={
                        <>
                          <img
                            style={styles.smallImage}
                            src='/images/customizeToolRenders/Height.png'
                            alt='#'
                          />
                        </>
                      }
                      color='disabled'>
                      <InfoIcon />
                    </Tooltip>
                  </span>
                </Grid>
                <Grid xs={3}>
                  <FormControl
                    size='small'
                    variant='outlined'
                    style={styles.formControl}>
                    <Select
                      id='length-whole'
                      value={lengthWhole}
                      name='lengthWhole'
                      onChange={(e) => {
                        setLengthWhole(e.target.value);
                        dispatch(
                          getProgramsByName({
                            name: decodedValue,
                            color: paramColor,
                            length: lengthRound(e.target.value),
                            width: widthRound(widthWhole),
                          }),
                        );
                      }}>
                      {lengthOptionsWhole(product)}
                    </Select>
                  </FormControl>
                  <FormControl
                    size='small'
                    variant='outlined'
                    style={styles.formControl}>
                    <Select
                      id='length-frac'
                      value={lengthFraction}
                      name='lengthFraction'
                      onChange={(e) => setLengthFraction(e.target.value)}>
                      {lengthOptionsFrac(product)}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {/* measure */}

              <Grid
                container
                direction='row'
                padding='2px'
                className='widthMarginProduct'>
                <Grid item xs={3} className='selfAlign'>
                  <span className='reviewNamePading'>
                    Measure{' '}
                    <Tooltip
                      title={
                        <>
                          <h4>Inside Mount</h4>
                          <img
                            style={styles.smallImage}
                            alt='#'
                            src='/images/newHome/Inside_White_border.jpg'
                          />
                          <h4>Outside Mount</h4>

                          <img
                            style={styles.smallImage}
                            alt='#'
                            src='/images/newHome/Outside_White_border.jpg'
                          />

                          {/* <span style={styles.spanInfo}>
                            Cloth : Fabric made to given width.
                          </span> */}
                        </>
                      }
                      color='disabled'>
                      <InfoIcon />
                    </Tooltip>
                  </span>
                </Grid>
                <Grid xs={3}>
                  <FormControl
                    size='small'
                    variant='outlined'
                    style={styles.formControl}>
                    <Select
                      id='length-frac'
                      value={measure}
                      onChange={handleMeasure}>
                      <MenuItem value='Tight'>Inside Mount</MenuItem>;
                      <MenuItem value='Finish'>Outside Mount</MenuItem>;
                      {/* <MenuItem value='Cloth'>Cloth</MenuItem>; */}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Colors */}
              <Grid container direction='row' className='widthMarginProduct'>
                <Grid item xs={3} className='selfAlign'>
                  {' '}
                  <span className='reviewNamePading'>
                    Fabric Colour - {'    '}{' '}
                    {selectedColor || product?.SG_Color}
                  </span>
                </Grid>
                <Grid xs={3} className='displayColour'>
                  {product?.Colors?.map((product_color, index) => {
                    const myColour = product_color;
                    return (
                      <div style={styles.colorIconCon}>
                        <Button
                          style={{
                            ...styles.colorIconBtn,
                          }}
                          value={product_color}
                          key={index}
                          onClick={(e) => {
                            setImage(
                              buildRenderUrl({
                                name: product.Program,
                                color: e.target.value,
                              }),
                            );
                            setSelectedColor(e.target.value);
                            dispatch(
                              getProgramsByName({
                                name: decodedValue,
                                color: myColour,
                                length: lengthRound(lengthWhole),
                                width: widthRound(widthWhole),
                              }),
                            );
                          }}></Button>

                        <img
                          style={{
                            ...styles.colorIcon,
                            background: product_color.toLowerCase(),
                          }}
                          src={`/images/color_round/${product.Program}/${product_color}.png`
                            .replaceAll('%', '')
                            .replaceAll(' ', '_')
                            .toLowerCase()}
                          alt={product_color}></img>
                      </div>
                    );
                  })}
                </Grid>
              </Grid>
              <hr style={styles.divider} />

              <Grid container direction='row' className='widthMarginProduct'>
                <Grid item xs={3} className='selfAlign'>
                  <Button
                    className='redButton'
                    style={{
                      margin: '.5rem 0rem',
                      width: '10rem',
                      backgroundColor: '#ef4036',
                      color: '#fff',
                    }}
                    onClick={addToCartHandler}>
                    Add to Cart
                  </Button>

                  {product.Operation != 'Aria' &&
                  product.Fabric != 'Spotty' &&
                  product.Fabric != 'Yarn' &&
                  product.Fabric != 'Metro Plains' &&
                  product.Fabric != 'Punk Skunk' &&
                  product.Fabric != 'Izumi' &&
                  product.Fabric != 'Spiro' ? (
                    <Button
                      className='redButton'
                      style={{
                        margin: '.5rem 0rem',
                        width: '10rem',
                        backgroundColor: '#797979',
                        color: '#fff',
                      }}
                      onClick={customizeHandler}
                      href='/customize'>
                      Customise Shade
                    </Button>
                  ) : null}
                </Grid>

                {/* {allFabrics.length > 0 && (
                    <>

                      <Grid
                        container
                        justifyContent='center'
                        style={{
                          position: 'relative',
                          width: '75%',
                          height: 'auto',
                        }}>
                        <h6 style={{ width: '100%', textAlign: 'center' }}>
                          Free Swatch Samples
                        </h6>
                        {allFabrics.map((index) => (
                          <Grid>
                            <Grid container direction='row' justifyContent='center'>
                              {index.image.map((image, i) => (
                                <Grid
                                  style={{
                                    textAlign: 'center',
                                    margin: '1rem 3px',
                                  }}>
                                  <div style={{ position: 'relative', width: '100px' }}>
                                    <div
                                      style={{
                                        backgroundImage: `url(${encodeURI(image)})`,
                                        width: '100%',
                                        paddingBottom: '100%',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        borderRadius: '5px',
                                      }}></div>
                                    <div
                                      style={{
                                        position: 'absolute',
                                        bottom: '5px',
                                        width: '100%',
                                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                        padding: '0.5rem',
                                      }}>
                                      {index.colour[i]}
                                    </div>
                                  </div>
                                  <Button
                                    variant='outlined'
                                    size='small'
                                    style={{ marginTop: '1rem' }}
                                    onClick={(e) =>
                                      handleFabric(
                                        index.series +
                                        '||' +
                                        index.colour[i] +
                                        '||' +
                                        index.code[i],
                                      )
                                    }>
                                    {existsInCart(
                                      index.series +
                                      '||' +
                                      index.colour[i] +
                                      '||' +
                                      index.code[i],
                                    )
                                      ? 'Added'
                                      : 'Order'}
                                  </Button>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  )} */}
              </Grid>
            </Grid>

            {/* New Design */}
            {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label='basic tabs example'>
                    <Tab label='About This Shade' />
                    <Tab label='Reviews' />
                    <Tab label='Item Three' />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  Item One
                </TabPanel>
                <TabPanel value={value} index={1}>
                  Item Two
                </TabPanel>
                <TabPanel value={value} index={2}>
                  Item Three
                </TabPanel> */}
          </Grid>
        </Grid>

        {/* Tabs */}
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              TabIndicatorProps={{ style: { background: '#EF4036' } }}
              textColor='inherit'
              onChange={handleChange}
              aria-label='basic tabs example'>
              <Tab label='About' {...a11yProps(0)} />
              <Tab label='Free Swatches' {...a11yProps(1)} />
              <Tab label='Reviews ' {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {/* <hr style={styles.dividerSmallTabs} /> */}
            {/* <h5 className='textCenter widthMarginProduct'>About This Shade</h5> */}
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='top'
              style={styles.infoContainer}>
              <Grid item style={styles.card}>
                <Grid>
                  <h5>Valance - {product.Valance}</h5>
                </Grid>

                <img
                  src={`/images/valandbottom/${product.Valance}.png`}
                  style={styles.imgContainer}
                  alt='Valance'
                />
                <Typography style={styles.smallText}>
                  {product.Valance_info}
                </Typography>
              </Grid>

              <Grid item style={styles.card}>
                <Grid>
                  <h5 className='responsiveProductHeading'>
                    Hem - {product.Bottom_Bar}
                  </h5>
                </Grid>
                <img
                  src={`/images/valandbottom/${product.Bottom_Bar}.png`}
                  style={styles.imgContainer}
                  alt='Bottom Bar'
                />
                <Typography style={styles.smallText}>
                  {product.BottomBar_info}
                </Typography>
              </Grid>

              <Grid item style={styles.card}>
                <Grid>
                  <h5 className='responsiveProductHeading'>
                    {' '}
                    Operation - {product.Operation}
                  </h5>
                </Grid>
                <img
                  src={`/images/operation/${product.Operation}.png`}
                  style={styles.imgContainer}
                  alt='Bottom Bar'
                />
                <Typography style={styles.smallText}>
                  {product.Control_info}
                </Typography>
              </Grid>
              <Grid item style={styles.card}>
                <Grid variant='h5'>
                  <h5 className='responsiveProductHeading'>
                    Fabric - {product.Fabric}
                  </h5>
                </Grid>
                <img
                  src={`/images/programs/${product?.Program}/fabric.png`
                    .replaceAll('%', '')
                    .replaceAll(' ', '_')
                    .toLowerCase()}
                  style={styles.imgContainer}
                  alt={product.Fabric}
                />
                <Typography style={styles.smallText}>
                  {product.Design_Features}
                </Typography>
              </Grid>
              <hr style={styles.dividerSmall} />
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {/* <hr style={styles.dividerSmallTabs} />  */}
            {/* <h5 className='textCenter'>Free Swatch Samples</h5> */}
            {allFabrics.length > 0 && (
              <>
                {/* <hr style={styles.divider} /> */}
                <Grid container justifyContent='center'>
                  {allFabrics.map((index) => (
                    <Grid>
                      <Grid container direction='row' justifyContent='center'>
                        {index.image.map((image, i) => (
                          <Grid
                            style={{
                              textAlign: 'center',
                              margin: '1rem 3px',
                            }}>
                            <div
                              style={{ position: 'relative', width: '100px' }}>
                              <div
                                style={{
                                  backgroundImage: `url(${encodeURI(
                                    image.toLowerCase(),
                                  )})`,
                                  width: '100%',
                                  paddingBottom: '100%',
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center',
                                  borderRadius: '5px',
                                }}></div>
                              <div
                                style={{
                                  position: 'absolute',
                                  bottom: '5px',
                                  width: '100%',
                                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                  padding: '0.5rem',
                                }}>
                                {index.colour[i]}
                              </div>
                            </div>
                            <Button
                              variant='outlined'
                              size='small'
                              style={{ marginTop: '1rem' }}
                              onClick={(e) =>
                                handleFabric(
                                  index.series +
                                    '||' +
                                    index.colour[i] +
                                    '||' +
                                    index.code[i],
                                )
                              }>
                              {existsInCart(
                                index.series +
                                  '||' +
                                  index.colour[i] +
                                  '||' +
                                  index.code[i],
                              )
                                ? 'Added'
                                : 'Add'}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <hr style={styles.dividerSmall} />
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {/* <hr style={styles.dividerSmallTabs} /> */}

            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'>
              {ReviewDisplay(
                product,
                reviewArr,
                userLogin,
                newRatingState,
                setNewRatingState,
                setShowToastRev,
                revDisable,
                setRevDisable,
              )}
            </Grid>
            <hr style={styles.dividerSmall} />

          </TabPanel>
        </Box>

        {/* About Shade */}

        {/* <hr style={styles.dividerSmall} /> */}
        {/* <h5 className='textCenter widthMarginProduct'>About This Shade</h5>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='top'
          style={styles.infoContainer}>
          <Grid item style={styles.card}>
            <Grid>
              <h6 className="responsiveProductHeading">Valance - {product.Valance}</h6>
            </Grid>

            <img
              src={`/images/valandbottom/${product.Valance}.png`}
              style={styles.imgContainer}
              alt='Valance'
            />
            <Typography style={styles.smallText}>
              {product.Valance_info}
            </Typography>
          </Grid>

          <Grid item style={styles.card}>
            <Grid>
              <h6 className="responsiveProductHeading">Hem - {product.Bottom_Bar}</h6>
            </Grid>
            <img
              src={`/images/valandbottom/${product.Bottom_Bar}.png`}
              style={styles.imgContainer}
              alt='Bottom Bar'
            />
            <Typography style={styles.smallText}>
              {product.BottomBar_info}
            </Typography>
          </Grid>

          <Grid item style={styles.card}>
            <Grid>
              <h6 className="responsiveProductHeading"> Operation - {product.Operation}</h6>
            </Grid>
            <img
              src={`/images/operation/${product.Operation}.png`}
              style={styles.imgContainer}
              alt='Bottom Bar'
            />
            <Typography style={styles.smallText}>
              {product.Control_info}
            </Typography>
          </Grid>
          <Grid item style={styles.card}>
            <Grid variant='h5'>
              <h6 className="responsiveProductHeading">Fabric - {product.Fabric}</h6>
            </Grid>
            <img
              src={`/images/programs/${product?.Program}/fabric.png`
                .replaceAll('%', '')
                .replaceAll(' ', '_')
                .toLowerCase()}
              style={styles.imgContainer}
              alt={product.Fabric}
            />
            <Typography style={styles.smallText}>
              {product.Design_Features}
            </Typography>
          </Grid>
          <hr style={styles.dividerSmall} />
        </Grid> */}

        {/* <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'>
          {ReviewDisplay(
            product,
            reviewArr,
            userLogin,
            newRatingState,
            setNewRatingState,
            setShowToastRev,
            revDisable,
            setRevDisable,
          )}
        </Grid> */}

        {/* <hr style={styles.dividerSmall} /> */}
        {/* Similar Products */}
        <Typography variant='h4' style={styles.aboutShade}>
          Similar Products
        </Typography>
        <Grid
          container
          direction='row'
          justifyContent='center'
          // alignItems="center"
          alignItems='flex-start'
          style={styles.widthCorrector}
          spacing={6}>
          {!loading && showProducts()}
        </Grid>
        <Grid item xs={12}></Grid>
        <Snackbar
          open={swatchCartToast}
          autoHideDuration={6000}
          onClose={() => setSwatchCartToast(false)}>
          <Alert severity='success'>
            <AlertTitle>
              The swatch has been added to the cart successfully.
            </AlertTitle>
          </Alert>
        </Snackbar>
        <Snackbar open={showToast} autoHideDuration={6000} onClose={closeToast}>
          <Alert onClose={closeToast} severity='success'>
            <AlertTitle>Added to Cart</AlertTitle>
          </Alert>
        </Snackbar>
        <Snackbar
          open={showToastRev}
          autoHideDuration={6000}
          onClose={closeToastRev}>
          <Alert onClose={closeToastRev} severity='success'>
            <AlertTitle>review sent. awaiting moderation</AlertTitle>
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

export default ProductScreenDetailed;
