/** @format */

import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import TextField from '@mui/material/TextField';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../actions/userActions';
import Message from '../components/Message';
import { Loader } from '../components/Loader';
import Menu3 from '../components/Menu3';
import './style.css';

const styles = {
  heavyText: {
    fontWeight: 400,
    textAlign: 'center',
  },
  leftHeader: {
    color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '1.5rem',
    textAlign: 'left',
    margin: '1rem 0',
  },
  divider: {
    width: '100%',
    borderTop: '1px solid black',
    marginTop: '1rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  button: {
    border: '1px solid black',
    background: 'transparent',
    color: 'black',
    textTransform: 'capitalize',
    minWidth: '10rem',
    backgroundColor: '#EEEBDD',
    width: '20rem',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
};

const RegisterScreen = ({ location, history }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = 'null';
  const [password1ErrorText, setPassword1ErrorText] = useState();
  const [password2ErrorText, setPassword2ErrorText] = useState();

  const dispatch = useDispatch();

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, userInfo } = userRegister;

  const redirect = location.search ? location.search.split('=')[1] : '/';

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
  }, [history, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage('Passwords Do not Match');
    } else {
      dispatch(register(name, email, password));
    }
  };

  return (
    <>
      <Menu3 />
      <Container>
        {loading && <Loader />}
        <p style={styles.leftHeader}>Sign Up</p>
        <hr style={styles.divider} />
        {error && <Message variant='danger'>{error}</Message>}
        <form onSubmit={submitHandler}>
          <Grid style={{ width: '20rem' }} container direction='column'>
            <TextField
              style={{ marginTop: '1.5rem', width: '20rem' }}
              size='small'
              variant='outlined'
              id='outlined-multiline-flexible'
              label='Name'
              required
              value={name}
              type='name'
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              style={{ marginTop: '1.5rem', width: '20rem' }}
              size='small'
              variant='outlined'
              id='outlined-multiline-flexible'
              label='Email'
              required
              value={email}
              type='email'
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              style={{ marginTop: '1.5rem', width: '20rem' }}
              size='small'
              variant='outlined'
              id='outlined-multiline-flexible'
              label='Password'
              required
              //value={password}
              error={password1ErrorText}
              helperText={password1ErrorText}
              type='password'
              onChange={(e) => {
                let str = e.target.value;
                //console.log(str.match('[0-9]') != null && str.length>=6)
                if (str.match('[0-9]') != null && str.length >= 6) {
                  setPassword1ErrorText('');
                  setPassword(str);
                } else {
                  setPassword1ErrorText(
                    'passwords must be 6 characters long and contain numbers',
                  );
                }
                //setPassword(e.target.value)
              }}
            />
            <TextField
              style={{
                marginTop: '1.5rem',
                width: '20rem',
                marginBottom: '1.5rem',
              }}
              size='small'
              variant='outlined'
              id='outlined-multiline-flexible'
              label='Confirm Password'
              required
              //value={confirmPassword}
              error={password2ErrorText}
              helperText={password2ErrorText}
              type='password'
              onChange={(e) => {
                let str = e.target.value;
                //console.log(str.match('[0-9]') != null && str.length>=6)
                if (str == password) {
                  setPassword2ErrorText('');
                  setConfirmPassword(str);
                } else {
                  setPassword2ErrorText('passwords must match');
                }
                //setPassword(e.target.value)
              }}
            />

            <Button
              className='redButton'
              style={{
                margin: '1rem 0rem',
                width: '10rem',
                backgroundColor: '#ef4036',
                color: '#fff',
              }}
              type='submit'
              variant='outlined'>
              Register
            </Button>
          </Grid>
        </form>

        <p>Already have an account?</p>
        <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
          <Button
            className='redButton'
            style={{
              margin: '1rem 0rem',
              width: '10rem',
              backgroundColor: '#ef4036',
              color: '#fff',
            }}
            variant='outlined'>
            Log In
          </Button>
        </Link>
      </Container>
    </>
  );
};

export default RegisterScreen;
