/** @format */

import React, { useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { customizeProduct, getDrive } from '../actions/blindActions';
import { Loader } from './Loader';
import { calculateTotalPrice } from '../actions/blindActions';
import './menu.css';
import LoadingOverlay from 'react-loading-overlay';
import ImageZoom from './ImageZoom';

const CustomizeDrive = ({
  page,
  productInfo,
  optionInfo,
  totalPrice,
  setTotalPrice,
  switchPage,
  setSwitchPage,
  fabricPrice,
  setDisabled,
}) => {
  var dispatch = useDispatch();

  const δ = productInfo.δ;
  const shadeWidthFrac = optionInfo.widthFraction;
  const shadeLengthFrac = optionInfo.lengthFraction;
  const shadeLength = optionInfo.lengthWhole;
  var fabricType = productInfo.Fabric_Code;
  if (fabricType.includes('Gossamer-') && !fabricType.includes('Pale')) {
    fabricType = fabricType.replace(/\s/g, '');
  }
  const fabricColor = productInfo.SG_Color;
  const shadeWidth = optionInfo.widthWhole;
  const hemType = productInfo.Bottom_Bar;

  var [invalid, setInvalid] = useState(false);
  var [position, setPosition] = useState(productInfo.position);
  var [data, setData] = useState(null);
  var [system, setSystem] = useState(productInfo.Operation);
  const cancelToken = new AbortController();
  var [loading, setLoading] = useState(true);

  const shadeTitle = productInfo.Type + ' Shade';

  useEffect(() => {
    setDisabled(loading);
  }, [loading]);

  const styles = {
    divider: {
      width: '100%',
      margin: '1rem auto',
    },
    leftHeader: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    items: {
      margin: '1rem',
      padding: '2rem',
    },
    image: {
      width: '150px',
      height: '150px',
      marginBottom: '2rem',
      objectFit: 'cover',
    },
    atcButton: {
      // background: '#ebebeb',
      minWidth: '10rem',
      marginRight: '1rem',
      marginTop: '1rem',
    },
  };

  useEffect(() => {
    setLoading(true);
    console.log('CustomizeDrive: useEffect');
    showDriveOptions();
  }, [
    shadeTitle,
    fabricType,
    shadeWidth,
    shadeWidthFrac,
    shadeLength,
    shadeLengthFrac,
    productInfo.Operation,
    productInfo.Valance,
    productInfo.L_Channel,
    hemType,
    δ,
    system,
    fabricColor,
  ]);

  useEffect(() => {
    return () => cancelToken.abort();
  }, [cancelToken]);

  useEffect(() => {
    if (switchPage && page.current == 4) {
      var t4 = document.getElementById('t4');
      t4.style = styles.darkText;

      var toHide = document.getElementById('t' + switchPage);

      toHide.style.color = '#9b9b9b';
      toHide.style.fontSize = '0.8rem';
      setSwitchPage(0);
    }
  }, [switchPage]);

  const forwardPage = () => {
    page.current = 5;
    dispatchDetails();
    var toChange = document.getElementById('drive');
    toChange.style.display = 'none';
    var t4 = document.getElementById('t4');

    t4.style.color = '#9b9b9b';
    t4.style.fontSize = '0.8rem';

    var t5 = document.getElementById('t5');
    t5.style = styles.darkText;

    toChange = document.getElementById('valance');
    toChange.style.display = 'block';
  };

  const previousPage = () => {
    page.current = 3;
    dispatchDetails();
    var toChange = document.getElementById('drive');
    toChange.style.display = 'none';
    var t4 = document.getElementById('t4');

    t4.style.color = '#9b9b9b';
    t4.style.fontSize = '0.8rem';

    var t3 = document.getElementById('t3');
    t3.style = styles.darkText;

    toChange = document.getElementById('bottombars');
    toChange.style.display = 'block';
  };

  const dispatchDetails = () => {
    productInfo.position = position;
    productInfo.Operation = system;
    optionInfo.OUR_PRICE = totalPrice;
    dispatch(
      customizeProduct({
        productInfo: {
          ...productInfo,
        },
        optionInfo: {
          ...optionInfo,
        },
      }),
    );
  };

  const getPrice = () => {
    if (page.current != 4) return;
    let width = optionInfo.widthWholeFrac
      ? parseFloat(optionInfo.widthWhole) +
      parseFloat(optionInfo.widthWholeFrac)
      : optionInfo.widthWhole;
    let length = optionInfo.lengthWholeFrac
      ? parseFloat(optionInfo.lengthWhole) +
      parseFloat(optionInfo.lengthWholeFrac)
      : optionInfo.lengthWhole;

    if (length % 1 !== 0) {
      length = eval(length);
    }
    if (width % 1 !== 0) {
      width = eval(width);
    }

    let shade = productInfo.Type + ' Shade';
    let priceGroup = productInfo.Price_Group;
    let drive = system;
    let valance = productInfo.Valance;
    let channel = productInfo.L_Channel;
    let quantity = optionInfo.quantity;

    calculateTotalPrice(
      length,
      width,
      shade,
      priceGroup,
      drive,
      valance,
      channel,
    ).then((tp) => {
      if (tp.hasOwnProperty('type')) {
        console.log(tp);
        return;
      }
      console.log(quantity);
      let temp = quantity * tp.totalPrice;

      temp = Math.round(temp * 100) / 100;
      totalPrice = temp;
      // if (fabricPrice.current && productInfo.Type != 'Sheer')
      //   temp += `${fabricPrice.current}`;
      // if (drive == 'Motor') temp += `${tp.drivePrice}`;
      // if (drive == 'Zero Gravity') temp += `${tp.drivePrice}`;
      // if (tp.valancePrice) temp += `${tp.valancePrice}`;
      // if (channel != 'None') temp += `${tp.channelPrice}`;
      setTotalPrice(temp);
      console.log(totalPrice);
    });
  };

  const handleSystemChange = (e) => {
    system = e.target.value;
    setSystem(e.target.value);
    // If system is Zero Gravity, set position to Right
    if (e.target.value == 'Zero Gravity') {
      setPosition('Right');
    }
    getPrice();
  };

  const showDriveOptions = () => {
    var totalWidth = shadeWidthFrac
      ? parseFloat(shadeWidth) + parseFloat(shadeWidthFrac)
      : shadeWidth;
    var totalLength = shadeLengthFrac
      ? parseFloat(shadeLength) + parseFloat(shadeLengthFrac)
      : shadeLength;

    if (totalLength % 1 !== 0) {
      totalLength = eval(totalLength);
    }
    if (totalWidth % 1 !== 0) {
      totalWidth = eval(totalWidth);
    }
    console.log('TOTAL LENGTH: ' + totalLength);
    console.log('TOTAL WIDTH: ' + totalWidth);
    getDrive(
      shadeTitle,
      fabricType,
      totalWidth,
      totalLength,
      hemType,
      δ,
      system,
      cancelToken,
    ).then((res) => {
      console.log(res);
      // Combine res.valance and res.valanceInfo

      productInfo.valanceArray = res.valance;
      productInfo.spring = res.spring;
      productInfo.Price_Group = res.price_group;

      dispatch(
        customizeProduct({
          productInfo: {
            ...productInfo,
          },
          optionInfo: optionInfo,
        }),
      );
      if (!res.system.includes(system)) {
        setSystem('Chain');
      }
      res = [res];
      if (res.length != 0 && !res[0].hasOwnProperty('type')) {
        handleInvalidError(0);
        setData(res);
        setLoading(false);
      } else {
        handleInvalidError(1);
      }
    });
  };

  // 0 Means reset invalid to false and 1 means set invalid to true
  const handleInvalidError = (type) => {
    switch (type) {
      case 0: {
        if (invalid == true) {
          console.log('Reached 2');
          setInvalid(false);
          invalid = false;
          productInfo.invalid = false;
          dispatch(
            customizeProduct({
              productInfo: {
                ...productInfo,
              },
              optionInfo: optionInfo,
            }),
          );
        }
        break;
      }
      case 1: {
        if (invalid == false) {
          setInvalid(true);
          invalid = true;
          productInfo.invalid = true;
          dispatch(
            customizeProduct({
              productInfo: {
                ...productInfo,
              },
              optionInfo: optionInfo,
            }),
          );
        }
        setData(null);
        break;
      }
    }

  };

  return (
    <>
      {' '}
      {/* 4. Drive */}

      <Grid
        id='drive'
        visible='none'
        // Check if localStorage page is 4 and if yes then show the div
        style={page.current == 4 ? { display: 'block' } : { display: 'none' }}
        spacing={1}
        onHide={switchPage && dispatchDetails()}>
        <LoadingOverlay
          active={loading}
          spinner

          styles={{
            overlay: (base) => ({
              ...base,
              background: 'rgba(255, 255, 255, 0.5)',
            }),
            spinner: (base) => ({
              ...base,
              width: '100px',
              '& svg circle': {
                stroke: '#ef4036'
              }
            })
          }}

        >
          <Grid>
            <p style={styles.leftHeader}>4. Select Control</p>
            <hr className='divider' />
            <Grid container direction='row'>
              {invalid ? null : (data ? data.map((option) => {
                // Get the system's items from option one by one and make it a menu item

                let items = option.system.map((item) => {
                  return (
                    <div
                      className={
                        system.toLowerCase() == item.toLowerCase()
                          ? 'featureOption selected optionsBoxMain'
                          : 'featureOption optionsBoxMain'
                      }
                      onClick={() => {
                        handleSystemChange({ target: { value: item } });
                      }}>
                      {system.toLowerCase() == item.toLowerCase() && (
                        <span
                          className='featureOptioncheckmark'
                          style={{ display: 'block' }}></span>
                      )}
                      <div
                        className='option feature optionFeatures'
                        style={{ height: '132.406px' }}>
                        <div
                          className='image optionsFeatures'
                          style={{
                            backgroundImage: `url(${encodeURI(
                              '/images/customizeToolRenders/' + item + '.png',
                            )})`,
                          }}
                          src={item}>
                         <ImageZoom imageUrl={encodeURI(
                              '/images/customizeToolRenders/' + item + '.png',
                            )} />
                          <div className='overlay'></div>
                        </div>
                      </div>

                      <div className='featureText withMediaIcons'>
                        <div className='featureoptionradio ' style={{}}>
                          <div
                            className='customRadio radioFeatureButton'
                            style={{ float: 'left', left: '-2px' }}>
                            <span className='radio selected'>
                              <span className='checked'></span>
                            </span>
                            <input
                              defaultChecked='checked'
                              groupname='Feature1320'
                              id='SelectedProductFeatureOptions3743'
                              name='AvailableFeatures[9].FeatureOptions[2].Selected'
                              type='checkbox'
                              defaultValue='true'
                            />
                            <input
                              name='AvailableFeatures[9].FeatureOptions[2].Selected'
                              type='hidden'
                              defaultValue='false'
                            />
                            <input
                              id='AvailableFeatures_9__FeatureOptions_2__OriginallySelected'
                              name='AvailableFeatures[9].FeatureOptions[2].OriginallySelected'
                              type='hidden'
                              defaultValue='True'
                            />
                          </div>
                          <div style={{}} className='featureOptionNamelbl'>
                            <label
                              htmlFor='SelectedProductFeatureOptions3743'
                              className='featureOptionName'
                              data-featuretitle='Curved Cassette Valance'>
                              {item}
                              <br />
                              {item == 'Chain' ? (
                                <b>Free</b>
                              ) : (
                                <b>${data[0].driveInfo[item]}</b>
                              )}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                });
                return items;
              }) : null)}
            </Grid>
            <hr className='divider' />

            {invalid ? null : (
              <>
                {system != 'Zero Gravity' && (
                  <div
                    className={
                      position == 'Left'
                        ? 'featureOption selected optionsBoxMain'
                        : 'featureOption optionsBoxMain'
                    }
                    onClick={() => {
                      setPosition('Left');
                    }}>
                    <span
                      className={
                        position == 'Left' ? 'featureOptioncheckmark' : ''
                      }
                      style={{ display: 'block' }}></span>
                    {/* )} */}
                    <div
                      className='option feature optionFeatures'
                      style={{ height: '132.406px' }}>
                      <div
                        className='image optionsFeatures'
                        style={{
                          backgroundImage: `url(/images/customizeToolRenders/left.png)`,
                        }}>
                          <ImageZoom imageUrl={'/images/customizeToolRenders/left.png'} />
                        <div className='overlay'></div>
                      </div>
                    </div>

                    <div className='featureText withMediaIcons'>
                      <div className='featureoptionradio' style={{}}>
                        <div
                          className='customRadio radioFeatureButton'
                          style={{ float: 'left', left: '-2px' }}>
                          <span className='radio selected'>
                            <span className='checked'></span>
                          </span>
                          <input
                            defaultChecked='checked'
                            groupname='Feature1320'
                            id='SelectedProductFeatureOptions3743'
                            name='AvailableFeatures[9].FeatureOptions[2].Selected'
                            type='checkbox'
                            defaultValue='true'
                          />
                          <input
                            name='AvailableFeatures[9].FeatureOptions[2].Selected'
                            type='hidden'
                            defaultValue='false'
                          />
                          <input
                            id='AvailableFeatures_9__FeatureOptions_2__OriginallySelected'
                            name='AvailableFeatures[9].FeatureOptions[2].OriginallySelected'
                            type='hidden'
                            defaultValue='True'
                          />
                        </div>
                        <div style={{}} className='featureOptionNamelbl'>
                          <label
                            htmlFor='SelectedProductFeatureOptions3743'
                            className='featureOptionName'
                            data-featuretitle='Curved Cassette Valance'>
                            Position Left
                            <br />
                            <b>Free</b>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={
                    position == 'Right'
                      ? 'featureOption selected optionsBoxMain'
                      : 'featureOption optionsBoxMain'
                  }
                  onClick={() => {
                    setPosition('Right');
                  }}>
                  <span
                    className={
                      position == 'Right' ? 'featureOptioncheckmark' : ''
                    }
                    style={{ display: 'block' }}></span>
                  {/* )} */}
                  <div
                    className='option feature optionFeatures'
                    style={{ height: '132.406px' }}>
                    <div
                      className='image optionsFeatures'
                      style={{
                        backgroundImage: `url(/images/customizeToolRenders/right.png)`,
                      }}>
                     <ImageZoom imageUrl={'/images/customizeToolRenders/right.png'} />
                      <div className='overlay'></div>
                    </div>
                  </div>

                  <div className='featureText withMediaIcons'>
                    <div className='featureoptionradio' style={{}}>
                      <div
                        className='customRadio radioFeatureButton'
                        style={{ float: 'left', left: '-2px' }}>
                        <span className='radio selected'>
                          <span className='checked'></span>
                        </span>
                        <input
                          defaultChecked='checked'
                          groupname='Feature1320'
                          id='SelectedProductFeatureOptions3743'
                          name='AvailableFeatures[9].FeatureOptions[2].Selected'
                          type='checkbox'
                          defaultValue='true'
                        />
                        <input
                          name='AvailableFeatures[9].FeatureOptions[2].Selected'
                          type='hidden'
                          defaultValue='false'
                        />
                        <input
                          id='AvailableFeatures_9__FeatureOptions_2__OriginallySelected'
                          name='AvailableFeatures[9].FeatureOptions[2].OriginallySelected'
                          type='hidden'
                          defaultValue='True'
                        />
                      </div>
                      <div style={{}} className='featureOptionNamelbl'>
                        <label
                          htmlFor='SelectedProductFeatureOptions3743'
                          className='featureOptionName'
                          data-featuretitle='Curved Cassette Valance'>
                          Position Right
                          <br />
                          <b>Free</b>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Grid>
          <Grid container direction='row'>
            <Grid item xs={6}>
              <Button id='back' onClick={previousPage} style={styles.atcButton}>
                Back
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button  className='redButton'
              style={{
                margin: '.5rem 0rem',
                width: '10rem',
                backgroundColor: '#ef4036',
                color: '#fff',
                marginTop: '1rem',
              }} onClick={forwardPage}>
                Next
              </Button>
            </Grid>
          </Grid>
          <br></br>
        </LoadingOverlay>
      </Grid>
    </>
  );
};

export default CustomizeDrive;
