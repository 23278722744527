import bumpy from '../../../wallpaperimg/patterns/bumpy.png'
import p1 from '../../../wallpaperimg/patterns/1.png'
import p2 from '../../../wallpaperimg/patterns/2.png'
import p3 from '../../../wallpaperimg/patterns/3.png'
import p4 from '../../../wallpaperimg/patterns/4.png'
import p5 from '../../../wallpaperimg/patterns/5.png'
import p6 from '../../../wallpaperimg/patterns/6.png'
import p7 from '../../../wallpaperimg/patterns/7.png'
import p8 from '../../../wallpaperimg/patterns/8.png'
import p9 from '../../../wallpaperimg/patterns/9.png'
import p10 from '../../../wallpaperimg/patterns/10.png'

export const patternObject = {
    'bumpy':bumpy,
    'p1':p1,
    'p2':p2,
    'p3':p3,
    'p4':p4,
    'p5':p5,
    'p6':p6,
    'p7':p7,
    'p8':p8,
    'p9':p9,
    'p10':p10
}