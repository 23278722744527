/** @format */

import React, { useState } from 'react';
import { Snackbar, TextField, Grid, Typography } from '@material-ui/core';
import { English } from '../localization/strings';
import Menu3 from '../components/Menu3';
import { Alert, AlertTitle } from '@mui/material';
import { apiMail } from '../actions/userActions';

const styles = {
  divider: {
    width: '100%',
    margin: '1rem auto',
  },
  heavyText: {
    fontWeight: 400,
  },
  lightText: {
    fontWeight: 300,
  },
  paragraph: {
    // fontSize: '1rem',
    margin: '1rem',
    fontWeight: 400,
    lineHeight: '2rem',
  },
  topSpacer: {
    marginTop: '2rem',
  },
  image: {
    width: '100%',
  },
  imageContainer: {
    width: '80%',
    margin: 'auto',
  },
  smallImage: {
    width: '20rem',
    height: '15rem',
    aspectRatio: 1,
    margin: 'auto',
    objectFit: 'cover',
  },
  smallText: {
    width: '80%',
    margin: '1rem auto',
    // fontSize: '1.1rem',
    lineHeight: '2rem',
  },
  infoContainer: {
    margin: '1.5rem auto',
    textAlign: 'center',
  },
  map: {
    width: '100%',
    height: '15rem',
    allowfullscreen: '',
    border: '1px solid red',
  },
  padding: {
    padding: '2rem',
  },
  shadow: {
    position: 'absolute',
    // color: 'white',
    justifyContent: 'center',
    textAlign: 'center',
  },
  align: {
    alignSelf: 'center',
  },
};

const Contact = () => {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  let unused = window.onbeforeunload();

  const [fName, setFName] = useState();
  const [lName, setLName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [emailErrorText, setEmailErrorText] = useState();
  const [toast, setToast] = useState(false);
  const [popState, setPopState] = useState(false);

  const closeToast = () => {
    setToast(false);
  };

  const handleSub = (e) => {
    e.preventDefault();
    apiMail(
      'info@offcutshades.com',
      `Contact request: ${fName} ${lName}`,
      `<h1>${fName} ${lName} has requested a consultation  \n email: ${email}  \n message: ${message}</h1>`,
    );
    apiMail(
      email,
      `Thank You For Contacting Us: ${fName} ${lName}`,
      `<div class="es-wrapper-color">
      <!--[if gte mso 9]>
        <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
          <v:fill type="tile" color="#fafafa"></v:fill>
        </v:background>
      <![endif]-->
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td class="esd-email-paddings" valign="top">
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-header esd-header-popover"
                align="center"
              >
                <tbody>
                  <tr>
                    <td
                      class="esd-stripe esd-synchronizable-module"
                      align="center"
                    >
                      <table
                        bgcolor="#ffffff"
                        class="es-header-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        width="600"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="esd-structure es-p10t es-p10b es-p20r es-p20l"
                              align="left"
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="560"
                                      class="es-m-p0r esd-container-frame"
                                      valign="top"
                                      align="center"
                                      esd-custom-block-id="744422"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-image"
                                              style="font-size: 0px;"
                                            >
                                              <a
                                                target="_blank"
                                                href="https://offcutshades.com"
                                              >
                                                <img
                                                  src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/offcutshadeslogo.png"
                                                  alt="Logo"
                                                  style="display: block;"
                                                  width="200"
                                                  title="Logo"
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="left"
                                              class="esd-block-text"
                                            >
                                              <p style="text-align: center;">
                                                Shading Solutions Simplified
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              class="esd-block-menu"
                                              esd-tmp-menu-color="#444444"
                                              esd-tmp-menu-font-family="'open sans','helvetica neue',helvetica,arial,sans-serif"
                                              esd-tmp-menu-font-weight="bold"
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                class="es-menu"
                                              >
                                                <tbody>
                                                  <tr class="links">
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      width="25%"
                                                      class="es-p10t es-p10b es-p5r es-p5l"
                                                    >
                                                      <a
                                                        target="_blank"
                                                        href="https://offcutshades.com/products"
                                                        style="
                                                          color: #444444;
                                                          font-family: 'open sans',
                                                            'helvetica neue',
                                                            helvetica, arial,
                                                            sans-serif;
                                                          font-weight: bold;
                                                        "
                                                      >
                                                        Window Coverings
                                                      </a>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      width="25%"
                                                      class="es-p10t es-p10b es-p5r es-p5l"
                                                    >
                                                      <a
                                                        target="_blank"
                                                        href="https://offcutshades.com/wallpaperinit"
                                                        style="
                                                          color: #444444;
                                                          font-family: 'open sans',
                                                            'helvetica neue',
                                                            helvetica, arial,
                                                            sans-serif;
                                                          font-weight: bold;
                                                        "
                                                      >
                                                        Wallpaper
                                                      </a>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      width="25%"
                                                      class="es-p10t es-p10b es-p5r es-p5l"
                                                    >
                                                      <a
                                                        target="_blank"
                                                        href="https://offcutshades.com/help"
                                                        style="
                                                          color: #444444;
                                                          font-family: 'open sans',
                                                            'helvetica neue',
                                                            helvetica, arial,
                                                            sans-serif;
                                                          font-weight: bold;
                                                        "
                                                      >
                                                        Help
                                                      </a>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      width="25%"
                                                      class="es-p10t es-p10b es-p5r es-p5l"
                                                    >
                                                      <a
                                                        target="_blank"
                                                        href="https://offcutshades.com/contact"
                                                        style="
                                                          color: #444444;
                                                          font-family: 'open sans',
                                                            'helvetica neue',
                                                            helvetica, arial,
                                                            sans-serif;
                                                          font-weight: bold;
                                                        "
                                                      >
                                                        Contact
                                                      </a>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-content"
                align="center"
              >
                <tbody>
                  <tr>
                    <td class="esd-stripe" align="center">
                      <table
                        bgcolor="#ffffff"
                        class="es-content-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        width="600"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="esd-structure es-p10t es-p10b es-p20r es-p20l"
                              align="left"
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="560"
                                      class="es-m-p0r esd-container-frame"
                                      valign="top"
                                      align="center"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-image"
                                              style="font-size: 0px;"
                                            >
                                              <a target="_blank">
                                                <img
                                                  class="adapt-img"
                                                  src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/thewaitisalmostover.gif"
                                                  alt
                                                  style="display: block;"
                                                  width="560"
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-text es-m-txt-l es-p15t es-p10b"
                                            >
                                              <h3
                                                style="
                                                  font-size: 20px;
                                                  font-family: 'open sans',
                                                    'helvetica neue',
                                                    helvetica, arial,
                                                    sans-serif;
                                                  color: #444444;
                                                "
                                              >
                                                <strong>
                                                  You are one step closer to
                                                  getting the perfect shades
                                                  for your home!
                                                </strong>
                                              </h3>
                                              <p
                                                style="
                                                  font-size: 14px;
                                                  font-family: 'open sans',
                                                    'helvetica neue',
                                                    helvetica, arial,
                                                    sans-serif;
                                                  color: #444444;
                                                "
                                              >
                                                <br />
                                                You have spent hours looking
                                                for the right shades, and now
                                                your ideas are about to come
                                                to fruition.
                                                <em>This is exciting!</em>
                                                <br />
                                                <br />
                                                Thanks for choosing our team
                                                to make your project a
                                                reality.
                                                <strong>
                                                  In the next 24 business
                                                  hours, one of our design
                                                  specialists will contact you
                                                </strong>
                                                to arrange the details of your
                                                in-home consultation.
                                                <br />
                                                <br />
                                                Meanwhile, you can check our
                                                Help section to get familiar
                                                with some of the terms
                                                regarding window coverings.
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-button es-p10t"
                                            >
                                              <span
                                                class="es-button-border"
                                                style="
                                               
                                                  border-radius: 0px;
                                                "
                                              >
                                                <a
                                                  href="https://offcutshades.com/help"
                                                  class="es-button"
                                                  target="_blank"
                                                  style="
                                                  color: #444444;
                                                  font-family: 'open sans',
                                                    'helvetica neue',
                                                    helvetica, arial,
                                                    sans-serif;
                                                  font-weight: bold;
                                                "
                                                >
                                                  Check Guides
                                                </a>
                                              </span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-footer esd-footer-popover"
                align="center"
              >
                <tbody>
                  <tr>
                    <td
                      class="esd-stripe"
                      align="center"
                      esd-custom-block-id="746268"
                      bgcolor="#efefef"
                      style="background-color: #efefef;"
                    >
                      <table
                        class="es-footer-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        width="600"
                        style="background-color: transparent;"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="esd-structure es-p20"
                              align="left"
                              esd-custom-block-id="744444"
                            >
                              <!--[if mso]><table width="560" cellpadding="0" cellspacing="0"><tr><td width="145" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-left"
                                align="left"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="125"
                                      class="es-m-p0r es-m-p20b esd-container-frame"
                                      align="center"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-image"
                                              style="font-size: 0px;"
                                            >
                                              <a target="_blank">
                                                <img
                                                  class="adapt-img"
                                                  src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/madetolasticon.png"
                                                  alt
                                                  style="display: block;"
                                                  width="30"
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-text es-p5t"
                                            >
                                              <p>Made to last</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td class="es-hidden" width="20"></td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td width="145" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-left"
                                align="left"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="125"
                                      class="es-m-p20b esd-container-frame"
                                      align="center"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-image"
                                              style="font-size: 0px;"
                                            >
                                              <a target="_blank">
                                                <img
                                                  class="adapt-img"
                                                  src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/readytoshipicon.png"
                                                  alt
                                                  style="display: block;"
                                                  width="30"
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-text es-p5t"
                                            >
                                              <p>Ready to ship in 2 days</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td class="es-hidden" width="20"></td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td width="125" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-left"
                                align="left"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="125"
                                      class="es-m-p20b esd-container-frame"
                                      align="center"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-image"
                                              style="font-size: 0px;"
                                            >
                                              <a target="_blank">
                                                <img
                                                  class="adapt-img"
                                                  src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/guaranteeiconorange.png"
                                                  alt
                                                  style="display: block;"
                                                  width="30"
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-text es-p5t"
                                            >
                                              <p>Guaranteed Fit</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td width="20"></td><td width="125" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-right"
                                align="right"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="125"
                                      align="center"
                                      class="esd-container-frame"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-image"
                                              style="font-size: 0px;"
                                            >
                                              <a target="_blank">
                                                <img
                                                  class="adapt-img"
                                                  src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/pricerighticon.png"
                                                  alt
                                                  style="display: block;"
                                                  width="30"
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-text es-p5t"
                                            >
                                              <p>Price Right every day</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td></tr></table><![endif]-->
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="esd-structure es-p20t es-p20b es-p20r es-p20l"
                              align="left"
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="560"
                                      class="esd-container-frame"
                                      align="left"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              class="esd-block-text"
                                            >
                                              <p style="text-align: center;">
                                                <strong>Follow Us</strong>
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-social es-p15t es-p15b"
                                              style="font-size: 0;"
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                class="es-table-not-adapt es-social"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      class="es-p40r"
                                                    >
                                                      <a
                                                        target="_blank"
                                                        href="https://www.facebook.com/OffCutShadesOnline/"
                                                      >
                                                        <img
                                                          title="Facebook"
                                                          src="https://vdpuhr.stripocdn.email/content/assets/img/social-icons/logo-black/facebook-logo-black.png"
                                                          alt="Fb"
                                                          width="32"
                                                        />
                                                      </a>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      class="es-p40r"
                                                    >
                                                      <a
                                                        target="_blank"
                                                        href="https://www.instagram.com/offcutshades/"
                                                      >
                                                        <img
                                                          title="Instagram"
                                                          src="https://vdpuhr.stripocdn.email/content/assets/img/social-icons/logo-black/instagram-logo-black.png"
                                                          alt="Inst"
                                                          width="32"
                                                        />
                                                      </a>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      esd-tmp-icon-type="pinterest"
                                                    >
                                                      <a
                                                        target="_blank"
                                                        href="https://www.pinterest.ca/offcutshades/"
                                                      >
                                                        <img
                                                          title="Pinterest"
                                                          src="https://vdpuhr.stripocdn.email/content/assets/img/social-icons/logo-black/pinterest-logo-black.png"
                                                          alt="P"
                                                          width="32"
                                                        />
                                                      </a>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-text es-p35b"
                                            >
                                              <p>Off Cut Shades&nbsp;</p>
                                              <p>
                                                50 Hollinger road, Toronto, ON
                                                ·
                                                <a
                                                  target="_blank"
                                                  href="tel:+14162856001"
                                                >
                                                  416.285.6001
                                                </a>
                                                <br />
                                                You are receiving this email
                                                because&nbsp;you have
                                                requested an in-home
                                                consultation.&nbsp;
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>`,
    );
    setFName('');
    setLName('');
    setEmail('');
    setMessage('');
    // setEmail('');
    // setFName('');
    // setLName('');
    // setMessage('');
    setPopState(false);
    setToast(true);
  };

  return (
    <div>
      <Menu3 />
      <section class='text-light  p-lg-0  text-center text-sm-start'>
        {/* <div class='container'> */}
        <div class='d-flex align-items-center justify-content-center'>
          <img
            class='img-fluid'
            src='/images/newHome/OCS-Contact-page-header.jpg'
            alt=''
          />
          <div style={styles.shadow}>
            {/* <img
              src={MapleLeaf}
              style={styles.leafIcon}
              alt='Maple Leaf Icon'
            /> */}
            <h1 class='my-2 text-white '>Contact Us</h1>
            <h4 class='lead text-white'>We are ready to assist you</h4>
          </div>
        </div>
        {/* </div> */}
      </section>
      <div class='container '>
        <div class='row'>
          <div class='col p-5'>
            <div class='row g-0'>
              <div class='col-md-4 text-center' style={styles.align}>
                <img
                  src='/images/newHome/Personal Service Logo.png'
                  class='img-fluid rounded-start'
                  alt='...'
                />
              </div>
              <div class='col-md-4'>
                <div class='card-body'>
                  <h5 class='card-title lead'>(416) 285-6001</h5>
                  <h5 class='card-title lead'>info@offcutshades.com</h5>
                </div>
              </div>
            </div>
            <div class='row g-0'>
              <div class='col-md-4 text-center ' style={styles.align}>
                <img
                  src='/images/newHome/Showroom logo.png'
                  class='img-fluid rounded-start'
                  alt='...'
                />
              </div>
              <div class='col-md-5'>
                <div class='card-body'>
                  <h5 class='card-title lead'>
                    50 Hollinger Road, Toronto, Ontario.
                    <br /> M4B 3G5
                  </h5>
                </div>
              </div>
            </div>

            <div class='row g-0'>
              <div class='col-md-10'>
                <div class='card-body p-5'>
                  <p class='card-text lead'>Visit our Showroom:</p>
                  <p>
                    Here we have on display our products and offering. Have one
                    of our sales representatives walk you through all the steps
                    necessary for ordering a shade.
                  </p>
                  <p class='card-text lead'>Showroom Hours:</p>
                  <p>
                    Monday - Friday: 9:00 AM to 5:00 PM
                    <p>Saturday - Sunday: Closed</p>
                  </p>
                  <p>
                    We recommend that you book an appointment for a better and
                    more personalized shopping experience.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class='col p-5'>
            <div class='row'>
              <div class='col-md-10'>
                <div class='card-body'>
                  <p class='card-text lead'>
                    We want to make your window covering shopping experience as
                    easy as possible. If you need assistance picking your
                    shades, have any questions about your order, or have a
                    general inquiry, please don’t hesitate to contact us. You
                    may fill out the form below or talk with one of our product
                    specialists.
                  </p>
                </div>
              </div>
            </div>
            <div class='row'>
              <div class='col-md-10'>
                <form class='p-2' onSubmit={handleSub}>
                  <TextField
                    style={{ margin: '.5rem' }}
                    id='filled-basic'
                    label='First Name'
                    required
                    value={fName}
                    type='text'
                    onChange={(e) => setFName(e.target.value)}
                  />
                  <TextField
                    style={{ margin: '.5rem' }}
                    //
                    id='filled-basic'
                    label='Last Name'
                    required
                    value={lName}
                    type='text'
                    onChange={(e) => setLName(e.target.value)}
                  />
                  <TextField
                    style={{ margin: '.5rem' }}
                    id='filled-basic'
                    label='Email Address'
                    value={email}
                    helperText={emailErrorText}
                    error={emailErrorText}
                    onChange={(e) => {
                      let str = e.target.value;
                      if (
                        str.match(
                          '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$',
                        ) != null
                      ) {
                        setEmailErrorText('');
                        setEmail(str);
                      } else {
                        setEmailErrorText('invalid email');
                      }
                    }}
                  />
                  <TextField
                    style={{ margin: '.5rem', width: '25rem' }}
                    id='filled-basic'
                    label='Message'
                    value={message}
                    required
                    multiline
                    rows={3}
                    type='text'
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  {/* <div class='row'> */}
                  {/* <div class='col-md-10 text-end'> */}
                  <button
                    class='btn btn-outline-dark my-4 mainPageBtn buttonMainPage'
                    type='submit'>
                    Submit
                  </button>
                  {/* </div> */}
                  {/* </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='container p-2'>
        <Snackbar autoHideDuration={6000} open={toast} close={closeToast}>
          <Alert severity='success' onClose={closeToast}>
            <AlertTitle>Request submitted</AlertTitle>
          </Alert>
        </Snackbar>
        <Grid item xs={12} sm={12}>
          <iframe
            style={styles.map}
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2883.798940277393!2d-79.31543274872995!3d43.714727279016856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cd1093170493%3A0x49fe692c4ded4364!2sOff%20Cut%20Shades!5e0!3m2!1sen!2sca!4v1579535251248!5m2!1sen!2sca'></iframe>
        </Grid>
      </div>
    </div>
  );
};

export default Contact;

// /** @format */

// const AboutUsScreen = () => {
//   window.onbeforeunload = function () {
//     window.scrollTo(0, 0);
//   };
//   let unused = window.onbeforeunload();
//   const language = English;

// export default AboutUsScreen;
