import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import { createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import Menu2 from './components/Menu2';
import Footer from './components/Footer';
import CartScreen from './screens/CartScreen';
import ShippingScreen from './screens/ShippingScreen';
import PaymentScreen from './screens/PaymentScreen';
import HelpScreen from './screens/HelpScreen';
import AllShadeScreen from './screens/AllShadeScreen';
import EnvironmentScreen from './screens/EnvironmentScreen';
import ProductScreenDetailed from './screens/ProductScreenDetailed';
import InstallationScreen from './screens/help/InstallationScreen';
import MeasuringGuideScreen from './screens/help/MeasuringGuideScreen';
import HomeScreen from './screens/HomeScreen';
import NewHome from './screens/NewHome';
import AboutUsScreen from './screens/AboutUsScreen';
import ProductSelectionGuideScreen from './screens/help/ProductSelectionGuideScreen';
import PicksScreen from './screens/PicksScreen';
import ShippingPolicyScreen from './screens/help/ShippingPolicyScreen';
import ReturnPolicyScreen from './screens/help/ReturnPolicyScreen';
import WarrantyScreen from './screens/help/WarrantyScreen';
import MotorGuideScreen from './screens/help/MotorGuideScreen';
import CollectionsScreen from './screens/CollectionsScreen';
import LoginScreen from './screens/LoginScreen';
import RecoveryScreen from './screens/RecoveryScreen';
import PasswordResetScreen from './screens/PasswordResetScreen';
import RegisterScreen from './screens/RegisterScreen';
import TeamScreen from './screens/TeamScreen';
import ProfileScreen from './screens/ProfileScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen';
import RecommendedScreen from './screens/RecommendedScreen';
import ConfirmationScreen from './screens/ConfirmationScreen';
import MyOrderScreen from './screens/MyOrderScreen';
import wallStart from './components/wallPaperComponents/startWallPaper';
import TheForgeFunc from './components/wallPaperComponents/TheForge/TheForge';
import { useState } from 'react';
import ReactGA from 'react-ga';
import Contact from './screens/Contact';
import Customize from './screens/Customize';
import CustomizeShade from './screens/CustomizeShade';
import FabricSwatch from './screens/FabricSwatch';
import Magazine from './screens/Magazine';
import SwatchCheckout from './screens/SwatchCheckout';

// const TRACKING_ID = "G-FZ1WRJFPRF"; // YOUR_OWN_TRACKING_ID

// ReactGA.initialize(TRACKING_ID);


const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        a: {
          textDecoration: 'none',
          color: 'black',
        },
      },
    },
  },
  palette: {
    primary: {
      // main: grey[50],
      // main: '#fff',
      // Black needed for text fields
      main: '#000',
    },
    secondary: {
      // main: red[600],
      // main: '#EF4138',
      main: '#E61610',
    },
    text: {
      primary: '#000',
      secondary: '#474747',
    },
  },
  typography: {
    fontFamily: 'Helvetica W01 Cn, Helvetica, Arial, sans-serif',
    button: {
      fontFamily: 'Source Sans Pro',
      fontSize: '1rem',
      border: '1px solid black',
    },
  },
  shape: {
    // borderRadius: '1px',
  },
});

const App = () => {
  const [filterState,setFilterState] = useState(
    JSON.stringify({
      'color':[],
      'light':[],
      'price':[],
      'type':[],
      'operation':[],
      'collection':[],
    })
  )

  document.body.style = 'background: white;'

  return (
    // forceRefresh = { true}
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <Route path='/aboutus' component={AboutUsScreen} />
          <Route path='/contact' component={Contact} />
          <Route path='/cart/:program_id?' component={CartScreen} />
          <Route
            path='/collections/:collection?'
            exact
            component={CollectionsScreen}
          />
          <Route path='/payment' component={PaymentScreen} />
          <Route path='/shipping' component={ShippingScreen} />
          <Route path='/environment' component={EnvironmentScreen} />
          <Route path='/help' component={HelpScreen} />
          <Route path='/installation' component={InstallationScreen} />
          <Route
            path='/productselection'
            component={ProductSelectionGuideScreen}
          />
          <Route path='/measuring' component={MeasuringGuideScreen} />
          <Route path='/shippingpolicy' component={ShippingPolicyScreen} />
          <Route path='/returnpolicy' component={ReturnPolicyScreen} />
          <Route path='/warranty' component={WarrantyScreen} />
          <Route path='/motorguide' component={MotorGuideScreen} />
          <Route path='/picks/:pick' component={PicksScreen} />
          <Route path='/products/:query' exact component={AllShadeScreen} />
          <Route path='/products' exact component={AllShadeScreen} />
          <Route path='/login' component={LoginScreen} />
          <Route path='/accountRecovery' component={RecoveryScreen}/>
          <Route path='/resetPassword' component={PasswordResetScreen}/>
          <Route path='/register' component={RegisterScreen} />
          <Route path='/profile' component={ProfileScreen} />
          <Route path='/myorders' component={MyOrderScreen} />

          <Route path='/orders' component={OrderScreen} />
          <Route path='/placeorder' component={PlaceOrderScreen} />
          <Route path='/confirmation' component={ConfirmationScreen} />
          <Route
            path='/products/name/:name'
            exact
            component={ProductScreenDetailed}
          />
          <Route path='/team' exact component={TeamScreen} />
          <Route path='/recommended' exact component={RecommendedScreen} />
          <Route path='/wallpaperinit' exact component={wallStart} />
          <Route path='/wallpapergenerator' exact component={TheForgeFunc} />
          <Route path='/' exact component={NewHome} />
          <Route path='/newhome' exact component={NewHome} />
          <Route path='/customize' exact component={Customize} />
          <Route path='/customizeshade' exact component={CustomizeShade} />
          <Route path='/fabricswatch' exact component={FabricSwatch} />
          <Route path='/magazine' exact component={Magazine} />
          <Route path='/swatchcheckout' exact component={SwatchCheckout} />
          <Footer />
        </CssBaseline>
      </ThemeProvider>
    </Router >

  );
};

export default App;
