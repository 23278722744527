import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getOrderDetails } from '../actions/orderActions';
import { ORDER_PAY_RESET } from '../constants/orderConstants';
import { useHistory } from 'react-router-dom'

const useAffirm = ({ order, successPay }, match) => {
    const dispatch = useDispatch();
    const orderId = match.params.id;
   
    const historyHook = useHistory()
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const addAffirmScript = async () => {

            const script = document.createElement("script");
            script.src = "https://cdn1.affirm.com/js/v2/affirm.js";
            script.async = true;
            script.onload = () => {
                
                window._affirm_config = {
                    public_api_key: "{YOUR_PUBLIC_API_KEY}",
                    locale: "en_CA",
                    country_code: "CAN",
                };

                window.affirm.ui.ready(function () {
                    window.affirm.ui.configure();
                });
                
            };

            document.body.appendChild(script);
        }
        if (!order || successPay) {
            dispatch({ type: ORDER_PAY_RESET })
            dispatch(getOrderDetails(orderId,historyHook));
        } else if (!order.isPaid) {
            if (!window.affirm) {
                addAffirmScript();
            } else {
                setLoaded(true);
            }
        }
       
    }, [dispatch, orderId, successPay, order, loaded]);

    return loaded;
};

export default useAffirm;
