
import { useSelector } from 'react-redux'
import {addImageToDoc,printDoc,createDoc,getDoc} from './localStorageSystem'

import '../../../wallpaperimg/main.css'


 export default function useSaveToCache(imgMap){
 
    
     var redux = {}
     redux.dimensions = useSelector((state) => state.wallpaperDims.value)
     redux.frame = useSelector((state)=>state.wallpaperFrame.value)
     //var email = useSelector((state)=>state.userLogin.userInfo)
     var email = 'WALLuser'
     redux.user = email
     createDoc(email,imgMap,redux)
     //return true
 }