/** @format */

import React, { useEffect, useState } from 'react';
import { Button, Grid, Snackbar } from '@material-ui/core';
import { addToCart } from '../actions/cartActions';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertTitle } from '@material-ui/lab';
import { addEmailToCartMailChimp } from '../actions/userActions';

const CustomizeSummary = ({
  page,
  productInfo,
  optionInfo,
  totalPrice,
  switchPage,
  setSwitchPage,
  userInfo,
}) => {
  var dispatch = useDispatch();
  var priceToDisplay =
    totalPrice.toString().split('\n')[0] -
    (totalPrice.toString().split('\n')[0] * productInfo.Discount).toFixed(2);
  //   console.log(totalPrice.toString().split('\n')[0]);
  //   console.log(priceToDisplay);
  optionInfo.RETAIL_PRICE = (
    priceToDisplay +
    productInfo.Discount * priceToDisplay
  ).toFixed(2);
  optionInfo.OUR_PRICE = priceToDisplay.toFixed(2);

  var [submitted, setSubmitted] = useState(false);
  var [invalidToast, setInvalidToast] = useState(false);
  var [errorText, setErrorText] = useState(
    'Please choose all the options before going forward.',
  );

  const styles = {
    disabledText: {
      color: '#9b9b9b',
      borderBottom: '1px solid #9b9b9b;',
      fontSize: '1.1rem',
      // fontFamily: 'Lato',
    },
    leftHeader: {
      color: '#000',
      borderBottom: '1px solid #000;',
      fontSize: '1.5rem',
      textAlign: 'left',
      margin: '2rem 0 0 0',
    },
    heavyText: {
      fontWeight: 400,
    },
    topSpacer: {
      marginTop: '2rem',
    },
    panel: {
      background: '#e3e3e3d9',
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
      textAlign: 'center',
    },
    couponCode: {
      color: '#000',
      fontSize: '1.5rem',
      textAlign: 'left',
      width: '100%',
    },
    priceText: {
      fontSize: '1rem',
      margin: '.5rem 0',
    },
    priceTextSave: {
      fontSize: '1rem',
      margin: '.5rem 0',
      color: '#E61610',
    },
    priceTextSaveStrike: {
      fontSize: '1rem',
      margin: '.5rem 0',
      color: '#E61610',
      textDecoration: 'line-through',
    },
    hide: {
      visibility: 'hidden',
    },
    priceTotal: {
      fontSize: '1.2rem',
      margin: '.5rem 0',
      borderTop: '1px solid #000;',
    },
    priceSaving: {
      fontSize: '1.2rem',
      margin: '.5rem 0',
      color: '#E61610',
    },
    atcButton: {
      background: '#ebebeb',
      minWidth: '12rem',
      marginRight: '1rem',
      marginTop: '1rem',
    },
    divider: {
      width: '100%',
      margin: '1rem auto',
    },
    darkText: {
      color: '#000',
      borderBottom: '1px solid #000;',
      fontSize: '1.1rem',
      // fontFamily: 'Lato',
    },

    headText: {
      fontWeight: 400,
      fontSize: '1rem',
      // textTransform: 'uppercase',
      padding: '.25rem 0',
      textAlign: 'left',
    },
    subText: {
      fontWeight: 300,
      fontSize: '1rem',
      padding: '.25rem 0',
    },

    cartItem: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    },
    imgContainer: {
      width: '100%',
    },
    msrpTag: {
      textAlign: 'Left',
      fontWeight: 400,
      fontSize: '1rem',
      textTransform: 'Capitalize',
      textDecoration: 'line-through',
    },
    otherPirces: {
      textAlign: 'Left',
      fontWeight: 400,
      fontSize: '1rem',
      textTransform: 'Capitalize',
    },
    ourTag: {
      textAlign: 'Left',
      fontWeight: 400,
      fontSize: '1rem',
      textTransform: 'Capitalize',
      color: '#E61610',
    },
    msrPrice: {
      textAlign: 'Left',
      fontWeight: 400,
      fontSize: '2rem',
      textTransform: 'Capitalize',
      textDecoration: 'line-through',
    },
    ourPrice: {
      textAlign: 'Left',
      fontWeight: 400,
      fontSize: '2rem',
      textTransform: 'Capitalize',
      color: '#E61610',
    },
    removeButton: {
      border: 'none',
    },
    quantityField: {
      maxWidth: '35%',
      minWidth: '4rem',
      margin: '1rem 0 0 1rem',
    },
    TextField: {
      marginBottom: '1rem',
      background: '#ebebeb',
      minWidth: '12rem',
      marginRight: '1rem',
    },

    divider: {
      width: '100%',
      margin: '1rem auto',
    },
    leftHeader: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    items: {
      margin: '1rem',
      padding: '2rem',
    },
    image: {
      width: '150px',
      height: '150px',
      marginBottom: '2rem',
      objectFit: 'cover',
    },
    atcButton: {
      // background: '#ebebeb',
      minWidth: '10rem',
      marginRight: '1rem',
      marginTop: '1rem',
    },
    msrpTag: {
      textAlign: 'Left',
      fontWeight: 400,
      fontSize: '1rem',
      textTransform: 'Capitalize',
    },
    ourTag: {
      textAlign: 'right',
      fontWeight: 400,
      fontSize: '1rem',
      textTransform: 'Capitalize',
      color: '#E61610',
      whiteSpace: 'break-spaces',
    },
    msrPrice: {
      textAlign: 'Left',
      fontWeight: 400,
      fontSize: '1.5rem',
      textTransform: 'Capitalize',
    },
    ourPrice: {
      textAlign: 'Left',
      fontWeight: 400,
      fontSize: '1.5rem',
      textTransform: 'Capitalize',
      color: '#E61610',
    },
  };

  const forwardPage = () => {
    if (
      totalPrice == 0 ||
      !productInfo?.Valance_Color ||
      !productInfo?.Bottom_Bar_Color ||
      !productInfo?.Valance ||
      (productInfo?.Valance_Color == '-' && productInfo?.Valance != 'Open Roll')
    ) {
      if (
        !productInfo?.Valance_Color ||
        (productInfo?.Valance_Color == '-' &&
          productInfo?.Valance != 'Open Roll')
      )
        setErrorText('Please choose a valance color.');
      else if (!productInfo?.Bottom_Bar_Color)
        setErrorText('Please choose a bottom bar color.');
      else if (!productInfo?.Valance) setErrorText('Please choose a valance.');
      setInvalidToast(true);
      return;
    }
    if (optionInfo.customize) optionInfo.OUR_PRICE = priceToDisplay.toFixed(2);
    if (userInfo?.email) {
      addEmailToCartMailChimp(userInfo?.email);
    }
    dispatch(
      addToCart({
        productInfo: { ...productInfo },
        optionInfo: { ...optionInfo },
      }),
    );

    setSubmitted(true);
    // Redirect after 4 seconds
    setTimeout(() => {
      window.location.href = '/cart';
    }, 1000);
    return;
  };

  const resetToast = () => {
    setInvalidToast(false);
  };

  useEffect(() => {
    if (switchPage && page.current == 6) {
      var t6 = document.getElementById('t6');
      t6.style = styles.darkText;

      var toHide = document.getElementById('t' + switchPage);

      toHide.style.color = '#9b9b9b';
      toHide.style.fontSize = '0.8rem';
      setSwitchPage(0);
    }
  }, [switchPage]);

  const previousPage = () => {
    page.current = 5;

    var toChange = document.getElementById('summary');
    toChange.style.display = 'none';
    var t6 = document.getElementById('t6');

    t6.style.color = '#9b9b9b';
    t6.style.fontSize = '0.8rem';

    var t5 = document.getElementById('t5');
    t5.style = styles.darkText;

    toChange = document.getElementById('valance');
    toChange.style.display = 'block';
  };

  return (
    <>
      {' '}
      {/* 6. Summary */}
      <Grid
        container
        id='summary'
        visible='none'
        direction='row'
        style={page.current == 6 ? { display: 'block' } : { display: 'none' }}
        justifyContent='space-around'
        alignItems='flex-center'
        spacing={1}>
        <Grid>
          <p style={styles.leftHeader}>6. Summary</p>
          <hr style={styles.divider} />
          <Grid container direction='column'>
            <span style={styles.headText}>{productInfo?.Program}</span>
            <span style={styles.headText}>
              Size :
              <span style={styles.subText}>
                {optionInfo?.Type} {optionInfo?.widthWhole}{' '}
                {optionInfo?.widthFraction === '0/16'
                  ? ''
                  : optionInfo?.widthFraction}{' '}
                x {optionInfo?.lengthWhole}{' '}
                {optionInfo?.lengthFraction === '0/16'
                  ? ''
                  : optionInfo?.lengthFraction}{' '}
              </span>
            </span>

            <span style={styles.headText}>
              {' '}
              Type : <span style={styles.subText}> {productInfo?.Type}</span>
            </span>
            <span style={styles.headText}>
              {' '}
              Drive :{' '}
              <span style={styles.subText}>{productInfo?.Operation}</span>{' '}
            </span>
            <span style={styles.headText}>
              {' '}
              Drive Position :{' '}
              <span style={styles.subText}>{productInfo?.position}</span>
            </span>
            <span style={styles.headText}>
              {' '}
              Fabric :{' '}
              <span style={styles.subText}> {productInfo?.Fabric}</span>
            </span>
            <span style={styles.headText}>
              {' '}
              Fabric Color :{' '}
              <span style={styles.subText}>{optionInfo?.selectedColor}</span>
            </span>

            <span style={styles.headText}>
              {' '}
              Valance :{' '}
              <span style={styles.subText}>{productInfo?.Valance}</span>
            </span>
            <span style={styles.headText}>
              {' '}
              Valance Color :{' '}
              <span style={styles.subText}>{productInfo?.Valance_Color}</span>
            </span>
            <span style={styles.headText}>
              {' '}
              Bottom Bar :{' '}
              <span style={styles.subText}> {productInfo?.Bottom_Bar}</span>
            </span>
            <span style={styles.headText}>
              {' '}
              Bottom Bar Color :{' '}
              <span style={styles.subText}>
                {productInfo?.Bottom_Bar_Color}
              </span>
            </span>
            <span style={styles.headText}>
              {' '}
              L Channel :{' '}
              <span style={styles.subText}>{productInfo?.L_Channel}</span>
            </span>
          </Grid>
          {/* </Grid> */}
        </Grid>
        <Snackbar
          open={invalidToast}
          autoHideDuration={3000}
          onClose={resetToast}>
          <Alert severity='error'>
            <AlertTitle>{errorText}</AlertTitle>
          </Alert>
        </Snackbar>
        <Snackbar open={submitted} autoHideDuration={2000}>
          <Alert severity='success'>
            <AlertTitle>
              The customized product has been added to the cart successfully.
              Redirecting in 2 seconds...
            </AlertTitle>
          </Alert>
        </Snackbar>
        <Grid container direction='row'>
          <Grid item xs={6}>
            <Button id='back' onClick={previousPage} style={styles.atcButton}>
              Back
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className='redButton'
              style={{
                margin: '.5rem 0rem',
                width: '10rem',
                backgroundColor: '#ef4036',
                color: '#fff',
                marginTop: '1rem',
              }}
              onClick={forwardPage}>
              Add To Cart
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomizeSummary;
