import React, { useState } from 'react';

function ImageZoom(props) {
    const [showModal, setShowModal] = useState(false);


    const handleClick = () => {
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    return (

        <div>
            <div className='mediaIcons'>
                <div className='zoomIcon' onClick={handleClick} />
                {/* <i className="fa fa-search-plus"></i> */}
            </div>
            <div className="image-container">

                {showModal && (
                    <div className="modal" onClick={handleClose}>
                        <img src={props.imageUrl} alt={props.altText} />
                        <button className="close highlighted" onClick={handleClose}>
                            &times;
                        </button>
                    </div>
                )}
            </div>

        </div>
    );
}

export default ImageZoom;
