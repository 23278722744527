/** @format */

import React from 'react';
import { Container, Grid, Menu, Typography } from '@material-ui/core';
import Menu3 from '../../components/Menu3';
// import { English } from '../../localization/strings';

const styles = {
  heavyText: {
    // fontWeight: 400,
    textAlign: 'center',
  },
  subText: {
    fontWeight: 300,
    // width: '80%',
    margin: '2rem auto',
  },
  subHead: {
    fontWeight: 400,
    width: '80%',
    margin: '0rem auto',
    marginTop: '1rem',
    textAlign: 'center',
  },
  subDesc: {
    fontWeight: 300,
    width: '80%',
    margin: '0rem auto',
  },
  listStyle: {
    listStyle: 'none',
    fontSize: '1.5rem',
    fontWeight: '300',
    padding: 0,
    margin: '1.5rem auto',
  },
  itemSpacer: {
    margin: '1.25rem auto',
  },
  divider: {
    width: '100%',
    borderTop: '1px solid black',
    marginTop: '1rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  align: {
    alignSelf: 'center',
    textAlign: 'center',
  },
};

const WarrantyScreen = () => {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  window.onbeforeunload();

  return (
    <div>
     <Menu3/>
      <div class='container '>
        <div class='p-4 ' style={styles.align}>
          <img
            src='/images/newHome/Warranty-policy-80x80.png'
            class='img-fluid rounded-start'
            alt='...'
          />
        </div>
        <h4 class='my-2 text-center fw-bolder'>Guaranteed Fit & Warranty</h4>
        <div class=' p-4'>
          <p class='my-2 text-center'>
            You have invested time and money in finding the right window shade
            for your home. We want to offer you the peace of mind that after you
            buy a product from us, we will be there in case something unexpected
            happens. Below is how your investment is protected.
          </p>
        </div>
        <hr style={styles.divider} />

        <p class='lead fw-bolder '>Guaranteed Fit</p>
        <p>
          We test all our shades before sending them to the customers. In the
          unlikely event that a shade does not match your measurements, we will
          replace the shade at no cost to you.
        </p>
        <p>
          We also understand that mistakes happen. If you find out that your
          measurements are inaccurate, contact us within 14 days from the date
          of purchase. We will work with you to find the best and most
          affordable solution. In certain situations, we will be able to adjust
          your shades to the proper measurements at no cost.
        </p>
        <hr style={styles.divider} />

        <p class='lead fw-bolder'>Colour Accuracy </p>
        <p>
          Despite our best efforts to give you an accurate representation of the
          colour and texture of the fabrics listed on our website, the actual
          colour may differ. Additionally, colour accuracy varies due to
          technical differences in display devices. If you require an exact
          colour match please request a sample of the fabrics you like before
          placing an order. Also, we suggest that you buy all your blinds of a
          single colour in the same order. Dye lots slightly vary from time to
          time.
        </p>
        <hr style={styles.divider} />

        <p class='lead fw-bolder'>Satisfaction Guaranteed</p>
        <p>
          If you are not completely satisfied with the quality, colour or design
          of your new shade, contact us within 14 days from the date of
          purchase. We will work with you to make it right.
        </p>
        <hr style={styles.divider} />

        <p class='lead fw-bolder'>5-Year warranty</p>
        <p>
          All of our products are made locally by expert craftsmen using top
          grade materials and components. Under normal use, your products should
          operate problem free for years. In the rare event that you experience
          issues related to manufacturing defects, contact our customer service
          team, and we will repair or replace the defective product. The
          Warranty is extended to the original retail purchaser only, is
          non-transferable, and only applies to custom-made products in the
          original windows for which the product was ordered and intended. and
          is valid for 5 years from the date of purchase.
        </p>
        <hr style={styles.divider} />

        <p>
          The warranty does not cover defects resulting from abuse, misuse,
          normal wear and tear, accidents, alterations, exposure to the
          elements, excessive humidity, or failure to follow use and care
          instructions. Normal maintenance associated with motors and related
          accessories including, but not limited to, recharging batteries. Any
          condition or damage resulting from removal of the product or
          reinstallation in the same or another window or application. Products
          unusable by reason of miss-measurement or assembly or installation not
          in accordance with product installation instructions. Shipping costs,
          costs of removal, re-measuring and reinstallation are the
          responsibility of the purchaser.{' '}
        </p>
      </div>
    </div>
  );
};

export default WarrantyScreen;
