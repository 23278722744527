

export const fontObject = {
    // 'lato':'lato',
    'updock':"updock",
    'Romanesco':"romanesco",
    'Comfortaa':'comfortaa',
    'Pacifico':'pacifico',
    'Bebas Neue':'bebas neue',
    'Oswald':'oswald',
    'Raleway':'raleway',
    'Great Vibes':'great vibes',
    'Vidaloka':'vidaloka',
    'Playball':'playball'


}