/** @format */

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import OCSApi from '../OCSApi';
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  IconButton,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import StyledBadge from '@mui/material/Badge';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import MapleLeaf from './CanadianMapleLeaf.svg';
import { logout } from '../actions/userActions';
import { splitQName } from 'soap/lib/utils';
import { setFilter } from '../actions/filterActions';
import { useHistory } from 'react-router-dom';
import './menu.css';

const styles = {
  logo: {
    height: '4rem',
    alignItems: 'center',
    paddingLeft: '1.5rem',
  },
  buttonBorder: {
    borderRight: '1px solid black',
  },

  button: {
    padding: '0 1.5rem',
    border: 'none',
    background: 'transparent',
    color: 'white',
    textTransform: 'capitalize',
    minWidth: '10rem',
    width: 'fit-content',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },

  buttonBottom: {
    padding: '0 .8rem',
    border: 'none',
    background: 'transparent',
    color: 'white',
    textTransform: 'capitalize',
    minWidth: '10rem',
    width: 'fit-content',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    // margin:1px
  },

  cartButton: {
    border: 'none',
    background: 'transparent',
    color: 'black',
    textTransform: 'capitalize',
    width: 'fit-content',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },

  menuHeading: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },

  subtitle: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  cartPosition: {
    margin: '-1.5rem',
    width: '80%',
  },
  roundButton: {
    background: 'black',
    color: 'white',
    width: '1rem',
    height: '1rem',
    margin: '0.1rem',
  },
  divider: {
    // width: '100%',
    padding: '3rem ',
    backgroundColor: 'white',
  },
  divider2: {
    width: '90%',
    backgroundColor: 'lightGrey',
    color: 'lightGrey',
  },
  popperLayer: {
    zIndex: 2,
  },
  leafIcon: {
    height: '1rem',
    // marginLeft: '25rem',
  },
  cartIcon: {
    color: 'black',
    // height:'.5rem'
  },
  a: {
    fontWeight: 300,
    width: 'max-content',
    fontSize: '1rem',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'blue',
    },
  },
};

const Menu = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);

  if (userLogin?.isGuest) var userInfo = null;
  else var { userInfo } = userLogin;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const swatchCart = useSelector((state) => state.swatchCart);
  const { swatchCartItems } = swatchCart;

  const [openFirst, setOpenFirst] = useState(false);
  // const [openSecond, setOpenSecond] = useState(false);
  const [openThird, setOpenThird] = useState(false);
  const [openFourth, setOpenFourth] = useState(false);
  const [openFifth, setOpenFifth] = useState(false);

  const [coverings, setCoverings] = useState([]);
  const [collections, setCollections] = useState([]);
  const [picks, setPicks] = useState([]);

  const [colorNav, setColorNav] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColorNav(true);
    } else {
      setColorNav(false);
    }
  };

  window.addEventListener('scroll', changeColor);
  // const [open, setOpen] = useState(false);
  const anchorRefOne = useRef(null);
  const anchorRefTwo = useRef(null);
  const anchorRefThree = useRef(null);
  const anchorRefFour = useRef(null);
  const anchorRefFive = useRef(null);

  const handleToggle = (e) => {
    let menuNumber = e.currentTarget.value;

    switch (menuNumber) {
      case 'First':
        setOpenFirst((prevOpen) => !prevOpen);
        // setOpenSecond(false);
        setOpenThird(false);
        setOpenFourth(false);
        setOpenFifth(false);

        break;
      case 'Second':
        setOpenFirst(false);
        // setOpenSecond((prevOpen) => !prevOpen);
        setOpenThird(false);
        setOpenFourth(false);
        setOpenFifth(false);

        break;
      case 'Third':
        setOpenFirst(false);
        // setOpenSecond(false);
        setOpenThird((prevOpen) => !prevOpen);
        setOpenFourth(false);
        setOpenFifth(false);

        break;
      case 'Fourth':
        setOpenFirst(false);
        // setOpenSecond(false);
        setOpenThird(false);
        setOpenFourth((prevOpen) => !prevOpen);
        setOpenFifth(false);
        break;
      case 'Fifth':
        setOpenFirst(false);
        // setOpenSecond(false);
        setOpenThird(false);
        setOpenFourth(false);
        setOpenFifth((prevOpen) => !prevOpen);
        break;
      default:
        break;
    }
    // menuHandler()
  };

  const handleClose = (event) => {
    if (anchorRefOne.current && anchorRefOne.current.contains(event.target)) {
      // setOpenSecond(false);
      setOpenThird(false);
      setOpenFourth(false);
      setOpenFifth(false);
      return;
    }

    if (anchorRefTwo.current && anchorRefTwo.current.contains(event.target)) {
      setOpenFirst(false);
      setOpenThird(false);
      setOpenFourth(false);
      setOpenFifth(false);
      return;
    }

    if (
      anchorRefThree.current &&
      anchorRefThree.current.contains(event.target)
    ) {
      setOpenFirst(false);
      // setOpenSecond(false);
      setOpenFourth(false);
      setOpenFifth(false);
      return;
    }

    if (anchorRefFour.current && anchorRefFour.current.contains(event.target)) {
      setOpenFirst(false);
      // setOpenSecond(false);
      setOpenThird(false);
      setOpenFifth(false);
      return;
    }

    if (anchorRefFive.current && anchorRefFive.current.contains(event.target)) {
      setOpenFirst(false);
      // setOpenSecond(false);
      setOpenThird(false);
      setOpenFourth(false);
      return;
    }

    setOpenFirst(false);
    // setOpenSecond(false);
    setOpenThird(false);
    setOpenFourth(false);
    setOpenFifth(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenFirst(false);
      // setOpenSecond(false);
      setOpenThird(false);
      setOpenFourth(false);
      setOpenFifth(false);
    }
  }

  useEffect(() => {
    OCSApi.getMenuCoverings().then((e) => {
      setCoverings(e);
    });

    OCSApi.getMenuCollections().then((e) => {
      setCollections(e);
    });

    OCSApi.getMenuPicks().then((e) => {
      setPicks(e);
    });
  }, []);

  const logoutHandler = () => {
    dispatch(logout());
  };

  const history = useHistory();
  const menuHandler = (e) => {
    let reduxval = {
      color: [],
      light: [],
      price: [],
      type: [],
      operation: [],
      collection: [],
    };
    let paramsfull = e.target.id;
    let params = e.target.id.split('_');

    if (params[0] != 'ALL') {
      reduxval[params[0]] = [params[1]];
    }

    setFilter(dispatch, reduxval);
    history.push('/products');
    handleClose(e);
  };

  return (
    <>
      <div
        className={
          colorNav
            ? 'navbar navbar-expand-lg  navbar-dark bg-dark fixed-top header-bg'
            : 'navbar navbar-expand-lg navbar-dark bg-dark  fixed-top'
        }>
        <div className='container'>
          <a href='#' className='navbar-brand '>
            <Link to='/'>
              <img
                style={styles.logo}
                src='/images/logoLight.png'
                alt='OCS Logo'
              />
            </Link>
          </a>

          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navmenu'>
            <span className='navbar-toggler-icon'></span>
          </button>

          <div className='collapse navbar-collapse' id='navmenu'>
            <ul className='navbar-nav ms-auto align-items-center '>
              {/* <li className='nav-item text-light fs-6'>
                <Link to='/' style={styles.button}>
                  Home
                </Link>
              </li> */}
              <li className='nav-item'>
                <button
                  className='nav-item text-light fs-6 windowHover'
                  style={styles.button}
                  // variant='contained'
                  // color='primary'
                  disableElevation
                  ref={anchorRefOne}
                  aria-controls={openFirst ? 'menu-list-grow' : undefined}
                  aria-haspopup='true'
                  onClick={handleToggle}
                  value='First'>
                  Window Coverings
                </button>
                <Popper
                  style={styles.popperLayer}
                  open={openFirst}
                  anchorEl={anchorRefOne.current}
                  role={undefined}
                  transition
                  disablePortal
                  placement='bottom-start'>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom',
                      }}>
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <div
                            className='liii'
                            autoFocusItem={openFirst}
                            id='menu-list-grow'
                            onKeyDown={handleListKeyDown}>
                            <div class='g-2 navBox'>
                              <div class='col-md col-md1  '>
                                <ul className='ul'>All</ul>
                                <Link to={'/products'}>
                                  <li
                                    className='li'
                                    style={styles.a}
                                    id='ALL_ALL_menu'
                                    onClick={menuHandler}>
                                    All Products
                                  </li>
                                </Link>
                                <ul className='ul'>Shade Type</ul>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='type_Roller_menu'
                                  onClick={menuHandler}>
                                  Roller
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='type_Banded_menu'
                                  onClick={menuHandler}>
                                  Banded
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='type_Sheer_menu'
                                  onClick={menuHandler}>
                                  Sheer
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='type_Aria_menu'
                                  onClick={menuHandler}>
                                  Aria
                                </li>
                                {/* </div> */}
                                <ul className='ul'>Collection</ul>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='collection_The Dark Side_menu'
                                  onClick={menuHandler}>
                                  The Dark Side
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='collection_Pattern Perfect_menu'
                                  onClick={menuHandler}>
                                  Pattern Perfect
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='collection_Happy Hues_menu'
                                  onClick={menuHandler}>
                                  Happy Hues
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id="collection_Child's Play_menu"
                                  onClick={menuHandler}>
                                  Child's Play
                                </li>
                              </div>
                              <div class='col-md col-md1 '>
                                <ul className='ul'>Light Filter</ul>
                                {/* <hr style={styles.divider2} /> */}

                                <li
                                  className='li'
                                  style={styles.a}
                                  id='light_Blackout_menu'
                                  onClick={menuHandler}>
                                  Blackout
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='light_Privacy_menu'
                                  onClick={menuHandler}>
                                  Privacy
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='light_Sunscreen_menu'
                                  onClick={menuHandler}>
                                  Sun Screen
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='light_Translucent_menu'
                                  onClick={menuHandler}>
                                  Translucent
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='light_Transparent_menu'
                                  onClick={menuHandler}>
                                  Transparent
                                </li>
                              </div>
                              <div class='col-md col-md1 '>
                                <ul className='ul'>Price</ul>
                                {/* <hr style={styles.divider2} /> */}
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='price_$_menu'
                                  onClick={menuHandler}>
                                  $
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='price_$$_menu'
                                  onClick={menuHandler}>
                                  $$
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='price_$$$_menu'
                                  onClick={menuHandler}>
                                  $$$
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='price_$$$$_menu'
                                  onClick={menuHandler}>
                                  $$$$
                                </li>
                              </div>
                              <div class='col-md col-md1 '>
                                <ul className='ul'>Colour</ul>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='color_White_menu'
                                  onClick={menuHandler}>
                                  White
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='color_Grey_menu'
                                  onClick={menuHandler}>
                                  Grey
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='color_Beige_menu'
                                  onClick={menuHandler}>
                                  Beige
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='color_Black_menu'
                                  onClick={menuHandler}>
                                  Black
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='color_Blue_menu'
                                  onClick={menuHandler}>
                                  Blue
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='color_Brown_menu'
                                  onClick={menuHandler}>
                                  Brown
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='color_Off White_menu'
                                  onClick={menuHandler}>
                                  Off White
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='color_Orange_menu'
                                  onClick={menuHandler}>
                                  Orange
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='color_Green_menu'
                                  onClick={menuHandler}>
                                  Green
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='color_Yellow_menu'
                                  onClick={menuHandler}>
                                  Yellow
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='color_Pink_menu'
                                  onClick={menuHandler}>
                                  Pink
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='color_Purple_menu'
                                  onClick={menuHandler}>
                                  Purple
                                </li>
                              </div>
                              <div class='col-md col-md1 '>
                                <ul className='ul'>Operation</ul>
                                {/* <hr style={styles.divider2} /> */}
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='operation_Chain_menu'
                                  onClick={menuHandler}>
                                  Chain
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='operation_Chainless_menu'
                                  onClick={menuHandler}>
                                  Chainless
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='operation_Motor_menu'
                                  onClick={menuHandler}>
                                  Motor
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='operation_Zero Gravity_menu'
                                  onClick={menuHandler}>
                                  Zero Gravity
                                </li>
                                <li
                                  className='li'
                                  style={styles.a}
                                  id='operation_Aria_menu'
                                  onClick={menuHandler}>
                                  Aria
                                </li>
                              </div>
                            </div>
                          </div>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </li>
              <li className='nav-item fs-6'>
                <Link to='/customizeshade' style={styles.button}>
                  Customize Your Own Shade
                </Link>
              </li>
              <li className='nav-item fs-6'>
                <Link to='/wallpaperinit' style={styles.button}>
                  Wallpaper
                </Link>
              </li>
              <li className='nav-item fs-6'>
                <Link to='/help' style={styles.button}>
                  Help
                </Link>
              </li>
              <li className='nav-item fs-6'>
                <Link to='/contact' style={styles.button}>
                  Contact
                </Link>
              </li>
              <li className='nav-item fs-6'>
                <Link to='/fabricswatch'>
                  <IconButton aria-label='cart'>
                    <StyledBadge
                      badgeContent={swatchCartItems.length}
                      color='error'
                      style={styles.cartIcon}>
                      {/* <img src='/images/newHome/shopping-cart-white-01.png' /> */}
                      <AutoAwesomeMotionIcon style={{ color: 'white' }} />
                    </StyledBadge>
                  </IconButton>
                </Link>
              </li>
              <li className='nav-item fs-6'>
                <Link to='/cart'>
                  <IconButton aria-label='cart'>
                    <StyledBadge
                      badgeContent={cartItems.length}
                      color='error'
                      style={styles.cartIcon}>
                      <img src='/images/newHome/shopping-cart-white-01.png' />
                    </StyledBadge>
                  </IconButton>
                </Link>
              </li>
              {userInfo ? (
                <li className='nav-item'>
                  <button
                    startIcon={<AccountCircleIcon />}
                    style={styles.button}
                    variant='contained'
                    color='primary'
                    disableElevation
                    ref={anchorRefFive}
                    aria-controls={openFifth ? 'menu-list-grow' : undefined}
                    aria-haspopup='true'
                    onClick={handleToggle}
                    value='Fifth'>
                    Hello , {userInfo.name}
                  </button>

                  <Popper
                    style={styles.popperLayer}
                    open={openFifth}
                    anchorEl={anchorRefFive.current}
                    role={undefined}
                    transition
                    disablePortal
                    placement='bottom-start'>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom'
                              ? 'center top'
                              : 'center bottom',
                        }}>
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={openFifth}
                              id='menu-list-grow'
                              onKeyDown={handleListKeyDown}>
                              <Link to='/profile'>
                                <MenuItem onClick={handleClose}>
                                  Profile
                                </MenuItem>
                              </Link>
                              <Link to='/myorders'>
                                <MenuItem onClick={handleClose}>
                                  Orders
                                </MenuItem>
                              </Link>
                              <Link to='/'>
                                <MenuItem onClick={logoutHandler}>
                                  Logout
                                </MenuItem>
                              </Link>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </li>
              ) : (
                <li className='nav-item fs-6'>
                  <Link to='/login'>
                    <Button
                      style={styles.button}
                      variant='contained'
                      disableElevation
                      startIcon={<AccountCircleIcon />}>
                      Login
                    </Button>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className='red'>
        <ul className='container navbar-nav ms-auto align-items-center  row2'>
          <li className='nav-item text-light fontSize bottomNavText'>
            <Link to='/fabricswatch' style={styles.buttonBottom}>
              Free Swatches
              <img
                src='/images/newHome/swatch.png'
                alt='Swatches'
                className='bottomMenu'
              />
            </Link>
          </li>
          <li className='nav-item text-light fontSize bottomNavText'>
            <Link to='/shippingpolicy' style={styles.buttonBottom}>
              Free Shipping
              <img
                src='/images/newHome/Shipping-Information-12x12-white-bold.png'
                alt='Swatches'
                className='bottomMenu'
              />
            </Link>
          </li>
          <li className='nav-item text-light fontSize bottomNavText'>
            <Link to='/warranty' style={styles.buttonBottom}>
              Guaranteed Fit
              <img
                src='/images/newHome/Guarantee-fit-12x12.png'
                alt='Swatches'
                className='bottomMenu'
              />
            </Link>
          </li>
          <li className='nav-item text-light fontSize bottomNavText'>
            <Link to='/installation' style={styles.buttonBottom}>
              Easy Installation
              <img
                src='/images/newHome/EasyInstall.png'
                alt='Swatches'
                className='bottomMenu'
              />
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Menu;
