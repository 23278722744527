export const English = {
    Hello: "Hello",
    FillerText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id. Sed rhoncus, tortor sed eleifend tristique, tortor mauris molestie elit, et lacinia ipsum quam nec dui. Quisque nec mauris sit amet elit iaculis pretium sit amet quis magna. Aenean velit odio, elementum in tempus ut, vehicula eu diam.",

    //WarrantyInfo
    WarrantyInfo: "We provide a 1 year warranty on the product against manufacturing defects (i.e., a flaw in the product design, materials or workmanship that causes the product to no longer function) under normal use. If your product is defective during the warranty period, we will repair or replace the defective product. This shall be your sole remedy under this Warranty. The Warranty is extended to the original retail purchaser only, is non-transferable, and only applies to custom-made products in the original windows for which the product was ordered and intended.",
    WarrantyDesc: "Defects that result from abuse, misuse, normal wear and tear, accidents, alterations, exposure to the elements, excessive humidity, or failure to follow use and care instructions. Examples of normal wear and tear include, but are not limited to: fraying of fabrics, loss of color intensity, exposure to salt air, damage from insects or pets, normal fading from sunlight, or loss of pleating if product is not being cycled held in the raised position for an appreciable portion of the time.Normal maintenance associated with motors and related accessories including, but not limited to, recharging and replacement of batteries.Any condition or damage resulting from removal of the product or reinstallation in the same or any other window or application.Products unusable by reason of miss-measurement or assembly or installation not in accordance with product installation instructions. Shipping costs, costs of removal, re-measuring and reinstallation.",
    WarrantyService: "If Warranty service is ever needed please contact by email to info@offcutshades.com and we will arrange for warranty repair or replacement as quickly as possible. When contacting Customer Service to request warranty repair or replacement, please be prepared to provide your name, address, a description of the problem, the date of purchase, and the date and conditions under which the problem occurred. When shipping the product to back Customer Service, please include: a copy of order receipt to verify the original date of purchase; your name; address; a description of the problem; and the date and conditions under which the problem occurred.",
    //Product Selection page
    MainTextInfo: "With so many choices it can be difficult to select the right window treatment for you home or office. The choices you will need to consider include; the operating system, whether it be manual chain operated, chainless or automated; the valance type; the hem type and most important, the fabric. You should consider your objective for purchasing window treatments; is it to provide privacy, security, UV protection or comfort. Different fabrics provide different effects; for blocking the sun or outside light completely, choose an opaque fabric; for reducing glare while maintain outward visibility choose a Sunscreen; for creating atmosphere when there is no need for privacy consider a transparent fabric; for privacy without blocking the sun completely consider a translucent or privacy fabric. Or make a statement with a shade that becomes a focal point, complements your décor or inspires conversation with patterned, textured or dramatic colour choice, available in all fabric categories.",

    // About Us Page
    AboutUs: "Off Cut Shades is a brand in the Sun Glow Window Covering portfolio. This allows us to bring to you Canadian made craftsmanship directly from the factory. No middle man!",
    AboutUs2:"At Off Cut Shades, our goal and commitment is to provide a simple alternative to a complicated shopping experience. Our in-house design team expertly configured various shade options so you don't have to. ",
    AboutUs3:"We've removed the challenges of customizing window treatments online. We manage every detail to provide curated solutions with fast and reliable lead times. All you have to do is pick your size and fabric colour and your shade will be custom made from our factory to your door. ",
    Canadian: "Off Cut Shades sources the best materials globally then all of our products are made in Toronto, Ontario, in our factory located in East York. Our unique designs are inspired by the diversity of Toronto's neighbors and the people who live there.",
    Local: "By keeping attractive manufacturing jobs here at home, we empower our communities to grow and prosper. It benefits everyone to buy locally assembled products.",
    Innovation: "Our in-house engineers and industrial designers develop uniquely superior products and perform stringent testing on all our products. By designing, testing, and manufacturing in-house, Off Cut Shades maintains high quality and reliability of our complete product line.",
    Service: "Off Cut Shades cares primary about our end customers are provides the support and expertise needed to supply quality products at an affordable price range. ",

    // Installation Page
    InstallTitle: "Installation Guide",
    InstallText: "Off Cut Shades window coverings are easy to install. Please download the installation guide associated with your purchased product and get installing.",
    InstallDownload: "PDF Downloads",

    //motorization guide
    MotorguideTitle: 'Motorization Guide',
    MotorguideText: "We can help you with every step to ensure you select the right fabric for the right job, and get that perfect custom shade fit for your home.",


    // Measuring Page
    MeasureTitle: "Window Shade Measuring Guide",
    MeasurePDF: "PDF Measurement Guide",
    MeasureTemplate: "Measurement Template",
    MeasureSteps: "Measuring Steps",
    MeasureIO: "Decide on Inside or Outside Mount",
    MeasureInside: "Inside Mount",
    MeasureOutside: "Outside Mount",
    Download: "Download",
    MeasureWhenIn: "When to Use Inside Mount",
    MeasureWhenInList: [
        "Inside mounting will give you the cleanest, integrated look.",
        "Allows you to hang draperies over the blind.",
        "You will have a light gap on either side of the shade.",
    ],
    MeasureWhenOut: "When to Use Outside Mount",
    MeasureWhenOutList: [
        "Gives you more flexibility with valance selection as there are less restrictions on space.",
        "When the inside depth of your window is shallow not giving you enough room to mount the shades.",
        "When you want to make a window look wider and longer.",
        "When you have an unattractive window that you want to hide or if it is not square",
        "There will be no light gap as the shade will cover the window completely.",
    ],
    MeasureValance: "Decide on Valance Option",
    MeasureValanceIn1: "Determine where you will mount the shade, making sure you have at least 2 in. of flat space to install.",
    MeasureValanceIn2: "When deciding on your valance option, make sure your mounting depth can accommodate the valance (see valance section for sizes) or decide if you are ok with the valance protruding outside the window frame.",
    MeasureValanceOut: "Valance Option does not effect outside mounts.",
    MeasureObs: "Obstructions",
    MeasureObsSub: "You must consider any obstructions to the smooth operation of your shade so that they can be taken into account in deciding on your options. ",
    MeasureObsIn: "Obstructions can include window cranks and handles. An option to avoid cranks and handles is to select reverse roll for your shade or if you have the depth to install the shade so it clears the obstruction.",
    MeasureObsOut: "If outside mount baseboards, ledges. Options include, cutting the baseboard to accommodate the shade or decreasing your width.",

    // ContactUs
    ContactUs: "Contact Us",
    Address: "Address",
    AddressContent1: "50 Hollinger Road",
    AddressContent2: "Toronto, ON.",
    AddressContent3: "M4B 3G5 Canada",
    Phone: "Phone",
    PhoneContent: "(416) 765-8888",
    Email: "Email",
    EmailContent: "info@offcutshades.com",
    Social: "Social Media",
    SocialContent1: "F:offcutshades",
    SocialContent2: "I:@offcutshades",
    Showroom: "Showroom",
    Hours: "Hours",
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    Sunday: "Sunday",
    NineToFive: "9:00 AM - 5:00 PM",
    Closed: "Closed",

    // General
    Newsletter: "Newsletter",
    SignUp: "Sign up to our Newsletter to learn about new product offerings and seasonal deals!",
    Subscribe: "Subscribe",
    ContactMessage: "Contact Message",
    Message: "Message",
    FirstName: "First Name",
    LastName: "Last Name",
    Subject: "Subject",
    Submit: "Submit",

    // Cart
    Colour: "Colour",
    RetailPrice: "Retail Price",
    OurPrice: "Our Price",
    Remove: "Remove",
}

export const French = {
    Hello: "Bonjour",
}