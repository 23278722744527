import axios from 'axios';

import {CUSTOMIZE_PRODUCT} from '../constants/productConstants';

const API = process.env.REACT_APP_OCS_API;

export const getDeflection = async (length) => {
    try {
        const res = await axios.post(`${API}api/editBlind/getDeflection`, {length: length});
        var deflection = res.data;
        return deflection;
    } catch (error) {
        console.log(error);
    }
}

export const groupFabric = async (width, shade, cancelToken) => {
    try {
        const res = await axios.post(`${API}api/editBlind/getFabrics`, {
            width: width,
            shade: shade,
            signal: cancelToken.signal
        });
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const filterFabricsByGroup = async (width, shade, group, series, cancelToken) => {
    try {
        const res = await axios.post(`${API}api/editBlind/filterFabrics`, {
            width: width,
            shade: shade,
            group: group,
            series: series
        });
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

// export const groupSeriesFilter = async (width, shade, group, series, title, cancelToken) => {
//     try {
//         const res = await axios.post(`${API}api/editBlind/getSeriesFilters`, {
//             width: width,
//             shade: shade,
//             group: group,
//             series: series,
//             title: title,
//             signal: cancelToken.signal
//         });
//         return res.data;
//     } catch (error) {
//         console.log(error);
//     }
// };

export const calculateTotalPrice = async(length, width, shade, priceGroup, drive, valance, channel) => {
    try {
        const res = await axios.post(`${API}api/editBlind/calculatePrice`, {
            length: length,
            width: width,
            shade: shade,
            priceGroup: priceGroup,
            drive: drive,
            valance: valance,
            channel: channel
        });
        return res.data;
    }
    catch (error) {
        console.log(error);
    }
};

export const getBottomBars = async (shade, hem_name, defaultBottomBar, cancelToken) => {
    try {
        const res = await axios.post(`${API}api/editBlind/getBottomBars`, {shade: shade, hem_name: hem_name, defaultBottomBar: defaultBottomBar, signal: cancelToken.signal});
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const customizeProduct = (data) => async (dispatch) => {
    dispatch({type: CUSTOMIZE_PRODUCT, payload: data});
    localStorage.setItem('customizeProduct', JSON.stringify(data));
};


export const getDrive = async (shade, fabric, width, length, hem, δ, system, cancelToken) => {
    console.log("TOKEN ", cancelToken.signal);
    try {
        const res = await axios.post(`${API}api/editBlind/getDrive`, {
            shade: shade,
            fabric: fabric,
            width: width,
            length: length,
            hem: hem,
            deflection: δ,
            system: system, 
            signal: cancelToken.signal
        });
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getPriceDifference = async (shade, length, width) => {
    try {
        const res = await axios.post(`${API}api/editBlind/getPriceDifference`, {
            shade: shade,
            length: length,
            width: width
        });
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const calculateDrop = (scale, maxDrop) => {
    return scale * maxDrop;
};

export const calculateDiameter = (tube_diam, totalDrop, currentDrop, thickness) => {
    return Math.sqrt((4 / Math.Pi()) * ((Math.Pi() * (tube_diam ** 2) / 4) + (totalDrop - currentDrop) * thickness));

};

export const calculateTotalRevsToWind = (drop, maxDiam, tubeDiam) => {
    return drop / (Math.Pi() * ((maxDiam + tubeDiam) / 2));
};

export const calculateRotations = (revs, currentDrop, maxDrop) => {
    return revs * currentDrop / maxDrop;
};

export const calculateWeightFabDrop = (width, deduction, currentDrop, fabWeight) => {
    return(width - deduction) * currentDrop * fabWeight / 1000000000;
};


export const calculateResultingOD = (tube_diam, wrap, thickness) => {
    let resulting_OD = 2 * Math.sqrt(tube_diam ** 2 / 4 + (wrap * thickness) / Math.Pi());
    // resulting_OD = round(resulting_OD, 2, PHP_ROUND_HALF_DOWN);
    return resulting_OD;
};

export const calculateMaxRollDia = (tube_diam, drop, wrap, thickness) => {
    let maxOD = 2 * Math.sqrt(tube_diam ** 2 / 4 + ((drop + wrap) * thickness) / Math.Pi());
    // maxOD = round(maxOD, 2, PHP_ROUND_HALF_DOWN);
    return maxOD;
};

export const calculateAffectOfSA = (k, diam, rotations) => {
    return -k / (diam / 2 / 1000) / 9.81 * rotations;
};

export const calculateAffectOfPreRots = (preRots, k, diam) => {
    return -preRots * k / (diam / 2 / 1000) / 9.81;
};

export const calculatePulleyRatio = (avgRollDiam, pulleyDiam) => {
    return avgRollDiam / pulleyDiam;
};

export const calculateKgToLift = (_clutchDrag, _pulleyRatio, _totalWeight, _affectSA, _affectPreRot, _friction, _gearRatio) => {
    return _clutchDrag + _pulleyRatio * (_totalWeight + _affectSA + _affectPreRot) * (1 + _friction) / _gearRatio;
};

export const calculateKgToLower = (_clutchDrag, _pulleyRatio, _totalWeight, _affectSA, _affectPreRot, _friction, _gearRatio) => {
    return _clutchDrag + _pulleyRatio * (-_totalWeight - _affectSA - _affectPreRot) * (1 + _friction) / _gearRatio;
};

export const calculateKValue = (weightTop, weightBottom, avgDiam, rotTop, rotBottom) => {
    return -(((weightBottom - weightTop) * (avgDiam / 2 / 1000) * (9.81)) / (rotTop - rotBottom));
};

export const calculatePreRots = (weight, affectS, avgRollDiam, k) => {
    return((weight + affectS) * (avgRollDiam / 2 / 1000) * 9.81) / k;
};

// Create a class ScaleRow
export class ScaleRow {

    constructor(scale, width, length, thickness, diam, maxDiam, deduction, fWeight, hWeight, k, avgDiam, pRotations, pDiam, pRatio, fric, gRatio) {
        this.scale = scale;
        this.currentDrop = calculateDrop(scale, length);
        this.diameter = calculateDiameter(diam, length, this.currentDrop, thickness);
        let totalRevs = calculateTotalRevsToWind(length, maxDiam, diam);
        this.rotations = calculateRotations(totalRevs, this.currentDrop, length);
        this.weightFabDrop = calculateWeightFabDrop(width, deduction, this.currentDrop, fWeight);
        this.weightBB = hWeight;
        this.weightTotalDrop = this.weightFabDrop + this.weightBB;
        this.affectSA = calculateAffectOfSA(k, avgDiam, this.rotations);
        this.affectPreRots = calculateAffectOfPreRots(pRotations, k, avgDiam);
        this.pulleyRatio = calculatePulleyRatio(avgDiam, pDiam);
        this.KgToLift = calculateKgToLift(0.50, pRatio, this.weightTotalDrop, this.affectSA, this.affectPreRots, fric, gRatio);
        this.KgToLower = calculateKgToLower(0.50, pRatio, this.weightTotalDrop, this.affectSA, this.affectPreRots, fric, gRatio);
    }

    updateTableAfterK(k, avgDiam, pRotations, pDiam, pRatio, fric, gRatio) {
        this.affectSA = calculateAffectOfSA(k, avgDiam, this.rotations);
        this.affectPreRots = calculateAffectOfPreRots(pRotations, k, avgDiam);
        this.pulleyRatio = calculatePulleyRatio(avgDiam, pDiam);
        this.KgToLift = calculateKgToLift(0.50, pRatio, this.weightTotalDrop, this.affectSA, this.affectPreRots, fric, gRatio);
        this.KgToLower = calculateKgToLower(0.50, pRatio, this.weightTotalDrop, this.affectSA, this.affectPreRots, fric, gRatio);
    }
};
