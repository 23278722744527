import rustic1 from "../../../wallpaperimg/Frames1Rustic/frameRustic1.png"
import rustic2 from "../../../wallpaperimg/Frames1Rustic/frameRustic2.png"
import rustic3 from "../../../wallpaperimg/Frames1Rustic/frameRustic3.png"
import rustic4 from "../../../wallpaperimg/Frames1Rustic/frameRustic4.png"
import rustic5 from "../../../wallpaperimg/Frames1Rustic/frameRustic5.png"
import rustic6 from "../../../wallpaperimg/Frames1Rustic/frameRustic6.png"
import rustic7 from "../../../wallpaperimg/Frames1Rustic/frameRustic7.png"
import rustic8 from "../../../wallpaperimg/Frames1Rustic/frameRustic8.png"
import rustic9 from "../../../wallpaperimg/Frames1Rustic/frameRustic9.png"
import rustic10 from "../../../wallpaperimg/Frames1Rustic/frameRustic10.png"
//../../../wallpaperimg
import casual1 from "../../../wallpaperimg/Frames2Casual/frameCasual1.png"
import casual2 from "../../../wallpaperimg/Frames2Casual/frameCasual2.png"
import casual3 from "../../../wallpaperimg/Frames2Casual/frameCasual3.png"
import casual4 from "../../../wallpaperimg/Frames2Casual/frameCasual4.png"
import casual5 from "../../../wallpaperimg/Frames2Casual/frameCasual5.png"
import casual6 from "../../../wallpaperimg/Frames2Casual/frameCasual6.png"
import casual7 from "../../../wallpaperimg/Frames2Casual/frameCasual7.png"
import casual8 from "../../../wallpaperimg/Frames2Casual/frameCasual8.png"
import casual9 from "../../../wallpaperimg/Frames2Casual/frameCasual9.png"

import modern1 from "../../../wallpaperimg/Frames3Modern/frameModern1.png"
import modern2 from "../../../wallpaperimg/Frames3Modern/frameModern2.png"
import modern3 from "../../../wallpaperimg/Frames3Modern/frameModern3.png"
import modern4 from "../../../wallpaperimg/Frames3Modern/frameModern4.png"
import modern5 from "../../../wallpaperimg/Frames3Modern/frameModern5.png"
import modern6 from "../../../wallpaperimg/Frames3Modern/frameModern6.png"
import modern7 from "../../../wallpaperimg/Frames3Modern/frameModern7.png"
import modern8 from "../../../wallpaperimg/Frames3Modern/frameModern8.png"
import modern9 from "../../../wallpaperimg/Frames3Modern/frameModern9.png"

import victorian1 from "../../../wallpaperimg/Frames4Victorian/frameVictorian1.png"
import victorian2 from "../../../wallpaperimg/Frames4Victorian/frameVictorian2.png"
import victorian3 from "../../../wallpaperimg/Frames4Victorian/frameVictorian3.png"
import victorian4 from "../../../wallpaperimg/Frames4Victorian/frameVictorian4.png"
import victorian5 from "../../../wallpaperimg/Frames4Victorian/frameVictorian5.png"
import victorian6 from "../../../wallpaperimg/Frames4Victorian/frameVictorian6.png"
import victorian7 from "../../../wallpaperimg/Frames4Victorian/frameVictorian7.png"
import victorian8 from "../../../wallpaperimg/Frames4Victorian/frameVictorian8.png"


import white1 from "../../../wallpaperimg/Frames5White/frameWhite1.png"
import white2 from "../../../wallpaperimg/Frames5White/frameWhite2.png"
import white3 from "../../../wallpaperimg/Frames5White/frameWhite3.png"
import white4 from "../../../wallpaperimg/Frames5White/frameWhite4.png"
import white5 from "../../../wallpaperimg/Frames5White/frameWhite5.png"
import white6 from "../../../wallpaperimg/Frames5White/frameWhite6.png"
import white7 from "../../../wallpaperimg/Frames5White/frameWhite7.png"
import white8 from "../../../wallpaperimg/Frames5White/frameWhite8.png"
import white9 from "../../../wallpaperimg/Frames5White/frameWhite9.png"

export function frameMap(setTitle){

    var rusticArr = []
    rusticArr.push({'frameFile': rustic1, 'translate':0,'diolate':0})
    rusticArr.push({'frameFile': rustic2, 'translate':0,'diolate':0})
    rusticArr.push({'frameFile': rustic3, 'translate':0,'diolate':0})
    rusticArr.push({'frameFile': rustic4, 'translate':0,'diolate':0})
    rusticArr.push({'frameFile': rustic5, 'translate':0,'diolate':0})
    rusticArr.push({'frameFile': rustic6, 'translate':0,'diolate':0})
    rusticArr.push({'frameFile': rustic7, 'translate':0,'diolate':0})
    rusticArr.push({'frameFile': rustic8, 'translate':0,'diolate':0})
    rusticArr.push({'frameFile': rustic9, 'translate':0,'diolate':0})
    rusticArr.push({'frameFile': rustic10, 'translate':0,'diolate':0})

    var casualArr = []
    casualArr.push({'frameFile': casual1, 'translate':0,'diolate':0})
    casualArr.push({'frameFile': casual2, 'translate':0,'diolate':0})
    casualArr.push({'frameFile': casual3, 'translate':0,'diolate':0})
    casualArr.push({'frameFile': casual4, 'translate':0,'diolate':0})
    casualArr.push({'frameFile': casual5, 'translate':0,'diolate':0})
    casualArr.push({'frameFile': casual6, 'translate':0,'diolate':0})
    casualArr.push({'frameFile': casual7, 'translate':0,'diolate':0})
    casualArr.push({'frameFile': casual8, 'translate':0,'diolate':0})
    casualArr.push({'frameFile': casual9, 'translate':0,'diolate':0})

    var modernArr = []
    modernArr.push({'frameFile': modern1, 'translate':0,'diolate':0})
    modernArr.push({'frameFile': modern2, 'translate':0,'diolate':0})
    modernArr.push({'frameFile': modern3, 'translate':0,'diolate':0})
    modernArr.push({'frameFile': modern4, 'translate':0,'diolate':0})
    modernArr.push({'frameFile': modern5, 'translate':0,'diolate':0})
    modernArr.push({'frameFile': modern6, 'translate':0,'diolate':0})
    modernArr.push({'frameFile': modern7, 'translate':0,'diolate':0})
    modernArr.push({'frameFile': modern8, 'translate':0,'diolate':0})
    modernArr.push({'frameFile': modern9, 'translate':0,'diolate':0})

    var victorianArr = []
    victorianArr.push({'frameFile': victorian1, 'translate':0,'diolate':1})
    victorianArr.push({'frameFile': victorian2, 'translate':0,'diolate':1})
    victorianArr.push({'frameFile': victorian3, 'translate':0,'diolate':1})
    victorianArr.push({'frameFile': victorian4, 'translate':0,'diolate':1})
    victorianArr.push({'frameFile': victorian5, 'translate':0,'diolate':1})
    victorianArr.push({'frameFile': victorian6, 'translate':0,'diolate':1})
    victorianArr.push({'frameFile': victorian7, 'translate':0,'diolate':1})
    victorianArr.push({'frameFile': victorian8, 'translate':0,'diolate':1})

    var whiteArr = []
    whiteArr.push({'frameFile': white1, 'translate':0,'diolate':0})
    whiteArr.push({'frameFile': white2, 'translate':0,'diolate':0})
    whiteArr.push({'frameFile': white3, 'translate':0,'diolate':0})
    whiteArr.push({'frameFile': white4, 'translate':0,'diolate':0})
    whiteArr.push({'frameFile': white5, 'translate':0,'diolate':0})
    whiteArr.push({'frameFile': white6, 'translate':0,'diolate':0})
    whiteArr.push({'frameFile': white7, 'translate':0,'diolate':0})
    whiteArr.push({'frameFile': white8, 'translate':0,'diolate':0})
    whiteArr.push({'frameFile': white9, 'translate':0,'diolate':0})

    switch(setTitle){
        case "rustic":
            return rusticArr
            //break;
        case "casual":
            return casualArr
        case "modern":
            return modernArr
        case "victorian":
            return victorianArr
        case "white":
            return whiteArr
        default:
            return rusticArr//use rustic as default
    }
}