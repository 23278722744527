/** @format */

export function setCoords(x, y, w, h) {
  return {
    position: 'absolute',
    background: '#9e9e9e',
    top: y.toString() + 'vh',
    left: x.toString() + 'vh',
    width: w.toString() + 'vh',
    height: h.toString() + 'vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  //return "position:absolute;\nleft:${x}px;\ntop:${y}px"
}
