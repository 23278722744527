/** @format */

import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import Menu3 from '../../components/Menu3';

const styles = {
  heavyText: {
    // fontWeight: 400,
    textAlign: 'center',
  },
  subText: {
    fontWeight: 300,
    // width: '80%',
    margin: '2rem auto',
  },
  subHead: {
    fontWeight: 400,
    width: '80%',
    margin: '0rem auto',
    marginTop: '1rem',
    textAlign: 'center',
  },
  subDesc: {
    fontWeight: 400,
    width: '90%',
    margin: '0rem auto',
  },
  listStyle: {
    listStyle: 'none',
    fontSize: '1.5rem',
    fontWeight: '300',
    padding: 0,
    margin: '1.5rem auto',
  },
  itemSpacer: {
    margin: '1.25rem auto',
  },
  divider: {
    width: '100%',
    borderTop: '1px solid black',
    marginTop: '1rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  red: {
    // backgroundColor:'crimson',
    padding: '1rem',

    height: '5rem',
    // width:'30rem',
    textAlign: 'center',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  align: {
    alignSelf: 'center',
    textAlign: 'center',
  },
};

const ReturnPolicyScreen = () => {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  let unused = window.onbeforeunload();
  const cardDetails = [
    {
      desc: 'All of our products are made locally by expert craftsmen using top grade materials and components. Under normal use, your products should operate problem free for years. If you have any issues with the operation of your product, e-mail us your concern at info@offcutshades.com and we will find a solution.',
    },
    {
      desc: "In the event that your product arrives damaged in transit, has a manufacturer's defect, or is missing parts, please e-mail a description of the issue with a photo or video where applicable, to info@offcutshades.com within 7 days of receipt of the product. We will contact you to resolve the issue which could include :",
    },
    {
      desc: 'Sending replacement parts; taking the product back to be repaired; remaking the product or providing a refund. If we are remaking a product and cannot guarantee the exact materials,we will provide an equivalent option',
    },
    {
      desc: 'We do not refund if you have buyer’s remorse and change your mind or made a mistake in ordering. Please contact us and we will find a solution.',
    },
  ];

  return (
    <>
      <Menu3 />
      <Container>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'>
          <div class='p-4 ' style={styles.align}>
            <img
              src='/images/newHome/Return-policy-80x80.png'
              class='img-fluid rounded-start'
              alt='...'
            />
          </div>
          <h4 class='my-2 text-center fw-bolder'>Return Policy</h4>
          <Grid
            container
            spacing={2}
            direction='row'
            justify='center'
            alignItems='center'>
            <Grid
              container
              spacing={2}
              // direction='row'
              justify='center'
              alignItems='center'
              style={styles.red}>
              <img
                style={styles.red}
                src='/images/selection/heart.png'
                alt='#'
              />
            </Grid>
            {/* <Typography variant='h6' style={styles.subHead}>
            We want you to love your blinds
          </Typography> */}
            {cardDetails.map((cardDetail) => (
              <>
                <Typography variant='h6' style={styles.subHead} />
                <Typography style={styles.subDesc}>
                  {cardDetail.desc}
                </Typography>
              </>
            ))}
          </Grid>

          <hr style={styles.itemSpacer} />
          {/* <hr style={styles.divider} /> */}
        </Grid>
      </Container>
    </>
  );
};

export default ReturnPolicyScreen;
