/** @format */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Hidden } from '@material-ui/core';
// import Message from '../components/Message';
import { PayPalButton } from 'react-paypal-button-v2';
// import { Link } from 'react-router-dom';
import { createOrder } from '../actions/orderActions';
import { Loader } from '../components/Loader';
// import axios from 'axios';
import usePaypal from '../hooks/usePaypal';
import {
  cencelPromoCode,
  resetCart,
  savePaymentResult,
} from '../actions/cartActions';
import { CallWallpaperAPI } from '../actions/wallpaperActions';
import { useHistory, useParams } from 'react-router-dom';
import { apiMail } from '../actions/userActions';
import Menu2 from '../components/Menu2';
import Menu3 from '../components/Menu3';
import AffirmCheckout from '../components/AffirmCheckout';
import useAffirm from "../hooks/useAffirm";

const styles = {
  divider: {
    width: '100%',
    margin: '1rem auto',
  },
  darkText: {
    color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '1.1rem',
    // fontFamily: 'Lato',
  },
  disabledText: {
    color: '#9b9b9b',
    borderBottom: '1px solid #9b9b9b;',
    fontSize: '1.1rem',
    // fontFamily: 'Lato',
  },
  leftHeader: {
    color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '1.5rem',
    textAlign: 'left',
    margin: '2rem 0 0 0',
  },
  heavyText: {
    fontWeight: 400,
  },
  topSpacer: {
    marginTop: '2rem',
  },
  panel: {
    background: '#e3e3e3d9',
    // marginTop: '-1rem',
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    textAlign: 'center',
  },
  couponCode: {
    color: '#000',
    fontSize: '1.5rem',
    textAlign: 'left',
    width: '100%',
  },
  priceText: {
    fontSize: '1rem',
    margin: '.5rem 0',
  },
  priceTextSave: {
    fontSize: '1rem',
    margin: '.5rem 0',
    color: '#E61610',
  },
  priceTotal: {
    fontSize: '1.2rem',
    margin: '.5rem 0',
    borderTop: '1px solid #000;',
  },
  priceSaving: {
    fontSize: '1.2rem',
    margin: '.5rem 0',
    color: '#E61610',
  },
  atcButton: {
    background: '#ebebeb',
    // borderRadius: '50px',
    minWidth: '20rem',
    marginTop: '1rem',
    marginRight: '1rem',
  },
  imgContainer: {
    width: '40%',
  },
  priceTextSave: {
    fontSize: '1rem',
    margin: '.5rem 0',
    color: '#E61610',
  },
  hide: {
    visibility: 'hidden',
  },
};
const OrderScreen = ({ match, history }) => {
  // const orderId = match.params.id

  const dispatch = useDispatch();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { success: successPay } = orderPay;

  const cart = useSelector((state) => state.cart);
  const { cartItems, cartSummary, shippingAddress } = cart;

  const promo = cartSummary?.promoCode;

  const sdkReady = usePaypal({ order, successPay }, match);

  const params = useParams();
  const status = params.status;
  const loaded = useAffirm({ order, successPay }, match);


  var wallArr = [];
  var cartItemArr = Array.from(cartItems);
  var d = new Date();
  var reciptEmail = '\n\n';
  for (let i = 0; i < cartItemArr.length; i++) {
    //make recipt
    let line =
      cartItems[i].optionInfo.quantity +
      ' ' +
      cartItems[i].optionInfo.selectedColor +
      ' ' +
      cartItems[i].productInfo.Program +
      ' ' +
      cartItems[i].optionInfo.widthWhole +
      ' ' +
      cartItems[i].optionInfo.widthFraction +
      'X' +
      cartItems[i].optionInfo.lengthWhole +
      ' ' +
      cartItems[i].optionInfo.lengthFraction +
      '\n\n';
    reciptEmail = reciptEmail + line;
  }

  reciptEmail += ``;

  if (typeof cartItemArr != undefined) {
    for (let i = 0; i < cartItemArr.length; i++) {
      if (cartItemArr[i]?.productInfo.Program == 'wallpaper') {
        wallArr.push(cartItemArr[i]?.wallPaperInfo);
      }
    }
  }
  const usersName = useSelector((state) => state.userLogin.userInfo.name);
  const usersEmail = useSelector((state) => state.userLogin.userInfo.email);
  //var wallAPI = useCallWallpaperAPI(wallArr,usersName)

  // const [paymentResult, setPaymentResult] = useState('')
  const historyHook = useHistory();


  useEffect(() => {
    if(status == 'success') {
      successPaymentHandler(
        {
          status: 'COMPLETED',
        },
      )
    }
  
  }, [])
  

  const successPaymentHandler = (paymentResult) => {
    // dispatch(payOrder(orderId, paymentResult,history))
    if (paymentResult.status === 'COMPLETED') {
      dispatch(
        createOrder(
          {
            cartItems,
            cartSummary,
            shippingAddress,
          },
          historyHook,
        ),
      );
      dispatch(savePaymentResult(paymentResult));
      history.push('/confirmation');
      dispatch(cencelPromoCode(promo));
      CallWallpaperAPI(wallArr, usersName);
      apiMail(
        usersEmail,
        'Thank you for shopping with us! Enjoy your shades!',
        // reciptEmail,
        `<div class="es-wrapper-color">
        <!--[if gte mso 9]>
          <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
            <v:fill type="tile" color="#ffffff"></v:fill>
          </v:background>
        <![endif]-->
        <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td class="esd-email-paddings" valign="top">
                <table
                  cellpadding="0"
                  cellspacing="0"
                  class="es-content esd-header-popover"
                  align="center"
                >
                  <tbody>
                    <tr>
                      <td class="esd-stripe" align="center">
                        <table
                          bgcolor="#ffffff"
                          class="es-content-body"
                          align="center"
                          cellpadding="0"
                          cellspacing="0"
                          width="600"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="esd-structure es-p20t es-p20r es-p20l"
                                align="left"
                              >
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="560"
                                        class="es-m-p0r esd-container-frame"
                                        valign="top"
                                        align="center"
                                        esd-custom-block-id="744422"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-image"
                                                style="font-size: 0px;"
                                              >
                                                <a
                                                  target="_blank"
                                                  href="https://offcutshades.com"
                                                >
                                                  <img
                                                    src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/offcutshadeslogo.png"
                                                    alt="Logo"
                                                    style="display: block;"
                                                    width="200"
                                                    title="Logo"
                                                  />
                                                </a>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-text"
                                              >
                                                <p>
                                                  Shading
                                                  Solutions&nbsp;Simplified
                                                </p>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                class="esd-block-menu"
                                                esd-tmp-menu-color="#444444"
                                                esd-tmp-menu-font-family="'open sans','helvetica neue',helvetica,arial,sans-serif"
                                                esd-tmp-menu-font-weight="bold"
                                              >
                                                <table
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  width="100%"
                                                  class="es-menu"
                                                >
                                                  <tbody>
                                                    <tr class="links">
                                                      <td
                                                        align="center"
                                                        valign="top"
                                                        width="25%"
                                                        class="es-p10t es-p10b es-p5r es-p5l"
                                                      >
                                                        <a
                                                          target="_blank"
                                                          href="https://offcutshades.com/products"
                                                          style="
                                                            color: #444444;
                                                            font-family: 'open sans',
                                                              'helvetica neue',
                                                              helvetica, arial,
                                                              sans-serif;
                                                            font-weight: bold;
                                                          "
                                                        >
                                                          Window Coverings
                                                        </a>
                                                      </td>
                                                      <td
                                                        align="center"
                                                        valign="top"
                                                        width="25%"
                                                        class="es-p10t es-p10b es-p5r es-p5l"
                                                      >
                                                        <a
                                                          target="_blank"
                                                          href="https://offcutshades.com/wallpaperinit"
                                                          style="
                                                            color: #444444;
                                                            font-family: 'open sans',
                                                              'helvetica neue',
                                                              helvetica, arial,
                                                              sans-serif;
                                                            font-weight: bold;
                                                          "
                                                        >
                                                          Wallpaper
                                                        </a>
                                                      </td>
                                                      <td
                                                        align="center"
                                                        valign="top"
                                                        width="25%"
                                                        class="es-p10t es-p10b es-p5r es-p5l"
                                                      >
                                                        <a
                                                          target="_blank"
                                                          href="https://offcutshades.com/help"
                                                          style="
                                                            color: #444444;
                                                            font-family: 'open sans',
                                                              'helvetica neue',
                                                              helvetica, arial,
                                                              sans-serif;
                                                            font-weight: bold;
                                                          "
                                                        >
                                                          Help
                                                        </a>
                                                      </td>
                                                      <td
                                                        align="center"
                                                        valign="top"
                                                        width="25%"
                                                        class="es-p10t es-p10b es-p5r es-p5l"
                                                      >
                                                        <a
                                                          target="_blank"
                                                          href="https://offcutshades.com/contact"
                                                          style="
                                                            color: #444444;
                                                            font-family: 'open sans',
                                                              'helvetica neue',
                                                              helvetica, arial,
                                                              sans-serif;
                                                            font-weight: bold;
                                                          "
                                                        >
                                                          Contact
                                                        </a>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-image"
                                                style="font-size: 0px;"
                                              >
                                                <a target="_blank">
                                                  <img
                                                    class="adapt-img"
                                                    src="https://vdpuhr.stripocdn.email/content/guids/CABINET_4376e85e14052d76d7429bd7aec4612f/images/someoneisgettingnewshades.gif"
                                                    alt
                                                    style="display: block;"
                                                    width="560"
                                                  />
                                                </a>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        width="560"
                                        class="esd-container-frame"
                                        align="center"
                                        valign="top"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-text es-p10t"
                                              >
                                                <h2
                                                  style="
                                                    color: #444444;
                                                    font-size: 24px;
                                                  "
                                                >
                                                  Thank you for shopping with us!
                                                </h2>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-text es-p5t es-p5b"
                                              >
                                                <p>
                                                  We are preparing your order and
                                                  making sure everything is how
                                                  you want it to be. It won't take
                                                  long. We will be shipping your
                                                  order in the next 48 business
                                                  hours.&nbsp;
                                                </p>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        width="560"
                                        class="esd-container-frame"
                                        align="center"
                                        valign="top"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-text"
                                              >
                                                <h3
                                                  style="
                                                    line-height: 150%;
                                                    color: #444444;
                                                  "
                                                >
                                                 
                                                </h3>
                                                <p
                                                  style="
                                                    line-height: 150%;
                                                    color: #444444;
                                                  "
                                                >
                                           ${d}
                                                </p>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                align="left"
                                                class="esd-block-text es-m-txt-c es-p20t"
                                              >
                                                <p style="color: #444444;">
                                                  ITEMS ORDERED
                                                </p>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-spacer es-p5t es-p5b"
                                                style="font-size: 0;"
                                              >
                                                <table
                                                  border="0"
                                                  width="100%"
                                                  height="100%"
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style="
                                                          border-bottom: 1px solid
                                                            #444444;
                                                          background: none;
                                                          height: 1px;
                                                          width: 100%;
                                                          margin: 0px;
                                                        "
                                                      ></td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            
                            ${reciptEmail}

                            <tr>
                              <td
                                class="esd-structure es-p20r es-p20l"
                                align="left"
                              >
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="560"
                                        class="esd-container-frame"
                                        align="center"
                                        valign="top"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-spacer es-p5t es-p5b"
                                                style="font-size: 0;"
                                              >
                                                <table
                                                  border="0"
                                                  width="100%"
                                                  height="100%"
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style="
                                                          border-bottom: 1px solid
                                                            #444444;
                                                          background: none;
                                                          height: 1px;
                                                          width: 100%;
                                                          margin: 0px;
                                                        "
                                                      ></td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td
                                class="esd-structure es-p20r es-p20l esdev-adapt-off"
                                align="left"
                              >
                                <table
                                  width="560"
                                  cellpadding="0"
                                  cellspacing="0"
                                  class="esdev-mso-table"
                                >
                                  <tbody>
                                    <tr>
                                      <td class="esdev-mso-td" valign="top">
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          class="es-left"
                                          align="left"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                width="466"
                                                class="esd-container-frame"
                                                align="left"
                                              >
                                                <table
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        align="right"
                                                        class="esd-block-text"
                                                      >
                                                        <p>
                                                          Subtotal
                                                          <br />
                                                          Sales tax
                                                          <br />
                                                          Shipping
                                                          <br />
                                                          <b>Total</b>
                                                        </p>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td width="20"></td>
                                      <td class="esdev-mso-td" valign="top">
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          class="es-right"
                                          align="right"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                width="74"
                                                align="left"
                                                class="esd-container-frame"
                                              >
                                                <table
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        align="right"
                                                        class="esd-block-text"
                                                      >
                                                        <p>
                                                         ${cartSummary.TOTAL}
                                                          <br />
                                                          ${cartSummary.FINAL_TAX}
                                                          <br />
                                                          ${cartSummary.shipping}
                                                          <br />
                                                          <strong> ${cartSummary.finalTOTAL}</strong>
                                                        </p>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td
                                class="esd-structure es-p20t es-p20r es-p20l"
                                align="left"
                              >
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="560"
                                        class="esd-container-frame"
                                        align="center"
                                        valign="top"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="left"
                                                class="esd-block-text es-m-txt-c es-p20t"
                                              >
                                                <p style="color: #333333;">
                                                  SHIPPING ADDRESS
                                                </p>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                align="left"
                                                class="esd-block-text"
                                              >
                                                <p>
                                                 ${shippingAddress.addressLine1} ${shippingAddress.addressLine2} ${shippingAddress.city} ${shippingAddress.province} ${shippingAddress.postalCode}
                                                </p>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  cellpadding="0"
                  cellspacing="0"
                  class="es-content"
                  align="center"
                >
                  <tbody>
                    <tr>
                      <td class="esd-stripe" align="center">
                        <table
                          bgcolor="#ffffff"
                          class="es-content-body"
                          align="center"
                          cellpadding="0"
                          cellspacing="0"
                          width="600"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="esd-structure es-p20r es-p20l"
                                align="left"
                              >
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="560"
                                        class="esd-container-frame"
                                        align="center"
                                        valign="top"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-spacer es-p5t es-p5b"
                                                style="font-size: 0;"
                                              >
                                                <table
                                                  border="0"
                                                  width="100%"
                                                  height="100%"
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style="
                                                          border-bottom: 1px solid
                                                            #333333;
                                                          background: none;
                                                          height: 1px;
                                                          width: 100%;
                                                          margin: 0px;
                                                        "
                                                      ></td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-text es-p10t"
                                              >
                                                <h2>
                                                  <b>We are here to help you</b>
                                                </h2>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-text es-p5t es-p5b"
                                                esd-links-color="#EF4036"
                                              >
                                                <p
                                                  style="
                                                    line-height: 120%;
                                                    color: #444444;
                                                  "
                                                >
                                                  If you have any questions,
                                                  please don't hesitate to
                                                  <a
                                                    target="_blank"
                                                    style="
                                                      line-height: 120%;
                                                      color: #ef4036;
                                                    "
                                                    href="https://offcutshades.com/contact"
                                                  >
                                                    contact us.
                                                  </a>
                                                </p>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td
                                class="esd-structure es-p20r es-p20l es-m-p10r es-m-p10l"
                                align="left"
                              >
                                <!--[if mso]><table width="560" cellpadding="0" cellspacing="0"><tr><td width="265" valign="top"><![endif]-->
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  align="left"
                                  class="es-left"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="265"
                                        class="esd-container-frame"
                                        align="center"
                                        valign="top"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-button es-p10t es-p10b"
                                              >
                                                <span
                                                  class="es-button-border"
                                                  style="
                                                    display: block;
                                                    border-radius: 0px;
                                                  "
                                                >
                                                  <a
                                                    href="https://offcutshades.com/installation"
                                                    class="es-button"
                                                    target="_blank"
                                                    style="
                                                      border-left-width: 20px;
                                                      border-right-width: 20px;
                                                      display: block;
                                                      border-radius: 0px;
                                                    "
                                                  >
                                                    Installation Guides
                                                  </a>
                                                </span>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <!--[if mso]></td><td width="30"></td><td width="265" valign="top"><![endif]-->
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  class="es-right"
                                  align="right"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="265"
                                        align="left"
                                        class="esd-container-frame"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-button es-p10t es-p10b"
                                              >
                                                <span
                                                  class="es-button-border"
                                                  style="
                                                    display: block;
                                                    border-radius: 0px;
                                                  "
                                                >
                                                  <a
                                                    href="https://offcutshades.com/shippingpolicy"
                                                    class="es-button"
                                                    target="_blank"
                                                    style="
                                                      border-left-width: 20px;
                                                      border-right-width: 20px;
                                                      display: block;
                                                      border-radius: 0px;
                                                    "
                                                  >
                                                    Shipping Information
                                                  </a>
                                                </span>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <!--[if mso]></td></tr></table><![endif]-->
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  cellpadding="0"
                  cellspacing="0"
                  class="es-footer esd-footer-popover"
                  align="center"
                >
                  <tbody>
                    <tr>
                      <td
                        class="esd-stripe"
                        align="center"
                        esd-custom-block-id="746268"
                        bgcolor="#efefef"
                        style="background-color: #efefef;"
                      >
                        <table
                          class="es-footer-body"
                          align="center"
                          cellpadding="0"
                          cellspacing="0"
                          width="600"
                          style="background-color: transparent;"
                        >
                          <tbody>
                            <tr>
                              <td
                                class="esd-structure es-p20"
                                align="left"
                                esd-custom-block-id="744444"
                              >
                                <!--[if mso]><table width="560" cellpadding="0" cellspacing="0"><tr><td width="145" valign="top"><![endif]-->
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  class="es-left"
                                  align="left"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="125"
                                        class="es-m-p0r es-m-p20b esd-container-frame"
                                        align="center"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-image"
                                                style="font-size: 0px;"
                                              >
                                                <a target="_blank">
                                                  <img
                                                    class="adapt-img"
                                                    src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/madetolasticon.png"
                                                    alt
                                                    style="display: block;"
                                                    width="30"
                                                  />
                                                </a>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-text es-p5t"
                                              >
                                                <p style="font-size: 12px;">
                                                  Made to last
                                                </p>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td class="es-hidden" width="20"></td>
                                    </tr>
                                  </tbody>
                                </table>
                                <!--[if mso]></td><td width="145" valign="top"><![endif]-->
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  class="es-left"
                                  align="left"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="125"
                                        class="es-m-p20b esd-container-frame"
                                        align="center"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-image"
                                                style="font-size: 0px;"
                                              >
                                                <a target="_blank">
                                                  <img
                                                    class="adapt-img"
                                                    src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/readytoshipicon.png"
                                                    alt
                                                    style="display: block;"
                                                    width="30"
                                                  />
                                                </a>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-text es-p5t"
                                              >
                                                <p style="font-size: 12px;">
                                                  Ready to ship in 2 days
                                                </p>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td class="es-hidden" width="20"></td>
                                    </tr>
                                  </tbody>
                                </table>
                                <!--[if mso]></td><td width="125" valign="top"><![endif]-->
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  class="es-left"
                                  align="left"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="125"
                                        class="es-m-p20b esd-container-frame"
                                        align="center"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-image"
                                                style="font-size: 0px;"
                                              >
                                                <a target="_blank">
                                                  <img
                                                    class="adapt-img"
                                                    src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/guaranteeiconorange.png"
                                                    alt
                                                    style="display: block;"
                                                    width="30"
                                                  />
                                                </a>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-text es-p5t"
                                              >
                                                <p style="font-size: 12px;">
                                                  Guaranteed Fit
                                                </p>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <!--[if mso]></td><td width="20"></td><td width="125" valign="top"><![endif]-->
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  class="es-right"
                                  align="right"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="125"
                                        align="center"
                                        class="esd-container-frame"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-image"
                                                style="font-size: 0px;"
                                              >
                                                <a target="_blank">
                                                  <img
                                                    class="adapt-img"
                                                    src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/pricerighticon.png"
                                                    alt
                                                    style="display: block;"
                                                    width="30"
                                                  />
                                                </a>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-text es-p5t"
                                              >
                                                <p style="font-size: 12px;">
                                                  Price Right every day
                                                </p>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <!--[if mso]></td></tr></table><![endif]-->
                              </td>
                            </tr>
                            <tr>
                              <td
                                class="esd-structure es-p20t es-p20b es-p20r es-p20l"
                                align="left"
                              >
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="560"
                                        class="esd-container-frame"
                                        align="left"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                align="left"
                                                class="esd-block-text"
                                              >
                                                <p style="text-align: center;">
                                                  <strong>Follow Us</strong>
                                                </p>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-social es-p15t es-p15b"
                                                style="font-size: 0;"
                                              >
                                                <table
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  class="es-table-not-adapt es-social"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        align="center"
                                                        valign="top"
                                                        class="es-p40r"
                                                      >
                                                        <a
                                                          target="_blank"
                                                          href="https://www.facebook.com/OffCutShadesOnline/"
                                                        >
                                                          <img
                                                            title="Facebook"
                                                            src="https://vdpuhr.stripocdn.email/content/assets/img/social-icons/logo-black/facebook-logo-black.png"
                                                            alt="Fb"
                                                            width="32"
                                                          />
                                                        </a>
                                                      </td>
                                                      <td
                                                        align="center"
                                                        valign="top"
                                                        class="es-p40r"
                                                      >
                                                        <a
                                                          target="_blank"
                                                          href="https://www.instagram.com/offcutshades/"
                                                        >
                                                          <img
                                                            title="Instagram"
                                                            src="https://vdpuhr.stripocdn.email/content/assets/img/social-icons/logo-black/instagram-logo-black.png"
                                                            alt="Inst"
                                                            width="32"
                                                          />
                                                        </a>
                                                      </td>
                                                      <td
                                                        align="center"
                                                        valign="top"
                                                        esd-tmp-icon-type="pinterest"
                                                      >
                                                        <a
                                                          target="_blank"
                                                          href="https://www.pinterest.ca/offcutshades/"
                                                        >
                                                          <img
                                                            title="Pinterest"
                                                            src="https://vdpuhr.stripocdn.email/content/assets/img/social-icons/logo-black/pinterest-logo-black.png"
                                                            alt="P"
                                                            width="32"
                                                          />
                                                        </a>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                align="center"
                                                class="esd-block-text es-p35b"
                                                esd-links-color="#333333"
                                              >
                                                <p style="font-size: 12px;">
                                                  Off Cut Shades&nbsp;
                                                </p>
                                                <p style="font-size: 12px;">
                                                  50 Hollinger road, Toronto, ON ·
                                                  <a
                                                    target="_blank"
                                                    href="tel:+14162856001"
                                                    style="
                                                      font-size: 12px;
                                                      color: #333333;
                                                    "
                                                  >
                                                    416.285.6001
                                                    <br />
                                                  </a>
                                                  You are receiving this email
                                                  because&nbsp;you made a purchase
                                                  from Off Cut Shades.
                                                </p>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>`,
      );
      dispatch(resetCart());
    }
  };

  return (
    <>
      <Menu3 />
      <Container>
        <Grid
          container
          direction='row'
          justifycontent='center'
          alignItems='center'
          style={styles.panel}
          spacing={0}>
          <Grid item sm={3} style={styles.disabledText}>
            <p>1. Shopping Cart</p>
          </Grid>

          <Hidden smDown>
            <Grid item md={3} style={styles.disabledText}>
              <p>2. Shipping Details</p>
            </Grid>
          </Hidden>

          <Hidden smDown>
            <Grid item md={3} style={styles.disabledText}>
              <p>3. Place Order</p>
            </Grid>
          </Hidden>
          {/* <Hidden smDown>
            <Grid item md={3} style={styles.darkText}>
              <p>4. Payment </p>
            </Grid>
          </Hidden> */}
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='space-around'
          alignItems='flex-start'
          spacing={0}>
          <Grid item sm={12} md={8}>
            <p style={styles.leftHeader}>Checkout</p>
            <hr style={styles.divider} />
            <Grid
              container
              direction='column'
              justifyContent='space-around'
              alignItems='flex-start'>
              <Grid item sm={12} md={8}>
                <p style={styles.leftHeader}>Shipping Details</p>
                <span>
                  <strong>First Name : </strong>
                  {cart.shippingAddress.firstName},
                </span>
                <br />
                <span>
                  <strong>Last Name : </strong>
                  {cart.shippingAddress.lastName},
                </span>
                <br />
                <span>
                  <strong>Phone Number : </strong>
                  {cart.shippingAddress.phoneNumber},
                </span>
                <br />
                <span>
                  <strong>Street Address : </strong>
                  {cart.shippingAddress.addressLine1},
                </span>
                <br />
                <span>
                  <strong>Address Line 2 : </strong>
                  {cart.shippingAddress.addressLine2},
                </span>
                <br />
                <span>
                  <strong>City : </strong>
                  {cart.shippingAddress.city},
                </span>
                <br />
                <span>
                  <strong>Province : </strong>
                  {cart.shippingAddress.province},
                </span>
                <br />
                <span>
                  <strong>Country : </strong>
                  {cart.shippingAddress.country},
                </span>
                <br />
                <span>
                  <strong>Postal Code : </strong>
                  {cart.shippingAddress.postalCode},
                </span>
                <br />
              </Grid>
              <Grid item sm={12} md={8}>
                <p style={styles.leftHeader}>Order Items</p>
                {cartItems.length === 0 ? (
                  <h2>Your cart is Empty</h2>
                ) : (
                  <Grid
                    container
                    direction='column'
                    justifyContent='space-around'
                    alignItems='flex-start'>
                    {cartItems.map((item, index) => (
                      <Grid
                        container
                        direction='row'
                        justifyContent='space-around'
                        alignItems='center'
                        spacing={1}
                        style={styles.cartItem}
                        key={index}>
                        <Grid item xs={3}>
                          {
                            <img
                              src={`/images/programs/${item.productInfo?.Program}/window/${item.optionInfo?.selectedColor}.jpg`
                                .replaceAll('%', '')
                                .replaceAll(' ', '_')
                                .toLowerCase()}
                              style={styles.imgContainer}
                              alt='Shade'></img>
                          }
                        </Grid>

                        <Grid item xs={8}>
                          <Grid
                            container
                            direction='column'
                            justifyContent='space-around'
                            alignItems='flex-start'>
                            <span style={styles.subText}>
                              {item.productInfo?.Program}
                              {item.optionInfo?.Type} -{' '}
                              {item.optionInfo?.selectedColor}{' '}
                              {item.optionInfo?.widthWhole}{' '}
                              {item.optionInfo?.widthFraction === '0/16'
                                ? ''
                                : item.optionInfo?.widthFraction}{' '}
                              x {item.optionInfo?.lengthWhole}{' '}
                              {item.optionInfo?.lengthFraction === '0/16'
                                ? ''
                                : item.optionInfo?.lengthFraction}{' '}
                              {'  QTY -   '}
                              {item.optionInfo?.quantity}
                            </span>
                            {/* <Grid
                                container
                                direction='row'
                                justifyContent='right'
                                alignItems='flex-start'
                              >
                              </Grid> */}
                            {/* </span> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={12} md={3}>
            <p style={styles.leftHeader}>Summary</p>
            <hr style={styles.divider} />
            {/* <TextField style={styles.couponCode} label="Enter Coupon Code" value={this.state.couponCode} onChange={this.handleCouponCode} /> */}
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              style={styles.priceText}>
              <Grid item>MSRP</Grid>
              <Grid item>${cartSummary.FINAL_MSRP}</Grid>
            </Grid>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              style={styles.priceTextSave}>
              <Grid item>OUR PRICE</Grid>
              <Grid item>${cartSummary.FINAL_PRICE}</Grid>
            </Grid>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              style={styles.priceText}>
              <Grid item>SHIPPING</Grid>
              {/* <Grid item>${cartSummary.shipping}</Grid> */}
              <Grid item>FREE</Grid>
            </Grid>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              style={styles.priceText}>
              <Grid item>TAX</Grid>
              <Grid item>${cartSummary.FINAL_TAX}</Grid>
            </Grid>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            //style={styles.priceTextSave}
            >
              <Grid
                item
                style={
                  cartSummary.withPromoPrice !== 0
                    ? styles.priceTextSave
                    : styles.hide
                }>
                promotion
              </Grid>
              <Grid
                item
                style={
                  cartSummary.withPromoPrice !== 0
                    ? styles.priceTextSave
                    : styles.hide
                }>
                {/* ${(finalPriceState * promoMultiplier).toFixed(2)} */}$
                {cartSummary.withPromoPrice}
              </Grid>
            </Grid>
            <hr style={styles.divider} />

            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              style={styles.priceSaving}>
              <Grid item>SAVINGS</Grid>
              <Grid item>${cartSummary.SAVINGS}</Grid>
            </Grid>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              style={styles.priceTotal}>
              <Grid item>TOTAL</Grid>
              <Grid item>${cartSummary.finalTOTAL}</Grid>
            </Grid>
            {sdkReady ? (
              <Loader />
            ) : (
              <>
                <PayPalButton
                  options={{
                    currency: 'CAD',
                    //Production ID
                    clientId:
                    'AdDDn4x5I_36bzOj2z82vwYhibFw78nRBomRgQqm5LxGpO2vTZX5mduQ2GdWAXrlgvjw_HxT0BpqqkXr',
                    // clientId:
                    //   'AakoIEu2t-4fmMs7qJLSKWfZCSN7jwV18hDyRbwfGa606pTtVuDSpDdM2tnGXY4zlHMC3WUO0ZM5jL8F',
                  }}
                  amount={cartSummary.finalTOTAL}
                  onSuccess={successPaymentHandler}
                />
                <AffirmCheckout loaded={loaded}/>
              
                </>
                )
              }
          </Grid>
        </Grid>
        {/* <hr style={styles.divider} /> */}
      </Container>
    </>
  );
};

export default OrderScreen;
