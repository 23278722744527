/** @format */

import { cloneElement, useEffect, useState } from 'react';
//import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import React, { Component, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';

//import {FrameLRG,FrameMED,FrameSML} from "./logical_functions/placeFrames"
import { checkCollision } from './logical_functions/collision';
import { setCoords } from './logical_functions/setCoords';
import { Container, List, Grid, buttonClasses, Button } from '@mui/material';

import uploadIcon from '../../../wallpaperimg/uploadIcon.png';
import uploadIcon2 from '../../../wallpaperimg/uploadIcon2.png';
import { useDropzone } from 'react-dropzone';

import { db, addImageToDb, DBReset, removeImageFromDb } from '../DexieDB';
import { useLiveQuery } from 'dexie-react-hooks';
import { width } from '@mui/system';
import { frameMap } from './frameImportFile';

import { Base64 } from 'js-base64';
import '../../../wallpaperimg/main.css';
import Popup from 'reactjs-popup';
import Modalcontent from './modal';
import EnterSize from '../startWallPaper';
import { addImageToDoc, getDocImgArray } from './localStorageSystem';

export function picturePanel(picIDMap, setPicIDMap, doc) {
  let myStyle = {
    position: 'fixed',
    background: 'white',
    top: '10vh',
    right: 0,
    width: '0vw',
    height: '0%',
    borderColor: '#414042',
    borderWidth: '1px',
    borderStyle: 'solid',
  };
  return (
    <div style={myStyle}>{PanelRelPosTest(picIDMap, setPicIDMap, doc)}</div>
  );
}

function PanelRelPosTest(picIDMap, setPicIDMap, doc) {
  //just for experimenting with css relative positioning
  var myStyle = { position: 'absolute', top: '0vh', left: 0 };

  return (
    // <div style={myStyle}>
    //     <h1>SunGlow WallPaper forge</h1>
    // </div>
    <div style={{ position: 'absolute', top: 0, height: '100%' }}>
      <div style={{ height: '10%' }}>
        <Container>
          <h1>place your pictures!</h1>
        </Container>
      </div>
      <div style={{ height: '58%', overflowY: 'scroll' }}>
        {PictureGrid(picIDMap, setPicIDMap, doc)}
      </div>

      {GalleryMenu(picIDMap, doc)}
    </div>
  );
}

function MyDropzone(doc) {
  async function getBase64Image(img) {
    let base64_result = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = (e) => resolve(reader.result);
    });

    return base64_result;
  }

  const onDrop = useCallback((acceptedFiles) => {
    if (Array.isArray(acceptedFiles)) {
      for (var i = 0; i < acceptedFiles.length; i++) {
        addImageToDb(acceptedFiles[i]);
        let test = getBase64Image(acceptedFiles[i]);
        //addImageToDoc(doc,test)
      }
    } else {
      addImageToDb(acceptedFiles);
      //addImageToDoc(doc,acceptedFiles[i])
    }

    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      style={{ height: '100%', width: '50%', background: '#4287f5' }}
      draggable='false'>
      <input {...getInputProps()} draggable='false' />
      {isDragActive ? (
        <img
          src={uploadIcon2}
          alt='error'
          style={{ height: '100%' }}
          draggable='false'
        />
      ) : (
        <img
          src={uploadIcon}
          alt='error'
          style={{ height: '100%' }}
          draggable='false'
        />
      )}
    </div>
  );
}

function PictureGrid(picIDMap, setPicIDMap, doc) {
  var allImages = useLiveQuery(() => db.usrImages.toArray(), []);
  //var allImages = getDocImgArray(doc)

  if (!allImages) {
    //if empty/undefined
    // return(
    // <div>/////////////////////
    //   <Container style={{height:'70vh', overflowY: "scroll", background:"#8a3a08"}}></Container>
    // </div>)
  } else {
    var galleryArr = []; //hi shivm lol

    for (var i = 0; i < allImages.length; i++) {
      if (allImages[i].used) {
        galleryArr.push(GalleryPicture(allImages[i], i, picIDMap, setPicIDMap));
      }
    }

    return (
      <div>
        <Container>
          <Grid
            container
            spacing={0}
            style={{ background: 'white', width: '25vw', borderStyle: '1px' }}>
            {galleryArr}
          </Grid>
        </Container>
      </div>
    );
  }
}

var gallPicId = 0;
function GalleryPicture(pic, id, picIDMap, setPicIDMap) {
  function dragS(ev) {
    ev.dataTransfer.setData('text', ev.target.id);
  }

  function remove(ev) {
    ev.preventDefault();
    var keyArr = Array.from(picIDMap.keys());
    var valArr = Array.from(picIDMap.values());
    var myMap = new Map(picIDMap);

    for (var i = 0; i < valArr.length; i++) {
      if (valArr[i] == id) {
        myMap.delete(keyArr[i]);
      }
    }

    setPicIDMap(myMap);
    removeImageFromDb(pic['id']);
    ////DBReset()//temp
  }

  var keyArr = Array.from(picIDMap.keys());
  var valArr = Array.from(picIDMap.values());
  const occurrenceMap = valArr.reduce(
    (acc, e) => acc.set(e, (acc.get(e) || 0) + 1),
    new Map(),
  );

  var display = URL.createObjectURL(pic['image']);
  return (
    <Grid item xs={6} key={gallPicId++} style={{ textAlign: 'center' }}>
      <img
        src={display}
        alt='error'
        onContextMenu={remove}
        onDragStart={dragS}
        id={pic['id']}
        style={{ maxWidth: '100%', maxHeight: '100%' }}
        draggable='true'
      />
      <div>
        <label
          style={{
            fontFamily: 'Helvetica W01 Cn, Helvetica, Arial, sans-serif',
            backgroundColor: '#414042',
            color: 'white',
          }}>
          {occurrenceMap.get(id)}
        </label>
      </div>
    </Grid>
  );
}

var ldbVer = 2;

export var b64Map = new Map();
export var picIDMapFinal = new Map();
function GalleryMenu(picIDMap, doc) {
  const allImages = useLiveQuery(() => db.usrImages.toArray(), []);
  //const gotoOrder = () => nav('/Order')
  const dispatch = useDispatch();
  const frameArr = useSelector((state) => state.wallpaperFrame.value);

  function handleClear(e) {
    // db.delete()
    // db.version(ldbVer++)
    DBReset();
  }
  //const nav = useNavigate();
  //const navToOrder = () => nav('/Order')

  function handlePlaceOrder(e) {
    var keyArr = Array.from(picIDMap.keys());
    var valArr = Array.from(picIDMap.values());

    for (let i = 0; i < keyArr.length; i++) {
      let reader = new FileReader();
      reader.readAsDataURL(allImages[valArr[i]]['image']);
      reader.onload = () => {
        b64Map.set(keyArr[i], reader.result);
      };
      reader.onerror = function () {};
    }
    picIDMapFinal = picIDMap;
  }

  return (
    <div
      style={{
        display: 'flex',
        height: '20%',
        position: 'relative',
        bottom: 0,
      }}>
      <div style={{ height: '100%', width: '50%' }}>{MyDropzone(doc)}</div>
      <div style={{ height: '100%', width: '25%' }}>{Modal(picIDMap)}</div>
      <div style={{ height: '100%', width: '25%' }}>
        <button
          style={{
            height: '100%',
            width: '100%',
            backgroundColor: 'white',
            fontFamily: 'lato',
            fontSize: '150%',
            borderColor: 'black',
            borderWidth: '1px',
            fontWeight: 'bold',
            color: '#ef4136',
          }}
          onClick={handlePlaceOrder}>
          Place order
        </button>
      </div>
    </div>
  );
}

function Modal(picIDMap) {
  var ret = (
    <Popup
      closeOnEscape={true}
      closeOnDocumentClick={true}
      trigger={
        <button
          className='button'
          style={{
            height: '100%',
            width: '100%',
            color: 'white',
            backgroundColor: 'white',
            fontFamily: 'lato',
            fontSize: '150%',
            borderColor: 'black',
            borderWidth: '1px',
            fontWeight: 'bold',
            color: 'black',
          }}>
          {' '}
          Open Modal{' '}
        </button>
      }
      modal>
      <div
        style={{
          height: '100vh',
          width: '100vw',
          backgroundColor: '#414042',
          opacity: '95%',
        }}></div>
      <div
        style={{
          height: '70vh',
          width: '70vw',
          position: 'absolute',
          top: '15vh',
          left: '15vw',
        }}>
        {' '}
        {Modalcontent(picIDMap)}
      </div>
    </Popup>
  );

  return ret;
}
