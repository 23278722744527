/** @format */

import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
// import ShadowStaff from '../extra comp/ShadowStaff';

const styles = {
  divider: {
    width: '100%',
    margin: '2rem auto',
  },
  heavyText: {
    fontWeight: 400,
  },
  topSpacer: {
    marginTop: '2rem',
  },
};

const TeamScreen = () => {
  const peoples = [
    {
      title: 'Zach',
      caption: 'CEO',
      image: './images/cards/roman_office.png',
      width: '33%',
      cover: false,
      description: null,
    },
    {
      title: 'Vivian Kanargelidis',
      caption: 'Owner',
      image: './images/cards/roman_office.png',
      width: '33%',
      cover: false,
      description: '',
    },
    {
      title: 'Sebastien Blanchet',
      caption: 'COO',
      image: './images/cards/roman_office.png',
      width: '33%',
      cover: true,
      description: '',
    },
    {
      title: 'Paul Cashen',
      caption: 'Retail Sales Representative',
      image: './images/cards/roman_office.png',
      width: '33%',
      cover: true,
      description: '',
    },
    {
      title: 'Ida Medeiros',
      caption: 'Retail Sales Representative',
      image: './images/cards/roman_office.png',
      width: '33%',
      cover: true,
      description: '',
    },
    {
      title: 'Michael Walpole',
      caption: 'Design & IT',
      image: './images/cards/roman_office.png',
      width: '33%',
      cover: true,
      description: '',
    },
    {
      title: 'Shivam Patel',
      caption: 'Full Stack Developer',
      image: './images/cards/roman_office.png',
      width: '33%',
      cover: true,
      description: '',
    },
    {
      title: 'Muthu Venkatachalam',
      caption: 'R&D',
      image: './images/cards/roman_office.png',
      width: '33%',
      cover: true,
      description: '',
    },
  ];

  return (
    <Container style={styles.cardContainer}>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'>
        <Typography variant='h4' color='textSecondary' style={styles.topSpacer}>
          Our Dedicated Team
        </Typography>
        <hr style={styles.divider} />
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          style={styles.widthCorrector}
          spacing={3}>
          {peoples.map((people) => (
            <Grid item xs={10} sm={4}>
              {/* <ShadowStaff data={people} /> */}
            </Grid>
          ))}
          {/* <Grid item xs={10} sm={4}>
            <ShadowStaff data={vivian} />
          </Grid>
          <Grid item xs={10} sm={4}>
            <ShadowStaff data={seb} />
          </Grid>
          <Grid item xs={10} sm={4}>
            <ShadowStaff data={paul} />
          </Grid>
          <Grid item xs={10} sm={4}>
            <ShadowStaff data={ida} />
          </Grid>
          <Grid item xs={10} sm={4}>
            <ShadowStaff data={mike} />
          </Grid>
          <Grid item xs={10} sm={4}>
            <ShadowStaff data={shivam} />
          </Grid>
          <Grid item xs={10} sm={4}>
            <ShadowStaff data={muthu} />
          </Grid> */}
        </Grid>
      </Grid>
      <hr style={styles.divider} />
    </Container>
  );
};

export default TeamScreen;
