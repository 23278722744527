/** @format */

import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Typography,
  Button,
  Container,
  Card,
  CardContent,
  Box,
} from '@material-ui/core';
import { Rating } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import {
  getProductReview,
  postProductReview,
} from '../actions/userReviewActions';
import { useSelector } from 'react-redux';
import RateReviewIcon from '@mui/icons-material/RateReview';
import axios from 'axios';
// import '../screens/reviews.css';
var IPGeolocationAPI = require('ip-geolocation-api-javascript-sdk');

const styles = {
  heavyText: {
    fontWeight: 500,
  },
  lightText: {
    fontWeight: 300,
  },
  image: {
    width: '100%',
  },
  imageContainer: {
    width: '75%',
    margin: 'auto',
  },
  programName: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1.5rem',
    textTransform: 'Capitalize',
  },
  collectionName: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'Capitalize',
  },
  msrpTag: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'Capitalize',
    textDecoration: 'strike-through',
  },
  ourTag: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'Capitalize',
    color: '#E61610',
  },
  msrPrice: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1.5rem',
    textTransform: 'Capitalize',
    textDecoration: 'strike-through',
  },
  ourPrice: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1.5rem',
    textTransform: 'Capitalize',
    color: '#E61610',
  },
  divider: {
    width: '100%',
    borderTop: '1px solid',
    borderColor: 'rgb(96, 96, 96, 0.4)',
    marginTop: '1rem',
    marginBottom: '2rem',
  },
  dividerSmall: {
    width: '100%',
    borderTop: '1px solid',
    borderColor: 'rgb(96, 96, 96, 0.4)',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  formControl: {
    margin: 10,
    minWidth: '25%',
  },
  colorIcon: {
    zIndex: 9,
    // margin: '20px'
    borderRadius: '50%',
    // border: '1px solid black',
    height: '3rem',
    // minWidth: '3rem',
    margin: '0.25rem',

    width: '3rem',
  },
  colorIconCon: {
    position: 'relative',
  },
  colorIconBtn: {
    // zIndex: 100,
    borderRadius: '50%',

    border: '1px solid grey',
    height: '3rem',
    width: '3rem',
    minWidth: '3rem',
    margin: '0.25rem',
    position: 'absolute',
  },
  colorIconActive: {
    borderRadius: '50%',
    // border: '2px solid #E61610',
    height: '2rem',
    width: '2rem',
    minWidth: '2rem',
    margin: '0.25rem',
  },
  colorSwatch: {
    borderRadius: '0%',
    // border: '1px solid black',
    height: '6rem',
    width: '6rem',
    // minWidth: '2rem',
    margin: '0.5rem',
  },

  aboutShade: {
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '1.5rem',
    textTransform: 'Capitalize',
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '1rem',
  },
  smallImage: {
    width: '80%',
    margin: 'auto',
  },
  smallText: {
    // maxWidth: '100%',
    margin: '0 1rem',
    lineHeight: '1.5rem',
  },
  supportText: {
    // maxWidth: '100%',
    margin: '0 1rem',
    lineHeight: '1.5rem',
    color: '#ef4136',
  },
  featuresList: {
    textAlign: 'left',
    lineHeight: '1.5rem',
    width: '100%',
    margin: '1rem auto',
  },
  containerVertical: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  lineSpacer: {
    margin: '1rem auto',
  },
  atcButton: {
    // background: '#ebebeb',
    minWidth: '12rem',
  },
  helpButtons: {
    background: '#ebebeb',
    minWidth: '15rem',
    margin: '3rem auto',
  },
  infoContainer: {
    textAlign: 'center',
    padding: '1rem',
  },
  imgContainer: {
    width: '50%',
  },
  recommendedText: {
    // marginTop: '1rem',
    fontStyle: 'italic',
    color: 'dimGrey',
  },
  reviewField: {
    //minWidth: "15rem",
    margin: '1rem',
    border: '1px solid grey',
    width: '30rem',
  },
  smallButton: {
    background: '#ebebeb',
    minWidth: '4rem',
  },
  postButton: {
    background: '#ebebeb',
    minWidth: '12rem',
  },
};

//export const reviewRead = (product) => {};

export const ReviewDisplay = (
  product,
  reviewArr,
  userLogin,
  newRatingState,
  setNewRatingState,
  setShowToastRev,
  revDisable,
  setRevDisable,
) => {
  const handleStar = (event, newValue) => {
    event.preventDefault();
    setNewRatingState(newValue);
  };

  reviewArr = reviewArr.value;
  const otherReviewScroll = (reviewArr) => {
    let ret = [];
    for (let i = 0; i < reviewArr.length; i++) {
      ret.push(
        <>
          <Grid
            container
            style={{
              border: '1px solid #ddd',
              borderRadius: '4px',
              padding: '16px',
              marginBottom: '16px',
            }}>
            <Grid item xs={12}>
              <Grid
                container
                alignItems='center'
                style={{ marginBottom: '8px' }}>
                <Grid item>
                  <Typography
                    variant='h6'
                    style={{ fontWeight: 'bold', marginRight: '8px' }}>
                    {reviewArr[i].title}
                  </Typography>
                  <span style={{ color: '#888', fontSize: '16px' }}>by</span>{' '}
                  {reviewArr[i].name}
                  <span style={{ color: '#888', fontSize: '16px' }}>
                    {' '}
                    from{' '}
                  </span>
                  {reviewArr[i].location}
                </Grid>
                <Grid item></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                alignItems='center'
                style={{ marginBottom: '8px' }}>
                <Grid item>
                  <Rating
                    name='read-only'
                    style={{ color: '#ef4136' }}
                    value={reviewArr[i].star}
                    readOnly
                  />
                </Grid>
                <Grid item>
                  <Typography style={{ color: '#888', fontSize: '16px' }}>
                    ({reviewArr[i].star}/5)
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontSize: '16px', marginBottom: '16px' }}>
                {reviewArr[i].review}
              </Typography>
              <Typography style={{ color: '#888', fontSize: '16px' }}>
                {new Date(reviewArr[i].date).toLocaleString('en-US', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </Typography>
            </Grid>
          </Grid>
        </>,
      );
    }

    return ret;
  };

  var showStars = 0;
  for (let i = 0; i < reviewArr.length; i++) {
    showStars += reviewArr[i].star;
  }
  showStars = showStars / reviewArr.length;

  var cantPost = true;

  if (userLogin.userInfo != null) {
    cantPost = false;
  }

  const { userInfo } = userLogin;

  const [reviewPopup, setReviewPopup] = useState(false);

  const dispatchReview = (location) => {
    postProductReview(
      userInfo.id ? userInfo.id : null,
      product,
      document.getElementById('userReviewInput').value,
      newRatingState,
      document.getElementById('userReviewEmail').value,
      document.getElementById('userReviewName').value,
      document.getElementById('userReviewTitle').value,
      location,
    );
    document.getElementById('userReviewInput').value = '';
    document.getElementById('userReviewEmail').value = '';
    document.getElementById('userReviewName').value = '';
    document.getElementById('userReviewTitle').value = '';
    document.getElementById('userReviewLocation').value = '';
    setShowToastRev(true);
    setRevDisable(true);
    setReviewPopup(false);
  };

  const postReview = (e) => {
    e.preventDefault();
    var location = document.getElementById('userReviewLocation').value;
    if (location == '') {
      var ipgeolocationApi = new IPGeolocationAPI(
        'b9b223f282ae44fcafcafb7ff96782ef',
        true,
      );
      ipgeolocationApi.getGeolocation((result) => {
        location =
          result.city + ', ' + result.state_prov + ', ' + result.country_name;
        console.log(location);
        dispatchReview(location);
      });
    } else dispatchReview(location);

    return;
  };

  return (
    <>
      <Container>
        <Grid container direction='row'>
          {/* <h4 id='reviews' style={styles.aboutShade}>
            Customer Reviews
          </h4>
           */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '10px',
              justifyContent: 'center',
            }}>
            <Rating
              name='read-only'
              readOnly
              sx={{ color: '#ef4136' }}
              precision={0.5}
              value={showStars}
            />
            <Typography variant='body1'>
              ({reviewArr.length} Reviews)
            </Typography>
          </div>
            <Button
              style={{
                margin: '.5rem 1rem',
                marginBottom:'2rem',
                width: '10rem',
                backgroundColor: '#ef4036',
                color: '#fff',
              }}
              onClick={() => {
                setReviewPopup(true);
              }}>
              Write a review
            </Button>
        </Grid>
        {/* <h1 style={styles.divider}  /> */}

        <Dialog
          maxWidth={'xs'}
          fullWidth={true}
          open={reviewPopup}
          onClose={() => {
            setReviewPopup(false);
          }}>
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            style={{
              padding: '1.5rem',
              backgroundColor: '#F2F2F2',
              borderRadius: '1rem',
              boxShadow: '0 0 10px #BBBBBB',
            }}>
            <Grid
              direction='column'
              alignItems='center'
              style={{ padding: '1.5rem' }}>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                  textAlign: 'center',
                  fontStretch: 'condensed',
                }}>
                Your Rating <RateReviewIcon />
              </p>
              <Rating
                name='input-rating'
                sx={{ color: '#ef4136' }}
                precision={0.5}
                onChange={handleStar}
                disabled={revDisable}
              />
            </Grid>
            <b>Fields marked with * are required</b>
            <form onSubmit={postReview} style={{ width: 'min-content' }}>
              <TextField
                style={{ margin: '1.5rem 0 0 0', width: '18rem' }}
                size='small'
                variant='outlined'
                disabled={revDisable}
                fullWidth
                required
                label='Name'
                id='userReviewName'
              />

              <TextField
                style={{ margin: '1.5rem 0 0 0', width: '18rem' }}
                size='small'
                variant='outlined'
                disabled={revDisable}
                fullWidth
                type='email'
                required
                label='Email '
                id='userReviewEmail'
              />

              <TextField
                style={{ margin: '1.5rem 0 0 0', width: '18rem' }}
                size='small'
                variant='outlined'
                disabled={revDisable}
                fullWidth
                type='text'
                required
                label='Title '
                id='userReviewTitle'
              />

              <TextField
                style={{ margin: '1.5rem 0 0 0', width: '18rem' }}
                size='small'
                variant='outlined'
                disabled={revDisable}
                fullWidth
                type='text'
                label='Location '
                id='userReviewLocation'
              />

              <TextField
                style={{ margin: '1.5rem 0 0 0', width: '18rem' }}
                size='small'
                variant='outlined'
                disabled={revDisable}
                fullWidth
                required
                multiline
                rows={5}
                label='Write a review here '
                id='userReviewInput'
              />

              <Grid
                container
                justifyContent='center'
                style={{ marginTop: '1.5rem' }}>
                <Button
                  className='redButton'
                  style={{
                    margin: '1rem 0rem',
                    width: '10rem',
                    backgroundColor: '#ef4036',
                    color: '#fff',
                  }}
                  type='submit'
                  variant='outlined'
                  disabled={revDisable}>
                  post
                </Button>
              </Grid>
            </form>
          </Grid>
        </Dialog>
        <Grid
          container
          spacing={2}
          direction='row'
          justify='center'
          alignItems='center'>
          {otherReviewScroll(reviewArr)}
        </Grid>
      </Container>
    </>
  );
};
