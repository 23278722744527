/** @format */

import { logout } from '../actions/userActions';

export const tokenRedirectCheck = (data, history) => async (dispatch) => {
  if ('auth' in data && data.auth == false) {
    //un auth

    dispatch(logout());

    history.push('/login');
  }
};
