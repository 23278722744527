/** @format */

import axios from 'axios';
import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_GUEST_SUCCESS,
  USER_REGISTER_SUCCESS,
} from '../constants/userConstant';
import { tokenRedirectCheck } from '../utils/tokenRedirectCheck';

const API = process.env.REACT_APP_OCS_API;

//Register new user
export const register = (name, email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });
    //headers
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.post(`${API}api/users/register`, {
      name,
      email,
      password,
      config,
    });
    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem('userInfo', JSON.stringify(data));
    return "Success";
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    return "Error: " + error.response.data.message;
  }
};

//Login
export const login = (email, password) => async (dispatch) => {
  
  try {
    // Guest login
    if(password == null) {
      let data = {
        isGuest: true,
        email: email,
        userInfo: null
      };
      
      dispatch({
        type: USER_GUEST_SUCCESS,
        payload: data,
      });
     
      localStorage.setItem('userLogin', JSON.stringify(
        {
          isGuest: true,
          loading: false,
          userInfo: {email:email},
        }
      ));
      
      return;
    }

    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.post(
      `${API}api/users/login`,
      { email, password },
      config,
    );
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//Logout
export const logout = () => async (dispatch) => {
  localStorage.removeItem('userInfo');
  dispatch({ type: USER_LOGOUT });
  return 'done';
};

//User Details
export const getUserDetails =
  (userInfo, history) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_DETAILS_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${API}api/users/profile`,
        userInfo,
        config,
      );

      dispatch(tokenRedirectCheck(data, history)); //checks if token i

      dispatch({
        type: USER_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addEmailToMailChimp = (email) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  axios.post(`${API}api/users/emailList`, { email: email }, config);
};

export const addEmailToCartMailChimp = (email) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  axios.post(`${API}api/users/cartMailChimp`, { email: email }, config);
};

export const removeEmailFromCartMailChimp = (email) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  axios.post(`${API}api/users/removeCartMailChimp`, { email: email }, config);
};

export const apiMail = (to, subject, html) => {
  axios.post(`${API}api/mail/sendMail`, {
    to: to,
    subject: subject,
    html: html,
    auth: 'SG1000',
  });
};

export const requestRecovery = (email) => {
  axios.post(`${API}api/users/passwordRecoveryRequest`, { userEmail: email });
};

export const resetPassword = (id, password, valid) => {
  axios.post(`${API}api/users/resetPassword`, {
    id: id,
    password: password,
    valid: valid,
  });
};

export const updateUserPassword =
  (userInfo, newPass, history) => async (dispatch, getState) => {
    try {
      console.log(userInfo);
      //dispatch(tokenRedirectCheck(data, history)); //checks if token i
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const data = await axios.post(
        `${API}api/users/updatePassword`,
        { id: userInfo.id, password: newPass },
        config,
      );

      dispatch(logout());
      history.push('/login');
    } catch (error) {}
  };
