/** @format */

import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Grid, Snackbar } from '@material-ui/core';
import { getAllBlinds } from '../actions/productActions';
import { fetchReviewSummary } from '../actions/userReviewActions';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from '../actions/filterActions';
import MapleLeaf from '../components/Maple.png';
import { Rating } from '@mui/material';
import Menu from '../components/Menu';
import { apiMail } from '../actions/userActions';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import { Alert, AlertTitle } from '@material-ui/lab';
import './style.css';
import Menu3 from '../components/Menu3';

const styles = {
  divider: {
    width: '100%',
    borderTop: '1px solid black',
  },
  logo: {
    height: '4rem',
    alignItems: 'center',
    paddingLeft: '1.5rem',
  },
  align: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    textAlign: 'center',
  },
  section: {
    marginTop: '2rem',
    marginBottom: '4rem',
  },
  button: {
    margin: '2rem',
    textTransform: 'Capitalize',
    padding: '1em 2em',
  },
  heavyText: {
    fontWeight: 400,
    textAlign: 'center',
  },
  lightText: {
    width: '70%',
    fontWeight: 400,
    textAlign: 'center',
    margin: '1rem',
    padding: '4rem 2rem',
  },
  orSection: {
    display: 'flex',
    padding: '1rem 0',
    flexDirection: 'row',
    minHeight: '45vh',
  },
  sectionCol6: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 6,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '1rem',
    margin: '1rem',
    height: 'inherit',
  },
  sectionCol1: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 6,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '1rem',
    margin: '1rem',
  },
  greyButton: {
    borderRadius: '5px',
    fontSize: '0.8rem',
    padding: '0.25rem 3rem',
    fontWeight: 400,
    minHeight: '4rem',
    textTransform: 'Capitalize',
  },
  newsSection: {
    display: 'flex',
    padding: '1rem 0',
    flexDirection: 'row',
  },
  newsAlign: {
    alignItems: 'flex-start',
  },
  subscribe: {
    border: 0,
    textTransform: 'capitalize',
    padding: '0.25em 2em',
  },
  arrivalsTest: {
    maxWidth: '100%',
  },
  widthCorrector: {
    maxWidth: '100%',
  },
  helpButton: {
    padding: '0rem 5rem',
    margin: '1rem',
    fontWeight: 400,
    textTransform: 'Capitalize',
  },
  button: {
    background: '#ebebeb',
    minWidth: '10rem',
    margin: '1rem',
  },
  card: {
    maxWidth: 300,
    margin: 'auto',
    marginTop: '2rem',
    marginBottom: '2rem',
    transition: '0.3s',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.6)',
      color: 'yellow',
    },
    border: '.5px solid lightGrey',
  },
  cardhover: {
    color: 'yellow',
  },
  media: {
    width: '14rem',
    height: '14rem',
    alignItems: 'center',
    margin: '1rem',
  },
  content: {
    textAlign: 'center',
    fontWeight: '300',
  },
  heading: {
    fontWeight: 'bold',
  },
  subheading: {
    lineHeight: 1.8,
  },
  avatar: {
    display: 'inline-block',
    border: '2px solid white',
  },
  red: {
    height: '4rem',
    width: '4rem',
  },
  insp: {
    marginBottom: '1rem',
    textAlign: 'center',
    color: '#ef4136',
  },

  //new
  shadow: {
    position: 'absolute',
    justifyContent: 'center',
    textAlign: 'center',
  },

  shadow1: {
    position: 'absolute',
    display: 'flex',
  },
  leafIcon: {
    height: '4rem',
    margin: '1rem',
  },
  border: {
    border: '1px solid grey',
    margin: '2rem',
  },
  pointer: {
    cursor: 'pointer',
  },
  borderTopBottom: {
    border: '1px white',
    borderStyle: 'solid none',
    // padding: '2rem',
  },
  bottomBorderText: {
    border: '1px grey',
    borderStyle: ' none none solid none',
    paddingBottom: '1rem',
  },
  bottomBorderMain: {
    border: '1px grey',
    borderStyle: ' none none solid none',
  },
  boxBottom: {
    height: '20rem',
  },
  logoSize: {
    height: '4rem',
  },
};

const banner = {
  title: '100% Canadian Made Window Coverings',
  caption: 'Designer Shades Direct to you Door',
  link: '/products/All',
  image: './images/lifestyle/banner.jpg',
  width: '33%',
};

const marketingA = {
  title: 'Best Sellers',
  caption: 'View our tried and tested products',
  // link: '/picks/b',
  image: './images/lifestyle/best_seller.jpg',
  width: '33%',
};

const marketingB = {
  title: 'Blackout Shades',
  caption: 'Get a good night sleep',
  link: '/products',
  image: './images/lifestyle/blackout.jpg',
  width: '33%',
  filter: 'light_Blackout',
};

const consultationData = {
  title: 'Request In Home Consultation',
  caption:
    'Off Cut Shades offers in home consultations across the GTA and Canada, with our partner design team. Consultations are free, so please feel free to request one below.',
  // link: '/consultation',
  // image: './images/lifestyle/in_home.jpg',
  width: '33%',
};

const showroomData = {
  title: 'Visit Our Showroom',
  caption:
    "Off Cut Shades' showroom is located in East York, Toronto. Here we have on display our products and offerings. Have one of our sales representatives walk you through all the steps necessary for order.",
  link: '/aboutus',
  // image: './images/lifestyle/visit.jpg',
  width: '33%',
};

const stepsInfoFabric2 = [
  {
    img: '/images/newHome/Online logo.png',
    desc: 'Online',
  },
  {
    img: '/images/newHome/in-home logo.png',
    desc: 'Request In-home consultation',
  },
  {
    img: '/images/newHome/Showroom logo.png',
    desc: 'Visit our showroom',
  },
];

const cardDetails = [
  {
    link: '/products',
    num: 1,
    type: 'Pick Your Shade',
    desc: 'Pick a shade that is perfect for you. All of our shades have been specially designed by our in-house design team.',
    image: '/images/steps/step1.jpg',
  },
  {
    link: '/aboutus',
    num: 2,
    type: 'Made In Canada',
    desc: 'At our Toronto based factory, we hand-make all shades custom within 2 weeks of purchase.',
    image: '/images/steps/step2.jpg',
  },
  {
    link: '/shippingpolicy',
    num: 3,
    type: 'Ship To Your Door',
    desc: 'We ship all across North America. Shipping times in Canada are between 1 and 4 business days.',
    image: '/images/steps/step3.jpg',
  },
  {
    link: '/installation',
    num: 4,
    type: 'Easy Installation',
    desc: 'Installation is easy as 1, 2, 3. Check out our installation guides for instructions.',
    image: '/images/steps/step4.jpg',
  },
];

const HomeScreen = ({ match }) => {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  let unused = window.onbeforeunload();
  const history = useHistory();
  const dispatch = useDispatch();

  const [popState, setPopState] = useState(false);

  const [popUp, setPopUp] = useState(false);
  const [popUp2, setPopUp2] = useState(false);
  const [popUp3, setPopUp3] = useState(false);
  const [popUp4, setPopUp4] = useState(false);

  const [fName, setFName] = useState();
  const [lName, setLName] = useState();
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [unit, setUnit] = useState();
  const [city, setCity] = useState();
  const [province, setProvince] = useState();
  const [zip, setZip] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();

  const [addressErrorText, setAddressErrorText] = useState();
  const [zipErrorText, setZipErrorText] = useState();
  const [phoneErrorText, setPhoneErrorText] = useState();
  const [emailErrorText, setEmailErrorText] = useState();
  const [toast, setToast] = useState(false);
  const closeToast = () => {
    setToast(false);
  };

  useEffect(() => {
    console.log('Before dispatch 1');
    dispatch(getAllBlinds());
  }, []);

  var productArr = useSelector((state) => state.products.products);

  useEffect(() => {
    console.log('Before dispatch 2');
    fetchReviewSummary(dispatch, productArr);
  }, []);

  const menuHandler = (e) => {
    let reduxval = {
      color: [],
      light: [],
      price: [],
      type: [],
      operation: [],
      collection: [],
    };
    let paramsfull = e.target.id;
    let params = e.target.id.split('_');

    if (params[0] != 'ALL') {
      reduxval[params[0]] = [params[1]];
    }

    setFilter(dispatch, reduxval);
    history.push('/products');
  };

  const goToAll = (event) => {
    let reduxval = {
      color: [],
      light: [],
      price: [],
      type: [],
      operation: [],
      collection: [],
    };
    setFilter(dispatch, reduxval);
    history.push('/products');
    //handleClose(e);
  };

  const handleSub = (e) => {
    e.preventDefault();
    apiMail(
      'info@offcutshades.com',
      `consultation request: ${fName} ${lName}`,
      `${fName} ${lName} has requested a consultation \n address: ${address1} ${address2} \n unit: ${unit} \n city: ${city} \n Province/State: ${province} \n phone: ${phone} \n email: ${email} \n zip/postal: ${zip} \n message: ${message}`,
    );
    apiMail(
      email,
      `Thank You For Contacting Us: ${fName} ${lName}`,
      `<div class="es-wrapper-color">
      <!--[if gte mso 9]>
        <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
          <v:fill type="tile" color="#fafafa"></v:fill>
        </v:background>
      <![endif]-->
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td class="esd-email-paddings" valign="top">
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-header esd-header-popover"
                align="center"
              >
                <tbody>
                  <tr>
                    <td
                      class="esd-stripe esd-synchronizable-module"
                      align="center"
                    >
                      <table
                        bgcolor="#ffffff"
                        class="es-header-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        width="600"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="esd-structure es-p10t es-p10b es-p20r es-p20l"
                              align="left"
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="560"
                                      class="es-m-p0r esd-container-frame"
                                      valign="top"
                                      align="center"
                                      esd-custom-block-id="744422"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-image"
                                              style="font-size: 0px;"
                                            >
                                              <a
                                                target="_blank"
                                                href="https://offcutshades.com"
                                              >
                                                <img
                                                  src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/offcutshadeslogo.png"
                                                  alt="Logo"
                                                  style="display: block;"
                                                  width="200"
                                                  title="Logo"
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="left"
                                              class="esd-block-text"
                                            >
                                              <p style="text-align: center;">
                                                Shading Solutions Simplified
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              class="esd-block-menu"
                                              esd-tmp-menu-color="#444444"
                                              esd-tmp-menu-font-family="'open sans','helvetica neue',helvetica,arial,sans-serif"
                                              esd-tmp-menu-font-weight="bold"
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                class="es-menu"
                                              >
                                                <tbody>
                                                  <tr class="links">
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      width="25%"
                                                      class="es-p10t es-p10b es-p5r es-p5l"
                                                    >
                                                      <a
                                                        target="_blank"
                                                        href="https://offcutshades.com/products"
                                                        style="
                                                          color: #444444;
                                                          font-family: 'open sans',
                                                            'helvetica neue',
                                                            helvetica, arial,
                                                            sans-serif;
                                                          font-weight: bold;
                                                        "
                                                      >
                                                        Window Coverings
                                                      </a>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      width="25%"
                                                      class="es-p10t es-p10b es-p5r es-p5l"
                                                    >
                                                      <a
                                                        target="_blank"
                                                        href="https://offcutshades.com/wallpaperinit"
                                                        style="
                                                          color: #444444;
                                                          font-family: 'open sans',
                                                            'helvetica neue',
                                                            helvetica, arial,
                                                            sans-serif;
                                                          font-weight: bold;
                                                        "
                                                      >
                                                        Wallpaper
                                                      </a>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      width="25%"
                                                      class="es-p10t es-p10b es-p5r es-p5l"
                                                    >
                                                      <a
                                                        target="_blank"
                                                        href="https://offcutshades.com/help"
                                                        style="
                                                          color: #444444;
                                                          font-family: 'open sans',
                                                            'helvetica neue',
                                                            helvetica, arial,
                                                            sans-serif;
                                                          font-weight: bold;
                                                        "
                                                      >
                                                        Help
                                                      </a>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      width="25%"
                                                      class="es-p10t es-p10b es-p5r es-p5l"
                                                    >
                                                      <a
                                                        target="_blank"
                                                        href="https://offcutshades.com/contact"
                                                        style="
                                                          color: #444444;
                                                          font-family: 'open sans',
                                                            'helvetica neue',
                                                            helvetica, arial,
                                                            sans-serif;
                                                          font-weight: bold;
                                                        "
                                                      >
                                                        Contact
                                                      </a>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-content"
                align="center"
              >
                <tbody>
                  <tr>
                    <td class="esd-stripe" align="center">
                      <table
                        bgcolor="#ffffff"
                        class="es-content-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        width="600"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="esd-structure es-p10t es-p10b es-p20r es-p20l"
                              align="left"
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="560"
                                      class="es-m-p0r esd-container-frame"
                                      valign="top"
                                      align="center"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-image"
                                              style="font-size: 0px;"
                                            >
                                              <a target="_blank">
                                                <img
                                                  class="adapt-img"
                                                  src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/thewaitisalmostover.gif"
                                                  alt
                                                  style="display: block;"
                                                  width="560"
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-text es-m-txt-l es-p15t es-p10b"
                                            >
                                              <h3
                                                style="
                                                  font-size: 20px;
                                                  font-family: 'open sans',
                                                    'helvetica neue',
                                                    helvetica, arial,
                                                    sans-serif;
                                                  color: #444444;
                                                "
                                              >
                                                <strong>
                                                  You are one step closer to
                                                  getting the perfect shades
                                                  for your home!
                                                </strong>
                                              </h3>
                                              <p
                                                style="
                                                  font-size: 14px;
                                                  font-family: 'open sans',
                                                    'helvetica neue',
                                                    helvetica, arial,
                                                    sans-serif;
                                                  color: #444444;
                                                "
                                              >
                                                <br />
                                                You have spent hours looking
                                                for the right shades, and now
                                                your ideas are about to come
                                                to fruition.
                                                <em>This is exciting!</em>
                                                <br />
                                                <br />
                                                Thanks for choosing our team
                                                to make your project a
                                                reality.
                                                <strong>
                                                  In the next 24 business
                                                  hours, one of our design
                                                  specialists will contact you
                                                </strong>
                                                to arrange the details of your
                                                in-home consultation.
                                                <br />
                                                <br />
                                                Meanwhile, you can check our
                                                Help section to get familiar
                                                with some of the terms
                                                regarding window coverings.
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-button es-p10t"
                                            >
                                              <span
                                                class="es-button-border"
                                                style="
                                              
                                                  border-radius: 0px;
                                                "
                                              >
                                                <a
                                                  href="https://offcutshades.com/help"
                                                  class="es-button"
                                                  target="_blank"
                                                  style="
                                                  color: #444444;
                                                  font-family: 'open sans',
                                                    'helvetica neue',
                                                    helvetica, arial,
                                                    sans-serif;
                                                  font-weight: bold;
                                                "
                                                >
                                                  Check Guides
                                                </a>
                                              </span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                cellpadding="0"
                cellspacing="0"
                class="es-footer esd-footer-popover"
                align="center"
              >
                <tbody>
                  <tr>
                    <td
                      class="esd-stripe"
                      align="center"
                      esd-custom-block-id="746268"
                      bgcolor="#efefef"
                      style="background-color: #efefef;"
                    >
                      <table
                        class="es-footer-body"
                        align="center"
                        cellpadding="0"
                        cellspacing="0"
                        width="600"
                        style="background-color: transparent;"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="esd-structure es-p20"
                              align="left"
                              esd-custom-block-id="744444"
                            >
                              <!--[if mso]><table width="560" cellpadding="0" cellspacing="0"><tr><td width="145" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-left"
                                align="left"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="125"
                                      class="es-m-p0r es-m-p20b esd-container-frame"
                                      align="center"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-image"
                                              style="font-size: 0px;"
                                            >
                                              <a target="_blank">
                                                <img
                                                  class="adapt-img"
                                                  src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/madetolasticon.png"
                                                  alt
                                                  style="display: block;"
                                                  width="30"
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-text es-p5t"
                                            >
                                              <p>Made to last</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td class="es-hidden" width="20"></td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td width="145" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-left"
                                align="left"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="125"
                                      class="es-m-p20b esd-container-frame"
                                      align="center"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-image"
                                              style="font-size: 0px;"
                                            >
                                              <a target="_blank">
                                                <img
                                                  class="adapt-img"
                                                  src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/readytoshipicon.png"
                                                  alt
                                                  style="display: block;"
                                                  width="30"
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-text es-p5t"
                                            >
                                              <p>Ready to ship in 2 days</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td class="es-hidden" width="20"></td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td width="125" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-left"
                                align="left"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="125"
                                      class="es-m-p20b esd-container-frame"
                                      align="center"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-image"
                                              style="font-size: 0px;"
                                            >
                                              <a target="_blank">
                                                <img
                                                  class="adapt-img"
                                                  src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/guaranteeiconorange.png"
                                                  alt
                                                  style="display: block;"
                                                  width="30"
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-text es-p5t"
                                            >
                                              <p>Guaranteed Fit</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td width="20"></td><td width="125" valign="top"><![endif]-->
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                class="es-right"
                                align="right"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="125"
                                      align="center"
                                      class="esd-container-frame"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-image"
                                              style="font-size: 0px;"
                                            >
                                              <a target="_blank">
                                                <img
                                                  class="adapt-img"
                                                  src="https://vdpuhr.stripocdn.email/content/guids/CABINET_b8c8b7c577e4447014c17b593ad6eb1b/images/pricerighticon.png"
                                                  alt
                                                  style="display: block;"
                                                  width="30"
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-text es-p5t"
                                            >
                                              <p>Price Right every day</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td></tr></table><![endif]-->
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="esd-structure es-p20t es-p20b es-p20r es-p20l"
                              align="left"
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="560"
                                      class="esd-container-frame"
                                      align="left"
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              class="esd-block-text"
                                            >
                                              <p style="text-align: center;">
                                                <strong>Follow Us</strong>
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-social es-p15t es-p15b"
                                              style="font-size: 0;"
                                            >
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                class="es-table-not-adapt es-social"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      class="es-p40r"
                                                    >
                                                      <a
                                                        target="_blank"
                                                        href="https://www.facebook.com/OffCutShadesOnline/"
                                                      >
                                                        <img
                                                          title="Facebook"
                                                          src="https://vdpuhr.stripocdn.email/content/assets/img/social-icons/logo-black/facebook-logo-black.png"
                                                          alt="Fb"
                                                          width="32"
                                                        />
                                                      </a>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      class="es-p40r"
                                                    >
                                                      <a
                                                        target="_blank"
                                                        href="https://www.instagram.com/offcutshades/"
                                                      >
                                                        <img
                                                          title="Instagram"
                                                          src="https://vdpuhr.stripocdn.email/content/assets/img/social-icons/logo-black/instagram-logo-black.png"
                                                          alt="Inst"
                                                          width="32"
                                                        />
                                                      </a>
                                                    </td>
                                                    <td
                                                      align="center"
                                                      valign="top"
                                                      esd-tmp-icon-type="pinterest"
                                                    >
                                                      <a
                                                        target="_blank"
                                                        href="https://www.pinterest.ca/offcutshades/"
                                                      >
                                                        <img
                                                          title="Pinterest"
                                                          src="https://vdpuhr.stripocdn.email/content/assets/img/social-icons/logo-black/pinterest-logo-black.png"
                                                          alt="P"
                                                          width="32"
                                                        />
                                                      </a>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              class="esd-block-text es-p35b"
                                            >
                                              <p>Off Cut Shades&nbsp;</p>
                                              <p>
                                                50 Hollinger road, Toronto, ON
                                                ·
                                                <a
                                                  target="_blank"
                                                  href="tel:+14162856001"
                                                >
                                                  416.285.6001
                                                </a>
                                                <br />
                                                You are receiving this email
                                                because&nbsp;you have
                                                requested an in-home
                                                consultation.&nbsp;
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>`,
    );
    setPopState(false);
    setToast(true);
  };

  return (
    <div style={styles.bottomBorderMain}>
      <Menu />
      <section class='text-light p-lg-0 text-center text-sm-start '>
        {/* <div class='container'> */}
        <div class='d-flex align-items-center justify-content-center '>
          <img
            class='img-fluid d-md-block'
            src='/images/newHome/Hero.jpg'
            alt=''
          />
          <div style={styles.shadow}>
            <img
              src={MapleLeaf}
              className='d-md-inline leaf leafIcon'
              // style={styles.leafIcon}
              alt='Maple Leaf Icon'
            />
            <h1 class='d-md-block text-white textOne'>
              Shading Solutions Simplified
            </h1>
            <h4 class='lead d-md-block text-white textTwo'>
              100% Made in Canada
            </h4>
            <h4 class='lead d-md-block text-white textThree'>
              Buy Factory Direct
            </h4>

            <button
              class='btn btn-outline-dark my-4 mainPageBtn buttonMainPage'
              onClick={goToAll}>
              Start Shopping
            </button>
          </div>
        </div>
      </section>

      {/* Fabric with 4 STeps */}
      <section class='text-light  p-lg-0  text-center text-sm-start section_2'>
        <div class='d-flex align-items-center justify-content-center'>
          <img
            class='img-fluid d-none d-md-block mainpPhoto'
            src='/images/newHome/homeFabric.jpg'
            alt=''
          />
          <div className='d-md-block block2'>
            <div class=' row text-center g-3'>
              <div class=' col-md'>
                <a
                  href='#!'
                  style={styles.pointer}
                  onClick={() => {
                    setPopUp(true);
                  }}>
                  <div class='text-light'>
                    <div class='card-body text-center'>
                      <img
                        src='/images/newHome/Logo made to last.png'
                        className='p-1 m-1'
                      />
                      <h4 class='text-dark textTwo'>Made to last</h4>
                    </div>
                  </div>
                </a>
              </div>

              <div class='col-md'>
                <a
                  href='#!'
                  style={styles.pointer}
                  onClick={() => {
                    setPopUp2(true);
                  }}>
                  <div class='text-light'>
                    <div class='card-body text-center'>
                      <img
                        src='/images/newHome/Ready to ship logo.png'
                        className='p-1 m-1'
                      />
                      <h4 class='text-dark textTwo'>Ready to ship in 2 days</h4>
                    </div>
                  </div>
                </a>
              </div>

              <div class='col-md'>
                <a
                  href='#!'
                  style={styles.pointer}
                  onClick={() => {
                    setPopUp3(true);
                  }}>
                  <div class='text-light'>
                    <div class='card-body text-center'>
                      <img
                        src='/images/newHome/Personal Service Logo.png'
                        className='p-1 m-1'
                      />
                      <h4 class='text-dark textTwo'>
                        Exceptional customer service
                      </h4>
                    </div>
                  </div>
                </a>
              </div>
              <div class=' col-md'>
                <a
                  href='#!'
                  style={styles.pointer}
                  onClick={() => {
                    setPopUp4(true);
                  }}>
                  <div class='text-light'>
                    <div class='card-body text-center'>
                      <img
                        src='/images/newHome/Price Right Logo.png'
                        className='p-1 m-1'
                      />
                      <h4 class='text-dark textTwo'>Priced right every day</h4>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Boxes --> */}
      <section class='px-5'>
        <div class='container'>
          <div class='row text-center g-4'>
            <h2 class='text-black-50'>Shop by type of shade </h2>
            <div class='col-md'>
              <div class='card-body '>
                <img
                  class=' img-fluid'
                  src='/images/newHome/roller-shade-test-1 1.png'
                  alt=''
                />
                <button
                  class='btn btn-outline-dark my-4 mainPageBtn buttonMainPage'
                  id='type_Roller_menu'
                  onClick={menuHandler}>
                  Roller Shades
                </button>
              </div>
            </div>
            <div class='col-md'>
              <div class='card-body '>
                <img
                  class=' img-fluid'
                  src='/images/newHome/banded-shade-test-1 1.png'
                  alt=''
                />
                <button
                  class='btn btn-outline-dark my-4 mainPageBtn buttonMainPage'
                  id='type_Banded_menu'
                  onClick={menuHandler}>
                  Banded Shades
                </button>
              </div>
            </div>
            <div class='col-md'>
              <div class='card-body '>
                <img
                  class=' img-fluid'
                  src='/images/newHome/sheer-shade-test-1 2.png'
                  alt=''
                />
                <button
                  class='btn btn-outline-dark my-4 mainPageBtn buttonMainPage'
                  id='type_Sheer_menu'
                  onClick={menuHandler}>
                  Sheer Shades
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class='text-light  p-lg-0  text-center text-sm-start'>
        {/* <div class='container'> */}
        <div class=' d-flex align-items-center justify-content-center'>
          <img
            class='img-fluid'
            src='/images/newHome/madeincanada.jpg'
            alt=''
          />
          <div className='px-5' style={styles.shadow1}>
            <h1 class='text-light mx-4 textMadeInCanadac borderTopAndBottom'>
              <img
                className='leafIcon'
                src={MapleLeaf}
                // style={styles.leafIcon}
                alt='Maple Leaf Icon'
              />
              100% Made in Canada
            </h1>

            {/* <p class='lead my-4 text-dark'>100% Made in Canada</p> */}
            {/* <button
              class='btn btn-primary mx-4'
              data-bs-toggle='modal'
              data-bs-target='#enroll'>
              Learn More
            </button> */}
          </div>
        </div>
        {/* </div> */}
        {/* <div class='container'> */}
      </section>
      <section id='instructors' class='p-5'>
        <div class='container'>
          <h2 class='text-center text-black-50 mb-5'>What our customers say</h2>

          <div class='row g-5'>
            <div class='col-md-6 col-lg-4'>
              <div class='card h-100'>
                <div class='card-body text-center'>
                  <img
                    // src='https://randomuser.me/api/portraits/men/11.jpg'
                    class='rounded-circle mb-3'
                    alt=''
                  />
                  <h3 class='card-title mb-3'>Great Experience</h3>
                  <Rating
                    name='read-only'
                    value={5}
                    readOnly
                    sx={{ color: '#ef4136' }}
                    precision={0.5}
                  />
                  <div class='card-body'>
                    <p>
                      Had a great experience with Zach and Off Cut Shades.
                      Really helpful sales experience and they didn't at all try
                      to sell us anything more than we needed. Very
                      communicative and the price was reasonable, we felt. We
                      self-installed our roller shades and it couldn't have been
                      easier. Positive all around.
                    </p>
                  </div>
                  <div class='card-footer'>
                    <h4 class=' card-subtitle mb-2 text-muted'>
                      Manrico Erasmi
                    </h4>
                    {/* <small class='text-muted'>Last updated 3 mins ago</small> */}
                  </div>
                  {/* 
                    <a href='#'>
                      <i class='bi bi-linkedin text-dark mx-1'></i>
                    </a>
                    <a href='#'>
                      <i class='bi bi-instagram text-dark mx-1'></i>
                    </a> */}
                </div>
              </div>
            </div>

            <div class='col-md-6 col-lg-4'>
              <div class='card h-100'>
                <div class='card-body text-center h-100'>
                  <img
                    // src='https://randomuser.me/api/portraits/women/11.jpg'
                    class='rounded-circle mb-3'
                    alt=''
                  />
                  <h3 class='card-title mb-3'>Highly Recommend</h3>
                  <Rating
                    name='read-only'
                    value={5}
                    readOnly
                    sx={{ color: '#ef4136' }}
                    precision={0.5}
                  />
                  <div class='card-body'>
                    <p>
                      Highly recommend Offcut Shades, for their quality product
                      but also their great customer service and support. That
                      seems kinda rare there days, but they really delivered.
                      We’ve since ordered another shade from them and it arrived
                      perfect. Support a Canadian business! We’re glad we did.
                    </p>
                  </div>
                  <div class='card-footer'>
                    <h4 class=' card-subtitle mb-2 text-muted'>
                      Dustin Mac Dougall
                    </h4>
                    {/* <small class='text-muted'>Last updated 3 mins ago</small> */}
                  </div>
                </div>
              </div>
            </div>

            <div class='col-md-6 col-lg-4'>
              <div class='card h-100'>
                <div class='card-body text-center h-100'>
                  <img
                    // src='https://randomuser.me/api/portraits/men/12.jpg'
                    class='rounded-circle mb-3'
                    alt=''
                  />
                  <h3 class='card-title mb-3'>Great Quality</h3>
                  <Rating
                    name='read-only'
                    value={5}
                    readOnly
                    sx={{ color: '#ef4136' }}
                    precision={0.5}
                  />
                  <div class='card-body'>
                    <p>
                      I have recently purchased motorized shades for my condo,
                      and I absolutely love them. Its a great quality product,
                      and they look amazing in my living room.
                    </p>
                  </div>
                  <div class='card-footer'>
                    <h4 class=' card-subtitle mb-2 text-muted'>Yana Rizhova</h4>
                    {/* <small class='text-muted'>Last updated 3 mins ago</small> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Made inCanada */}

      {/* shadeofthe month */}
      <section class='bg-light text-dark p-4 p-lg-0 pt-lg-3 text-center text-sm-start'>
        <div class='container'>
          <div class='d-sm-flex align-items-center justify-content-between'>
            <div className='p-5 table-active'>
              <h2 style={styles.bottomBorderText}>Child's Play</h2>
              <p class='lead my-3'>
                A kid’s room is a place where they spend most of their time
                playing, learning, and sleeping and should be fun and inspiring.
                Window shades can set the tone through colour and the additions
                of pulls and decorative braids to make an ordinary shade
                extraordinary.
              </p>
              <button
                class='btn btn-outline-dark my-4 mainPageBtn buttonMainPage'
                id="collection_Child's Play_menu"
                onClick={menuHandler}>
                Shop Now
              </button>
            </div>
            <img
              class='img-fluid w-50 d-none d-sm-block'
              src='/images/newHome/OCS-Kid-section.jpg'
              alt=''
            />
          </div>
        </div>
      </section>

      {/* weightLess Shade */}
      <section class='bg-light text-dark p-4 p-lg-0 pt-lg-5 text-center text-sm-start'>
        <div class='container'>
          <div class='d-sm-flex align-items-center justify-content-between'>
            <img
              class='img-fluid w-50 d-none d-sm-block'
              src='/images/newHome/OCS-Blackout-Section.jpg'
              alt=''
            />
            <div className='p-5'>
              <h2 style={styles.bottomBorderText}>The Dark Side</h2>
              <p class='lead my-3'>
                The Dark Side features Blackout shades. All fabrics in the
                collection are opaque with white backing to completely block the
                sun and darken the room. Enjoy better sleep by blocking the sun
                light, moonlight, or street lights. The simplicity of the shades
                and solid colour pallets gives you a clean canvas from which to
                accessorize your room.
              </p>
              <button
                class='btn btn-outline-dark my-4 mainPageBtn buttonMainPage'
                id='light_Blackout_menu'
                onClick={menuHandler}>
                Shop Now
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* bottomFabric */}
      <section class='text-light  p-lg-0  text-center text-sm-start'>
        {/* <div class='container'> */}
        <h2 class='text-black-50 text-center mt-5 d-none d-md-block'>
          3-Ways to shop{' '}
        </h2>
        <div class='d-flex align-items-center justify-content-center  d-none d-md-flex'>
          <img
            class='img-fluid'
            src='/images/newHome/OCS-Fabric-background-bottom.jpg'
            alt=''
          />
          <div style={styles.shadow}>
            <div class=' row text-center g-3'>
              {/* {stepsInfoFabric2.map((stepsInfo) => ( */}
              <div class=' col-md' style={styles.border}>
                <a style={styles.pointer} onClick={goToAll}>
                  <div class='text-light'>
                    <div class='card-body text-center'>
                      <div class='h1 mb-3'>
                        <i class='bi bi-people'></i>
                      </div>
                      {/* <ShadowCard data={consultationData} /> */}
                      <img
                        src='/images/newHome/Online logo.png'
                        // alt={stepsInfo.type}
                        className='p-1 m-1'
                        // style={styles.media}
                      />
                      <h4 class='text-dark'>Online</h4>
                    </div>
                  </div>
                </a>
              </div>

              <div class=' col-md' style={styles.border}>
                <a
                  style={styles.pointer}
                  onClick={() => {
                    setPopState(true);
                  }}>
                  <div class='text-light'>
                    <div class='card-body text-center'>
                      <div class='h1 mb-3'>
                        <i class='bi bi-people'></i>
                      </div>
                      {/* <ShadowCard data={consultationData} /> */}
                      <img
                        src='/images/newHome/in-home logo.png'
                        // alt={stepsInfo.type}
                        className='p-1 m-1'
                        // style={styles.media}
                      />
                      <h4 class='text-dark'>Request in-home consultation</h4>
                    </div>
                  </div>
                </a>
              </div>

              <div class=' col-md' style={styles.border}>
                <Link to='/contact'>
                  <div class='text-light'>
                    <div class='card-body text-center'>
                      <div class='h1 mb-3'>
                        <i class='bi bi-people'></i>
                      </div>
                      {/* <ShadowCard data={consultationData} /> */}
                      <img
                        src='/images/newHome/Showroom logo.png'
                        // alt={stepsInfo.type}
                        className='p-1 m-1'
                        // style={styles.media}
                      />
                      <h4 class='text-dark'>Visit our showroom</h4>
                    </div>
                  </div>
                </Link>
              </div>
              {/* ))} */}
            </div>
          </div>
        </div>
      </section>

      <section
        class='offcutshades text-light  text-center text-sm-start'
        style={styles.boxBottom}>
        <div class='h-100 d-inline-block d-flex align-items-center justify-content-center'>
          <div className='' style={styles.shadow1}>
            <h1 class='text-light textOne borderTopAndBottom'>
              Need Some Inspiration?
              <a target='_blank' href='https://www.instagram.com/offcutshades/'>
                <img
                  className='ms-2 socialMedia'
                  // style={styles.logoSize}
                  src='/images/newHome/Ig-logo.png'
                  alt=''
                />
              </a>
              <a
                target='_blank'
                href='https://www.facebook.com/OffCutShadesOnline'>
                <img
                  className='m-2 socialMedia'
                  // style={styles.logoSize}
                  src='/images/newHome/Fb-logo.png'
                  alt=''
                />
              </a>
            </h1>
          </div>
        </div>
        {/* </Grid> */}
      </section>

      <Dialog
        open={popUp}
        onClose={() => {
          setPopUp(false);
        }}>
        <div class='card mb-3'>
          <div class='row g-0'>
            <div class='col-md-4' style={styles.align}>
              <img
                src='/images/newHome/Logo made to last.png'
                class='img-fluid rounded-start'
                alt='...'
              />
            </div>
            <div class='col-md-8'>
              <div class='card-body'>
                <h5 class='card-title'>Made to last</h5>
                <p class='card-text'>
                  Our window shades are made right here in Canada by a team of
                  experts with 40 years of experience. We only use the highest
                  quality materials, so you can be confident that your new
                  shades are built to last. You can rest assured knowing that
                  when you purchase from us, you're getting a tried and true
                  product.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={popUp2}
        onClose={() => {
          setPopUp2(false);
        }}>
        <div class='card mb-3'>
          <div class='row g-0'>
            <div class='col-md-4' style={styles.align}>
              <img
                src='/images/newHome/Ready to ship logo.png'
                class='img-fluid rounded-start'
                alt='...'
              />
            </div>
            <div class='col-md-8'>
              <div class='card-body'>
                <h5 class='card-title'>Ready to ship in 2 days</h5>
                <p class='card-text'>
                  Our factory-direct advantage allows us to offer you one of the
                  fastest processing times for window shades in Canada. We will
                  ship your shades in 2 business days after we receive your
                  order, so you can enjoy the perfect amount of light and
                  privacy in your home sooner.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={popUp3}
        onClose={() => {
          setPopUp3(false);
        }}>
        <div class='card mb-3'>
          <div class='row g-0'>
            <div class='col-md-4' style={styles.align}>
              <img
                src='/images/newHome/Personal Service Logo.png'
                class='img-fluid rounded-start'
                alt='...'
              />
            </div>
            <div class='col-md-8'>
              <div class='card-body'>
                <h5 class='card-title'>Exceptional Customer Service</h5>
                <p class='card-text'>
                  We go the extra mile to make sure you're satisfied. From the
                  moment you reach out to us, you will feel cared for and
                  supported throughout your entire journey with us. Whether you
                  have a question about measuring, installation, or our
                  products, our team is always here to help. You can get in
                  touch by phone or in person at our showroom in Toronto.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={popUp4}
        onClose={() => {
          setPopUp4(false);
        }}>
        <div class='card mb-3'>
          <div class='row g-0'>
            <div class='col-md-4' style={styles.align}>
              <img
                src='/images/newHome/Price Right Logo.png'
                class='img-fluid rounded-start'
                alt='...'
              />
            </div>
            <div class='col-md-8'>
              <div class='card-body'>
                <h5 class='card-title'>Price Right Every Day</h5>
                <p class='card-text'>
                  You don't need to wait for a sales event to buy from us. Our
                  best price is always upfront, so you can feel confident that
                  you're getting the best possible deal every day.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={popState}
        onClose={() => {
          setPopState(false);
        }}>
        <form
          style={{ margin: '1.5rem' }}
          sx={{
            '& .MuiTextField-root': { m: 1, width: '20rem' },
          }}
          autoComplete='off'
          onSubmit={handleSub}>
          <TextField
            style={{ margin: '1.5rem' }}
            variant='outlined'
            id='outlined-multiline-flexible'
            label='First Name'
            required
            //value={firstName}
            type='text'
            onChange={(e) => setFName(e.target.value)}
          />
          <TextField
            style={{ margin: '1.5rem' }}
            variant='outlined'
            id='outlined-multiline-flexible'
            label='Last Name'
            required
            //value={lastName}
            type='text'
            onChange={(e) => setLName(e.target.value)}
          />

          <TextField
            style={{ margin: '1.5rem' }}
            variant='outlined'
            id='outlined-multiline-flexible'
            label='Address Line 1'
            required
            //value={addressLine2}
            //helperText={addressErrorText}
            //error={addressErrorText}
            //defaultValue={initialAddress2}
            type='text'
            helperText={addressErrorText}
            error={addressErrorText}
            onChange={(e) => {
              if (
                e.target.value.replace(/\s/g, '').match("^[a-zA-Z0-9s,'-]*$")
              ) {
                setAddressErrorText('');
                setAddress1(e.target.value);
              } else {
                setAddressErrorText('invalid address');
              }
            }}
          />

          <TextField
            style={{ margin: '1.5rem' }}
            variant='outlined'
            id='outlined-multiline-flexible'
            label='Address Line 2'
            type='text'
            helperText={addressErrorText}
            error={addressErrorText}
            onChange={(e) => {
              if (
                e.target.value.replace(/\s/g, '').match("^[a-zA-Z0-9s,'-]*$")
              ) {
                setAddressErrorText('');
                setAddress2(e.target.value);
              } else {
                setAddressErrorText('invalid address');
              }
            }}
          />

          <TextField
            style={{ margin: '1.5rem' }}
            variant='outlined'
            id='outlined-multiline-flexible'
            label='Unit'
            type='text'
            onChange={(e) => {
              setUnit(e.target.value);
            }}
          />

          <TextField
            style={{ margin: '1.5rem' }}
            variant='outlined'
            id='outlined-multiline-flexible'
            label='City'
            required
            type='text'
            onChange={(e) => {
              setCity(e.target.value);
            }}
          />

          <TextField
            style={{ margin: '1.5rem' }}
            variant='outlined'
            id='outlined-multiline-flexible'
            label='Province'
            required
            type='text'
            onChange={(e) => {
              setProvince(e.target.value);
            }}
          />

          <TextField
            style={{ margin: '1.5rem' }}
            variant='outlined'
            id='outlined-multiline-flexible'
            label='Phone Number'
            required
            helperText={phoneErrorText}
            error={phoneErrorText}
            //defaultValue={initialAddress2}
            type='text'
            onChange={(e) => {
              let str = e.target.value;
              str = str.replace(/\s/g, '');
              str = str.replaceAll('-', '');
              if (str.match('^[0-9]{10}$') != null) {
                setPhoneErrorText('');
                setPhone(str);
              } else {
                setPhoneErrorText('invalid phone #');
              }
            }}
          />

          <TextField
            style={{ margin: '1.5rem' }}
            variant='outlined'
            id='outlined-multiline-flexible'
            label='Email Address'
            required
            helperText={emailErrorText}
            error={emailErrorText}
            onChange={(e) => {
              let str = e.target.value;
              if (
                str.match('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$') !=
                null
              ) {
                setEmailErrorText('');
                setEmail(str);
              } else {
                setEmailErrorText('invalid email');
              }
            }}
          />

          <TextField
            style={{ margin: '1.5rem' }}
            variant='outlined'
            id='outlined-multiline-flexible'
            label='Postal/Zip Code'
            required
            helperText={zipErrorText}
            error={zipErrorText}
            type='text'
            onChange={(e) => {
              if (
                e.target.value
                  .replace(/\s/g, '')
                  .match('[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9]{1}')
              ) {
                setZipErrorText('');
                setZip(e.target.value);
              } else {
                setZipErrorText('invalid');
              }
            }}
          />
          <TextField
            style={{ margin: '1.5rem', width: '31rem' }}
            variant='outlined'
            id='outlined-multiline-flexible'
            label='Message'
            required
            multiline
            rows={3}
            type='text'
            onChange={(e) => setMessage(e.target.value)}
          />
          <Grid
            container
            style={styles.atcBut}
            justifyContent='center'
            alignItems='center'>
            <Button style={styles.atcButton} type='submit'>
              Submit
            </Button>
          </Grid>
        </form>
      </Dialog>

      {/* </div> */}
      <Snackbar autoHideDuration={6000} open={toast} close={closeToast}>
        <Alert severity='success' onClose={closeToast}>
          <AlertTitle>Request submitted</AlertTitle>
        </Alert>
      </Snackbar>
    </div>
    // </div>
  );
};

export default HomeScreen;
