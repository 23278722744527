/** @format */

import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';
import ShadowCard from '../components/ShadowCard';
import ShadowBanner from '../components/ShadowBanner';
import { getAllBlinds } from '../actions/productActions';
import { fetchReviewSummary } from '../actions/userReviewActions';
import { useDispatch, useSelector } from 'react-redux';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import Menu3 from '../components/Menu3';

// import Product from '../components/Product';
// import { useDispatch } from 'react-redux';
// import { getAllNewArrival } from '../actions/productActions';

const styles = {
  divider: {
    width: '100%',
    // zIndex: 1,
    // borderTop: '1px solid #e5e5e5',
    borderTop: '1px solid black',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  section: {
    marginTop: '2rem',
    marginBottom: '4rem',
  },
  button: {
    margin: '2rem',
    textTransform: 'Capitalize',
    padding: '1em 2em',
  },
  heavyText: {
    fontWeight: 400,
    textAlign: 'center',
  },
  lightText: {
    width: '70%',
    fontWeight: 400,
    textAlign: 'center',
    margin: '1rem',
    padding:'4rem 2rem',
    // padding:'2rem'
  },
  orSection: {
    display: 'flex',
    padding: '1rem 0',
    flexDirection: 'row',
    minHeight: '45vh',
    // borderTop: '1px solid grey'
  },
  sectionCol6: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 6,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '1rem',
    margin: '1rem',
    height: 'inherit',
  },
  sectionCol1: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 6,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '1rem',
    margin: '1rem',
  },
  greyButton: {
    borderRadius: '5px',
    fontSize: '0.8rem',
    padding: '0.25rem 3rem',
    fontWeight: 400,
    minHeight: '4rem',
    textTransform: 'Capitalize',
  },
  newsSection: {
    display: 'flex',
    padding: '1rem 0',
    flexDirection: 'row',
    // minHeight: '45vh',
    // borderTop: '1px solid grey'
  },
  newsAlign: {
    alignItems: 'flex-start',
  },
  subscribe: {
    border: 0,
    textTransform: 'capitalize',
    padding: '0.25em 2em',
  },

  dualCards: {
    // width: '35%',
  },
  arrivalsTest: {
    // marginLeft: '17%',
    // marginRight: '17%',
    maxWidth: '100%',
    // margin: '10px'
  },
  widthCorrector: {
    // margin: '1rem 0',
    // overflow: 'None'
    //sss
    maxWidth: '100%',
  },
  helpButton: {
    // borderRadius: '15px',
    // fontSize: '0.8rem',
    // background: '#d5d5d5',
    padding: '0rem 5rem',
    margin: '1rem',
    fontWeight: 400,
    // minHeight: '4rem',
    textTransform: 'Capitalize',
  },

  button: {
    background: '#ebebeb',
    minWidth: '10rem',
    margin: '1rem',
  },

  card: {
    maxWidth: 300,
    margin: 'auto',
    marginTop: '2rem',
    marginBottom: '2rem',
    transition: '0.3s',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.6)',
      color: 'yellow'
    },
    border: '.5px solid lightGrey',
  },
  'cardhover':{
    color: 'yellow'
  },

  media: {
    width: '14rem',
    height: '14rem',
    alignItems: 'center',
    margin: '1rem',
  },
  content: {
    textAlign: 'center',
    fontWeight: '300',
  },
  heading: {
    fontWeight: 'bold',
  },
  subheading: {
    lineHeight: 1.8,
  },
  avatar: {
    display: 'inline-block',
    border: '2px solid white',
  },
  red: {
    height: '4rem',
    width: '4rem',
    // textAlign: 'center',

    // paddingTop:'1rem'
  },
  insp: {
    marginBottom: '1rem',
    textAlign: 'center',
    color: '#ef4136',
  },
};

const banner = {
  title: '100% Canadian Made Window Coverings',
  caption: 'Designer Shades Direct to you Door',
  link: '/products/All',
  image: './images/lifestyle/banner.jpg',
  width: '33%',
};

const marketingA = {
  title: 'Best Sellers',
  caption: 'View our tried and tested products',
  // link: '/picks/b',
  image: './images/lifestyle/best_seller.jpg',
  width: '33%',
};

const marketingB = {
  title: 'Blackout Shades',
  caption: 'Get a good night sleep',
  link: '/products',
  image: './images/lifestyle/blackout.jpg',
  width: '33%',
  filter: 'light_Blackout',
};

const consultationData = {
  title: 'Request In Home Consultation',
  caption:
    'Off Cut Shades offers in home consultations across the GTA and Canada, with our partner design team. Consultations are free, so please feel free to request one below.',
  // link: '/consultation',
  // image: './images/lifestyle/in_home.jpg',
  width: '33%',
};

const showroomData = {
  title: 'Visit Our Showroom',
  caption:
    "Off Cut Shades' showroom is located in East York, Toronto. Here we have on display our products and offerings. Have one of our sales representatives walk you through all the steps necessary for order.",
  link: '/aboutus',
  // image: './images/lifestyle/visit.jpg',
  width: '33%',
};

// const saftyShieldData = {
//   title: 'Safty Shield',
//   caption:
//     'All our chain operated roller shades have been approved for use under the new industry standards for child safety.',
//   // link: '/consultation',
//   // image: './images/lifestyle/in_home.jpg',
//   width: '33%',
// };

// const ComingSoonData = {
//   title: 'Wallpaper Creator  ',
//   caption:
//     "Come back soon to check our wallpaper creator tool. You will be able to customize the wallpaper with your favourite pictures of friends and family.  ",
//   link: '/wallpaperinit',
//   // image: './images/lifestyle/visit.jpg',
//   width: '33%',
// };

const cardDetails = [
  {
    link: '/products',
    num: 1,
    type: 'Pick Your Shade',
    desc: 'Pick a shade that is perfect for you. All of our shades have been specially designed by our in-house design team.',
    image: '/images/steps/step1.jpg',
  },
  {
    link: '/aboutus',
    num: 2,
    type: 'Made In Canada',
    desc: 'At our Toronto based factory, we hand-make all shades custom within 2 weeks of purchase.',
    image: '/images/steps/step2.jpg',
  },
  {
    link: '/shippingpolicy',
    num: 3,
    type: 'Ship To Your Door',
    desc: 'We ship all across North America. Shipping times in Canada are between 1 and 4 business days.',
    image: '/images/steps/step3.jpg',
  },
  {
    link: '/installation',
    num: 4,
    type: 'Easy Installation',
    desc: 'Installation is easy as 1, 2, 3. Check out our installation guides for instructions.',
    image: '/images/steps/step4.jpg',
  },
];

const HomeScreen = ({ match }) => {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  let unused = window.onbeforeunload();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllBlinds());
  }, [dispatch]);

  var productArr = useSelector((state) => state.products.products);

  useEffect(() => {
    fetchReviewSummary(dispatch, productArr);
  }, [dispatch, productArr]);

  return (
    <Container>
      <Menu3/>
      {/* Top Banner */}
      <Grid container justifyContent='center' alignItems='center'>
        <ShadowBanner data={banner} />
      </Grid>

      {/* 2x Marketing Spots */}
      {/* <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        style={styles.widthCorrector}
        spacing={3}>
        <Grid item xs={10} sm={5}>
          <ShadowCard data={marketingA} />
        </Grid>
        <Grid item xs={10} sm={5}>
          {ShadowCard({ data: marketingB }, dispatch, history)}
        </Grid>
      </Grid> */}
      {/* Product Listings */}
      {/* <hr style={styles.divider} /> */}

      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        style={styles.widthCorrector}
        spacing={6}>
        <Typography variant='h6' color='textSecondary' style={styles.lightText}>
          {/* We know there are countless options and decisions to make when
          considering shades. You have come to the right place, instead of
          endlessly scrolling, we have curated for you various shade options,
          all decisions made – all you have to do is pick your size and fabric
          colour and your shade will be custom made from our factory to your
          door. */}
          Tired of endlessly scrolling through countless options and choices?
          You have come to the right place! We have curated for you various
          shade options, all decisions made – all you have to do is pick your
          size and fabric colour and your shade will be custom made from our
          factory to your door. Shading Solutions Simplified!
        </Typography>
        {/* <Typography variant='h6'>
          Are you ready to experience how OCS does shades, let’s get started.
        </Typography> */}
      </Grid>
      {/* In Home Consults and Visit Our Showroom */}
      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems='flex-start'>
        {cardDetails.map((cardDetail) => (
          <Card style={styles.card}>
            <Link to={cardDetail.link}>
              {/* <Typography variant='h5' style={styles.content}>
                {cardDetail.num}
              </Typography> */}
              <Divider dark />
              <CardMedia style={styles.media}>
                <img
                  src={cardDetail.image}
                  alt={cardDetail.type}
                  style={styles.media}
                />
              </CardMedia>
              <CardContent style={styles.content}>
                <Typography variant='h6'>{cardDetail.type}</Typography>
                <Divider dark />

                <Grid>
                  <Typography variant='subtitle1'>{cardDetail.desc}</Typography>
                </Grid>
                {/* <Button
                href={cardDetail.pdf}
                target='_blank'
                style={styles.button}>
                Download PDF
              </Button> */}
              </CardContent>
            </Link>
          </Card>
        ))}
      </Grid>

      {/* 2x Marketing Spots */}
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={3}>
        <Grid item xs={12} sm={6}>
          <ShadowCard data={consultationData} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ShadowCard data={showroomData} />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <ShadowCard data={saftyShieldData} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ShadowCard data={ComingSoonData} />
        </Grid> */}
      </Grid>
      <hr style={styles.divider} />

      <Container>
        {/* Need Help  */}
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'>
          <Typography
            variant='h5'
            color='textSecondary'
            style={styles.heavyText}>
            Need Help?
          </Typography>
          <Typography
            variant='h6'
            color='textSecondary'
            >
            Visit our help page to get help on ordering our window shades!
          </Typography>
          <Link to={'/help'}>
            <Button style={styles.button}>Help</Button>
          </Link>
        </Grid>
        <hr style={styles.divider} />
        {/* <a target='_blank' href='https://www.instagram.com/offcutshades/'> */}
        <Grid
          // container
          // spacing={2}
          direction='column'
          justify='center'
          alignItems='center'>
          <Typography variant='h5' color='textSecondary' style={styles.insp}>
            <img style={styles.red} src='/images/selection/bulb.png' alt='#' />
            Get Inspired
          </Typography>
          {/* <Typography
            variant='h6'
            color='textSecondary'
            style={styles.heavyText}>
            Visit our photo gallery to get some truly inspired decore ideas.
          </Typography> */}
          <Grid
            Box
            container
            direction='row'
            justifyContent='center'
            alignItems='center'>
            <a target='_blank' href='https://www.instagram.com/offcutshades/'>
              <IconButton aria-label='delete' style={styles.roundButton}>
                <InstagramIcon fontSize='large' />
              </IconButton>
            </a>
            <a target='_blank' href='https://twitter.com/offcutshades'>
              <IconButton aria-label='delete' style={styles.roundButton}>
                <TwitterIcon fontSize='large' />
              </IconButton>
            </a>

            <a
              target='_blank'
              href='https://www.facebook.com/OffCutShadesOnline'>
              <IconButton aria-label='delete' style={styles.roundButton}>
                <FacebookIcon fontSize='large' />
              </IconButton>
            </a>
            

          </Grid>
        </Grid>
        {/* </a> */}
      </Container>
      {/* <hr style={styles.divider} /> */}

      {/* <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}>
        <DialogTitle>Optional sizes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can set my maximum width and whether to adapt or not.
          </DialogContentText>
          <Box
            noValidate
            component='form'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}>

          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog> */}
    </Container>
  );
};

export default HomeScreen;
