/** @format */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Container, Grid, Hidden } from '@material-ui/core';
import { saveCartSummary, saveShippingAddress } from '../actions/cartActions';
import { getRates } from '../actions/orderActions';
import { set } from 'mongoose';
import Menu3 from '../components/Menu3';
import TextField from '@mui/material/TextField';
import {
  addEmailToCartMailChimp,
  apiMail,
  login,
  register,
} from '../actions/userActions';
import Message from '../components/Message';

// import getRates from "../hooks/useRates";

const styles = {
  divider: {
    width: '100%',
    // margin: '2rem auto',
  },
  darkText: {
    color: '#000',
    // borderBottom: '1px solid #000',
    fontSize: '1.1rem',
    // fontFamily: 'Lato',
  },
  disabledText: {
    color: '#9b9b9b',
    borderBottom: '1px solid #9b9b9b;',
    fontSize: '1.1rem',
    // fontFamily: 'Lato',
  },
  leftHeader: {
    color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '1.5rem',
    textAlign: 'left',
    // margin: '2rem 0 0 0',
  },
  heavyText: {
    fontWeight: 400,
  },
  topSpacer: {
    marginTop: '2rem',
  },
  panel: {
    background: '#e3e3e3d9',
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    textAlign: 'center',
  },
  couponCode: {
    color: '#000',
    fontSize: '1.5rem',
    textAlign: 'left',
    width: '100%',
  },
  priceText: {
    fontSize: '1rem',
    margin: '.5rem 0',
  },
  msrpTag: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'Capitalize',
    textDecoration: 'line-through',
  },
  priceTextSave: {
    fontSize: '1rem',
    margin: '.5rem 0',
    color: '#E61610',
  },
  hide: {
    visibility: 'hidden',
  },
  priceTotal: {
    fontSize: '1.2rem',
    margin: '.5rem 0',
    // borderTop: '1px solid #000',
  },
  priceSaving: {
    fontSize: '1.2rem',
    margin: '.5rem 0',
    color: '#E61610',
  },
  atcButton: {
    // margin: '1rem .5rem',
    // width: '12rem',
    // backgroundColor: '#ef4036',
    // color: '#fff',
    // background: '#ebebeb',
    // borderRadius: '50px',
    minWidth: '10rem',
    // marginRight: '1rem',
  },
};

const buildParcels = (cartItems) => {
  return cartItems.map((item) => {
    const weight = { value: item.optionInfo.widthWhole * 0.15, unit: 'kg' };
    const dimension = {
      width: 7,
      height: 7,
      depth: Number(item.optionInfo.widthWhole),
      unit: 'in',
    };
    let quantity = item.optionInfo.quantity;
    // const quantity = { items: [Number(item.optionInfo.quantity)] }

    return {
      description: 'Food XS',
      box_type: 'custom',
      weight,
      dimension,
      items: [
        {
          description: 'Food Bar',
          origin_country: 'JPN',
          quantity: quantity,
          price: { amount: 3, currency: 'CAD' },
          weight: { value: 0.6, unit: 'kg' },
          sku: 'PS4-2015',
        },
      ],
    };
  });
};

const buildShippingAddress = (shippingAddress, userInfo) => {
  return {
    contact_name: shippingAddress.name,
    street1: shippingAddress.addressLine1,
    city: shippingAddress.city,
    state: shippingAddress.province,
    postal_code: shippingAddress.postalCode,
    country: shippingAddress.country,
    phone: shippingAddress.phoneNumber,
    email: shippingAddress.email,
    type: 'residential',
  };
};

const ShippingScreen = ({ location, history }) => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { shippingAddress, cartSummary, cartItems } = cart;

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const rateForCart = useSelector((state) => state.rates);

  const [guestEmail, setGuestEmail] = useState('');
  const [initialAddress1, setInitialAddress1] = useState();
  const [initialAddress2, setInitialAddress2] = useState();
  const [initialZip, setInitialZip] = useState();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [addressLine1, setAddressLine1] = useState(
    shippingAddress.addressLine1,
  );
  const [addressLine2, setAddressLine2] = useState(
    shippingAddress.addressLine2,
  );
  const [province, setProvince] = useState(shippingAddress.province);
  const [city, setCity] = useState(shippingAddress.city);
  const [country, setCountry] = useState(shippingAddress.country);
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode);

  const [addressErrorText, setAddressErrorText] = useState();
  const [zipErrorText, setZipErrorText] = useState();
  const [phoneErrorText, setPhoneErrorText] = useState();

  const redirect = location.search ? location.search.split('=')[1] : '/';

  useEffect(() => {
    setInitialAddress1(shippingAddress?.addressLine1);
    setInitialAddress2(shippingAddress?.addressLine2);
    setInitialZip(shippingAddress?.postalCode);
  }, [shippingAddress?.addressLine1]);

  const taxForProvince = () => {
    var provinceL = province.toLowerCase();
    var taxRate = 0;
    switch (provinceL) {
      case 'on' || 'ontario':
        taxRate = 0.13;
        break;
      case 'ab' || 'alberta':
        taxRate = 0.05;
        break;
      case 'bc' || 'british colombia':
        taxRate = 0.12;
        break;
      case 'mb' || 'manitoba':
        taxRate = 0.12;
        break;
      case 'qc' || 'quebec':
        taxRate = 0.14975;
      default:
        taxRate = 0.15;
    }
  };

  const checkoutHandler = async () => {

    await dispatch(
      saveShippingAddress({
        firstName,
        lastName,
        phoneNumber,
        addressLine1,
        addressLine2,
        city,
        province,
        country,
        postalCode,
      }),
    );

    let parcels = buildParcels(cartItems);
    console.log(shippingAddress);
    let ship_to = buildShippingAddress(
      {
        addressLine1: addressLine1,
        city: city,
        province: province,
        postalCode: postalCode,
        country: country,
        phoneNumber: phoneNumber,
      },
      userInfo,
    );
    console.log(ship_to);
    dispatch(
      getRates(
        {
          parcels: parcels,
          ship_to: ship_to,
        },
        history,
      ),
    ).then(() => {
      let taxFloat = taxForProvince();

      dispatch(saveCartSummary(cartSummary));
    });
    history.push('/placeorder');
  };

  const submitHandler = (e) => {
    e.preventDefault();

    // if (document.getElementById('createAccount').checked) {
    // Generate a random password
    if (!userInfo) {
      const password = Math.random().toString(36).slice(-8);
      console.log(password);
      const name = firstName + ' ' + lastName;
      dispatch(register(name, guestEmail, password)).then((res) => {
        console.log(res);

        // Email the user their password
        if (res.includes('Error')) return;
        apiMail(
          guestEmail,
          'Your account has been created!',
          '<b>Your account details are as follows:</b><br/><br/> <b>Email:</b> ' +
          guestEmail +
          '<br/> <b>Password:</b> ' +
          password +
          '<br/><br/> You can change your password by logging in and going to your profile.',
        );
        checkoutHandler();
        addEmailToCartMailChimp(guestEmail);
      });
    }
    else {
      checkoutHandler();
      addEmailToCartMailChimp(userInfo?.email);
    }
    // } else {
    //   dispatch(login(guestEmail, null));
    // }
  };

  return (
    <>
      <Menu3 />
      <Container>
        {/* <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          style={styles.panel}
          spacing={0}>
          <Grid item sm={3} style={styles.disabledText}>
            <p>1. Shopping Cart</p>
          </Grid>
          <Hidden smDown>
            <Grid item md={3} style={styles.darkText}>
              <p>2. Shipping Details</p>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid item md={3} style={styles.disabledText}>
              <p>3. Place Order</p>
            </Grid>
          </Hidden>
 
        </Grid>
        <div class='stepper-wrapper'>
          <div class='stepper-item completed'>
            <div class='step-counter'>1</div>
            <div class='step-name'>Shopping Cart</div>
          </div>
          <div class='stepper-item completed'>
            <div class='step-counter'>2</div>
            <div class='step-name'>Shipping Details</div>
          </div>
          <div class='stepper-item active'>
            <div class='step-counter'>3</div>
            <div class='step-name'> Place Order</div>
          </div>
        </div>
           <Hidden smDown>
            <Grid item md={3} style={styles.disabledText}>
              <p>4. Payment </p>
            </Grid>
          </Hidden> *
        </Grid> */}

        <div className='stepper-wrapper'>
          <div
            className='stepper-item completed'>
            <div className='step-counter'>1</div>
            <div className='step-name'>Shopping Cart</div>
          </div>
          <div
            className='stepper-item active'>
            <div className='step-counter'>2</div>
            <div className='step-name'>Shipping Details</div>
          </div>
          <div
            className='stepper-item'>
            <div className='step-counter'>3</div>
            <div className='step-name'>Place Order</div>
          </div>
        </div>

        <Grid
          container
          direction='row'
          justifyContent='space-between'
          spacing={2}>
          <Grid item sm={12} md={8}>
            <p style={styles.leftHeader}> Shipping Details</p>
            {!userInfo && <><hr style={styles.divider} />

              <Link to={'/login'}>
                <li className='linkText'>Already have account? Log In</li>
              </Link></>}

            <hr style={styles.divider} />
            {error && <Message variant='danger'>{error}</Message>}
            {/* <form onSubmit={guestCheckoutHandler}></form> */}
            {/* <hr style={styles.divider} /> */}

            {/* )} */}
            {/* <li className='leftHeader'>Returning Customer? Log in</li>
            {loading && <Loader />}
            <hr style={styles.divider} />
            <Grid
              style={{ width: '20rem' }}
              direction='column'
              justifyContent='center'
              alignItems='center'>
              {error && <Message variant='danger'>{error}</Message>}
              <form onSubmit={submitHandler}>
                <TextField
                   style={{ margin: '1.5rem 0 0 0', width: '18rem' }}
                  id='basic'
                  size='small'
                  required
                  label='Email'
                  value={email}
                  type='email'
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                   style={{ margin: '1.5rem 0 0 0', width: '18rem' }}
                  id='basic'
                  size='small'
                  variant='outlined'
                  label='Password'
                  required
                  value={password}
                  type='password'
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  variant='outlined'
                  type='submit'
                  style={{
                    margin: '1rem 0rem',
                    width: '10rem',
                    backgroundColor: '#ef4036',
                    color: '#fff',
                  }}>
                  Log In
                </Button>
              </form>
              <Link to={'/accountRecovery'}>
                <p className='linkText'>Forgot your password?</p>
              </Link>

              <Link
                to={redirect ? `/register?redirect=${redirect}` : '/register'}>
                <Button
                  variant='outlined'
                  style={{ margin: '1rem 0rem', width: '10rem' }}>
                  Register
                </Button>
                <p className='linkText'>Don't have account?</p>
              </Link>
            </Grid> */}
            <Grid style={{ width: '20rem' }} container direction='column'>
              <p className='leftHeader'>{!userInfo ? 'Guest Checkout' : 'Checkout'}</p>
              {/* <hr style={styles.divider} /> */}
              <form onSubmit={submitHandler}>
              {!userInfo && <TextField
                  style={{ margin: '.5rem 0 .5rem 0', width: '18rem' }}
                  id='basic'
                  size='small'
                  required
                  label='Email'
                  value={guestEmail}
                  type='email'
                  onChange={(e) => setGuestEmail(e.target.value)}
                />}
                <TextField
                  style={{ margin: '.5rem 0 0 0', width: '18rem' }}
                  size='small'
                  variant='outlined'
                  id='outlined-multiline-flexible'
                  label='First Name'
                  required
                  value={firstName}
                  type='text'
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                  style={{ margin: '1.5rem 0 0 0', width: '18rem' }}
                  size='small'
                  variant='outlined'
                  id='outlined-multiline-flexible'
                  label='Last Name'
                  required
                  value={lastName}
                  type='text'
                  onChange={(e) => setLastName(e.target.value)}
                />
              
                <TextField
                  style={{ margin: '1.5rem 0 0 0', width: '18rem' }}
                  size='small'
                  variant='outlined'
                  id='outlined-multiline-flexible'
                  label='Phone Number'
                  type='text'
                  min='10'
                  max='10'
                  required
                  helperText={phoneErrorText}
                  error={phoneErrorText}
                  onChange={(e) => {
                    let str = e.target.value;
                    str = str.replace(/\s/g, '');
                    str = str.replaceAll('-', '');

                    if (str.match('^[0-9]{10}$') != null) {
                      setPhoneErrorText('');
                      setPhoneNumber(str);
                    } else {
                      setPhoneErrorText('invalid phone #');
                    }
                  }}
                />
                <TextField
                  style={{ margin: '1.5rem 0 0 0', width: '18rem' }}
                  size='small'
                  variant='outlined'
                  id='outlined-multiline-flexible'
                  label='Address Line 1'
                  required
                  defaultValue={initialAddress1}
                  helperText={addressErrorText}
                  error={addressErrorText}
                  type='text'
                  onChange={(e) => {
                    if (
                      e.target.value
                        .replace(/\s/g, '')
                        .match("^[a-zA-Z0-9s,'-]*$")
                    ) {
                      setAddressErrorText('');
                      setAddressLine1(e.target.value);
                    } else {
                      setAddressErrorText('invalid address');
                    }
                  }}
                  pattern="^[a-zA-Z0-9\s,'-]*$"
                />
                <TextField
                  style={{ margin: '1.5rem 0 0 0', width: '18rem' }}
                  size='small'
                  variant='outlined'
                  id='outlined-multiline-flexible'
                  label='Address Line 2'
                  helperText={addressErrorText}
                  error={addressErrorText}
                  defaultValue={initialAddress2}
                  type='text'
                  onChange={(e) => {
                    if (
                      e.target.value
                        .replace(/\s/g, '')
                        .match("^[a-zA-Z0-9s,'-]*$")
                    ) {
                      setAddressErrorText('');
                      setAddressLine2(e.target.value);
                    } else {
                      setAddressErrorText('invalid address');
                    }
                  }}
                />
                <TextField
                  style={{ margin: '1.5rem 0 0 0', width: '18rem' }}
                  size='small'
                  variant='outlined'
                  id='outlined-multiline-flexible'
                  label='City'
                  required
                  value={city}
                  type='text'
                  onChange={(e) => setCity(e.target.value)}
                />
                <TextField
                  style={{ margin: '1.5rem 0 0 0', width: '18rem' }}
                  size='small'
                  variant='outlined'
                  id='outlined-multiline-flexible'
                  label='Province'
                  required
                  value={province}
                  type='text'
                  onChange={(e) => setProvince(e.target.value)}
                />{' '}
                <TextField
                  style={{ margin: '1.5rem 0 0 0', width: '18rem' }}
                  size='small'
                  variant='outlined'
                  id='outlined-multiline-flexible'
                  label='Country'
                  required
                  value={country}
                  type='text'
                  onChange={(e) => setCountry(e.target.value)}
                />
                <TextField
                  style={{ margin: '1.5rem 0 0 0', width: '18rem' }}
                  size='small'
                  variant='outlined'
                  id='outlined-multiline-flexible'
                  label='Postal Code'
                  required
                  helperText={zipErrorText}
                  error={zipErrorText}
                  defaultValue={initialZip}
                  //value={postalCode}
                  type='text'
                  onChange={(e) => {
                    let str = e.target.value.replace(/\s/g, '');

                    if (
                      str.match(
                        '[A-Za-z]{1}[0-9]{1}[A-Za-z]{1}[0-9]{1}[A-Za-z]{1}[0-9]{1}',
                      )
                    ) {
                      setZipErrorText('');
                      setPostalCode(str.toUpperCase());
                    } else {
                      setZipErrorText(
                        'Postal Code must be in the format A1A 1A1',
                      );
                    }
                  }}
                />
                <Grid
                  style={{ margin: '1.5rem 0 2rem 0', width: '18rem' }}
                  size='small'
                  container
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='center'>
              
                  <Button
                    className='redButton'
                    style={{
                      margin: '1rem 0rem',
                      width: '10rem',
                      backgroundColor: '#ef4036',
                      color: '#fff',
                    }}
                    type='submit'
                    variant='outlined'>
                    {!userInfo ? 'Guest Checkout' : 'Checkout'}
                  </Button>
                  <Link to={'/cart'}>
                    <Button  style={styles.atcButton}>Back to Cart</Button>
                  </Link>
                  {/* <Button type='submit' style={styles.atcButton}>
                    Next
                  </Button> */}
                </Grid>
              </form>
            </Grid>
          </Grid>
          <Grid item sm={12} md={4}>
            <p style={styles.leftHeader}>Summary</p>
            <hr style={styles.divider} />
            <div className='productTotalsInnerWrap'>
              <div id='productTotals' className=''>
                <div className='firstProductSection'>
                  <div className='totalLine'>
                    <label className='productPriceInfo'>Retail Price:</label>
                    <div
                      className='total'
                      style={{ textDecoration: 'line-through' }}>
                      ${cartSummary.FINAL_MSRP}
                    </div>
                  </div>
                  <div className='totalLine'>
                    <label className='productPriceInfo totalLineRed'>
                      You Save 30%:
                    </label>
                    <div className='total totalLineRed'>
                      ${cartSummary.SAVINGS}
                    </div>
                  </div>
                  <div className='totalLine'>
                    <label className='productPriceInfo'>Tax:</label>
                    <div className='total '>-</div>
                  </div>
                  <div className='totalLine'>
                    <label className='productPriceInfo'>Shipping:</label>
                    <div className='total totalLineRed'>FREE</div>
                  </div>
                  <div className='totalLine'>
                    <label className='productPriceInfo totalLineRed'>
                     Promo:
                    </label>
                    <div className='total totalLineRed'>
                      -${cartSummary.withPromoPrice}
                    </div>
                  </div>
                  <div
                    className='totalLine sale'
                    style={{
                      paddingBottom: 0,
                      marginBottom: 15,
                      borderTop: '1px solid #ccc',
                    }}>
                    <label className='productPriceInfo'>Sale Price:</label>
                    <div className='total totalLineRed'>
                      {' '}
                      ${cartSummary.TOTAL}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ShippingScreen;
