/** @format */

import React, { useEffect } from 'react';
import { Grid, Typography, Button, Snackbar } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { customizeProduct, getBottomBars } from '../actions/blindActions';
import './menu.css';
import { Alert, AlertTitle } from '@material-ui/lab';
import LoadingOverlay from 'react-loading-overlay';
import ImageZoom from './ImageZoom';

const CustomizeBottomBar = ({
  page,
  productInfo,
  optionInfo,
  switchPage,
  setSwitchPage,
  renderHem,
  setRenderHem,
  setDisabled,
}) => {
  var dispatch = useDispatch();

  const shadeTitle = productInfo.Type + ' Shade';

  const styles = {
    divider: {
      width: '100%',
      margin: '1rem auto',
    },
    leftHeader: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    items: {
      margin: '1rem',
      padding: '2rem',
    },
    image: {
      width: '150px',
      height: '150px',
      marginBottom: '2rem',
      objectFit: 'cover',
    },
    atcButton: {
      // background: '#ebebeb',
      minWidth: '10rem',
      marginRight: '1rem',
      marginTop: '1rem',
    },
    sgBoxHemBox: {
      display: 'inline-block',
      width: '40%',
      borderRadius: '10%',
      transition: 'transform .5s',
      position: 'relative',
      cursor: 'pointer',
      margin: '0.5em 1em',
    },
    sgBoxRight: {
      textAlign: 'right',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    checkText: {
      margin: 'auto',
      fontSize: '1em',
      textTransform: 'capitalize',
      width: '70%',
      paddingBottom: '0.2em',
      display: 'inherit',
    },
    sgBoxLeft: {
      textAlign: 'left',
      display: 'inline-block',
      width: '50%',
      verticalAlign: 'middle',
    },
    sgBoxImageHem: {
      width: '100%',
      borderRadius: '10%',
      transition: 'transform .5s',
      position: 'relative',
      zIndex: 0,
    },
    colorLabel: {
      display: 'inline-block',
      width: '5em',
      height: '1.3em',
      textOverflow: 'clip',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      verticalAlign: 'sub',
      border: '#3f3f3f 1px solid',
      borderRadius: '3px',
      boxSizing: 'border-box',
      padding: '0',
    },
    colorRadio: {
      display: 'none',
      fontFamily: 'Neue_55',
      fontSize: '12pt',
      lineHeight: 1.15,
      margin: '0 10px',
      overflow: 'visible',
      border: 'none',
      maxWidth: '300px',
      height: '30px',
      fontStyle: 'italic',
      paddingLeft: '1%',
      border: 'none',
      width: 'auto',
      height: 'auto',
      margin: '0 10px',
      fontSize: '11px',
      fontFamily: 'Neue_55',
      fontStyle: 'italic',
      paddingLeft: '1%',
    },
  };

  var [bottomBar, setBottomBar] = useState(productInfo.Bottom_Bar);
  var [bottomBarColor, setBottomBarColor] = useState(
    productInfo.Bottom_Bar_Color,
  );
  var [data, setData] = useState();
  var [loading, setLoading] = useState(true);
  var [invalid, setInvalid] = useState(false);
  var [errorToast, setErrorToast] = useState(false);
  var [selectedWrapType, setSelectedWrapType] = useState(
    productInfo.Hem_Wrap_Type,
  );

  const cancelToken = new AbortController();

  useEffect(() => {
    if (switchPage && page.current == 3) {
      var t3 = document.getElementById('t3');
      t3.style = styles.darkText;

      var toHide = document.getElementById('t' + switchPage);

      toHide.style.color = '#9b9b9b';
      toHide.style.fontSize = '0.8rem';
      setSwitchPage(0);
    }
  }, [switchPage]);

  useEffect(() => {
    setDisabled(loading);
  }, [loading]);

  const handleBottomBar = (id) => {
    console.log(id);
    setBottomBar(id);
    if (id == 'Plain Hem') {
      setBottomBarColor('Standard');
    } else if (id != 'Plain Hem' && bottomBarColor == 'Standard') {
      setBottomBarColor('');
    }
    hemRender(id, bottomBarColor);
  };

  const hemRender = (id, color) => {
    var temp = '';
    if (selectedWrapType == 'Yes' && id.includes('Accubar')) temp = 'wab';
    else if (id.includes('Accubar')) temp = 'ab';
    else if (id.includes('Plain Hem')) temp = 'ph';
    else if (bottomBar.includes('Illusion')) temp = 'ill';
    else if (bottomBar.includes('Interlude')) temp = 'int';
    setRenderHem(temp + '/' + (id == 'Plain Hem' ? 'standard' : color));
  };

  const forwardPage = () => {
    if (
      !bottomBarColor ||
      (bottomBar != 'Plain Hem' && bottomBarColor == '-')
    ) {
      setErrorToast(true);
      return;
    }
    page.current = 4;
    dispatchDetails();
    var toChange = document.getElementById('bottombars');
    toChange.style.display = 'none';
    var t3 = document.getElementById('t3');

    t3.style.color = '#9b9b9b';
    t3.style.fontSize = '0.8rem';

    var t4 = document.getElementById('t4');
    t4.style = styles.darkText;

    toChange = document.getElementById('drive');
    toChange.style.display = 'block';
  };

  const previousPage = () => {
    page.current = 2;
    dispatchDetails();
    var toChange = document.getElementById('bottombars');
    toChange.style.display = 'none';
    var t3 = document.getElementById('t3');

    t3.style.color = '#9b9b9b';
    t3.style.fontSize = '0.8rem';

    var t2 = document.getElementById('t2');
    t2.style = styles.darkText;

    toChange = document.getElementById('fabric');
    toChange.style.display = 'block';
  };

  const handleBottomBarColor = (e) => {
    setErrorToast(false);
    setBottomBarColor(e.target.value);
    bottomBarColor = e.target.value;
    var temp = null;
    if (bottomBar.includes('Wrapped') && bottomBar.includes('Accubar'))
      temp = 'wab';
    else if (bottomBar.includes('Accubar')) temp = 'ab';
    else if (bottomBar.includes('Plain Hem')) temp = 'ph';
    else if (bottomBar.includes('Illusion')) temp = 'ill';
    else if (bottomBar.includes('Interlude')) temp = 'int';
    setRenderHem(
      temp + '/' + (bottomBar == 'Plain Hem' ? 'standard' : bottomBarColor),
    );
  };

  const dispatchDetails = () => {
    if (selectedWrapType == 'Yes' && !bottomBar.includes('Wrapped'))
      bottomBar = bottomBar.replace('Accubar', 'Front Wrapped Accubar');
    productInfo.Bottom_Bar_Color = bottomBarColor;
    productInfo.Bottom_Bar = bottomBar;
    productInfo.Hem_Wrap_Type = selectedWrapType;
    dispatch(
      customizeProduct({
        productInfo: {
          ...productInfo,
        },
        optionInfo: {
          ...optionInfo,
        },
      }),
    );
  };

  useEffect(() => {
    setLoading(true);
    showBottomBarOptions();
  }, [bottomBar, bottomBarColor, selectedWrapType]);

  useEffect(() => {
    return () => {
      cancelToken.abort();
    };
  }, [cancelToken]);

  const showBottomBarOptions = () => {
    getBottomBars(
      shadeTitle.toLowerCase(),
      bottomBar,
      productInfo.defaultBottomBar,
      cancelToken,
    ).then((res) => {
      console.log(res);

      if (bottomBarColor == 'Dark Bronze') bottomBarColor = 'Anodized';

      if (bottomBarColor == 'NULL' && bottomBar == 'Plain Hem')
        bottomBarColor = 'Standard';

      if (res.length != 0 && !res.hasOwnProperty('type')) {
        handleInvalidError(0);

        for (let i = 0; i < res.length; i++) {
          for (let j = 0; j < res[i].image.length; j++) {
            res[i].image[j] = res[i].image[j].replace('image/', 'images/');
          }

          if (res[i].hem_type == 'Sb Deluxe Flat') {
            res[i].hem_type = 'Deluxe Flat';
          }
        }

        res = res.filter((index) => {
          return !index.hem_type.includes('Wrapped');
        });

        setData(res);
        setLoading(false);
      } else {
        handleInvalidError(1);
      }
    });
  };

  // 0 Means reset invalid to false and 1 means set invalid to true
  const handleInvalidError = (type) => {
    switch (type) {
      case 0: {
        if (invalid == true) {
          console.log('Reached 4');
          setInvalid(false);
          invalid = false;
          productInfo.invalid = false;
          dispatch(
            customizeProduct({
              productInfo: {
                ...productInfo,
              },
              optionInfo: optionInfo,
            }),
          );
        }
        break;
      }
      case 1: {
        if (invalid == false) {
          setInvalid(true);
          invalid = true;
          productInfo.invalid = true;
          dispatch(
            customizeProduct({
              productInfo: {
                ...productInfo,
              },
              optionInfo: optionInfo,
            }),
          );
        }
        setData(null);
        break;
      }
    }
  };

  return (
    <>
      {/* 3. Bottom Bar */}

      <Grid
        id='bottombars'
        visible='none'
        style={page.current == 3 ? { display: 'block' } : { display: 'none' }}
        spacing={1}
        onHide={switchPage && dispatchDetails()}>
        <LoadingOverlay
          active={loading}
          spinner
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'rgba(255, 255, 255, 0.5)',
            }),
            spinner: (base) => ({
              ...base,
              width: '100px',
              '& svg circle': {
                stroke: '#ef4036',
              },
            }),
          }}>
          {invalid ? null : (
            <>
              <Grid spacing={1}>
                {/* A list of images and label under them in a row */}
                <p style={styles.leftHeader}>3. Select Bottom Bar</p>
                <hr className='divider' />
                <Grid
                  container
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='center'>
                  {data
                    ? data.map((index) => {
                        return (
                          <>
                            {/* //remove selected and set selected */}
                            <div
                              className={
                                index.hem_type.toLowerCase() ==
                                bottomBar.toLowerCase().replace(' wrapped', '')
                                  ? 'featureOption selected optionsBoxMainValance'
                                  : 'featureOption optionsBoxMainValance'
                              }
                              onClick={() => {
                                handleBottomBar(index.hem_type);
                              }}>
                              {index.hem_type.toLowerCase() ==
                                bottomBar
                                  .toLowerCase()
                                  .replace(' wrapped', '') && (
                                <span
                                  className='featureOptioncheckmark'
                                  style={{ display: 'block' }}></span>
                              )}
                              <div
                                className='option feature optionFeatures'
                                style={{ height: '132.406px' }}>
                                <div
                                  className='image optionsFeatures'
                                  style={{
                                    backgroundImage: `url(${encodeURI(
                                      index.image[0].toLowerCase(),
                                    )})`,
                                  }}
                                  src={index.image[0]}>
                                  <ImageZoom
                                    imageUrl={encodeURI(
                                      index.image[0].toLowerCase(),
                                    )}
                                  />
                                  <div className='overlay'></div>
                                </div>
                              </div>

                              <div className='featureText withMediaIcons'>
                                <div className='featureoptionradio' style={{}}>
                                  <div
                                    className='customRadio radioFeatureButton'
                                    style={{ float: 'left', left: '-2px' }}>
                                    <span className='radio selected'>
                                      <span className='checked'></span>
                                    </span>
                                    <input
                                      defaultChecked='checked'
                                      groupname='Feature1320'
                                      id='SelectedProductFeatureOptions3743'
                                      name='AvailableFeatures[9].FeatureOptions[2].Selected'
                                      type='checkbox'
                                      defaultValue='true'
                                    />
                                    <input
                                      name='AvailableFeatures[9].FeatureOptions[2].Selected'
                                      type='hidden'
                                      defaultValue='false'
                                    />
                                    <input
                                      id='AvailableFeatures_9__FeatureOptions_2__OriginallySelected'
                                      name='AvailableFeatures[9].FeatureOptions[2].OriginallySelected'
                                      type='hidden'
                                      defaultValue='True'
                                    />
                                  </div>
                                  <div
                                    style={{}}
                                    className='featureOptionNamelbl'>
                                    <label
                                      htmlFor='SelectedProductFeatureOptions3743'
                                      className='featureOptionName'
                                      data-featuretitle='Curved Cassette Valance'>
                                      {index.hem_type}
                                      <br />
                                      <b>Free</b>
                                    </label>
                                  </div>
                                </div>
                                <div style={{ float: 'right', paddingTop: 6 }}>
                                  <span
                                    className='helpTooltip'
                                    style={{ float: 'right' }}
                                    id='ttIcon_20363'>
                                    ?
                                  </span>
                                  <div id='Tooltip_20363' className='tooltip'>
                                    <h5>Curved Cassette Valance</h5>
                                    The curved cassette is constructed from
                                    high-grade aluminum in a matte finish.
                                    Available in White, Linen, Anodized, Brown
                                    or Black.
                                    <br />
                                    <br />
                                    The curved cassette with fabric wrap has the
                                    same fabric as the shade wrapped around the
                                    front of the cassette. The end caps are
                                    available in White, Linen, Anodized, Brown
                                    or Black.
                                  </div>
                                </div>

                                {index.hem_type != 'Plain Hem' && (
                                  <div>
                                    <span className='feature_name'>
                                      Select Colour
                                    </span>
                                    <select
                                      className='dd ddcommon borderRadius'
                                      value={
                                        index.hem_type.toLowerCase() ==
                                        bottomBar
                                          .toLowerCase()
                                          .replace('front wrapped ', '')
                                          ? bottomBarColor
                                          : ''
                                      }
                                      onChange={(e) => handleBottomBarColor(e)}>
                                      <option className='ddlabel' value=''>
                                        --Select--
                                      </option>
                                      {index.colour.map((color) => {
                                        return (
                                          <>
                                            <option
                                              value={color}
                                              style={{
                                                backgroundColor:
                                                  color == 'Anodized'
                                                    ? '#d3d2ce'
                                                    : color == 'Brown'
                                                    ? '#665339'
                                                    : color,
                                                color:
                                                  color == 'Black'
                                                    ? 'white'
                                                    : color == 'Brown'
                                                    ? 'white'
                                                    : 'black',
                                              }}>
                                              {color}
                                            </option>
                                          </>
                                        );
                                      })}
                                    </select>
                                  </div>
                                )}
                                {index.hem_type.includes('Accubar') && (
                                  <div className='subFeature horizontalFeature'>
                                    <div className='subTitle featuresTitle'>
                                      <div className='featureName'>
                                        <span className='feature_name'>
                                          Fabric Wrapped: <b>Free</b>
                                        </span>
                                        <div
                                          style={{
                                            float: 'right',
                                            position: 'relative',
                                          }}></div>

                                        <select
                                          className='dd ddcommon borderRadius'
                                          value={
                                            bottomBar
                                              .toLowerCase()
                                              .replace('front wrapped ', '') ==
                                            index.hem_type.toLowerCase()
                                              ? selectedWrapType
                                              : ''
                                          }
                                          name='hem_type-options'
                                          onChange={(e) => {
                                            setSelectedWrapType(e.target.value);
                                            setBottomBar(
                                              bottomBar.replace(
                                                'Accubar',
                                                'Front Wrapped Accubar',
                                              ),
                                            );

                                            bottomBar = bottomBar.replace(
                                              'Accubar',
                                              'Front Wrapped Accubar',
                                            );
                                          }}
                                          tabIndex={-1}>
                                          <option
                                            className='ddlabel'
                                            value='No'>
                                            No
                                          </option>
                                          <option
                                            className='ddlabel'
                                            value='Yes'>
                                            Yes
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })
                    : ''}{' '}
                </Grid>
                {/* </Grid> */}
              </Grid>
              <Snackbar open={errorToast}>
                <Alert severity='error'>
                  <AlertTitle>Please select a bottom bar colour</AlertTitle>
                </Alert>
              </Snackbar>
            </>
          )}{' '}
          <Grid container direction='row'>
            <Grid item xs={6}>
              <Button id='back' onClick={previousPage} style={styles.atcButton}>
                Back
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                className='redButton'
                style={{
                  margin: '.5rem 0rem',
                  width: '10rem',
                  backgroundColor: '#ef4036',
                  color: '#fff',
                  marginTop: '1rem',
                }}
                onClick={forwardPage}>
                Next
              </Button>
            </Grid>
          </Grid>
        </LoadingOverlay>
      </Grid>
    </>
  );
};
export default CustomizeBottomBar;
