/** @format */

import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Grid,
  Hidden,
  Switch,
  Snackbar,
} from '@material-ui/core';
import TextField from '@mui/material/TextField';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  removeFromCart,
  saveCartSummary,
  updateProductQuantity,
  readPromo,
} from '../actions/cartActions';
import Menu3 from '../components/Menu3';
import { removeEmailFromCartMailChimp } from '../actions/userActions';

const styles = {
  disabledText: {
    color: '#9b9b9b',
    borderBottom: '1px solid #9b9b9b;',
    fontSize: '1.1rem',
    // fontFamily: 'Lato',
  },
  leftHeader: {
    color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '1.5rem',
    textAlign: 'left',
    // margin: '2rem 0 0 0',
  },
  heavyText: {
    fontWeight: 400,
  },
  topSpacer: {
    marginTop: '2rem',
  },
  panel: {
    background: '#e3e3e3d9',
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    textAlign: 'center',
  },
  couponCode: {
    color: '#000',
    fontSize: '1.5rem',
    textAlign: 'left',
    width: '100%',
  },
  // priceText: {
  //   fontSize: '1rem',
  //   margin: '.5rem 0',
  // },
  priceTextSave: {
    color: '#E61610',
  },
  priceTextSaveStrike: {
    fontSize: '1rem',
    margin: '.5rem 0',
    color: '#E61610',
    textDecoration: 'line-through',
  },
  hide: {
    visibility: 'hidden',
  },
  priceTotal: {
    fontSize: '1.2rem',
    margin: '.5rem 0',
    borderTop: '1px solid #000;',
  },
  priceSaving: {
    fontSize: '1.2rem',
    margin: '.5rem 0',
    color: '#E61610',
  },
  atcButton: {
    background: '#ebebeb',
    minWidth: '12rem',
    marginRight: '1rem',
    marginTop: '1rem',
  },
  divider: {
    width: '100%',
    margin: '1rem auto',
  },
  darkText: {
    color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '1.1rem',
    // fontFamily: 'Lato',
  },
  holder: {
    width: '95%',
  },

  headText: {
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'uppercase',
    padding: '.25rem 0',
    textAlign: 'left',
  },
  mainText: {
    fontWeight: 500,
    fontSize: '1rem',
    padding: '.2rem 0',
    color: '#403c3c',
  },
  subText: {
    fontWeight: 300,
    fontSize: '.9rem',
    padding: '.25rem 0',
    color: 'grey',
  },

  cartItem: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  imgContainer: {
    width: '100%',
  },
  msrpTag: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'Capitalize',
    textDecoration: 'line-through',
  },
  otherPirces: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'Capitalize',
  },
  ourTag: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'Capitalize',
    color: '#E61610',
  },
  msrPrice: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '2rem',
    textTransform: 'Capitalize',
    textDecoration: 'line-through',
  },
  ourPrice: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '2rem',
    textTransform: 'Capitalize',
    color: '#E61610',
  },
  removeButton: {
    border: 'none',
    fontWeight: 300,
    fontSize: '.9rem',
    padding: '.25rem 0',
    color: '#403c3c',
  },
  quantityField: {
    maxWidth: '27%',
    minWidth: '3rem',
    margin: '1rem 0 ',
  },
};

//Calculating Prices in cart with Number of products and Quantity of productsss
const calculateCartInfo = (items) => {
  const FINAL_MSRP = items.reduce((total, curr) => {
    return total + curr?.quantity * curr?.RETAIL_PRICE;
  }, 0);

  const FINAL_PRICE = items.reduce((total, curr) => {
    return total + curr?.quantity * curr?.OUR_PRICE;
  }, 0);

  const TOTAL = FINAL_PRICE.toFixed(2);
  const sav = -(FINAL_PRICE - FINAL_MSRP).toFixed(2);
  const SAVINGS = sav.toFixed(2);
  return { FINAL_MSRP, FINAL_PRICE, TOTAL, SAVINGS };
};

const CartScreen = ({ history }) => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userId = userInfo?.id;
  const userEmail = userInfo?.email;

  var FINAL_MSRP, FINAL_PRICE, TOTAL, SAVINGS;

  const [finalMsrpState, setFinalMsrpState] = useState(0);
  const [finalOurPriceState, setFinalOurPriceState] = useState(0);
  const [finalPriceState, setFinalPriceState] = useState(0);
  // const [finalTaxState, setFinalTaxState] = useState(0);
  const [totalState, setTotalState] = useState(0);
  const [savingsState, setSavingsState] = useState(0);
  const [promoCode, setPromoCode] = useState();
  const [promoUsed, setPromoUsed] = useState(false);
  const [promoMultiplier, setPromoMultiplier] = useState(1);
  const [promoFieldText, setPromoFieldText] = useState();
  const [withPromoPrice, setWithPromoPrice] = useState(0);
  const [showPromoPop, setShowPromoPop] = useState(false);
  const [promoMsg, setPromoMsg] = useState();

  const closePromoPop = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowPromoPop(false);
  };

  useEffect(() => {
    if (cartItems.length === 0 && userEmail) {
      removeEmailFromCartMailChimp(userEmail);
    }
    var { FINAL_MSRP, FINAL_PRICE, TOTAL, SAVINGS } = calculateCartInfo(
      cartItems.map((item) => item?.optionInfo),
    );
    setFinalMsrpState(FINAL_MSRP);
    setFinalPriceState(FINAL_PRICE);
    // setFinalTaxState(FINAL_TAX);
    setTotalState(TOTAL);
    setSavingsState(SAVINGS);
  }, [history, cartItems]);

  //Removing Item From cart
  const removeFromCartHandler = (itemId) => {
    dispatch(removeFromCart(itemId));
  };

  var temp = null;

  //For Checkout User Must Login
  const checkOutHandler = () => {
    let FINAL_MSRP = finalMsrpState.toFixed(2);
    // let FINAL_PRICE = (finalPriceState - promoMultiplier).toFixed(2);
    let FINAL_PRICE = finalPriceState.toFixed(2);
    let FINAL_TAX = 0;
    let TOTAL = totalState;
    let SAVINGS = savingsState;

    dispatch(
      saveCartSummary({
        FINAL_MSRP,
        FINAL_PRICE,
        FINAL_TAX,
        TOTAL,
        SAVINGS,
        withPromoPrice,
        promoCode,
      }),
    );
    history.push('/shipping');
  };

  //Change Quantity in Redux State
  const changeQuantity = (itemIndex, quantity) => {
    dispatch(updateProductQuantity(itemIndex, quantity));
  };

  const handlePromo = async (e) => {
    var res = await readPromo(promoCode, userId ? userId : -1);

    console.log(res);
    if (res.data.type == 'used_once') {
      setPromoFieldText('Max Uses Exceeded');
      setPromoMsg('Only one gift card code can be used per user.');
      setPromoUsed(false);
      setShowPromoPop(true);
      return;
    }
    switch (res.data[0]?.Expired) {
      case 1:
        setPromoFieldText('expired');
        setPromoMsg('expired promo');
        setPromoUsed(false);
        setShowPromoPop(true);
        break;
      case 0:
        setPromoMultiplier(res.data[0].val);
        setPromoCode(promoCode);
        let promo = res.data[0].val?.toFixed(2);
        let newTotal = (finalPriceState - promo).toFixed(2);
        let savings = (finalMsrpState - newTotal).toFixed(2);

        setWithPromoPrice(promo);
        setTotalState(newTotal);
        setSavingsState(savings);
        setPromoFieldText('');
        setPromoUsed(true);
        setPromoMsg('Promo Applied!');
        setShowPromoPop(true);
        //}

        break;
      default:
        setPromoUsed(false);
        setPromoFieldText('invalid');
        setPromoMsg('invalid promo');
        setShowPromoPop(true);
        break;
    }
  };

  const valanceRender = (name, color) => {
    switch (name.toLowerCase()) {
      case 'sb compact curved cassette':
        name = 'ccc';
        break;
      case 'open roll':
        name = 'or';
        break;
      case '3" fascia':
        name = '3f';
        break;
      case '4" fascia':
        name = '4f';
        break;
      case 'sb compact curved casette':
      case 'curved cassette s':
        name = 'd8';
        break;
      case 'curved cassette m':
        name = 'd12';
        break;
      case 'curved cassette l':
        name = 'd16';
        break;
      case 'pvc valance':
        name = 'pvc';
        break;
      case 'sb 3" fascia':
        name = '3f';
        break;
      case 'sb 4" fascia':
      case 'sb 4" wrapped fascia':
        name = '4f';
        break;
      case 'sb curved cassette':
      case 'sb wrapped curved cassette':
        name = 'd12';
        break;
      default:
        name = 'or';
        break;
    }
    return 'render/valance/' + name + '/' + color + '.png';
  };

  return (
    <>
      <Menu3 />
      <Container>
        {/* <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          style={styles.panel}
          spacing={0}>
          <Grid item sm={3} style={styles.darkText}>
            <p>1. Shopping Cart</p>
          </Grid>
          <Hidden smDown>
            <Grid item md={3} style={styles.disabledText}>
              <p>2. Shipping Details</p>
            </Grid>
          </Hidden>

          <Hidden smDown>
            <Grid item md={3} style={styles.disabledText}>
              <p>3. Place Order</p>
            </Grid>
          </Hidden>
           <Hidden smDown>
            <Grid item md={3} style={styles.disabledText}>
              <p>4. Payment </p>
            </Grid>
          </Hidden> 
        </Grid> */}
        <div className='stepper-wrapper'>
          <div
            className='stepper-item active'>
            <div className='step-counter'>1</div>
            <div className='step-name'>Shopping Cart</div>
          </div>
          <div
            className='stepper-item'>
            <div className='step-counter'>2</div>
            <div className='step-name'>Shipping Details</div>
          </div>
          <div
            className='stepper-item'>
            <div className='step-counter'>3</div>
            <div className='step-name'>Place Order</div>
          </div>
        </div>


      <Grid container direction='row' spacing={2}>
        <Grid item sm={12} md={8}>
          <p style={styles.leftHeader}>Shopping Cart</p>
          <hr style={styles.divider} />
          {cartItems.length === 0 ? (
            <h2>Your cart is Empty</h2>
          ) : (
            <>
              {cartItems.map((item, index) => (
                <Grid container style={styles.cartItem} key={index}>
                  <Grid item xs={3}>
                    {item.productInfo?.Program.includes('Custom') ? (
                      <div
                        class='render-wrapper'
                        style={{
                          height: 'auto',
                        }}>
                        <div id='cContainer'>
                          <img
                            id='render-bg'
                            style={styles.holder}
                            src='/images/render/render_base.png'
                          />
                          <img
                            id='render-fab'
                            className='holder'
                            style={styles.holder}
                            // src='/images/render/rustica opaque black/fabric.png'
                            src={
                              '/images/render/' +
                              item.productInfo?.Fabric.toLowerCase() +
                              ' ' +
                              item.productInfo?.SG_Color.toLowerCase() +
                              '/fabric.png'
                            }
                          />
                          <img
                            id='render-val'
                            style={styles.holder}
                            src={
                              '/images/' +
                              valanceRender(
                                item.productInfo?.Valance,
                                item.productInfo?.Valance_Color,
                              ).toLowerCase()
                            }
                          // src={'/images/render/valance/'+renderValance+'.png'}
                          />
                          <img
                            id='render-val-insert'
                            style={styles.holder}
                            src={
                              '/images/' +
                              valanceRender(
                                item.productInfo?.Valance,
                                item.productInfo?.Valance_Color,
                              ).toLowerCase()
                            }
                          // src={'/images/render/valance/'+renderValance+'.png'}
                          />
                          <img
                            id='render-hem'
                            style={styles.holder}
                            // src='/images/render/option/hem/ph.png'
                            src={
                              '/images/render/hem/' +
                              (item.productInfo?.Bottom_Bar.includes(
                                'Wrapped',
                              ) &&
                                item.productInfo?.Bottom_Bar.includes('Accubar')
                                ? 'wab'
                                : item.productInfo?.Bottom_Bar.includes(
                                  'Accubar',
                                )
                                  ? 'ab'
                                  : item.productInfo?.Bottom_Bar.includes(
                                    'Plain Hem',
                                  )
                                    ? 'ph'
                                    : item.productInfo?.Bottom_Bar.includes(
                                      'Illusion',
                                    )
                                      ? 'ill'
                                      : item.productInfo?.Bottom_Bar.includes(
                                        'Interlude',
                                      )
                                        ? 'int'
                                        : 'null') +
                              '/' +
                              (item.productInfo?.Bottom_Bar == 'Plain Hem'
                                ? 'standard'
                                : item.productInfo?.Bottom_Bar_Color) +
                              '.png'
                            }
                          />
                          <img
                            id='render-hem-insert'
                            style={styles.holder}
                            // src='/images/render/empty.png'
                            src={
                              '/images/render/hem/' +
                              (item.productInfo?.Bottom_Bar.includes(
                                'Wrapped',
                              ) &&
                                item.productInfo?.Bottom_Bar.includes('Accubar')
                                ? 'wab'
                                : item.productInfo?.Bottom_Bar.includes(
                                  'Accubar',
                                )
                                  ? 'ab'
                                  : item.productInfo?.Bottom_Bar.includes(
                                    'Plain Hem',
                                  )
                                    ? 'ph'
                                    : item.productInfo?.Bottom_Bar.includes(
                                      'Illusion',
                                    )
                                      ? 'ill'
                                      : item.productInfo?.Bottom_Bar.includes(
                                        'Interlude',
                                      )
                                        ? 'int'
                                        : 'null') +
                              '/' +
                              (item.productInfo?.Bottom_Bar == 'Plain Hem'
                                ? 'standard'
                                : item.productInfo?.Bottom_Bar_Color) +
                              '.png'
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <img
                        src={`/images/programs/${item.productInfo?.Program}/window/${item.optionInfo?.selectedColor}.jpg`
                          .replaceAll('%', '')
                          .replaceAll(' ', '_')
                          .toLowerCase()}
                        style={styles.imgContainer}
                        alt='shade'
                      />
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <Grid
                      container
                      direction='column'
                      justifyContent='space-around'
                      alignItems='flex-start'>
                      <span style={styles.headText}>
                        {item.productInfo?.Program} | {item.optionInfo?.Type}{' '}
                        {item.optionInfo?.widthWhole}{' '}
                        {item.optionInfo?.widthFraction === '0/16'
                          ? ''
                          : item.optionInfo?.widthFraction}{' '}
                        x {item.optionInfo?.lengthWhole}{' '}
                        {item.optionInfo?.lengthFraction === '0/16'
                          ? ''
                          : item.optionInfo?.lengthFraction}{' '}
                      </span>
                      <span style={styles.subText}>
                        {' '}
                        <span style={styles.mainText}>Type : </span>
                        {item.productInfo?.Type}
                      </span>
                      <span style={styles.subText}>
                        {' '}
                        <span style={styles.mainText}>Drive : </span>{' '}
                        {item.productInfo?.Operation}{' '}
                        {item.productInfo?.position}
                      </span>
                      <span style={styles.subText}>
                        {' '}
                        <span style={styles.mainText}>Fabric : </span>{' '}
                        {item.productInfo?.Fabric} -{' '}
                        {item.optionInfo?.selectedColor}
                      </span>
                      <span style={styles.subText}>
                        {' '}
                        <span style={styles.mainText}>Valance : </span>{' '}
                        {item.productInfo?.Valance} -{' '}
                        {item.productInfo?.Valance_Color}
                      </span>
                      <span style={styles.subText}>
                        {' '}
                        <span style={styles.mainText}>
                          Bottom Bar :{' '}
                        </span>{' '}
                        {item.productInfo?.Bottom_Bar} -{' '}
                        {item.productInfo?.Bottom_Bar_Color}
                      </span>
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <Grid
                      container
                      direction='row'
                      justifyContent='space-around'>
                      {/* <span className='removePrice' style={styles.msrpTag}>
                          Retail Price
                        </span> */}
                      <span style={styles.msrpTag}>
                        ${item.optionInfo?.RETAIL_PRICE}
                      </span>
                    </Grid>

                    <Grid
                      container
                      direction='row'
                      justifyContent='space-around'>
                      {/* <span className='removePrice' style={styles.ourTag}>
                          Our Price
                        </span> */}
                      <span style={styles.ourTag}>
                        ${item.optionInfo?.OUR_PRICE}
                      </span>
                    </Grid>

                    <Grid
                      container
                      direction='row'
                      justifyContent='space-around'
                      alignItems='baseline'>
                      <TextField
                        id='outlined-number'
                        type='number'
                        size='small'
                        variant='outlined'
                        style={styles.quantityField}
                        value={item?.optionInfo?.quantity || 1}
                        onChange={(e) => {
                          var prunedVal =
                            e.target.value < 1 ? 1 : e.target.value;
                          e.target.value = prunedVal;
                          changeQuantity(index, parseInt(prunedVal, 10));
                        }}
                      />
                    </Grid>
                    <Grid
                      container
                      direction='row'
                      justifyContent='space-around'
                      alignItems='baseline'>
                      <Button
                        color='primary'
                        style={styles.removeButton}
                        onClick={() => removeFromCartHandler(index)}>
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </>
          )}
          {/* <Grid
              container
              direction='row'
              justifyContent='flex-start'
              alignItems='center'>
              <Link to={'/products'}>
                <Button style={styles.atcButton}>Back to Shopping</Button>
              </Link>

              <Button
                style={styles.atcButton}
                onClick={checkOutHandler}
                disabled={cartItems.length === 0}>
                Next
              </Button>
            </Grid> */}
        </Grid>
        <Grid item sm={12} md={4}>
          <p style={styles.leftHeader}>Summary</p>
          <hr style={styles.divider} />
          <div className='productTotalsInnerWrap'>
            <div id='productTotals' className=''>
              <div className='firstProductSection'>
                <div className='totalLine'>
                  <label className='productPriceInfo'>Retail Price:</label>
                  <div
                    className='total'
                    style={{ textDecoration: 'line-through' }}>
                    ${finalMsrpState.toFixed(2)}
                  </div>
                </div>
                <div className='totalLine'>
                  <label className='productPriceInfo totalLineRed'>
                    You Save 30%:
                  </label>
                  <div className='total totalLineRed'>${savingsState}</div>
                </div>
                <div className='totalLine'>
                  <label className='productPriceInfo'>Tax:</label>
                  <div className='total '>-</div>
                </div>
                <div className='totalLine'>
                  <label className='productPriceInfo'>Shipping:</label>
                  <div className='total totalLineRed'>FREE</div>
                </div>
                <div
                  className='totalLine sale'
                  style={{
                    paddingBottom: 0,
                    marginBottom: 15,
                    borderTop: '1px solid #ccc',
                  }}>
                  <label className='productPriceInfo'>Sale Price:</label>
                  <div className='total totalLineRed'> ${totalState}</div>
                </div>
                <div
                  className='totalLine'
                  style={promoUsed ? styles.priceTextSave : styles.hide}>
                  <label
                    className='productPriceInfo'
                    style={promoUsed ? styles.priceTextSave : styles.hide}>
                    Promo:
                  </label>
                  <div
                    className='total totalLineRed'
                    style={promoUsed ? styles.priceTextSave : styles.hide}>
                    {' '}
                    $-{withPromoPrice}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <TextField
            style={{ margin: '1rem 0rem', width: '18rem' }}
            id='basic'
            size='small'
            // variant='outlined'
            label='Enter your promo code'
            helperText={promoFieldText}
            error={promoFieldText}
            onChange={(e) => {
              setPromoCode(e.target.value);
            }}
          />

          <Button
            style={{
              margin: '1rem .5rem',
              width: '6rem',
              backgroundColor: '#ef4036',
              color: '#fff',
            }}
            variant='outlined'
            onClick={handlePromo}>
            Apply
          </Button>

          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'>
            <Link to={'/products'}>
              <Button
                style={{ margin: '1rem 0', width: '12rem' }}
                variant='outlined'>
                Back to Shopping
              </Button>
            </Link>
            <Button
              style={{
                margin: '1rem .5rem',
                width: '12rem',
                backgroundColor: '#ef4036',
                color: '#fff',
              }}
              variant='outlined'
              onClick={checkOutHandler}>
              Next
            </Button>
            {/* <Button
                style={styles.atcButton}
                onClick={checkOutHandler}
                disabled={cartItems.length === 0}>
                Next
              </Button> */}
          </Grid>
          {/* <Button style={styles.atcButton} onClick={handlePromo}>
            Apply
          </Button> */}
        </Grid>
      </Grid>
      <div style={styles.divider} />
      <Snackbar
        open={showPromoPop}
        autoHideDuration={6000}
        onClose={closePromoPop}>
        <Alert
          onClose={closePromoPop}
          severity={promoUsed ? 'success' : 'error'}>
          <AlertTitle>{promoMsg}</AlertTitle>
        </Alert>
      </Snackbar>
    </Container>
    </>
  );
};

export default CartScreen;
