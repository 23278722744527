/** @format */

import React, { useEffect } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getProgramsByCollection } from '../actions/productActions';
import Product from '../components/Product';
import { useParams } from 'react-router-dom';

const styles = {
  heavyText: {
    fontWeight: 400,
  },
  lightText: {
    fontWeight: 300,
  },
  divider: {
    width: '100%',
    borderTop: '1px solid black',
    margin: '1rem auto',
    textAlign: 'center',
  },
  widthCorrector: {
    maxWidth: '100%',
  },
  image: {
    width: '70%',
  },
  imageContainer: {
    width: '100%',
    margin: 'auto',
  },
  spacerTop: {
    marginTop: '1rem',
    textAlign: 'center',
  },
  dividerSmall: {
    width: '80%',
    borderTop: '1px solid',
    borderColor: 'rgb(96, 96, 96, 0.4)',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
};

const CollectionsScreen = ({ match }) => {
  const dispatch = useDispatch();
  const { products, loading } = useSelector((state) => state.products);

  const { collection } = useParams();
  useEffect(() => {
    let decodedValue = null;
    try {
      decodedValue = decodeURI(collection);
    } catch (URIError) {
      decodedValue = collection;
    }
    dispatch(getProgramsByCollection(decodedValue));
  }, [dispatch, collection]);

  return (
    <Container>
      <Typography variant='h5' style={styles.spacerTop}>
        {match.params.collection
          ? match.params.collection
          : 'Missing Collection'}
      </Typography>
      <hr style={styles.divider} />
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'>
        <Grid container item xs={12} sm={6}>
          <div style={styles.imageContainer}>
            <img
              style={styles.image}
              src='/images/cards/roman_office.png'
              alt='shade'
            />
          </div>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <div style={styles.imageContainer}>
            <Typography>
              {match.params.Collection_Description
                ? match.params.Collection_Description
                : 'The Dark Side features Blackout shades. All fabrics in the collection are opaque with white backing to completely block the sun and darken the room. Enjoy better sleep by blocking the sun light, moonlight, or street lights. The simplicity of the shades and solid colour pallets gives you a clean canvas from which to accessorize your room.'}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <hr style={styles.divider} />
      <Grid
        container
        direction='row'
        justifyContent='center'
        // alignItems="center"
        alignItems='flex-start'
        style={styles.widthCorrector}
        spacing={6}>
        {!loading &&
          products?.map((product, index) => {
            return (
              <Grid item xs={10} sm={4} key={index}>
                <Product product={product} />
              </Grid>
            );
          })}
      </Grid>
      {/* <hr style={styles.divider} /> */}
    </Container>
  );
};

export default CollectionsScreen;
