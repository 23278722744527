/** @format */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Container, Grid, Typography } from '@material-ui/core';
import { getAllBestSeller, getAllNewArrival } from '../actions/productActions';
import Product from '../components/Product';
import Menu3 from '../components/Menu3';

const styles = {
  heavyText: {
    fontWeight: 400,
  },
  lightText: {
    fontWeight: 300,
  },
  divider: {
    width: '100%',
    // zIndex: 1,
    borderTop: '1px solid black',
    margin: '2rem auto',
    textAlign: 'center',
    // marginLeft: 0,
  },
  widthCorrector: {
    // margin: '1rem 0',
    // overflow: 'None'
    maxWidth: '100%',
  },
  image: {
    width: '80%',
    // position: 'relative',
    // bottom: 0,
  },
  imageContainer: {
    width: '100%',
    margin: 'auto',
  },
  spacerTop: {
    marginTop: '3rem',
    textAlign: 'center',
  },
  dividerSmall: {
    width: '80%',
    // zIndex: 1,
    // borderTop: '1px solid #e5e5e5',
    borderTop: '1px solid',
    borderColor: 'rgb(96, 96, 96, 0.4)',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
};

const PicksScreen = () => {
  const dispatch = useDispatch();
  const { loading, products } = useSelector((state) => state.products);
  const [pickName, setPickName] = useState('');

  const { pick } = useParams();

  useEffect(() => {
    if (pick === 'a') {
      setPickName('New Arrivals');
      dispatch(getAllNewArrival());
    } else {
      setPickName('Best Seller');
      dispatch(getAllBestSeller());
    }
  }, [dispatch, pick]);

  return (
    <Container>
      <Menu3/>
      <Typography variant='h4' style={styles.spacerTop}>
        {pickName ? pickName : 'No Pick'}
      </Typography>
      <hr style={styles.divider} />
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        style={styles.widthCorrector}
        spacing={6}>
        {!loading &&
          products?.map((product, index) => {
            return (
              <Grid item xs={10} sm={4} key={index}>
                <Product product={product} />
              </Grid>
            );
          })}
      </Grid>
      {/* <hr style={styles.divider} /> */}
    </Container>
  );
};

export default PicksScreen;
