import axios from 'axios';

const apiPath = `${process.env.REACT_APP_OCS_API}`;

export default class OCSApi {
  static makeUrl(path) {
    return encodeURI(apiPath + path);
  }

  //
  // Low-level calls
  //

  static get(path, data) {
    return axios({
      method: 'get',
      url: this.makeUrl(path),
      data: data,
    }).then((result) => {
      return result.status === 200 ? result.data : 'GET Call Failed';
    });
  }

  // Program API
  static getProgramByName(name) {
    return this.get(`programs/name/${name}`);
  }

  static getProgramByCollection(collection) {
    return this.get(`programs/collection/${collection}`);
  }

  static getProgramPickA() {
    return this.get(`programs/pick_a`);
  }

  static getProgramPickB() {
    return this.get(`programs/pick_b`);
  }
  static getProgramRecommended() {
    return this.get(`programs/recommended`);
  }

  static getRecommendedPick(query) {
    return this.get(`programs/recommended/${query}`);
  }

  static getAllRecommended() {
    return this.get(`programs`);
  }

  // Menu API
  static getMenuCoverings() {
    return this.get(`menus/coverings`);
  }

  static getMenuDecore() {
    return this.get(`menus/decore`);
  }

  static getMenuCollections() {
    return this.get(`menus/collections`);
  }

  static getMenuPicks() {
    return this.get(`menus/picks`);
  }

  static getSimiliarFabricType(query) {
    return this.get(`programs/fabric_type/${query}`);
  }

  //Register
}
