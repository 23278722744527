/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Typography } from '@material-ui/core';
import Menu3 from '../components/Menu3';

const styles = {
  divider: {
    width: '100%',
    margin: '1rem auto',
  },
  smallIcon: {
    width: '30%',
    aspectRatio: 1,
    margin: '1rem',
    objectFit: 'cover',
  },
  spacerTop: {
    margin: '1rem auto',
    textAlign: 'center',
  },
};

const HelpScreen = () => {
  const helpers = [
    {
      name: 'Fabric Selection Guide',
      icon: '/images/newHome/Fabric-Selection-Logo-120x120.png',
      link: 'productselection',
    },
    {
      name: 'Measuring Guides',
      icon: '/images/newHome/Measuring-Guide-Logo-120x120.png',
      link: '/measuring',
    },
    {
      name: 'Installation Guides',
      icon: '/images/newHome/Installation-Guides-120x120.png',
      link: '/installation',
    },
    {
      name: 'Shipping Information and Policy',
      icon: '/images/newHome/Shipping-Information-120x120.png',
      link: 'shippingpolicy',
    },
    {
      name: 'Return Policy',
      icon: '/images/newHome/Return-policy-120x120.png',
      link: 'returnpolicy',
    },
    {
      name: 'Warranty Documentation',
      icon: '/images/newHome/Warranty-policy-120x120.png',
      link: 'warranty',
    },
  ];

  return (
    <div>
     <Menu3/>
      <div className='container p-4'>
        <Typography variant='h5' style={styles.spacerTop}>
          Help is Here!
        </Typography>
        <hr style={styles.divider} />
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'>
          {helpers.map((item, index) => {
            return (
              <Grid item xs={10} sm={4} key={index} style={styles.spacerTop}>
                <Link
                  key={index}
                  to={{
                    pathname: `${item.link}`,
                  }}>
                  <img
                    src={item.icon}
                    alt={item.name}
                    style={styles.smallIcon}
                  />
                  <Typography variant='h6'>{item.name}</Typography>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default HelpScreen;
