/** @format */

import { addToCart } from '../../actions/cartActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  db,
  DBStart,
  addImageToDb,
  DBReset,
  removeImageFromDb,
  storeDB,
} from './DexieDB';
import Dexie from 'dexie';
export const addToCartHandler = (dispatch, wallpaperOBJ, myStamp, readDB) => {
  const dummyInfo = {
    Program_id: 1,
    Program: 'wallpaper',
    Fabric_Type: ' ',
    Price_Range: ' ',
    Type: ' ',
    Operation: ' ',
    Colour_Family: ' ',
    Price_Group: ' ',
    Fabric_Code: null,
    Fabric: ' ',
    SG_Color: ' ',
    Discount: 0.3,
    Chain_Color: ' ',
    Valance: ' ',
    Valance_Color: '-',
    Bottom_Bar: ' ',
    Bottom_Bar_Color: ' ',
    Pick_A: null,
    Pick_B: ' ',
    Recommended: ' ',
    Recommended_Desc: ' ',
    Recommended_photo: ' ',
    Collection: ' ',
    Min_Width: 18,
    Max_Width: 100,
    Min_Height: 36,
    Max_Height: 120,
    Description: ' ',
    Valance_info: ' ',
    BottomBar_info: ' ',
    Control_info: ' ',
    Thumbnail_Description: ' ',
    Design_Features: ' ',
    Inspiration: ' ',
    Collection_Description: ' ',
  };

  var finalWidth = parseInt(wallpaperOBJ.dims.width);
  var extra = finalWidth % 53;
  if (extra != 0) {
    finalWidth = finalWidth + (53 - extra);
  }
  console.log(finalWidth)
  var h = parseInt(wallpaperOBJ.dims.height);
  var finalPrice = (((finalWidth * h)/144)*14).toFixed(2);

  dispatch(
    addToCart({
      productInfo: { ...dummyInfo },
      optionInfo: {
        widthWhole: wallpaperOBJ.dims.width,
        lengthWhole: wallpaperOBJ.dims.height,
        widthFraction:"",
        lengthFraction:"",
        selectedColor: 'custom',
        measure: null,
        quantity: 1,
        OUR_PRICE: finalPrice,
        RETAIL_PRICE: finalPrice * 1,

      },
      wallPaperInfo: wallpaperOBJ,
    }),
  );
  storeDB(myStamp, readDB);
};
