import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { English } from '../localization/strings'

const styles = ({
    divider: {
        width: '100%',
        margin: '2rem auto',
    },
    heavyText: {
        fontWeight: 400,
    },
    lightText: {
        fontWeight: 300,
    },
    paragraph: {
        fontSize: '1.25rem',
        margin: '1rem 3rem 3rem',
        fontWeight: 400,
        lineHeight: '2.25rem'
    },
    topSpacer: {
        marginTop: '2rem',
    },
    image: {
        width: '100%',
        // position: 'relative',
        // bottom: 0,
    },
    imageContainer: {
        width: '70%',
        margin: 'auto',
    },

    smallImage: {
        width: '100%',
        margin: 'auto',
    },
    smallText: {
        width: '80%',
        margin: '1rem auto',
        lineHeight: '2rem',
    },
    infoContainer: {
        margin: '3rem auto'
    },
});


const EnvironmentScreen = () => {
    const language = English;
    return (
        <Container>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                {/* Environmental Commitment */}
                <Typography variant="h4" color="textSecondary" >Environmental Commitment</Typography>
                <hr style={styles.divider} />
                <div style={styles.imageContainer}>
                    <img style={styles.image} src={'/images/windows/filler/1.jpg'} alt="shade" />
                </div>
                <Typography variant="body1" color="textSecondary" style={styles.paragraph}>{language.FillerText}</Typography>
                {/* 2 Image */}
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={styles.infoContainer}
                >
                    <Grid item xs={12} sm={4}>
                        <img style={styles.smallImage} src='/images/windows/filler/1.jpg' alt="shade" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h4">Safe Products</Typography>
                        <p style={styles.smallText}>{language.FillerText}</p>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <img style={styles.smallImage} src='/images/windows/filler/1.jpg' alt="shade" />
                    </Grid>

                </Grid>
                {/* 2 Text */}
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={styles.infoContainer}
                >
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h4">Artful Manufacturing</Typography>
                        <p style={styles.smallText}>{language.FillerText}</p>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <img style={styles.smallImage} src='/images/windows/filler/1.jpg' alt="shade" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h4">Keep it Local</Typography>
                        <p style={styles.smallText}>{language.FillerText}</p>
                    </Grid>

                </Grid>
            </Grid>
            {/* <hr style={styles.divider} /> */}
        </Container>
    )
}

export default EnvironmentScreen
