/** @format */

import { cloneElement, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import React, { Component, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { addListener, removeListener } from 'resize-detector';

//style={{height: (100/hI) + "%",width:'100%',borderWidth:'1px',borderStyle:'solid',position:'absolute',top:scaler*j+'%',left:0,backgroundColor:'white'}}

export default function Rulers(wI, hI, scalerPX, setScalerPXstate) {
  //console.log('scalerPX')
  //console.log(scalerPX)

  // console.log('ruler')
  // let wallViewHeightTotal = document.getElementById('wallViewTotal')
  // console.log('wallViewHeightTotal')
  // console.log(wallViewHeightTotal)
  // var scalerPX = 0//wallViewHeightTotal.clientHeight/hI

  // if(wallViewHeightTotal != null){
  //     scalerPX = wallViewHeightTotal.clientHeight/hI
  // }

  function makeMarkX(i) {
    let intDisplay = '';
    //if(i%6 == 0){
    // console.log('mark')
    // console.log(i)
    intDisplay = i;
    //}
    return (
      <label
        style={{
          width: scalerPX * 6 + 'px',
          height: '75%',
          position: 'absolute',
          top: 0,
          left: scalerPX * 6 * (i / 6) - scalerPX * 6 + 'px',
          fontFamily: 'lato',
          fontsize: '1rem',
          textAlign: 'right',
        }}>
        {intDisplay}''
      </label>
    );
  }

  function makeMarkY(j) {
    let intDisplay = '';
    // if((j)%6 == 0){
    //console.log('mark')
    //console.log(j)
    intDisplay = j;
    //console.log(intDisplay)
    //}

    return (
      <label
        style={{
          height: scalerPX * 6 + 'px',
          width: '75%',
          position: 'absolute',
          top: scalerPX * 6 * (j / 6) - scalerPX * 6 + 'px',
          left: 0,
          fontFamily: 'lato',
          fontsize: '1rem',
          writingMode: 'vertical-lr',
          textOrientation: 'mixed',
          textAlign: 'right',
        }}>
        {intDisplay}''
      </label>
    );
  }

  const scaler = 100 / hI;
  var retx = [];
  var retMarkX = [];
  for (var i = 0; i <= wI; i++) {
    if (i % 6 == 0 && i != 0) {
      retMarkX.push(makeMarkX(i));
    }
    //retMarkX.push(makeMarkX(i))

    retx.push(
      <div
        style={{
          width: scalerPX + 'px',
          height: '25%',
          borderWidth: '1px',
          borderStyle: 'solid',
          position: 'absolute',
          bottom: 0,
          left: scalerPX * i + 'px',
          backgroundColor: 'white',
          fontFamily: 'lato',
          fontsize: '1%',
        }}></div>,
      // <div style={{position:'relative',width: (scaler) + "vh",height:'100%',borderTopWidth:0,borderLeftWidth:0,borderBottomWidth:'10px',borderRightWidth:'10px',borderStyle:'solid',backgroundColor:'pink'}}></div>
    );
  }

  var rety = [];
  var retMarkY = [];
  for (var j = 0; j < hI; j++) {
    if (j % 6 == 0 && j != 0) {
      retMarkY.push(makeMarkY(j));
    }

    rety.push(
      <div
        style={{
          height: scalerPX + 'px',
          width: '25%',
          borderWidth: '1px',
          borderStyle: 'solid',
          position: 'absolute',
          top: scalerPX * j + 'px',
          right: 0,
          backgroundColor: 'white',
        }}></div>,
    );
  }

  var wallView = document.getElementById('wallView');
  if (wallView == null) {
    wallView = document.body.parentElement;
  } else {
    addListener(wallView, () => {
      setScalerPXstate(wallView.clientHeight / hI);
    });
  }

  return (
    <div>
      <div
        id='rulerx'
        style={{
          position: 'absolute',
          top: 0,
          left: '5%',
          height: '5%',
          width: scalerPX * wI + 'px',
          overflow: 'hidden',
          backgroundColor: 'white',
        }}>
        {retMarkX}
        {retx}
      </div>
      <div
        id='rulery'
        style={{
          position: 'absolute',
          top: '5%',
          left: 0,
          height: 95 + '%',
          width: '5%',
          backgroundColor: 'white',
        }}>
        {retMarkY}
        {rety}
      </div>
    </div>
  );
}
