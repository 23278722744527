import { Button, Container } from '@material-ui/core'
import React from 'react'
import Magazine from '../components/Magazine'
import Menu3 from '../components/Menu3'

const Test = () => {
  const iframe = '<iframe frameborder="0" allowtransparency="true" allowFullscreen="true" style="width: 100%; height: 880px; border: none;" src="https://online.visual-paradigm.com/share/book/ocs-magazine-2022-08-26-18kzn3hlw?p=1"></iframe>';
  return (
    <Container maxWidth="xl">
        <Menu3/>
        {/* Add a cross button */}
        <div dangerouslySetInnerHTML={{ __html: iframe }} />
        </Container>
  )
}

export default Test