
import { Grid } from "@material-ui/core";
import React, { Component} from "react";

export class Magazine extends Component {
    
    componentDidMount() {

        const script3 = document.createElement('script');
        script3.src = "/extras/modernizr.2.5.3.min.js";
        script3.async = true;
        document.body.appendChild(script3);
        
        const script2 = document.createElement('script');
        script2.src = "/extras/jquery.min.1.7.js";
        script2.async = true;
        document.body.appendChild(script2);

  
        const script4 = document.createElement('script');
        script4.src = "/lib/hash.js";
        script4.async = true;
        document.body.appendChild(script4);

        const script = document.createElement('script');
        script.src = "/magazine.js";
        script.async = true;
        document.body.appendChild(script);


       
        return () => {
            document.body.removeChild(script);
            document.body.removeChild(script2);
            document.body.removeChild(script3);
            document.body.removeChild(script4);
            
            
        }
    }

    render() {
        return (
                <div id="canvas">

                    <div className="zoom-icon zoom-icon-in"></div>

                    <div className="magazine-viewport">
                        <div className="container">
                            <div className="magazine">

                                <div ignore="1" className="next-button"></div>

                                <div ignore="1" className="previous-button"></div>
                            </div>
                        </div>
                    </div>

                </div>
           
        );
    }
}


export default Magazine
