/** @format */

import React, { useEffect, useState } from 'react';
import { Button, Container, Grid } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails, updateUserPassword } from '../actions/userActions';
import Message from '../components/Message';
import { Loader } from '../components/Loader';
import { getOrderDetails } from '../actions/orderActions';
import { useHistory } from 'react-router-dom';
import Menu3 from '../components/Menu3';

const styles = {
  heavyText: {
    fontWeight: 400,
    textAlign: 'center',
  },
  divider: {
    width: '100%',
    // borderTop: '1px solid black',
    marginTop: '1rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  leftHeader: {
    color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '1.5rem',
    textAlign: 'left',
    margin: '1rem 0',
  },
  button: {
    border: '1px solid black',
    background: 'transparent',
    color: 'black',
    backgroundColor: '#EEEBDD',
    textTransform: 'capitalize',
    minWidth: '10rem',
    hover: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    width: '20rem',
  },
};

const ProfileScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = 'null';

  const [password1ErrorText, setPassword1ErrorText] = useState();
  const [password2ErrorText, setPassword2ErrorText] = useState();
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const historyHook = useHistory();
  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    } else {
      dispatch(getUserDetails(userInfo, historyHook));
    }
  }, [history, dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    console.log('change pass');
    if (password !== confirmPassword) {
      setMessage('Passwords Do not Match');
    } else {
      //Dispatch update Profile
      console.log('change pass');
      console.log(
        password.match('[0-9]') != null &&
          password.length >= 6 &&
          password == confirmPassword,
      );
      if (
        password.match('[0-9]') != null &&
        password.length >= 6 &&
        password == confirmPassword
      ) {
        dispatch(updateUserPassword(userInfo, password, history));
      }
    }
  };

  return (
    <>
      <Menu3 />
      <Container>
        <p style={styles.leftHeader}>My Profile</p>
        <hr style={styles.divider} />
        {error && <Message variant='danger'>{error}</Message>}
        {loading && <Loader />}

        {error && <Message variant='danger'>{error}</Message>}
        <form onSubmit={submitHandler}>
          <Grid style={{ width: '20rem' }} container direction='column'>
            <TextField
              style={{ marginTop: '.5rem', width: '20rem' }}
              size='small'
              variant='outlined'
              id='outlined-multiline-flexible'
              label='Name'
              // required
              value={user?.name || ' '}
              type='name'
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              style={{ marginTop: '1.5rem', width: '20rem' }}
              size='small'
              variant='outlined'
              id='outlined-multiline-flexible'
              label='Email'
              // required
              value={user?.email || ' '}
              type='email'
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              style={{ marginTop: '1.5rem', width: '20rem' }}
              size='small'
              variant='outlined'
              id='outlined-multiline-flexible'
              label='Password'
              required
              //value={password}
              error={password1ErrorText}
              helperText={password1ErrorText}
              type='password'
              onChange={(e) => {
                let str = e.target.value;
                //console.log(str.match('[0-9]') != null && str.length>=6)
                if (str.match('[0-9]') != null && str.length >= 6) {
                  setPassword1ErrorText('');
                  setPassword(str);
                } else {
                  setPassword1ErrorText(
                    'passwords must be 6 characters long and contain numbers',
                  );
                }
                //setPassword(e.target.value)
              }}
            />
            <TextField
              style={{ marginTop: '1.5rem', width: '20rem' }}
              size='small'
              variant='outlined'
              id='outlined-multiline-flexible'
              label='Confirm Password'
              required
              //value={confirmPassword}
              error={password2ErrorText}
              helperText={password2ErrorText}
              type='password'
              onChange={(e) => {
                let str = e.target.value;
                //console.log(str.match('[0-9]') != null && str.length>=6)
                if (str == password) {
                  setPassword2ErrorText('');
                  setConfirmPassword(str);
                } else {
                  setPassword2ErrorText('passwords must match');
                }
                //setPassword(e.target.value)
              }}
            />
            <Link to={'/accountRecovery'}>
              <p>Forgot your password?</p>
            </Link>
            <Button
              className='redButton'
              style={{
                margin: '1rem 0rem',
                width: '10rem',
                backgroundColor: '#ef4036',
                color: '#fff',
              }}
              type='submit'
              variant='outlined'>
              Update
            </Button>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export default ProfileScreen;
