import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getOrderDetails } from '../actions/orderActions';
import { ORDER_PAY_RESET } from '../constants/orderConstants';
import {useHistory} from 'react-router-dom'

const usePaypal = ({ order, successPay }, match) => {
    const dispatch = useDispatch();
    const orderId = match.params.id;
    const setSdkReady = false
    const historyHook = useHistory()
    useEffect(() => {
        const addPaypalScript = async () => {
            const { data: clientId } = await axios.get('/api/config/paypal');
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `https://www.paypal.com/sdk/js`
            script.async = true;
            script.onload = () => {
                setSdkReady(true);
            };
            document.body.appendChild(script);
        };
        if (!order || successPay) {
            dispatch({ type: ORDER_PAY_RESET })
            dispatch(getOrderDetails(orderId,historyHook));
        } else if (!order.isPaid) {
            if (!window.paypal) {
                addPaypalScript();
            } else {
                setSdkReady(true);
            }
        }
    }, [dispatch, orderId, successPay, order, setSdkReady]);
    return setSdkReady;
};

export default usePaypal;
