/** @format */

import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { English } from '../../localization/strings';
import SGPDF from '../../pdf/OCS - Specifications & Programming - OCS Rechargeable Motors.pdf';
import SMPDF from '../../pdf/OCS - Installation Instructions - Curved Cassette.pdf';
import Menu3 from '../../components/Menu3';

const styles = {
  heavyText: {
    fontWeight: 400,
    textAlign: 'center',
  },
  subText: {
    textAlign: 'center',
    fontWeight: 300,
    width: '80%',
    margin: '1rem auto',
  },
  listStyle: {
    listStyle: 'none',
    fontSize: '1.5rem',
    fontWeight: '300',
    padding: 0,
    margin: '1.5rem auto',
  },
  itemSpacer: {
    margin: '1.25rem',
    textAlign: 'center',
    fontSize: '1.2rem',
  },
  divider: {
    width: '100%',
    borderTop: '1px solid black',
    marginTop: '1rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  button: {
    background: '#ebebeb',
    minWidth: '10rem',
    margin: '1rem',
  },

  card: {
    maxWidth: 300,
    margin: 'auto',
    marginBottom: '2rem',
    transition: '0.3s',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
    },
  },

  media: {
    width: '10rem',
    height: '10rem',
    alignItems: 'center',
    margin: '2.2rem',
  },
  content: {
    textAlign: 'center',
  },

  heading: {
    fontWeight: 'bold',
  },
  subheading: {
    lineHeight: 1.8,
  },
  avatar: {
    display: 'inline-block',
    border: '2px solid white',
    '&:not(:first-of-type)': {},
  },
};

const MotorGuideScreen = () => {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }
  let unused = window.onbeforeunload()
  const language = English;

  const cardDetails = [
    {
      type: 'Rechargable Motor',
      desc: 'Our open roll shades offer a pleasing minimalist design. Reverse roll conceals the tube and prevents the shade from contacting window cranks and other obstacles',
      image: '/images/in/motor.png',
      pdf: SGPDF,
    },
  ];

  return (
    <Container>
      <Menu3/>
      <Typography variant='h5' style={styles.heavyText}>
        {language.MotorguideTitle}
      </Typography>
      <hr style={styles.divider} />
      <Typography variant='h6' style={styles.subText}>
        {language.MotorguideText}
      </Typography>
      <Grid
        container
        spacing={2}
        direction='row'
        justify='flex-start'
        alignItems='flex-start'>
        {cardDetails.map((cardDetail) => (
          <Card style={styles.card}>
            <CardMedia style={styles.media}>
              <img
                src={cardDetail.image}
                alt={cardDetail.type}
                style={styles.media}
              />
            </CardMedia>
            <CardContent style={styles.content}>
              <Typography variant='h6' gutterBottom>
                {cardDetail.type}
              </Typography>
              <Typography variant='ception'>{cardDetail.desc}</Typography>
              <Divider light />
              <Button
                href={cardDetail.pdf}
                target='_blank'
                style={styles.button}>
                Download PDF
              </Button>
            </CardContent>
          </Card>
        ))}
      </Grid>
      {/* <hr style={styles.divider} /> */}
    </Container>
  );
};

export default MotorGuideScreen;
