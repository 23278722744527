/** @format */

import React, { Component } from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setWallPaperDims } from '../../actions/wallpaperActions';
import { createDoc, checkDoc, getDoc } from './localStorageSystem';
import { DBStart, DBReset } from './DexieDB';
import { db } from './DexieDB';
import pic1 from './pic1.jpg';

import {
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  Hidden,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Menu3 from '../Menu3';
export var newPaperBool = false;
export var loadIdMap;

const styles = {
  heavyText: {
    fontWeight: 400,
    textAlign: 'center',
  },
  subText: {
    fontWeight: 400,
    width: '80%',
    margin: '1rem auto',
    textAlign: 'center',
  },
  faq: {
    fontWeight: 300,
    width: '100%',
    // margin: '1rem auto',
    textAlign: 'center',
  },
  greyBut: {
    background: '#929292',
    textTransform: 'capitalize',
    color: 'white',
    width: '10rem',
    margin: '1.5rem',
  },
  listStyle: {
    listStyle: 'none',
    fontSize: '1.5rem',
    fontWeight: '300',
    padding: 0,
    margin: '1.5rem auto',
  },
  spacer: {
    margin: '3rem auto',
  },
  divider: {
    width: '100%',
    // zIndex: 1,
    borderTop: '1px solid black',
    marginTop: '1rem',
    marginBottom: '1rem',
    textAlign: 'center',
    // marginLeft: 0,
  },

  divider: {
    width: '100%',
    borderTop: '1px solid black',
    marginTop: '1rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  button: {
    marginTop: '2rem',
    border: '1px solid black',
    background: 'transparent',
    color: 'white',
    // color: '#F30000',
    // backgroundColor: 'white',

    backgroundColor: '#D41818',
    textTransform: 'capitalize',
    minWidth: '10rem',
    hover: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    width: '20rem',
  },
  shadowButton: {
    background: 'transparent',
    color: 'white',
    minWidth: '10rem',
    height: '1rem',
    margin: '0.1rem',
    border: '1px solid white',
    borderRadius: '15px',
    padding: '1rem',
    textTransform: 'capitalize',
  },
  card: {
    margin: 'auto',
    paddingBottom: '1rem',
    position: 'relative',
    width: '100%',
    display: 'flex',
  },
  image: {
    width: '100%',
    // height: '70vh',
    position: 'relative',
    bottom: 0,
    objectFit: 'cover',
  },
  shadow: {
    height: '20%',
    width: '100%',
    background: 'rgba(0,0,0,0.5)',
    position: 'absolute',
    bottom: 0,
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  caption: {
    fontFamily: 'Helvetica W01 Cn, Helvetica, Arial, sans-serif',
    width: '90%',
    fontWeight: 700,
    textAlign: 'center',
  },
  text: {
    // fontFamily: 'Lato',
    width: '80%',
    // fontWeight: 700,
    textAlign: 'center',
    marginTop: '3rem',
  },
  imageContainer: {
    width: '80%',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    // marginLeft: '6rem',
  },
  video: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    border: '1px solid rgb(212, 24, 24)',
  },
};

export default function MyForm() {
  //const nav = useNavigate();
  const [input, setInput] = useState({
    width: 0,
    height: 0,
    frameSet: null,
    colour: '#ef4136',
  });
  const [backgroundWordMenuState, setBackgroundWordMenuState] =
    useState('custom');
  const [backgroundPatternMenuState, setBackgroundPatternMenuState] =
    useState('custom');
  const [colourState, setColourState] = useState('#ef4136');
  const [frameState, setFrameState] = useState('rustic');
  //const proceed = () => nav('/TheForge')
  //const user = useSelector((state) => state.userLogin.userInfo.email);
  const user = 'user';

  const dispatch = useDispatch();
  const history = useHistory();
  function handleSubmit(e) {
    e.preventDefault();
    let update = {
      width: 0,
      height: 0,
      frameSet: 'rustic',
      colour: '#f0f0e9',
      strBack: '',
      patBack: 'bumpy',
      patColour: '#ffffff',
      // font: 'lato',
      doc: user,
    };

    //let frames
    newPaperBool = true;
    if (checkDoc('user')) {
      update = getDoc('user').redux.dimensions;
      newPaperBool = false;
    } else {
      createDoc(user);
    }

    setWallPaperDims(dispatch, update);

    DBStart(user);
    history.push('/wallpaperGenerator');
  }

  const qAndAs = [
    {
      question: ' Is there a limit to the size of wallpaper I can order?',
      answer:
        'Your wallpaper is custom made to your specific measurements so you can order enough for a room, a wall, or just a panel.',
    },
    {
      question: 'What size and format do my photos need to be?',
      answer:
        'When uploading your photos, make sure that your photos are high enough resolution to look good in 10x12" frames. Both Jpeg and Png files are accepted',
    },
    {
      question: 'How do I measure for my wallpaper?',
      answer:
        'Once you know where you want to apply the wallpaper - entire room, one whole wall, partial wall, inside a cabinet or bookshelf, sloped ceilings etc, just measure the widest width and highest height - this will account for any odd shaped applications. If you are wallpapering corners, add two inches per corner.',
    },
    {
      question: 'What size width does my wallpaper come in?',
      answer:
        'The wallpaper is 54" wide but the print area is 53", one is overlapped at each seam.  If your wallpaper print area exceeds 53", you will receive multiple panels in your exact height to meet your width requirements.  The wallpaper will be delivered in rolls, rolled together with the left side of the image as the most inner roll.  When you unroll it, the panels will be stacked so you can start installing from left to right, no need to spend time trying to match them up first, they are delivered in order.  ',
    },

    {
      question: 'How do you determine the price of the wallpaper?',
      answer:
        'The wallpaper is priced per 53" panel or less.  So if the width you want to cover is 65", you will be charged for two panels of 53".  But the wallpaper will be cut to size, you will not need to cut it.',
    },
    // {
    //   question: 'How do I install the wallpaper?',
    //   answer:
    //     'You can hire an expert wallpaper installer or you can install it yourself.  The wallpaper is peel and stick and the installation instructions can be seen here.  No matter who is installing, make sure your walls are clean and smooth before applying wallpaper.  ',
    // },
    {
      question: 'What is the wallpaper composition?',
      answer:
        'Our wallpaper is vinyl and flame retardant. The wallpaper is peel and stick. It can be removed up to 6 months from installation. It is ideal for flat surfaces.',
    },
    {
      question: 'Can I clean my wallpaper?',
      answer:
        'Yes, use  water to dampen a sponge to wipe clean.  Do not use harsh chemicals or abrasive cleaning materials as they may damage the printing.',
    },
  ];

  return (
    <>
      <Menu3 />
      <Container>
        {/* <Grid
        style={styles.widthCorrector}> */}
        <div style={styles.image}>
          <img style={styles.image} src={pic1} alt='shade' />
          <div style={styles.shadow}>
            <Typography variant='h4'>Create Your Wallpaper</Typography>
          </div>
        </div>
        {/* </Grid> */}
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          marginTop='2rem'>
          <Typography variant='h6' style={styles.text}>
            Hi I'm Vivian. This is my office and this feature wall displays
            wallpaper with pictures of family travels. A Sun Glow designer spent
            hours creating this for me but with the Off Cut Shades custom
            wallpaper creator, it could take you minutes to create your own.
          </Typography>
        </Grid>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'>
          <Button onClick={handleSubmit} style={styles.button}>
            Create a New Wallpaper
          </Button>
        </Grid>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'>
          <Typography variant='h5' style={styles.text}>
            FAQ
          </Typography>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'>
          <Grid
            container
            // direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={2}>
            {qAndAs.map((qAndA) => (
              <Grid item xs={8}>
                <Accordion>
                  <AccordionSummary>
                    <Typography variant='h6' style={styles.subText}>
                      {qAndA.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant='h6' style={styles.faq}>
                      {qAndA.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
            <Grid item xs={8}>
              <Accordion>
                <AccordionSummary>
                  <Typography variant='h6' style={styles.subText}>
                    How do I install the wallpaper?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant='h6' style={styles.faq}>
                    You can hire an expert wallpaper installer or you can
                    install it yourself. The wallpaper is peel and stick and the
                    installation instructions can be seen here. No matter who is
                    installing, make sure your walls are clean and smooth before
                    applying wallpaper.
                  </Typography>
                </AccordionDetails>
                <Grid style={styles.imageContainer}>
                  <video controls muted style={styles.video}>
                    <source
                      src='https://filedn.com/lkm5O5AzyMUQoB4pAxPAElH/OCS/installation/OCS%20Wallpaper%20Installation.mp4'
                      type='video/mp4'></source>
                  </video>
                </Grid>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
        {/* <hr style={styles.divider} /> */}
      </Container>
    </>
  );
}
