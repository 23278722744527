// All Products
export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';

//Single Product
export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST';
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS';
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL';

//Pick_a Product
export const PRODUCT_PICK_A_REQUEST = 'PRODUCT_PICK_A_REQUEST';
export const PRODUCT_PICK_A_SUCCESS = 'PRODUCT_PICK_A_SUCCESS';
export const PRODUCT_PICK_A_FAIL = 'PRODUCT_PICK_A_FAIL';

//Get Price from Table
export const PRODUCT_GET_PRICE_REQUEST = 'PRODUCT_GET_PRICE_REQUEST';
export const PRODUCT_GET_PRICE_SUCCESS = 'PRODUCT_GET_PRICE_SUCCESS';
export const PRODUCT_GET_PRICE_FAIL = 'PRODUCT_GET_PRICE_FAIL';

export const PRODUCT_RECOMMENDED_REQUEST = 'PRODUCT_RECOMMENDED_REQUEST';
export const PRODUCT_RECOMMENDED_SUCCESS = 'PRODUCT_RECOMMENDED_SUCCESS';
export const PRODUCT_RECOMMENDED_FAIL = 'PRODUCT_RECOMMENDED_FAIL';
export const CUSTOMIZE_PRODUCT = 'CUSTOMIZE_PRODUCT';
