/** @format */

import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { English } from '../../localization/strings';
import Menu3 from '../../components/Menu3';

const styles = {
  heavyText: {
    fontWeight: 400,
    textAlign: 'center',
  },
  subText: {
    fontWeight: 300,
    width: '80%',
    margin: '.5rem auto',
    textAlign: 'center',
  },
  greyBut: {
    background: '#929292',
    textTransform: 'capitalize',
    color: 'white',
    width: '10rem',
    margin: '1.5rem',
  },
  listStyle: {
    listStyle: 'none',
    fontSize: '1.5rem',
    fontWeight: '300',
    padding: 0,
    margin: '1.5rem auto',
  },
  spacer: {
    margin: '3rem auto',
  },
  divider: {
    width: '100%',
    borderTop: '1px solid black',
    marginTop: '1rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  button: {
    background: '#ebebeb',
    minWidth: '10rem',
    margin: '1rem',
  },

  card: {
    maxWidth: 300,
    margin: 'auto',
    marginBottom: '2rem',
    transition: '0.3s',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
    },
  },

  media: {
    width: '10rem',
    height: '10rem',
    alignItems: 'center',
    margin: '2.2rem',
  },
  content: {
    textAlign: 'center',
  },
  heading: {
    fontWeight: 'bold',
  },
  subheading: {
    lineHeight: 1.8,
  },
  avatar: {
    display: 'inline-block',
    border: '2px solid white',
  },

  imageContainer: {
    width: '70%',
    margin: 'auto',
  },

  smallImage: {
    width: '100%',
    margin: 'auto',
  },
  align: {
    alignSelf: 'center',
    textAlign: 'center',
  },
};

const MeasuringGuideScreen = () => {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  let unused = window.onbeforeunload();
  const language = English;

  const cardDetails = [
    {
      type: 'Measurement Guide',
      desc: 'Our open roll shades offer a pleasing minimalist design. Reverse roll conceals the tube and prevents the shade from contacting window cranks and other obstacles',
      image: '/images/in/open roll - reverse roll.png',
      //   pdf: CcPDF,
    },
    {
      type: 'Measurement Template',
      desc: 'Our open roll shades offer a pleasing minimalist design. Reverse roll conceals the tube and prevents the shade from contacting window cranks and other obstacles',
      image: '/images/in/fascia 3inch.png',
    },
  ];

  return (
    <>
      <Menu3 />
    <Container>
      <div class='p-4 ' style={styles.align}>
        <img
          src='/images/newHome/Measuring-Guide-Logo-80x80.png'
          class='img-fluid rounded-start'
          alt='...'
        />
      </div>
      <h4 class=' text-center fw-bolder'>Measuring Guide</h4>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='stretch'>
          <Grid item xs={12} sm={4}>
            <Grid container direction='column'>
              <Typography variant='h6' style={styles.subText}>
                {/* {language.MeasureInside} */}
                {language.MeasureWhenIn}
              </Typography>
              <hr style={styles.divider} />

              {/* <Typography variant='h6' style={styles.subText}>
                {language.MeasureWhenIn}
              </Typography> */}
              <img
                style={styles.smallImage}
                src='/images/windows/guide/mount_inside.png'
                alt='#'
              />
              <ul>
                {language.MeasureWhenInList.map((text, index) => {
                  return <li key={index}>{text}</li>;
                })}
              </ul>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container direction='column'>
              <Typography variant='h6' style={styles.subText}>
                {language.MeasureWhenOut}
              </Typography>
              <hr style={styles.divider} />
              <img
                style={styles.smallImage}
                src='/images/windows/guide/mount_outside.png'
                alt='#'
              />
              <ul>
                {language.MeasureWhenOutList.map((text, index) => {
                  return <li key={index}>{text}</li>;
                })}
              </ul>
            </Grid>
          </Grid>
        </Grid>

        {/* Step 2 */}

        <Typography variant='h6' style={styles.subText}>
          {language.MeasureValance}
        </Typography>
        {/* <hr style={styles.divider} /> */}
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='stretch'>
          <Grid item xs={12} sm={4}>
            <Grid container direction='column'>
              <ul>
                <li>
                  Determine where you will mount the shade, making sure you have
                  at least 2 in. of flat space to install.
                </li>
                <li>
                  When deciding on your valance option, make sure your mounting
                  depth can accommodate the valance (see valance section for
                  sizes) or decide if you are ok with the valance protruding
                  outside the window frame.
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container direction='column'>
              <ul>
                <li> Valance Option does not effect outside mounts.</li>
              </ul>
            </Grid>
          </Grid>
        </Grid>

        {/* Step 3 */}

        <Typography variant='h6' style={styles.subText}>
          Obstructions
        </Typography>
        {/* <hr style={styles.divider} /> */}
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='stretch'>
          <Grid item xs={12} sm={4}>
            <Grid container direction='column'>
              <ul>
                <li>
                  Obstructions can include window cranks and handles. An option
                  to avoid cranks and handles is to select reverse roll for your
                  shade or if you have the depth to install the shade so it
                  clears the obstruction.
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container direction='column'>
              <ul>
                <li>
                  If outside mount baseboards, ledges. Options include, cutting
                  the baseboard to accommodate the shade or decreasing your
                  width.
                </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='stretch'>
          <Grid item xs={12} sm={4}>
            <Grid container direction='column'>
              {/* <Typography variant='h6' style={styles.subText}>
                {language.MeasureWhenIn}
              </Typography> */}
              <hr style={styles.divider} />
              <img
                style={styles.smallImage}
                src='/images/windows/guide/measure_inside.jpg'
                alt='#'
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container direction='column'>
              {/* <Typography variant='h6' style={styles.subText}>
                {language.MeasureWhenIn}
              </Typography> */}
              <hr style={styles.divider} />
              <img
                style={styles.smallImage}
                src='/images/windows/guide/measure_outside.jpg'
                alt='#'
              />
            </Grid>
          </Grid>
        </Grid>
        <Typography variant='h6' style={styles.subText}>
          Width
        </Typography>
        {/* <hr style={styles.divider} /> */}
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='stretch'>
          <Grid item xs={12} sm={4}>
            <Grid container direction='column'>
              <ul>
                <li>
                  Measure to the nearest 1⁄8 in. between inside surfaces of
                  window frame; at the top, middle, and bottom. If the bottom or
                  middle measurement is less than ½ inch smaller than the top
                  measurement, use the top measurement as your width. If either
                  is more that ½ inch smaller than the top measurement, take the
                  smaller measurement as the width. The reason being, the fabric
                  will be at least ½ inch smaller than the headrail to
                  accommodate the operating system (clutch, motor or spring)
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container direction='column'>
              <ul>
                <li>
                  Measure to the nearest 1⁄8 in. between outermost points where
                  shade is to be placed. Measurement should overlap each side of
                  window frame by at least 1 1⁄2 in
                </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
        <Typography variant='h6' style={styles.subText}>
          Height
        </Typography>
        {/* <hr style={styles.divider} /> */}
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='stretch'>
          <Grid item xs={12} sm={4}>
            <Grid container direction='column'>
              <ul>
                <li>
                  Measure to the nearest 1⁄8 in. from inside surface at top of
                  window to top of sill; at the left, middle, and right. Record
                  the smallest height of the three.
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container direction='column'>
              <ul>
                <li>
                  Measure to the nearest 1⁄8 in. from where top of shade will be
                  located to top of sill, or measure to point of shade reach if
                  no sill. Consider adding 3 in. to your width measurement,
                  which will allow your blinds to extend past the window a bit
                  farther and block more light. You’ll also need to allow about
                  2 in. above your window frame for any required brackets.
                </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
        <Typography style={styles.subText}>
          You must consider any obstructions to the smooth operation of your
          shade so that they can be taken into account in deciding on your
          options.
        </Typography>
      </Grid>
      {/* <hr style={styles.divider} />s */}
    </Container>
    </>

  );
};

export default MeasuringGuideScreen;
