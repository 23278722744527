import { Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const AffirmCheckout = ({ loaded }) => {

    const { cartItems, shippingAddress, cartSummary } = useSelector((state) => state.cart);
    const { userInfo } = useSelector((state) => state.userLogin);

    const items = cartItems.map((item) => {
        return {
            display_name: item.productInfo?.Program,
            sku: null,
            unit_price: item.optionInfo?.OUR_PRICE,
            qty: item.optionInfo?.quantity,
            item_url: `https://www.offcutshades.com/products/name/${item.productInfo.Program}?color=${item.productInfo?.SG_Color}`,
            image_url: `https://www.offcutshades.com/images/programs/${item.productInfo.Program}/render/${item.productInfo?.SG_Color}+.jpg`,
        };
    });

    const tax = cartSummary?.FINAL_TAX;
    const total = cartSummary?.TOTAL;

    // Create order_id using the name of the product and the user's email address
    const order_id = cartItems[0].productInfo?.Program + "-" + userInfo?.email;

    const [checkoutObject, setCheckoutObject] = useState({
        merchant: {
            public_api_key: "{PUBLIC_API_KEY}",
            user_cancel_url: "https://www.offcutshades.com",
            user_confirmation_url: "https://www.offcutshades.com/confirmation?status=success",
            user_confirmation_url_action: "POST",
            name: "Off Cut Shades"
        },
        items: items,
        tax_amount: tax,
        shipping_amount: 0,
        total: total,
        currency: "CAD",
        billing: {
            name: shippingAddress?.firstName + " " + shippingAddress?.lastName,
            phone_number: shippingAddress?.phoneNumber,
            email: userInfo?.email,
            address: {
                street1: shippingAddress?.addressLine1,
                street2: shippingAddress?.addressLine2,
                city: shippingAddress?.city,
                region1_code: shippingAddress?.province,
                postal_code: shippingAddress?.postalCode,
                country: shippingAddress?.country,
            }
        },
        order_id: order_id,
    });

    const handleCheckout = () => {
        window.affirm.checkout(checkoutObject).then(function (data) {
            console.log(data);
            // Retreive the checkout_token and authorise it 
            // Set headers first
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "country-code": "CA",
                    "Accept:": "*/*",
                }
            };
            axios.post("https://api.global.affirm.com/api/v1/transactions", {
                transaction_id: data.checkout_token,
                order_id: order_id,
            }, config
            ).then(function (response) {
                console.log(response);
            }
            ).catch(function (error) {
                console.log(error);
            });
        }).catch(function (err) {
            console.log(err);

        });
    };

    return (
        (loaded) && (
        <div style={{ textAlign: 'center', fontSize: '18px', margin: '10px 0' }}>
            <Typography variant="h6" >
                OR
            </Typography>

            <Button
                style={{
                    backgroundColor: '#0077C9',
                    color: '#fff',
                    padding: '10px 20px',
                    position: 'relative',
                    right: '7%',
                    borderRadius: '5px',
                    border: 'none',
                    fontSize: '16px',
                    cursor: 'pointer',
                    width: '100%',
                    marginTop: '10px',
                }}
                onClick={handleCheckout}
            >
                Checkout with Affirm

            </Button>
            {/* Powered by Affirm */}
            <img
                src="https://cdn-assets.affirm.com/images/pay_over_time/244x60_white_2x.jpg"
                alt="Affirm"
                style={{ width: 'auto', height: '40px', position: 'relative', left: '5px', bottom: '15px' }}
            />
        </div>)
    );
};

export default AffirmCheckout;
