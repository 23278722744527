/** @format */

import '../../../wallpaperimg/main.css';

//use to set strings in backgound
export function PlaceBackText(strBack, wI, hI, font, fontColor) {
  var scaler = 100 / hI;
  var ret = [];

  let wallViewHeightTotal = document.getElementById('wallViewTotal');
  var scalerX = 0; //wallViewHeightTotal.clientHeight/hI

  if (wallViewHeightTotal != null) {
    scalerX = wallViewHeightTotal.clientHeight / hI;
  }

  var backArr = strBack.split(' ');
  var backStrIndex = 0;
  for (var i = 0; i < (hI - 2) / 5; i++) {
    //make a line
    let linestr = '';
    for (var j = 0; j < wI * 8; j++) {
      linestr = linestr + ' ' + backArr[backStrIndex++ % backArr.length];
    }
    ret.push(
      <div
        style={{
          whiteSpace: 'nowrap',
          position: 'absolute',
          top: (1 + i * 5) * scaler + '%',
          width: '100%',
          height: scaler + '%',
          overflowX: 'hidden',
          overflowY: 'hidden',
          fontFamily: font,
          fontSize: scalerX - 4 + 'px',
          color: fontColor,
        }}>
        {linestr}
      </div>,
    );
  }
  return ret;
}
