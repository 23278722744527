/** @format */

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Typography } from '@material-ui/core';
import { Rating } from '@mui/material';
import { getReviewSummary } from '../actions/userReviewActions';
import { useDispatch, useSelector } from 'react-redux';
const styles = {
  heavyText: {
    fontWeight: 400,
  },
  lightText: {
    fontWeight: 300,
  },
  imageContainer: {
    width: '100%',
  },
  windowImage: {
    width: '100%',
  },
  // colorIcon: {
  //   borderRadius: '50%',
  //   border: '1px solid black',
  //   height: '2rem',
  //   width: '2rem',
  //   minWidth: '2rem',
  //   margin: '0.25rem',
  // },
  colorIcon: {
    zIndex: 9,
    // borderRadius: '50%',
    height: '2rem',
    margin: '0.25rem',
    width: '2rem',
  },
  textHeight: {
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    color: 'grey',
  },
  colorPanelHeight: {
    minHeight: '4rem',
  },
  msrpTag: {
    color: '#E61610',
  },
  rating: {
    display: 'flex',
    justifyContent: 'center',
    margin: '.5rem',
  },
  colorIconCon: {
    position: 'relative',
  },
  colorIconScaled: {
    zIndex: 9,
    // borderRadius: '50%',
    height: '2rem',
    margin: '0.25rem',
    width: '2rem',
    transform: 'scale(1.2)',
  },
  colorIconBtn: {
    border: '1px solid grey',
    height: '2rem',
    width: '2rem',
    minWidth: '2rem',
    margin: '0.25rem',
    position: 'absolute',
  },
  colorIconBtnScaled: {
    border: '1px solid grey',
    height: '2rem',
    width: '2rem',
    minWidth: '2rem',
    margin: '0.25rem',
    position: 'absolute',
    transform: 'scale(1.2)',
  },
  colorIconActive: {
    height: '0.5rem',
    width: '0.5rem',
    minWidth: '0.5rem',
    margin: '0.25rem',
  },
  colorSwatch: {
    borderRadius: '0%',
    // border: '1px solid black',
    height: '3rem',
    width: '3rem',
    margin: '0.5rem',
  },
  // borderBox:{
  //   border: '.5px solid lightGrey',
  //   boxShadow: 'lightGrey 2px 2px 10px -3px ',
  // }
};

export const buildRenderUrl = ({ name, color }) => {
  const url = `/images/programs/${name}/render/${color}.jpg`
    .replaceAll('%', '')
    .replaceAll(' ', '_')
    .toLowerCase();
  return url;
};

export const buildImageUrl = ({ name, color }) => {
  const url = `/images/programs/${name}/window/${color}.jpg`
    .replaceAll('%', '')
    .replaceAll(' ', '_')
    .toLowerCase();
  return url;
};

// color_option
// export const buildColorUrl = ({ name, color }) => {
//   const url = `/images/color_round/${name}/${color}.jpg`
//     .replaceAll('%', '')
//     .replaceAll(' ', '_')
//     .toLowerCase();
//   return url;
// };

const Product = ({ product }) => {
  const [thumbnail, setThumbnail] = useState(0);
  const [stars, setStars] = useState(0);
  // Check if SG_Color is an array
  const [selectedColor, setSelectedColor] = useState(
    typeof product?.SG_Color === 'object'
      ? product?.SG_Color[0]
      : product?.SG_Color,
  );

  const dispatch = useDispatch();

  var reviewSumOBJ = useSelector((state) => state.reviewSummary.value);

  useEffect(() => {
    setThumbnail(
      buildImageUrl({
        name: product?.Program,
        color: product?.SG_Color[0],
      }),
    );

    try {
      console.log(product?.shade_class);
      setStars(reviewSumOBJ[product?.shade_class].avg);
    } catch {
      setStars(-1);
    }
  }, [product?.Program, product?.SG_Color, reviewSumOBJ]);

  let ratingView = {
    false: (
      <Rating
        name='read-only'
        value={stars}
        readOnly
        sx={{ color: '#ef4136' }}
        precision={0.5}
      />
    ),
    true: <p style={styles.lightText}> Be the first to Review!</p>,
  };

  var showStars = stars < 1;

  
  return (
    <div style={styles.borderBox}>
      <Link
        to={encodeURI(
          `/products/name/${encodeURIComponent(
            product.Program,
          )}?color=${selectedColor}#_`,
        )}>
        <div style={styles.imageContainer}>
          <img
            src={thumbnail}
            style={styles.windowImage}
            alt={`${product.Program} Window`}
          />
        </div>

        <Typography variant='h7' align='center' style={styles.textHeight}>
          {product?.Program}
        </Typography>
        <Typography align='center' style={styles.margin}>
          {selectedColor}
        </Typography>
      </Link>
      <Typography align='center' style={styles.text}>
        {/* Display a color pallete using SG_Color array */}

        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          >
          {typeof product?.SG_Color === 'object' ? (
            product?.SG_Color?.map((product_color, index) => {
             
              return (
                <div style={styles.colorIconCon}>
                  <Button
                    // style={{
                    //   ...styles.colorIconBtn,
                    // }}
                    style = {selectedColor === product_color ? {...styles.colorIconBtnScaled} : {...styles.colorIconBtn}}
                    value={product_color}
                    key={index}
                    onClick={(e) => {
                      setThumbnail(
                        buildImageUrl({
                          name: product.Program,
                          color: e.target.value,
                        }),
                      );

                      setSelectedColor(e.target.value);
                    }}></Button>

                  <img
                    style = {selectedColor === product_color ? {...styles.colorIconScaled, background: product_color.toLowerCase()}: {...styles.colorIcon, background: product_color.toLowerCase()}}
                    // style={{
                    //   ...styles.colorIcon,
                    //   background: product_color.toLowerCase(),
                    // }}
                    src={`/images/color_round/${product.Program}/${product_color}.png`
                      .replaceAll('%', '')
                      .replaceAll(' ', '_')
                      .toLowerCase()}
                    alt={product_color}></img>
                </div>
              );
            })
          ) : (
            <Typography variant='h6' align='center'>
              {product?.SG_Color}
            </Typography>
          )}
        </Grid>
      </Typography>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'>
        <Typography style={styles.margin}>
          Starting At<span style={styles.msrpTag}> ${product.SG_MinPrice}</span>
        </Typography>
        <Grid container style={styles.rating}></Grid>
      </Grid>

      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'>
        <a
          href={encodeURI(
            `/products/name/${encodeURIComponent(
              product.Program,
            )}?color=${selectedColor}#reviews`,
          )}>
          {ratingView[stars < 1]}
        </a>
      </Grid>
    </div>
  );
};

export default Product;
