/** @format */

import React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@mui/material';
import { printDoc } from './localStorageSystem';
import '../../../wallpaperimg/main.css';

export default function MyForm(picIDMap) {
  const [input, setInput] = useState({
    width: 0,
    height: 0,
    frameSet: null,
    colour: '#FFFFFF',
  });
  const [backgroundWordMenuState, setBackgroundWordMenuState] =
    useState('custom');
  const [backgroundPatternState, setBackgroundPatternState] = useState('bumpy');
  const [colourState, setColourState] = useState('#FFFFFF');
  const [patColourState, setPatColourState] = useState('#000000');
  const [fontState, setFontState] = useState('lato');

  const [frameState, setFrameState] = useState('rustic');

  const dispatch = useDispatch();

  function handleWidthChange(e) {
    e.preventDefault();
    let update = input;
    let newI = e.target.value;
    if (newI < 10) {
      newI = 10;
    }
    update['width'] = newI;
    setInput(update);
  }

  function handleHeightChange(e) {
    e.preventDefault();
    let update = input;
    let newI = e.target.value;
    if (newI < 10) {
      newI = 10;
    }
    update['height'] = newI;
    setInput(update);
  }

  const oldDims = useSelector((state) => state.wallpaperDims.value);

  function handleSubmit(e) {
    e.preventDefault();

    let update = JSON.parse(JSON.stringify(oldDims));

    update['frameSet'] = frameState;
    update['strBack'] = document.getElementById('wordEntry').value;
    update['patBack'] = backgroundPatternState;
    update['colour'] = colourState;
    update['patColour'] = patColourState;
    update['font'] = fontState;
  }

  function handleColorSelect(color) {
    let update = input;
    update['colour'] = color.hex;

    setColourState(color.hex);
  }

  function handlePatColorSelect(color) {
    let update = input;
    update['patColour'] = color.hex;
    setPatColourState(color.hex);
  }

  function backWordChange(e) {
    e.preventDefault();

    setBackgroundWordMenuState(document.getElementById('wordSelect').value);
  }

  function framePackChange(e) {
    e.preventDefault();
    setFrameState(document.getElementById('frameSelect').value);
  }

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        height: '100%',
        position: 'relative',
        left: 0,
        top: 0,
        width: '100%',
        backgroundColor: 'white',
      }}>
      <div
        style={{
          width: '225px',
          position: 'absolute',
          left: '0px',
          textAlign: 'center',
          // fontFamily: 'lato',
          fontSize: '1vw',
        }}>
        background
      </div>
      <div
        style={{
          width: '225px',
          position: 'absolute',
          left: '225px',
          textAlign: 'center',
          // fontFamily: 'lato',
          fontSize: '1vw',
        }}>
        pattern
      </div>
      <div style={{ position: 'absolute', top: '50%' }}>
        <div>
          <label style={{ fontFamily: 'Helvetica W01 Cn, Helvetica, Arial, sans-serif', fontSize: '2vw' }}>
            choose script font
          </label>
          {FontOptions(setFontState, fontState)}
        </div>
        <div>
          <label style={{ fontFamily: 'Helvetica W01 Cn, Helvetica, Arial, sans-serif', fontSize: '2vw' }}>
            choose background script
          </label>
          <select id='wordSelect' onChange={backWordChange}>
            <option value={'custom'}>custom</option>
            <option value={'wlocations'}>locations</option>
          </select>
        </div>

        {/* <Container> */}
        {BackroundContentPreviewWord(backgroundWordMenuState, fontState)}
        {/* </Container> */}

        <div>
          <button
            onClick={handleSubmit}
            style={{
              position: 'relative',
              height: '20%',
              width: '100%',
              fontFamily: 'Helvetica W01 Cn, Helvetica, Arial, sans-serif',
              fontSize: '3vw',
              backgroundColor: '#ef4136',
              color: 'white',
              borderWidth: '0px',
            }}>
            Generate Template
          </button>
        </div>

        <div>
          <button
            onClick={() => {
              printDoc();
            }}>
            log db
          </button>
        </div>
      </div>
      {FrameSelect(setFrameState, frameState, handleSubmit, colourState)}
      {PatternSelect(
        setBackgroundPatternState,
        backgroundPatternState,
        handleSubmit,
        patColourState,
        colourState,
      )}
    </form>
  );
}

function BackroundContentPreviewWord(selection, fontState) {
  var wordMap;
  var boolDisable;
  var strTemplate;
  if (selection == 'custom') {
    strTemplate = '';
    boolDisable = false;
  } else if (selection[0] == 'w') {
    switch (selection) {
      case 'wlocations':
        strTemplate =
          'Canada America France Italy Russia China Taiwan Germany Ukraine Japan Greenland';
        break;
      default:
        strTemplate = '';
    }

    boolDisable = true;
  }

  return (
    <TextField
      hiddenLabel
      id='wordEntry'
      defaultValue={strTemplate}
      variant='filled'
      multiline={true}
      className='largeText'
      disabled={boolDisable}
      style={{
        maxHeight: '100px',
        overflowY: 'scroll',
        width: '100%',
        fontFamily: fontState,
      }}
    />
  );
}

function BackroundContentPreviewPattern(selection) {
  var selectedImg;

  switch (selection) {
    case 'diagonalZigZag':
      //selectedImg = diagonalZigZag
      break;
    case 'bumpy':
      //selectedImg = bumpy
      break;
    default:
    //selectedImg = bumpy
  }

  return (
    <img
      src={selectedImg}
      alt='oops'
      id='patternEntry'
      style={{ width: '20vw', height: '20vh' }}
    />
  );
}

function FrameSelect(setFrameState, frameState, handleSubmit, colourState) {
  function onClickEv(ev) {
    ev.preventDefault();
    setFrameState(ev.target.id);
  }

  var ret = [];

  return (
    <div
      style={{
        height: '97%',
        width: '20%',
        borderColor: 'black',
        borderWidth: '2px',
        borderStyle: 'solid',
        position: 'absolute',
        top: '1%',
        right: '1%',
        overflowY: 'scroll',
      }}>
      {ret}
    </div>
  );
}

function PatternSelect(
  setBackgroundPatternState,
  backgroundPatternState,
  handleSubmit,
  patColourState,
  colourState,
) {
  function onClickEv(ev) {
    ev.preventDefault();

    setBackgroundPatternState(ev.target.id);
  }

  var ret = [];

  return (
    <div
      style={{
        height: '97%',
        width: '20%',
        borderColor: 'black',
        borderWidth: '2px',
        borderStyle: 'solid',
        position: 'absolute',
        top: '1%',
        right: '22%',
        overflowY: 'scroll',
      }}>
      {ret}
    </div>
  );
}

function FontOptions(setFontState, fontState) {
  function fontChange(ev) {
    ev.preventDefault();
    setFontState(document.getElementById('fontSelect').value);
  }

  var ret = [];

  return (
    <select id='fontSelect' onChange={fontChange}>
      {ret}
    </select>
  );
}
