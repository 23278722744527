/** @format */

import React, { useEffect, useState } from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../actions/userActions';
import Message from '../components/Message';
import { Loader } from '../components/Loader';
import { getOrderDetails } from '../actions/orderActions';
import { useHistory } from 'react-router-dom';
import Menu3 from '../components/Menu3';
import moment from 'moment';

const styles = {
  heavyText: {
    fontWeight: 400,
    textAlign: 'center',
  },
  divider: {
    width: '100%',
    borderTop: '1px solid black',
    marginTop: '1rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  leftHeader: {
    color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '1.5rem',
    textAlign: 'left',
    margin: '1rem 0',
  },
  button: {
    border: '1px solid black',
    background: 'transparent',
    color: 'black',
    textTransform: 'capitalize',
    minWidth: '10rem',
    backgroundColor: '#EEEBDD',
    width: '20rem',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
};
const MyOrderScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = 'null';

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  // const { email } = userInfo;

  const orderDetails = useSelector((state) => state.orderDetails);
  const { orders } = orderDetails;
  const historyHook = useHistory();
  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    } else {
      dispatch(getOrderDetails(userInfo, historyHook));
      // if
      //     (!user?.name) {
      //     dispatch(getUserDetails("profile"))
      // } else {
      //     setName(user.name)
      //     setEmail(user.email)
      // }
    }
  }, [history, dispatch, userInfo, user]);

  const floatToFraction = (decimal) => {
    var gcd = function (a, b) {
      if (!b) {
        return a;
      }

      return gcd(b, a % b);
    };

    if (decimal.toString().indexOf('.') > -1) {
      let numerator = parseFloat('0.' + decimal.toString().split('.')[1]) * 100;
      let denominator = 100;
      let div = gcd(numerator, denominator);

      return (
        decimal.toString().split('.')[0] +
        ' ' +
        (numerator / div).toString() +
        '/' +
        (denominator / div).toString()
      );
    } else {
      return decimal;
    }
  };

  return (
    <>
      <Menu3 />
      <Container>
        <p style={styles.leftHeader}>My Orders</p>
        <hr style={styles.divider} />
        {error && <Message variant='danger'>{error}</Message>}
        {loading && <Loader />}

        <TableContainer>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell align='center'>Quantity</TableCell>
                <TableCell align='center'>Date</TableCell>
                <TableCell align='center'>Shade</TableCell>
                <TableCell align='center'>Group</TableCell>
                <TableCell align='center'>Dimensions</TableCell>
                <TableCell align='center'>Control</TableCell>
                <TableCell align='center'>Valance</TableCell>
                <TableCell align='center'>Hem</TableCell>
                <TableCell align='center'>Measure</TableCell>
                {/* <TableCell align='center'>Price</TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {orders?.map((order, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align='center' component='th' scope='ord'>
                    {order.quantity}
                  </TableCell>
                  <TableCell align='center'>
                    {moment(order.date).format('MM-DD-YYYY')}
                  </TableCell>
                  <TableCell align='center'>{order.shade}</TableCell>
                  <TableCell align='center'>{order.group}</TableCell>
                  <TableCell align='center'>
                    {floatToFraction(order.width)} x{' '}
                    {floatToFraction(order.length)}
                  </TableCell>

                  <TableCell align='center'>{order.controlSystem}</TableCell>
                  <TableCell align='center'>{order.valance}</TableCell>
                  <TableCell align='center'>{order.hem}</TableCell>
                  <TableCell align='center'>{order.measure}</TableCell>
                  {/* <TableCell align='center'>$ {order.price.toFixed(2)}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Grid
              container
              direction='column'
              justifyContent='center'
              alignIt ems='center'
              >
              <Grid item xs={12} sm={6}>
              <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              >
              <FormControl style={{ width: '20rem' }}>
              <TextField
              style={{ marginTop: '2rem' }}
              variant='outlined'
              id='outlined-multiline-flexible'
              label='Name'
              required
              value={name}
              type='name'
              onChange={(e) => setName(e.target.value)}
              />
              <TextField
              style={{ marginTop: '2rem' }}
              variant='outlined'
              id='outlined-multiline-flexible'
              label='Email'
              required
              value={email}
              type='email'
              onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
              style={{ marginTop: '2rem' }}
              variant='outlined'
              id='outlined-multiline-flexible'
              label='Password'
              required
              value={password}
              type='password'
              onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
              style={{ marginTop: '2rem' }}
              variant='outlined'
              id='outlined-multiline-flexible'
              label='Confirm Password'
              required
              value={confirmPassword}
              type='password'
              onChange={(e) => setConfirmPassword(e.target.value)}
              />

              <Grid
              style={{ marginTop: '2rem' }}
              container
              direction='column'
              justifyContent='center'
              alignItems='right'
              >
              <Link to={'/'}>
              <Button onClick={submitHandler} style={styles.button}>
              Update
              </Button>
              </Link>
              </Grid>
              </FormControl>
              </Grid>
              </Grid>
            </Grid> */}
        {/* <Typography variant='h4' style={styles.heavyText}>
              My Orders
            </Typography> */}
        {/* <hr style={styles.divider} /> */}
      </Container>
    </>
  );
};

export default MyOrderScreen;
