import rustic from '../../../wallpaperimg/Frames1Rustic/framesRusticPreview.png'
import casual from '../../../wallpaperimg/Frames2Casual/framesCasualPreview.png'
import modern from '../../../wallpaperimg/Frames3Modern/framesModernPreview.png'
import victorian from '../../../wallpaperimg/Frames4Victorian/framesVictorianPreview.png'
import white from '../../../wallpaperimg/Frames5White/framesWhitePreview.png'

export const previews = {
    'rustic':rustic,
    'casual':casual,
    'modern':modern,
    //'victorian':victorian,
    'white':white
}

export function getPreviews(){
    const a ={
        'rustic':'Frames1Rustic/framesRusticPreview.png',
        'casual':'Frames2Casual/framesCasualPreview',
        'modern':'Frames3Modern/framesModernPreview',
        //'victorian':'Frames4Victorian/framesModernPreview',
        'white':'Frames5White/framesWhitePreview'
    }
    return(
        a
    )
}