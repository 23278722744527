/** @format */

import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import React from 'react';
import Menu3 from '../../components/Menu3';
import { English } from '../../localization/strings';

const styles = {
  divider: {
    width: '100%',
    margin: '1rem auto',
  },
  heavyText: {
    // fontWeight: 400,
    // justifyContent: 'center',
  },
  lightText: {
    fontWeight: 300,
  },
  paragraph: {
    // fontSize: '.9rem',
    // margin: '1rem',
    // fontWeight: 400,
    // lineHeight: 'rem',
  },
  topSpacer: {
    marginTop: '1rem',
  },
  image: {
    width: '100%',
  },
  imageContainer: {
    width: '70%',
    margin: 'auto',
  },

  smallImage: {
    width: '100%',
    margin: 'auto',
  },
  smallText: {
    width: '80%',
    margin: '1rem auto',
    lineHeight: '2rem',
  },
  // infoContainer: {
  //   margin: '1rem auto',
  // },
  button: {
    background: '#ebebeb',
    minWidth: '10rem',
    margin: '1rem',
  },

  card: {
    maxWidth: 300,
    margin: 'auto',
    marginBottom: '2rem',
    transition: '0.3s',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
    },
  },
  media: {
    width: '10rem',
    height: '10rem',
    alignItems: 'center',
    margin: '2rem',
  },
  fabricImg: {
    width: '15rem',
    height: '15rem',
    alignItems: 'center',
    margin: '2rem',
    // marginLeft:'5rem'
  },
  content: {
    textAlign: 'center',
  },
  heading: {
    fontWeight: 'bold',
  },
  subheading: {
    lineHeight: 1.8,
  },
  avatar: {
    display: 'inline-block',
    border: '2px solid white',
  },
};
const mainText =
  'With so many choices it can be difficult to select the right window treatment for you home or office. The choices you will need to consider include; the operating system, whether it be manual chain operated, chainless or automated; the valance type; the hem type and most important, the fabric. You should consider your objective for purchasing window treatments; is it to provide privacy, security, UV protection or comfort. Different fabrics provide different effects; for blocking the sun or outside light completely, choose an opaque fabric; for reducing glare while maintain outward visibility choose a Sunscreen; for creating atmosphere when there is no need for privacy consider a transparent fabric; for privacy without blocking the sun completely consider a translucent or privacy fabric. Or make a statement with a shade that becomes a focal point, complements your décor or inspires conversation with patterned, textured or dramatic colour choice, available in all fabric categories.';

const opratingSystem = [
  {
    head: 'Chain',
    desc: 'Simple, dependable, and colour coordinated. Roller Shades can be operated using a manual clutch and chain system. Chain finishes include: Stainless steel, Plastic in clear, white, linen, grey, brown, and black.',
  },
  {
    head: 'Chainless',
    desc: 'Roller shades are compatible with the Chainless system. Simply pull the shade down to the desired height by the bottom bar. The shade can easily be retracted by a slight pull of the bottom bar or blind pull. Diamond is an all new roller shade chainless system made simple, smooth, silent, and for small shades.',
  },
  {
    head: 'Motorized',
    desc: 'Roller shades are compatible with a wide range of versatile automation solutions from elegant and intuitive home automation to robust building integration systems.',
  },
];

const cardDetails = [
  {
    type: 'Open Roll-Reverse Roll',
    desc: 'Raise or lower the shade by simply pulling the chain. Chain is available in white, grey, black, linen, brown, clear, and stainless steel. Compatible with Lift Assist mechanisms (LAM). Chain holddowns included as a standard with each shade. Systems can be wall, ceiling, or laterally mounted.',
    image: '/images/in/open roll - reverse roll.png',
  },
  {
    type: 'Fascia 3" & 4"',
    desc: 'Conceals roller and components for a very clean and contemporary appearance. Universal bracket suits all mounting conditions. Commercial grade aluminum construction. Available in five colours with coordinating bracket covers.',
    image: '/images/in/fascia 3inch.png',
  },
  {
    type: 'Aria',
    desc: 'New curved valance provides a clean and finished look. The Aria Chainless Shade system is a simple and contemporary solution for smaller shades.',
    image: '/images/in/aria.png',
  },
  {
    type: 'Curved Cassette',
    desc: 'Three headrail sizes accommodate greater range of drop length possibilities and use of thicker fabrics. Fully assembled design makes for quick and easy installation.',
    image: '/images/in/wrapped curved cassette.png',
  },
];

const bottomBar = [
  {
    type: 'Delux Flat',
    desc: 'The Delux Flat aluminum bar offers a contemporary, clean look and available in White, Linen, Anodized (Grey), Brown and Black',
    image: '/images/valandbottom/delux flat.png',
  },
  {
    type: 'Zero Gravity',
    desc: 'ZeroGravity™ specific aluminum bottom bar in a colour to co ordinate with the Valance',
    image: '/images/valandbottom/zero gravity.png',
  },
  {
    type: 'Illusion',
    desc: 'Exclusive to the Sheer Shade, this aluminum bottom bar co-ordinates in colour to the fabric chosen.',
    image: '/images/valandbottom/illusion.png',
  },
  {
    type: 'Interlude',
    desc: 'Tear Drop Shaped Aluminum Bottom Bar in a colour to match the Curved Cassette.',
    image: '/images/valandbottom/interlude.png',
  },
];

const fabrics = [
  {
    type: 'Blackout Shades',
    desc: 'Our Opaque fabrics let you block out the sunlight completely. Our rich collection of fabrics has a palette of exclusive colours, textures, and patterns. Ideal for bedrooms, where darkness gives you a restful sleep or theatre rooms for vivid colours on your screen. Many of the available fabrics are green certified and emission free. They also protect your interior furnishings from UV damage, provide privacy, act as a thermal insulator, and allow for complete light blockage.',
    image: '/images/selection/blackout.jpg',
  },
  {
    type: 'Privacy Shades',
    desc: 'Our extensive collection of privacy fabrics has it all: offering patterns, tone on tone, multi-tone, solids, and textures, allowing you to find the perfect fabric. Privacy fabrics do just that – provide privacy while considerably blocking the sun. There will be a slight amount of light visible, but no outward or inward visibility. Privacy shades are perfect for a bedroom or bathroom or where your windows face the street or the neighbours. Our rich collection allows you to create from contemporary to glamourous décor and PVC free.',
    image: '/images/selection/privacy.jpg',
  },
  {
    type: 'Sunscreen Shades',
    desc: 'Sunscreen fabrics are performance or technical fabrics which give the ultimate control over the natural light. Our broad range of sunscreens make them appropriate for any application. Whether your goal is reducing solar heat gain, protecting interiors against damaging UV, or improving the comfort of any interior space through glare control or temperature control, sunscreens are the solution.',
    image: '/images/selection/solar.jpg',
  },
  {
    type: 'Transparent Shades',
    desc: 'Our diverse transparent fabric collection gives you a multitude of decorating options. Consider linen or textured fabrics … all allowing full outward visibility and a softened glow of natural light. These fabrics are great for northern exposure windows because they let in all available natural light. They also complement drapery, allowing some warmth when the drapes are open and privacy when the drapes are closed.',
    image: '/images/selection/transparent.jpg',
  },
  {
    type: 'Translucent Shades',
    desc: 'Our translucent fabrics all have a unique element of interest, from a subtle sheen to pronounced textures to multi-tone. They provide some privacy and a warm, intimate glow to your room with diffused sunlight. In the evening with the lights on, only silhouettes can be seen from the outside. These fabrics reduce glare and provide protection from harmful UV rays. These fabrics are eco-friendly and PVC free.',
    image: '/images/selection/translucent.jpg',
  },
];

const ProductSelectionGuideScreen = () => {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  let unused = window.onbeforeunload();
  const language = English;
  return (
    <>
      <Menu3 />
    <Container>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'>
        {/* Environmental Commitment */}
        <div class='p-4 ' style={styles.align}>
          <img
            src='/images/newHome/Fabric-Selection-Logo-80x80.png'
            class='img-fluid rounded-start'
            alt='...'
          />
        </div>
        <h4 class='my-2 text-center fw-bolder'> Fabric Selection </h4>

        {/* <Typography
          variant='body1'
          color='textSecondary'
          style={styles.paragraph}>
          {language.MainTextInfo}
        </Typography> */}

        {/* Operations */}
        {/* <hr style={styles.topSpacer} />
        <Typography variant='h6' style={styles.heavyText}>
          Operating System
        </Typography>
        <hr style={styles.topSpacer} />

        <Grid
          container
          spacing={2}
          direction='row'
          justify='flex-start'
          alignItems='flex-start'>
          {opratingSystem.map((cardDetail) => (
            <Card style={styles.card}>
              <CardContent style={styles.content}>
                <Typography variant='h6' gutterBottom>
                  {cardDetail.head}
                </Typography>
                <Grid>
                  <Typography variant='ception'>{cardDetail.desc}</Typography>
                </Grid>
                <Divider light />
              </CardContent>
            </Card>
          ))}
        </Grid>
        <hr style={styles.topSpacer} /> */}

        {/* Valance
        <Typography variant='h6' style={styles.heavyText}>
          Valance Options
        </Typography>
        <hr style={styles.topSpacer} />

        <Grid
          container
          spacing={2}
          direction='row'
          justify='flex-start'
          alignItems='flex-start'>
          {cardDetails.map((cardDetail) => (
            <Card style={styles.card}>
              <CardMedia style={styles.media}>
                <img
                  src={cardDetail.image}
                  alt={cardDetail.type}
                  style={styles.media}
                />
              </CardMedia>
              <CardContent style={styles.content}>
                <Typography variant='h6' gutterBottom>
                  {cardDetail.type}
                </Typography>
                <Grid>
                  <Typography variant='ception'>{cardDetail.desc}</Typography>
                </Grid>
                <Divider light />
              </CardContent>
            </Card>
          ))}
        </Grid> */}

        {/* Hem */}
        {/* <hr style={styles.topSpacer} />

        <Typography variant='h6' style={styles.heavyText}>
          Bottom Bar Options
        </Typography>
        <Grid
          container
          spacing={2}
          direction='row'
          justify='flex-start'
          alignItems='flex-start'>
          {bottomBar.map((cardDetail) => (
            <Card style={styles.card}>
              <CardMedia style={styles.media}>
                <img
                  src={cardDetail.image}
                  alt={cardDetail.type}
                  style={styles.media}
                />
              </CardMedia>
              <CardContent style={styles.content}>
                <Typography variant='h6' gutterBottom>
                  {cardDetail.type}
                </Typography>
                <Grid>
                  <Typography variant='ception'>{cardDetail.desc}</Typography>
                </Grid>
                <Divider light />
              </CardContent>
            </Card>
          ))}
        </Grid> */}

        {/* 2 Image */}

        {/* <Typography variant='h6' justifyContent='center'>
          Fabric Selection
        </Typography> */}
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          style={styles.infoContainer}>
          <Grid item>
            <img
              style={styles.smallImage}
              src='/images/windows/filler/1.jpg'
              alt='#'
            />
          </Grid>
        </Grid>

        {/* 2 Text */}
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          style={styles.infoContainer}>
          {fabrics.map((fabric) => (
            <>
              <Grid item xs={12} sm={4}>
                <img
                  src={fabric.image}
                  alt={fabric.type}
                  style={styles.fabricImg}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography variant='h6'>{fabric.type}</Typography>
                <Typography variant='textSecondary'>{fabric.desc}</Typography>
              </Grid>
            </>
          ))}
        </Grid>
      </Grid>
      {/* <hr style={styles.divider} /> */}
    </Container>
    </>

  );
};

export default ProductSelectionGuideScreen;
