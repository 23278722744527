import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_PAYMENT_RESULT,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_UPDATE_QTY,
  CART_SAVE_SUMMARY,
  CART_RESET,
  PROMO_CODE_SUMBMITED_REQUEST,
  PROMO_CODE_SUMBMITED_SUCCESS,
  PROMO_CODE_SUMBMITED_FAIL,
  SWATCH_CART_ADD_ITEM,
  SWATCH_CART_REMOVE_ITEM,
  SWATCH_CART_SAVE_SHIPPING_ADDRESS,
  SWATCH_CART_RESET
} from '../constants/cartConstants';

export const swatchCartReducer = (swatchState = { swatchCartItems: [], swatchShippingAddress: {} }, action) => {
  switch (action.type) {
    case SWATCH_CART_ADD_ITEM:
      return { ...swatchState, swatchCartItems: [...swatchState.swatchCartItems, action.payload] };
    case SWATCH_CART_REMOVE_ITEM:
        return {
          ...swatchState,
          swatchCartItems: swatchState.swatchCartItems.filter(
            (item) => item !== action.payload
          ),
        };
    case SWATCH_CART_SAVE_SHIPPING_ADDRESS:
      return { ...swatchState, swatchShippingAddress: action.payload };
    case SWATCH_CART_RESET:
        return { ...swatchState, swatchCartItems: [] }
    default:
        return swatchState;
    }
};
  

export const cartReducer = (
  state = { cartItems: [], shippingAddress: {} },
  action
) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      return { ...state, cartItems: [...state.cartItems, action.payload] };

    

    //Remove From Cart Item
    case CART_REMOVE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (x, index) => index !== action.payload
        ),
      };

    
    //Update Quantity
    case CART_UPDATE_QTY:
      const cartItems = state.cartItems.map((item, index) => {
        if (index === action.payload.index) {
          const optionInfo = {
            ...item.optionInfo,
            quantity: action.payload.quantity,
          };
          return { ...item, optionInfo };
        }
        return { ...item };
      });
      return { ...state, cartItems };

    case CART_SAVE_SHIPPING_ADDRESS:
      return { ...state, shippingAddress: action.payload };
    case CART_SAVE_PAYMENT_RESULT:
      return { ...state, paymentResult: action.payload };
    case CART_SAVE_SUMMARY:
      return { ...state, cartSummary: action.payload }
    case CART_RESET:
      return { ...state, cartItems: [] }
    default:
      return state;
  }
};


export const disablePromoCodeReducer = (state = {},action)=>{
  switch (action.type) {
    case PROMO_CODE_SUMBMITED_REQUEST:
        return { loading: true }
    case PROMO_CODE_SUMBMITED_SUCCESS:
        return { loading: false, userInfo: action.payload }
    case PROMO_CODE_SUMBMITED_FAIL:
        return { loading: false, error: action.payload }
    default:
        return state
}
}