/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
// import { savePaymentMethod } from "../actions/cartActions";
import {
  Box,
  Button,
  Container,
  FormLabel,
  Grid,
  Hidden,
  RadioGroup,
} from '@material-ui/core';
import Menu3 from '../components/Menu3';

const styles = {
  divider: {
    width: '100%',
  },
  darkText: {
    color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '1.1rem',
    // fontFamily: 'Lato',
  },
  disabledText: {
    color: '#9b9b9b',
    borderBottom: '1px solid #9b9b9b;',
    fontSize: '1.1rem',
    // fontFamily: 'Lato',
  },
  leftHeader: {
    color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '1.5rem',
    textAlign: 'left',
    margin: '3rem 0 0 0',
  },
  heavyText: {
    fontWeight: 400,
  },
  topSpacer: {
    marginTop: '2rem',
  },
  panel: {
    background: '#e3e3e3d9',
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    textAlign: 'center',
  },
  couponCode: {
    color: '#000',
    fontSize: '1.5rem',
    textAlign: 'left',
    width: '100%',
  },
  priceText: {
    fontSize: '1rem',
    margin: '.5rem 0',
  },
  priceTextSave: {
    fontSize: '1rem',
    margin: '.5rem 0',
    color: '#E61610',
  },
  priceTotal: {
    fontSize: '1.2rem',
    margin: '.5rem 0',
    borderTop: '1px solid #000;',
  },
  priceSaving: {
    fontSize: '1.2rem',
    margin: '.5rem 0',
    color: '#E61610',
  },
  atcButton: {
    background: '#ebebeb',
    // borderRadius: '50px',
    minWidth: '12rem',
    marginRight: '1rem',
  },
};

const PaymentScreen = () => {
  const history = useHistory();

  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  if (!shippingAddress) {
    history.push('/shipping');
  }

  const submitHandler = (e) => {
    e.preventDefault();

    history.push('/placeorder');
  };

  return (
    <Container>
      <Menu3/>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        style={styles.panel}
        spacing={0}>
        <Grid item sm={3} style={styles.disabledText}>
          <p>1. Shopping Cart</p>
        </Grid>
        <Hidden smDown>
          <Grid item md={3} style={styles.disabledText}>
            <p>2. Shipping Details</p>
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid item md={3} style={styles.darkText}>
            <p>3. Payment Options</p>
          </Grid>
        </Hidden>
        {/* <Hidden smDown>
          <Grid item md={3} style={styles.disabledText}>
            <p>4. Place Order </p>
          </Grid>
        </Hidden> */}
      </Grid>
      <Grid container>
        <Grid item sm={12} md={8} style={{ margin: '2rem' }}>
          <Box
            component='form'
            sx={{
              '& .MuiTextField-root': { m: 1, width: '20rem' },
            }}
            noValidate
            autoComplete='off'
            justifyContent='space-between'>
            <FormLabel component='legend'>Payment Method</FormLabel>
            <RadioGroup
              aria-label='gender'
              defaultValue='female'
              name='radio-buttons-group'></RadioGroup>
            <Grid
              style={{ margin: '1.5rem', marginTop: '6rem' }}
              container
              justifyContent='flex-start'
              alignItems='center'>
              <Link
                to={{
                  pathname: '/shipping',
                }}>
                <Button style={styles.atcButton}>Back to Shipping</Button>
              </Link>
              <Link
                to={{
                  pathname: '/payment',
                }}></Link>
              <Button onClick={submitHandler} style={styles.atcButton}>
                Next
              </Button>
            </Grid>
          </Box>
        </Grid>
        <Grid item sm={12} md={3}>
          <p style={styles.leftHeader}>Summary</p>
          <hr style={styles.divider} />
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            style={styles.priceText}>
            <Grid item>MSRP</Grid>
          </Grid>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            style={styles.priceTextSave}>
            <Grid item>OUR PRICE</Grid>
          </Grid>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            style={styles.priceText}>
            <Grid item>SHIPPING</Grid>
            <Grid item>CALC</Grid>
          </Grid>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            style={styles.priceText}>
            <Grid item>TAX</Grid>
          </Grid>
          <hr style={styles.divider} />
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            style={styles.priceTotal}>
            <Grid item>TOTAL</Grid>
          </Grid>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            style={styles.priceSaving}>
            <Grid item>SAVINGS</Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <hr style={styles.divider} /> */}
    </Container>
  );
};

export default PaymentScreen;
