/** @format */

import React, { useEffect } from 'react';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { getUserDetails } from '../actions/userActions'
import Message from '../components/Message';
import { Loader } from '../components/Loader';
import Menu3 from '../components/Menu3';
import { removeEmailFromCartMailChimp } from '../actions/userActions';

const styles = {
  heavyText: {
    fontWeight: 400,
    textAlign: 'center',
  },
  divider: {
    width: '100%',
    // borderTop: '1px solid black',
    marginTop: '1rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  button: {
    border: '1px solid black',
    background: 'transparent',
    color: 'black',
    textTransform: 'capitalize',
    minWidth: '10rem',
    backgroundColor: '#EEEBDD',
    width: '20rem',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
};
const ConfirmationScreen = () => {
  const cart = useSelector((state) => state.cart);
  const { paymentResult, error, loading } = cart;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userEmail = userInfo?.email;
  
  useEffect(() => {
    if(userEmail) {
      removeEmailFromCartMailChimp(userEmail);
    }
  }, [])


  return (
    <Container>
       <Menu3/>
      <Typography variant='h4' style={styles.heavyText}>
        Payment Confirmation
      </Typography>
      <hr style={styles.divider} />
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'>
        {error && <Message variant='danger'>{error}</Message>}
        <Grid
          style={{ width: '20rem' }}
          container
          direction='column'
          justifyContent='center'
          alignItems='center'>
          <span>
            <strong>Status : </strong>
            {paymentResult.status},
          </span>
          <br />
          <span>
            <strong>Bill Id : </strong>
            {paymentResult.id},
          </span>
          <br />
          <span>
            <strong>Email : </strong>
            {paymentResult.payer.email_address},
          </span>
          <br />
          <span>
            <strong>Name : </strong>
            {paymentResult.payer.name.given_name}{' '}
            {paymentResult.payer.name.surname},
          </span>
          <br />
          <span>
            <strong>Total : </strong>
            {paymentResult.purchase_units[0].amount.value},
          </span>
          <br />
        </Grid>
        <Link to={'/myorders'}>
          <Button style={styles.button}>My Orders</Button>
        </Link>
      </Grid>
      <div style={styles.divider} />
    </Container>
  );
};

export default ConfirmationScreen;
