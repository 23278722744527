/** @format */

import { getEarlyVals } from '../options';

export function checkCollision(x, y, w, h, wI, hI, occupied) {
  //returns true if space is free
  var early = getEarlyVals();
  x = x - 1;
  y = y - 1;
  w = w + 2;
  h = h + 2;
  var ret = true;

  if (
    y <= parseInt(early.tbMarginT) ||
    y + h >= hI - parseInt(early.tbMarginB)
  ) {
    ret = false;
  }

  for (var i = 0; i < w; i++) {
    for (var j = 0; j < h; j++) {
      if (i + x >= wI || i + x < 0 || j + y >= hI || j + y < 0) {
        ret = false;
        continue;
      }

      if (occupied[i + x][j + y] == true) {
        ret = false; //space is already occupied
      }
    }
  }

  x = x + 1; //so margins can overlap
  y = y + 1;
  w = w - 2;
  h = h - 2;

  if (ret) {
    for (var i = x; i < x + w; i++) {
      for (var j = y; j < y + h; j++) {
        occupied[i][j] = true;
      }
    }
  }

  return ret;
}
