/** @format */

import { cloneElement, useState, useEffect } from 'react';
//import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import React, { Component, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { MainFrame } from './placeFrames';
import { checkCollision } from './logical_functions/collision';
import { picturePanel } from './panel';

import Dexie from 'dexie';
import { useLiveQuery } from 'dexie-react-hooks';
import { db, DBReset, DBStart } from './../DexieDB';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PlaceBackText } from './placeBackElements';
import { patternObject } from './importPatterns';
import Popup from 'reactjs-popup';
import { newPaperBool, loadIdMap } from '../startWallPaper';
import { getDoc } from './../localStorageSystem';
import MyOptions from './options';
import Rulers from './ruler';
import { getEarlyVals } from './options';
import { addListener, removeListener } from 'resize-detector';
import { createDoc, storeToken, getToken } from './../localStorageSystem';
import {
  setWallPaperFrames,
  setWallPaperDims,
} from '../../../actions/wallpaperActions';
import useSaveToCache from './useSaveToCacheHook';
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  Hidden,
} from '@material-ui/core';
import Menu3 from '../../Menu3';

const styles = {
  image: {
    width: '100%',
  },
  imageContainer: {
    width: '50%',
    margin: 'auto',
  },
  pickerContainer: {
    width: '50%',
    margin: 'auto',
    background: 'blue',
    left: '0',
    position: 'relative',
  },
  leftHeader: {
    color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '1.5rem',
    // textAlign: 'left',
    margin: '1rem 0 0 0',
  },
  disabledText: {
    color: '#9b9b9b',
    borderBottom: '1px solid #9b9b9b;',
    fontSize: '1.1rem',
    // fontFamily: 'Lato',
  },
  formControl: {
    minWidth: '12rem',
    minHeight: '2rem',
    margin: '1rem',
    border: '.05rem solid darkgrey',
  },
  heavyText: {
    fontWeight: 400,
  },
  topSpacer: {
    marginTop: '2rem',
  },
  panel: {
    //background: '#e3e3e3e3',
    marginTop: '1rem',
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
  },
  couponCode: {
    color: '#000',
    fontSize: '1.5rem',
    textAlign: 'left',
    width: '100%',
  },
  priceText: {
    fontSize: '1rem',
    margin: '.5rem 0',
  },
  priceTextSave: {
    fontSize: '1rem',
    margin: '.5rem 0',
    color: '#E61610',
  },
  priceTotal: {
    fontSize: '1.2rem',
    margin: '.5rem 0',
    borderTop: '1px solid #000;',
  },
  priceSaving: {
    fontSize: '1.2rem',
    margin: '.5rem 0',
    color: '#E61610',
  },
  atcButton: {
    background: '#red',
    borderRadius: '50px',
    minWidth: '12rem',
    margin: '1rem 1rem',
    border: '.05rem solid ',
    // backgroundColor:'Red'
  },
  atcSelect: {
    background: '#red',
    borderRadius: '50px',
    minWidth: '12rem',
    margin: '1rem 1rem',
    border: '.05rem solid ',
    height: '2.5rem',
    textAlign: 'center',
    // backgroundColor:'Red'
  },
  divider: {
    width: '100%',
    margin: '2rem auto',
  },
  darkText: {
    color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '1.1rem',
    // fontFamily: 'Lato',
  },

  headText: {
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'uppercase',
    padding: '.25rem 0',
    textAlign: 'left',
  },
  subText: {
    fontWeight: 300,
    fontSize: '.9rem',
    padding: '.25rem 0',
  },

  cartItem: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  imgContainer: {
    width: '100%',
  },
  msrpTag: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'Capitalize',
  },
  ourTag: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'Capitalize',
    color: '#E61610',
  },
  msrPrice: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '2rem',
    textTransform: 'Capitalize',
  },
  ourPrice: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '2rem',
    textTransform: 'Capitalize',
    color: '#E61610',
  },
  removeButton: {
    border: 'none',
  },
  quantityField: {
    maxWidth: '35%',
    margin: '1rem 0 0 1rem',
  },
  tabButtonDark: {
    background: '#e3e3e3e3',
    color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '1.1rem',
    // fontFamily: 'Lato',
  },
  tabButton: {
    background: '#e3e3e3e3',
    color: '#9b9b9b',
    borderBottom: '1px solid #9b9b9b;',
    fontSize: '1.1rem',
    // fontFamily: 'Lato',
  },
};

//randomly places the frames after changing values
function Roll(dims, dispatch) {
  let idM = -1;

  var widthIN = parseInt(dims[0]);
  var heightIN = parseInt(dims[1]);

  var scaler = 100 / heightIN;

  var ret = [];

  let occupied = new Array(widthIN);
  for (var i = 0; i < occupied.length; i++) {
    //make 2d occupied array
    occupied[i] = new Array(heightIN);
  }

  for (var i = 0; i < widthIN; i++) {
    //initialize occupied array
    for (var j = 0; j < heightIN; j++) {
      //if(i==0 || i==20 || j == 0 || j == 197){
      //  occupied[i][j] = true;
      //}else{
      occupied[i][j] = false;
      //}
    }
  }

  //dispatch(setOccupied(occupied))

  for (var i = 0; i < 10; i++) {
    //add large frames
    for (var j = 0; j < widthIN * heightIN * 5; j++) {
      //# of tries based on size
      var x = Math.floor(Math.random() * (widthIN + 1)); // Returns a random integer from 0 to 53:
      var y = Math.floor(Math.random() * (heightIN + 1));
      var w = 10;
      var h = 12;
      var land = LandRNG();
      if (land) {
        //then landscape mode
        w = 12;
        h = 10;
      }

      if (checkCollision(x, y, w, h, widthIN, heightIN, occupied)) {
        //if available place and move on
        //ret.push(FrameLRG(x,y,land,scaler))
        //ret.push([++idM,x,y,w,h,"img","frm"])

        ret.push({
          x: x,
          y: y,
          land: land,
          size: 3,
          frame: 0,
          id: ++idM,
          picture: null, //3 = large size
        });
        break;
      }
    }
  }

  for (var i = 0; i < 0; i++) {
    //add med frames
    for (var j = 0; j < 30; j++) {
      //30 tries
      var x = Math.floor(Math.random() * (widthIN + 1)); // Returns a random integer from 0 to 21:
      var y = Math.floor(Math.random() * (heightIN + 1));
      var w = 8;
      var h = 10;
      var land = LandRNG();
      if (land) {
        //then landscape mode
        w = 10;
        h = 8;
      }

      if (checkCollision(x, y, w, h, widthIN, heightIN, occupied)) {
        //if available place and move on
        //ret.push(FrameMED(x,y,land,scaler))
        //ret.push([++idM,x,y,w,h,"img","frm"])
        //idM++
        ret.push({
          x: x,
          y: y,
          land: land,
          size: 2,
          frame: 0,
          id: ++idM,
          picture: null, //2 = med size
        });
        break;
      }
    }
  }

  for (var i = 0; i < widthIN; i++) {
    //add sml frames
    for (var j = 0; j < heightIN; j++) {
      var land = LandRNG();
      var w = 6;
      var h = 8;
      if (land) {
        w = 8;
        h = 6;
      }

      if (checkCollision(i, j, w, h, widthIN, heightIN, occupied)) {
        //ret.push(FrameSML(i,j,land,scaler))
        // ret.push([++idM,x,y,w,h,"img","frm"])

        ret.push({
          x: i,
          y: j,
          land: land,
          size: 1,
          frame: 0,
          id: ++idM,
          picture: null, //1 = sml size
        });

        continue;
      }
      var temp = w;
      w = h;
      h = temp;
      if (checkCollision(i, j, w, h, widthIN, heightIN, occupied)) {
        //ret.push(FrameSML(i,j,!land))
        //ret.push([++idM,x,y,w,h,"img","frm"])

        ret.push({
          x: i,
          y: j,
          land: !land,
          size: 1,
          frame: 0,
          id: ++idM,
          picture: null, //1 = sml size
        });
        continue;
      }
    }
  }

  setWallPaperFrames(dispatch, ret);

  return true;
}


//differant algo for frames
function RollGrid(dims, dispatch) {
  //not in use rn maby future feature
  let idM = -1;
  var widthIN = parseInt(dims[0]);
  var heightIN = parseInt(dims[1]);

  //var scaler = 100/heightIN

  var ret = [];

  for (var i = 0; i < widthIN / 11 - 1; i++) {
    var thisColumnOfset = 1 + i * 11 + i + i;
    var remaining = heightIN - 2;

    while (remaining >= 8) {
      var type = Math.floor(Math.random() * 3) + 1;
      var land = LandRNG();

      if (type === 3 && remaining > 13 && land === false) {
        //large port
        ret.push({
          x: thisColumnOfset + 1,
          y: heightIN - remaining + 1,
          land: false,
          size: 3,
          frame: 0,
          id: ++idM,
          picture: null, //3 = large size
        });
        remaining -= 13;
      } else if (type === 3 && remaining > 11 && land === true) {
        //large land
        ret.push({
          x: thisColumnOfset,
          y: heightIN - remaining + 1,
          land: true,
          size: 3,
          frame: 0,
          id: ++idM,
          picture: null, //3 = large size
        });
        remaining -= 11;
      }

      if (type === 2 && remaining > 11 && land === false) {
        //med port
        ret.push({
          x: thisColumnOfset + 2,
          y: heightIN - remaining + 1,
          land: false,
          size: 2,
          frame: 0,
          id: ++idM,
          picture: null, //3 = large size
        });
        remaining -= 11;
      } else if (type === 2 && remaining > 9 && land === true) {
        //med land
        ret.push({
          x: thisColumnOfset + 1,
          y: heightIN - remaining + 1,
          land: true,
          size: 2,
          frame: 0,
          id: ++idM,
          picture: null, //3 = large size
        });
        remaining -= 9;
      }

      if (type === 1 && remaining > 9 && land === false) {
        //sml port
        ret.push({
          x: thisColumnOfset + 3,
          y: heightIN - remaining + 1,
          land: false,
          size: 1,
          frame: 0,
          id: ++idM,
          picture: null, //3 = large size
        });
        remaining -= 9;
      } else if (type === 1 && remaining > 7 && land === true) {
        //sml land
        ret.push({
          x: thisColumnOfset + 2,
          y: heightIN - remaining + 1,
          land: true,
          size: 1,
          frame: 0,
          id: ++idM,
          picture: null, //3 = large size
        });
        remaining -= 7;
      }
    } //while
  } //for

  setWallPaperFrames(dispatch, ret);

  return true;
}

//random number generator for portrait vc landscape
function LandRNG() {
  var landscapePer = parseInt(getEarlyVals().landscapePer);
  //var landscapePer = parseInt(25)
  if (Math.floor(Math.random() * 100) <= landscapePer) {
    return true;
  } else {
    return false;
  }
}

//makes backgroud color -- place backgound
function makeBackground(
  strColour,
  dims,
  strBack,
  imgBack,
  colPat,
  font,
  colorFilterState,
) {
  //const myStyle = setCoords(0,40,198*10,21*10)
  var strBackText = strBack;
  var wI = dims[0];
  var hI = dims[1];
  let scaler = 100 / hI;
  var backPattern = patternObject[imgBack];

  let wallViewHeightTotal = document.getElementById('wallView');

  var scalerX = 0; //wallViewHeightTotal.clientHeight/hI

  if (wallViewHeightTotal != null) {
    scalerX = wallViewHeightTotal.clientHeight / hI;
  }

  const myStyle = {
    position: 'absolute',
    background: strColour,
    top: '0px',
    left: '0px',
    width: wI * scalerX + 'px',
    height: 100 + '%',
    filter: colorFilterState,
    WebkitFilter: colorFilterState,
  };
  return (
    <div style={myStyle} id='backdiv'>
      {PlaceBackText(strBackText, wI, hI, font, colPat)}
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          maskImage: 'url(' + backPattern + ')',
          WebkitMaskImage: 'url(' + backPattern + ')',
          maskRepeat: 'repeat-xy',
          WebkitMaskRepeat: 'repeat-xy',
          backgroundColor: colPat,
          maskSize: '10vh',
          maskSize: '10vh',
          WebkitMaskSize: '10vh',
          WebkitMaskSize: '10vh',
          WebkitFilter: colorFilterState,
          filter: colorFilterState,
        }}></div>
    </div>
  );
}

export default function TheForgeFunc(props) {
  //console.log('the forge func')

  //DBReset()
  //tokenCheck()
  //const scaler = ((window.innerHeight)/198)*10;
  const [colorFilterState, setColorFilterState] = useState('grayscale(0%)');
  const [picIDMap, setPicIDMap] = useState(new Map());
  const bufferStyle = {
    position: 'absolute',
    background: '#60f542',
    width: '30vw',
    top: 0,
    left: 53 * (100 / 198) + 'vh',
  };
  const inputData = useSelector((state) => state.wallpaperDims.value);
  const dims = [parseInt(inputData['width']), parseInt(inputData['height'])];
  const [loadState, setLoadState] = useState(true);
  const [scalerPXstate, setScalerPXstate] = useState(1);
  const [filterState, setFilterState] = useState('');
  const [tabState, setTabState] = useState(0);
  const [iterationState, setIterationState] = useState(0);
  const [frameVisibilityState, setFrameVisibilityState] = useState('hidden');
  //const token = useSelector((state) => state.token.value)
  const dispatch = useDispatch();
  //const nav = useNavigate();
  //const reset = () => nav('/')

  var landper = parseInt(getEarlyVals().landscapePer);
  var tbMar = parseInt(getEarlyVals().tbMargin);

  var initTab = 0;

  //console.log(newPaperBool)
  useEffect(() => {
    //tc()
    console.log('effect');
    //console.log(newPaperBool)
    setPicIDMap(new Map());
    if (newPaperBool == true || frameVisibilityState == 'hidden') {
      Roll(dims, dispatch);
    } else {
      //let lsUserToken = getToken()
      //let user = lsUserToken.user
      //let token = lsUserToken.token

      var loadDoc = getDoc('user');
      console.log('retrieve chash');
      setWallPaperDims(dispatch, loadDoc.redux.dimensions);
      setWallPaperFrames(dispatch, loadDoc.redux.frame);
      setFrameVisibilityState('visible');

      var mapOBJ = Object.entries(loadDoc.idMap);
      for (var i = 0; i < mapOBJ.length; i++) {
        mapOBJ[i][0] = parseInt(mapOBJ[i][0]);
      }
      let loadIdMap = new Map(mapOBJ);
      //console.log(loadIdMap)
      //console.log("sub");
      createDoc('user');
      //DBStart(user)
      if (typeof db == 'undefined') {
        //console.log('db start now')
        DBStart('user');
      }

      setPicIDMap(loadIdMap);
    }

    //console.log('dim0')
    //console.log(dims[0])

    if (document.getElementById('wallView') != null) {
      setScalerPXstate(
        document.getElementById('wallView').clientHeight / dims[1],
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, iterationState]);
  if (newPaperBool == false && loadState) {
    //console.log('save from the forge')
    var loadDoc = getDoc('user')
    //console.log(loadDoc)
    setWallPaperDims(dispatch, loadDoc.redux.dimensions);
    setWallPaperFrames(dispatch, loadDoc.redux.frame);

    let newMap = new Map(Object.entries(loadDoc.idMap));
    setPicIDMap(newMap);
    setLoadState(false);
  }

  useSaveToCache(picIDMap);

  if (typeof db == 'undefined') {
    //console.log('db start now')
    //et lsUserToken = getToken()
    //let user = lsUserToken.user
    DBStart('user');
  }

  //console.log(a[0])
  return (
    <>
      <Menu3/>
    <div style={{ hight: '100vh', width: '100vw' }} id='theforgediv'>
      <DndProvider backend={HTML5Backend}>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          style={styles.panel}
          spacing={0}
          fullWidth>
          <Grid
            item
            sm={3}
            style={tabState == 0 ? styles.tabButtonDark : styles.tabButton}>
            <p
              onClick={(e) => {
                //e.preventDefault()
                tabState != -1 ? setTabState(0) : console.log('no');
              }}
              style={{ textAlign: 'center' }}>
              Step 1: Size
            </p>
          </Grid>
          <Hidden smDown>
            <Grid
              item
              md={3}
              style={tabState == 1 ? styles.tabButtonDark : styles.tabButton}>
              <p
                onClick={(e) => {
                  //e.preventDefault()
                  tabState != -1 ? setTabState(1) : console.log('no');
                }}
                style={{ textAlign: 'center' }}>
                Step 2: Style
              </p>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid
              item
              md={3}
              style={tabState == 2 ? styles.tabButtonDark : styles.tabButton}>
              <p
                onClick={(e) => {
                  //e.preventDefault()
                  tabState != -1 ? setTabState(2) : console.log('no');
                }}
                style={{ textAlign: 'center' }}>
                Step 3: Background
              </p>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid
              item
              md={3}
              style={tabState == 3 ? styles.tabButtonDark : styles.tabButton}>
              <p
                onClick={(e) => {
                  //e.preventDefault()
                  tabState != -1 ? setTabState(3) : console.log('no');
                }}
                style={{ textAlign: 'center' }}>
                Step 4: photos
              </p>
            </Grid>
          </Hidden>
        </Grid>
        <Container>
          <div
            style={{
              position: 'relative',
              height: '70vh',
              width: '100%',
              left: '0',
              top: '0vh',
              overflowX: 'auto',
              overflowY: 'hidden',
              minHeight: '700px',
            }}
            id='wallViewTotal'>
            <div
              style={{
                position: 'absolute',
                width: '40%',
                left: '600px',
                height: '70vh',
                overflowX: 'auto',
                overflowY: 'hidden',
              }}>
              {Rulers(dims[0], dims[1], scalerPXstate, setScalerPXstate)}
              <div
                style={{
                  height: '95%',
                  position: 'absolute',
                  top: '5%',
                  width: '95%',
                  left: '5%',
                }}
                id='wallView'
                onLoad={() => {
                  setScalerPXstate(
                    document.getElementById('wallView').clientHeight / dims[1],
                  );
                }}>
                {makeBackground(
                  inputData['colour'],
                  dims,
                  inputData['strBack'],
                  inputData['patBack'],
                  inputData['patColour'],
                  inputData['font'],
                  colorFilterState,
                )}
                {MainFrame(
                  picIDMap,
                  setPicIDMap,
                  scalerPXstate,
                  setScalerPXstate,
                  colorFilterState,
                  frameVisibilityState,
                )}
              </div>
            </div>

            <div
              style={{
                position: 'absolute',
                left: 0,
                top: '0vh',
                overflowX: 'hidden',
                overflowY: 'hidden',
              }}>
              {picturePanel(picIDMap, setPicIDMap, inputData['doc'])}
              {MyOptions(
                picIDMap,
                newPaperBool,
                setPicIDMap,
                inputData['doc'],
                colorFilterState,
                setColorFilterState,
                tabState,
                setTabState,
                iterationState,
                setIterationState,
                setFrameVisibilityState,
              )}
            </div>
          </div>
        </Container>
      </DndProvider>
    </div>
    </>
  );
}
