/** @format */

import React, { useEffect } from 'react';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRecommended } from '../actions/productActions';
import { Link } from 'react-router-dom';
import Menu3 from '../components/Menu3';
const ribbon = '/images/ribbon.svg';

const styles = {
  heavyText: {
    fontWeight: 400,
  },
  lightText: {
    fontWeight: 300,
  },
  divider: {
    width: '100%',
    // zIndex: 1,
    borderTop: '1px solid black',
    margin: '1rem auto',
    textAlign: 'center',
    // marginLeft: 0,
  },
  widthCorrector: {
    // margin: '1rem',
    // overflow: 'None'
    maxWidth: '100%',
  },
  image: {
    margin: '1rem',
    width: '70%',
  },
  imageContainer: {
    width: '100%',
    margin: 'auto',
  },
  spacerTop: {
    marginTop: '2rem',
  },
  spacerBot: {
    textAlign: 'center',
    marginBottom: '1rem',
  },
  dividerSmall: {
    width: '80%',
    // zIndex: 1,
    // borderTop: '1px solid #e5e5e5',
    borderTop: '1px solid',
    borderColor: 'rgb(96, 96, 96, 0.4)',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  colorIcon: {
    borderRadius: '50%',
    border: '1px solid grey',
    height: '5rem',
    width: '5rem',
    // minWidth: '5rem',
    // margin: '0.25rem',
  },
  programDesc: {
    fontSize: '.9rem',
    fontFamily: 'Source Sans Pro',
    // fontWeight: 400,
    // lineHeight: '1.5',
    fontStyle: 'Italic',
    margin: '1rem 0',
  },
  msrpTag: {
    fontFamily: 'Source Sans Pro',
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1.25rem',
    textTransform: 'Capitalize',
    color: 'rgb(96, 96, 96)',
    marginTop: '1rem',
  },
  ourTag: {
    fontFamily: 'Source Sans Pro',
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1.2rem',
    textTransform: 'Capitalize',
    color: 'black',
    marginBottom: '1rem',
  },
  viewButton: {
    background: '#ebebeb',
    minWidth: '12rem',
  },
  colorPanelHeight: {
    minHeight: '2rem',
    margin: '1rem auto',
  },
  recommendedText: {
    margin: '1rem 0',
    fontStyle: 'italic',
    color: 'dimGrey',
  },
  ribbonText: {
    //margin:'1rem',
    color: '#ef5136',
    fontSize: '1rem',
  },
  progMargin: {
    marginRight: '2rem',
  },
};

const RecommendedScreen = () => {
  const dispatch = useDispatch();
  const { loading, products } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(getAllRecommended());
  }, [dispatch]);

  return (
    <Container>
      <Menu3/>
      <Typography variant='h5' style={styles.spacerBot}>
        Recommended Picks
      </Typography>
      <hr style={styles.divider} />
      {!loading &&
        products?.map((product, index) => {
          try {
            let recString = product?.Recommended;
            let recArr = recString.split('#');
            var gridArr = [];
            for (let i = 0; i < recArr.length; i++) {
              if (recArr[i] == '') {
                continue;
              }

              gridArr.push(
                <Grid item sx={4}>
                  <img
                    src={ribbon}
                    alt='e'
                    style={{ height: '1.5rem', verticalAlign: 'middle' }}
                  />
                  <Typography variant='subtext'>{recArr[i]}</Typography>
                </Grid>,
              );
            }
          } catch {}

          return (
            <Grid item key={index}>
              <Grid
                container
                direction='row'
                justifyContent='center'
                // alignItems="center"
                alignItems='flex-start'
                style={styles.widthCorrector}
                spacing={6}>
                {/* Left */}

                <Grid item xs={12} sm={4}>
                  <div style={styles.imageContainer}>
                    <img
                      src={`/images/programs/${product?.Program}/render/${product?.SG_Color}.jpg`
                        .replaceAll('%', '')
                        .replaceAll(' ', '_')
                        .toLowerCase()}
                      style={styles.image}
                      alt='Window Render'
                    />
                  </div>
                </Grid>
                {/* Right */}

                <Grid item xs={12} sm={6}>
                  <Grid
                    container
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                    spacing={1}>
                    <Grid item sx={4} marginLeft='2rem'>
                      <Typography variant='h6' style={styles.progMargin}>
                        {product ? product?.Program : 'Missing Title'}
                      </Typography>
                    </Grid>
                    {gridArr}
                  </Grid>

                  {/* <Typography style={styles.programDesc}>{product && product?.Description ? product?.Description : "Missing Description"}</Typography> */}
                  <Typography style={styles.programDesc}>
                    {product && product?.Thumbnail_Description
                      ? product?.Thumbnail_Description
                      : 'Missing Description'}
                  </Typography>
                  <Grid direction='row' alignItems='center'>
                    <img
                      src={`/images/recommended/${product?.Recommended_photo}.jpg`}
                      style={styles.colorIcon}
                      alt='Person'
                    />
                    <Typography style={styles.recommendedText}>
                      {product?.Recommended_Desc
                        ? product?.Recommended_Desc
                        : ''}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Link
                      to={encodeURI(
                        `/products/name/${encodeURIComponent(
                          product.Program,
                        )}?color=${product?.SG_Color}`,
                      )}>
                      <Button style={styles.viewButton}>View Shade</Button>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <hr style={styles.divider} />
            </Grid>
          );
        })}
      {/* 
        <hr style={styles.divider} /> */}
    </Container>
  );
};

export default RecommendedScreen;
