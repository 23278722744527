export const wallpaperDimsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'WALL_DIM_SET':
          return {
            ...state,
            value: action.payload
          }   
        default: // need this for default case
          return {
            ...state,
            //value: action.payload
          } 
       }
}