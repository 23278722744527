/** @format */

import axios from 'axios';
const API = process.env.REACT_APP_OCS_API;


export const fetchReviewSummary = async (dispatch, productArr) => {
  var idString = '';
  let idArr = [];
  for (let i = 0; i < productArr.length; i++) {
    if (idArr.indexOf(productArr[i].shade_class) == -1) {
      idString += productArr[i].shade_class + '_';
      idArr.push(productArr[i].shade_class);
    }
  }

  let ret = await axios.get(
    `${API}api/review/reviewSummary?programs=` + idString,
  );

  dispatch({
    type: 'REV_SUM_SET',
    payload: ret.data,
  });
};

export const fetchProductReview = async (dispatch, product) => {
  let ret = await axios.get(
    `${API}api/review/programReviews?shade_class=` + product.shade_class,
  );

  dispatch({
    type: 'REV_CURRENT_SET',
    payload: ret.data.response,
  });
};

export const postProductReview = async (userId, product, review, rating, email, name, title, location) => {

  // const config = {
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: `Bearer ${userInfo.token}`,
  //   },
  // };

  let ret = await axios.post(
    `${API}api/review/makeReview`,
    {
      userID: userId,
      shade_class: product.shade_class,
      star: rating,
      reviewString: review,
      email: email,
      name: name,
      title: title,
      location: location,
    },
    // config,
  );
};
