import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import {productDetailsReducer, productListReducer, recommendedReducer} from './reducers/productReducers';

import {customizeReducer} from './reducers/customizeReducers';
import {cartReducer, swatchCartReducer} from './reducers/cartReducers';

import {userDetailsReducer, userLoginReducers, userRegisterReducer} from './reducers/userReducers';
import {getRatesReducer, orderCreateReducer, orderDetailsReducer, orderPayReducer} from './reducers/orderReducers';
import {wallpaperDimsReducer} from './reducers/wallpaperDimensionsReducers'
import {wallpaperFrameReducer} from './reducers/wallpaperFrameReducers'
import {filterReducer} from './reducers/filterReducer'
import {reviewSumaryReducer} from './reducers/reviewSummaryReducers';
import {productReviewReducer} from './reducers/productReviewReducers';
const reducer = combineReducers({
    products: productListReducer,
    product: productDetailsReducer,
    recommended: recommendedReducer,
    swatchCart: swatchCartReducer,
    cart: cartReducer,
    userLogin: userLoginReducers,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    orderCreate: orderCreateReducer,
    orderDetails: orderDetailsReducer,
    orderPay: orderPayReducer,
    rates: getRatesReducer,
    wallpaperDims: wallpaperDimsReducer,
    wallpaperFrame: wallpaperFrameReducer,
    filter: filterReducer,
    reviewSummary: reviewSumaryReducer,
    productReviews: productReviewReducer,
    customize: customizeReducer
});

const cartItemsFromStorage = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [];

const swatchCartItemsFromStorage = localStorage.getItem('swatchCartItems') ? JSON.parse(localStorage.getItem('swatchCartItems')) : [];

const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

const shippingAddressFromStorage = localStorage.getItem('shippingAddress') ? JSON.parse(localStorage.getItem('shippingAddress')) : {};

const swatchShippingAddressFromStorage = localStorage.getItem('swatchShippingAddress') ? JSON.parse(localStorage.getItem('swatchShippingAddress')) : {};

const userLoginFromStorage = localStorage.getItem('userLogin') ? JSON.parse(localStorage.getItem('userLogin')) : null;

const cartSummaryFromStorage = localStorage.getItem('cartSummary') ? JSON.parse(localStorage.getItem('cartSummary')) : {};

const ratesFromStorage = localStorage.getItem('rates') ? JSON.parse(localStorage.getItem('rates')) : {}

const customizeFromStorage = localStorage.getItem('customizeProduct') ? JSON.parse(localStorage.getItem('customizeProduct')) : {};

const initialState = {
    swatchCart: {
        swatchCartItems: swatchCartItemsFromStorage,
        swatchShippingAddress: swatchShippingAddressFromStorage
    },
    cart: {
        cartItems: cartItemsFromStorage,
        shippingAddress: shippingAddressFromStorage,
        cartSummary: cartSummaryFromStorage
    },
    customize: {
        customizeProduct: customizeFromStorage
    },
    userLogin: (userLoginFromStorage) ? userLoginFromStorage : {
        userInfo: userInfoFromStorage,
    },
    rates: {
        deliveryType: ratesFromStorage
    },
    wallpaperDims: {
        value: {
            width: 0,
            height: 0,
            frameSet: "rustic",
            colour: "#F0F0D3",
            strBack: "",
            patBack: "bumpy",
            patColour: "#000000",
            
            doc: 'user'
        }
    },
    wallpaperFrame: {
        value: {}
    },
    filter: {
        value: {
            'color': [],
            'light': [],
            'price': [],
            'type': [],
            'operation': [],
            'collection': []
        }
    },
    reviewSummary: {
        value: {}
    },
    productReviews: {
        value: []
    }
};

const middleware = [thunk];

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(... middleware)));

export default store;
