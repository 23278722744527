/** @format */

import { Container, Grid, Typography, Button } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { customizeProduct } from '../actions/blindActions';
import Menu3 from '../components/Menu3';

const CustomizeShade = () => {
  const dispatch = useDispatch();

  const styles = {
    atcButton: {
      // background: 'rgb(239, 64, 54)',
      border: 'none',
      minWidth: '12rem',
      marginRight: '1rem',
      marginTop: '1rem',
    },
  };

  const parseCustomization = (type) => {
    var product = null;
    var option = null;
    switch (type) {
      case 0: {
        product = {
          Program_id: 1,
          Program: 'Customized Roller Shade',
          Fabric_Type: 'All',
          Price_Range: '$',
          Type: 'Roller',
          Operation: 'Chain',
          Colour_Family: 'All',
          Price_Group: 0,
          Fabric_Code: 'Cottonwood-Milk-118',
          Fabric: 'Cottonwood',
          SG_Color: 'Milk',
          Min_Price: 0,
          Discount: 0.3,
          Chain_Color: '',
          Valance: 'Open Roll',
          Valance_Color: '-',
          Bottom_Bar: 'Plain Hem',
          Bottom_Bar_Color: 'Standard',
          Pick_A: 'NULL',
          Pick_B: 'Best Seller',
          Recommended: '#Blackout#$#Chain#The Dark Side',
          Recommended_Desc:
            '"Love This Fabric with 4 neutral colors selection and amazing price." - MIke',
          Recommended_photo: '3',
          Collection: 'All',
          Min_Width: 18,
          Max_Width: 100,
          Min_Height: 36,
          Max_Height: 120,
          Description:
            'The All-Purpose Black Out Shade ticks all the boxes; clean look, simple, timeless and functional. This room darkening shade is ideal for bedrooms or anywhere where room darkening is desired. The textured weave provides some interest and the neutral colour pallet will co-ordinate with any decor. Reverse roll softens the look, no valance required.  Easy to install and operate and very well priced.',
          Valance_info:
            'Our open roll shades offer a pleasing minimalist design. Reverse roll conceals the tube and prevents the shade from contacting window cranks and other obstacles',
          BottomBar_info:
            'The Deluxe Flat aluminum bar offers a contemporary, clean look. The colour will coordinate with the fabric selected.',
          Control_info:
            'Roller shades are operated using a manual, simple, dependable clutch and chain system.  The stainless steel chain is concealed in a Safety Shield.  White Safety Shield is provided for all fabric except for black, where a black Safety Shield will be provided.',
          Thumbnail_Description: 'Ideal where room darkening is desired',
          Design_Features:
            '100% Polyester, 4 colour selection, textured front and white fabric backing.',
          Inspiration:
            'The All Purpose Black out shade is great choice for price, function, athetic.  Excellent price for a blackout shade, great neutral colour pallet, looks amazing in the window.  Very durable, wipe clean fabric. iThe same fabric is offered in a privacy fabric if colour co-ordination is desired througout the house.',
          Collection_Description:
            'The Dark Side features Blackout shades.  All fabrics in the collection are opaque with white backing to completely block the sun and darken the room.  Enjoy better sleep by blocking  the sun light, moonlight, or street lights.  The simplicity of the shades and solid colour pallets gives you a clean canvas from which to accessorize your room.',
          shade_class: 0,
          Colors: ['White', 'Light Grey', 'Taupe', 'Black'],
          Price: 0,
          Valance_Price: 0,
          Operation_Price: {
            Price: 0,
          },
          fabricColorRadio: 'Cottonwood||Milk||Cottonwood-Milk-118',
          δ: 0.003,
          invalid: false,
          loading: true,
          position: 'Right',
          spring: null,
          originalPrice: '0.00',
          L_Channel: 'None',   
          defaultBottomBar: 'Plain Hem',
          Roll_Type: 'Regular',
          Wrap_Type: 'No',
          Hem_Wrap_Type: 'No',
        };
        option = {
          widthWhole: '24',
          widthFraction: '',
          lengthWhole: '36',
          lengthFraction: '',
          customize: true,
          selectedColor: 'Milk',
          measure: 'Tight',
          opacity: 1,
          OUR_PRICE: '0.00',
          RETAIL_PRICE: '0.00',
          quantity: 1,
        };
        break;
      }
      case 1: {
        product = {
          Program_id: 66,
          Program: 'Customized Banded Shade',
          Fabric_Type: 'All',
          Price_Range: '$',
          Type: 'Banded',
          Operation: 'Chain',
          Colour_Family: 'All',
          Price_Group: 0,
          Fabric_Code: 'Gossamer-Angel-110',
          Fabric: 'Gossamer',
          SG_Color: 'All',
          Min_Price: 0,
          Discount: 0.3,
          Chain_Color: '280',
          Valance: '',
          Valance_Color: '',
          Bottom_Bar: 'Interlude',
          Bottom_Bar_Color: 'White',
          Pick_A: 'NULL',
          Pick_B: 'NULL',
          Recommended: 'Banded',
          Recommended_Desc: '',
          Recommended_photo: '',
          Collection: 'All',
          Min_Width: 24,
          Max_Width: 90,
          Min_Height: 36,
          Max_Height: 100,
          Description:
            'The Toronto Banded Shade combines translucent fabric with tranparent vanes for total control over privacy and light. These vanes cascade around the system, allowing you to determine the perfect light and atmosphere for any room.  Chain operated, housed in a fabric Wrapped Curved Cassette.',
          Valance_info:
            'This shades includes a Curved Cassette wrapped in the same fabric as the shade to create a stunning window treatment.',
          BottomBar_info:
            'Tear drop shaped aluminum bottom bar; colour is set to match the valance.',
          Control_info:
            'Banded shades are operated using a manual, simple, dependable clutch and chain system.  The stainless steel chain is concealed in a Safety Shield.  White Safety Shield is provided for all fabric except for black, where a black Safety Shield will be provided.',
          Thumbnail_Description: 'Toronto Privacy Banded Shade with Valance',
          Design_Features: '100 % polyester material,  3 neutral colours',
          Inspiration:
            'Toronto Privacy Banded is a great entry level banded shade providing complete sunlight control.  The ribbing of the solid vanes and the horizontal lines of the transpartent vane provide a layering effect to add interest to even all white rooms.  The wrpped curved cassette finishes the look and allows for easy installation.',
          Collection_Description: 'NULL',
          shade_class: 10,
          Colors: ['Angel', 'White', 'Grey'],
          Price: 0,
          Valance_Price: 0,
          Operation_Price: {
            Price: 0,
          },
          fabricColorRadio: 'Gossamer||Angel||Gossamer-Angel-110',
          δ: 0.003,
          invalid: false,
          loading: true,
          position: 'Right',
          spring: null,
          originalPrice: '0.00',
          L_Channel: 'None',
          defaultBottomBar: 'Interlude',
          Roll_Type: 'Regular',
          Wrap_Type: 'No',
          Hem_Wrap_Type: 'No',
        };
        option = {
          widthWhole: '24',
          widthFraction: '',
          lengthWhole: '36',
          customize: true,
          lengthFraction: '',
          selectedColor: 'Angel',
          measure: 'Tight',
          opacity: 1,
          OUR_PRICE: '0.00',
          RETAIL_PRICE: '0.00',
          quantity: 1,
        };
        break;
      }
      case 2: {
        product = {
          Program_id: 86,
          Program: 'Customized Sheer Shade',
          Fabric_Type: 'All',
          Price_Range: '$$$',
          Type: 'Sheer',
          Operation: 'Chain',
          Colour_Family: 'All',
          Price_Group: 0,
          Fabric_Code: 'Natural Opaque-Dusk-110',
          Fabric: 'Natural',
          SG_Color: 'All',
          Min_Price: 292,
          Discount: 0.3,
          Chain_Color: '501',
          Valance: '',
          Valance_Color: '',
          Bottom_Bar: 'Illusion',
          Bottom_Bar_Color: 'White',
          Pick_A: 'NULL',
          Pick_B: 'NULL',
          Recommended: 'Sheer',
          Recommended_Desc: '',
          Recommended_photo: '',
          Collection: 'All',
          Min_Width: 24,
          Max_Width: 90,
          Min_Height: 36,
          Max_Height: 120,
          Description:
            'Elegance Sheer Shade combines transparent fabric with translucent vanes for total control over privacy and light. These vanes tilt open and closed allowing you to determine the perfect light and atmosphere for any room. Sheer shades create an elegant, sophisticated environment. Fabric wrapped curved cassette completes the look.',
          Valance_info:
            'This shades includes a Curved Cassette wrapped in the same fabric as the shade to create a stunning window treatment.',
          BottomBar_info:
            'Exclusive to the Sheer Shade, this aluminum bottom bar co-ordinates in colour to the fabric chosen.',
          Control_info:
            'Sheer Shades are operated using a manual, simple, dependable clutch and chain system.  The stainless steel chain is concealed in a Safety Shield.  White Safety Shield is provided for all fabric except for black, where a black Safety Shield will be provided.',
          Thumbnail_Description: 'Privacy Elegance Sheer Shade',
          Design_Features: '100% polyester in 3 colours',
          Inspiration:
            'With their  front and backÂ sheer fabric, sheer shades diffuse light and provide UV protection, and allow outward visibility. Tilting the vanes that float between the two sheer panels lets you control the amount of light entering your room. ',
          Collection_Description: 'NULL',
          shade_class: 15,
          Colors: ['Soft White', 'White', 'Grey'],
          Price: 0,
          Valance_Price: 0,
          Operation_Price: {
            Price: 0,
          },
          fabricColorRadio: 'Natural||Dusk||Natural Opaque-Dusk-110',
          δ: 0.003,
          invalid: false,
          loading: true,
          position: 'Right',
          spring: null,
          originalPrice: '0.00',
          L_Channel: 'None',
          defaultBottomBar: 'Illusion',
          Roll_Type: 'Regular',
          Wrap_Type: 'No',
          Hem_Wrap_Type: 'No',
        };
        option = {
          widthWhole: '24',
          widthFraction: '',
          lengthWhole: '36',
          customize: true,
          lengthFraction: '',
          selectedColor: 'Dusk',
          measure: 'Tight',
          opacity: 1,
          OUR_PRICE: '0.00',
          RETAIL_PRICE: '0.00',
          quantity: 1,
        };
        break;
      }
      default: {
        return;
      }
    }
    dispatch(
      customizeProduct({
        productInfo: { ...product },
        optionInfo: { ...option },
      }),
    ).then(() => {
      window.location.href = '/customize';
    });
  };

  return (
    <>
      <Menu3 />
      <Container>
        <section id='instructors' class='p-3'>
          <h2 class='text-black-50 text-center'>Design Your Shade</h2>
          <div class='row g-5'>
            <div class='col-md-6 col-lg-4'>
              <div class='card h-100'>
                <div class='card-body text-center'>
                  <img
                    class=' img-fluid'
                    src='/images/newHome/roller-shade-test-1 1.png'
                    alt=''
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      parseCustomization(0);
                    }}
                  />
                  <Button
                    class='btn btn-outline-dark my-4 mainPageBtn'
                    // style={styles.atcButton}
                    onClick={() => {
                      parseCustomization(0);
                    }}>
                    Roller Shade
                  </Button>
                  <div class='card-body'>
                    <h6>
                      Timeless and versatile. Roller shades are great window
                      coverings for any application. Completely block out light
                      using opaque / blackout fabrics, reduce glare and provide
                      UV protection while maintaining visibility using
                      sunscreens; or choose from light filtering fabric options,
                      privacy, translucent or transparent.
                    </h6>
                  </div>
                  <div class='card-footer'>
                    <h4 class='text-muted'>STARTING FROM $92</h4>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-md-6 col-lg-4'>
              <div class='card h-100'>
                <div class='card-body text-center'>
                  <img
                    class=' img-fluid'
                    src='/images/newHome/banded-shade-test-1 1.png'
                    alt=''
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      parseCustomization(1);
                    }}
                  />
                  <Button
                    class='btn btn-outline-dark my-4 mainPageBtn'
                    onClick={() => {
                      parseCustomization(1);
                    }}>
                    Banded Shade
                  </Button>
                  <div class='card-body'>
                    <h6>
                      A modernized approach to covering your windows. The two
                      layers of fabric allows you to transition between sheer
                      and privacy. Control light by alternating the vanes from
                      “open” to “close”. Leaving the vanes “open” will allow
                      outward views and natural light in. “Close” the vanes for
                      privacy and reduced light penetration.
                    </h6>
                  </div>
                  <div class='card-footer'>
                    <h4 class='text-muted'>STARTING FROM $214</h4>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-md-6 col-lg-4'>
              <div class='card h-100'>
                <div class='card-body text-center'>
                  <img
                    class=' img-fluid'
                    src='/images/newHome/sheer-shade-test-1 2.png'
                    alt=''
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      parseCustomization(2);
                    }}
                  />
                  <Button
                    class='btn btn-outline-dark my-4 mainPageBtn'
                    onClick={() => {
                      parseCustomization(2);
                    }}>
                    Sheer Shade
                  </Button>
                  <div class='card-body'>
                    <h6>
                      Elegant and luxurious. The solid vanes connect two sheer
                      fabric panels allowing natural light to diffuse into your
                      room. Tilting the vanes provides control over the amount
                      of diffused light as well as outward visibility giving you
                      complete control of light and privacy.
                    </h6>
                  </div>
                  <div class='card-footer'>
                    <h4 class='text-muted'>STARTING FROM $277</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <Typography variant='h2'>Design Your Shade</Typography> */}

        {/* <Grid container alignItems='left' style={{ marginTop: '20px' }}>
            <Grid
            item
            sm={4}
            style={{
              padding: '10px',
              marginRight: 'auto',
            }}>
            <img width='390' src='images/design/roller.jpg' />
            
            <Typography variant='h5'>ROLLER SHADES</Typography>
            
            <Button
            onClick={() => {
              parseCustomization(0);
            }}
            href='/customize'
                style={styles.atcButton}>
                Select
                </Button>
                
                <Typography variant='h6'>STARTING FROM $89</Typography>
                
                <hr />
                <p>
                Timeless and versatile. Roller shades are great window coverings for
                any application. Completely block out light using opaque / blackout
                fabrics, reduce glare and provide UV protection while maintaining
                visibility using sunscreens; or choose from light filtering fabric
                options, privacy, translucent or transparent.
                </p>
                </Grid>
                
                <Grid
                item
                sm={4}
                style={{
                  padding: '10px',
                  marginRight: 'auto',
                }}>
              <img width='390' src='images/design/zebra.jpg' />
              
              <Typography variant='h5'>BANDED SHADES</Typography>
              
              <Button
              onClick={() => {
                parseCustomization(1);
              }}
              href='/customize'
              style={styles.atcButton}>
              Select
              </Button>
              
              <Typography variant='h6'>STARTING FROM $227</Typography>
              
              <hr />
              <p>
                A modernized approach to covering your windows. The two layers of
                fabric allows you to transition between sheer and privacy. Control
                light by alternating the vanes from “open” to “close”. Leaving the
                vanes “open” will allow outward views and natural light in. “Close”
                the vanes for privacy and reduced light penetration.
                </p>
                </Grid>
                
                <Grid
                item
                sm={4}
                style={{
                  padding: '10px',
                  marginRight: 'auto',
                }}>
                <img width='390' src='images/design/sheer.jpg' />
                
                <Typography variant='h5'>SHEER SHADES</Typography>
                
                <Button
                onClick={() => {
                  parseCustomization(2);
                }}
                href='/customize'
                style={styles.atcButton}>
                Select
                </Button>
                
                <Typography variant='h6'>STARTING FROM $303</Typography>
                
                <hr />
                <p>
                Elegant and luxurious. The solid vanes connect two sheer fabric
                panels allowing natural light to diffuse into your room. Tilting the
                vanes provides control over the amount of diffused light as well as
                outward visibility giving you complete control of light and privacy.
                </p>
                </Grid>
                
                <hr />
              </Grid> */}
      </Container>
    </>
  );
};

export default CustomizeShade;
