/** @format */

import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { English } from '../localization/strings';
import Menu3 from '../components/Menu3';

const styles = {
  divider: {
    width: '100%',
    margin: '1rem auto',
  },
  heavyText: {
    fontWeight: 400,
  },
  lightText: {
    fontWeight: 300,
  },
  paragraph: {
    // fontSize: '1rem',
    margin: '1rem',
    fontWeight: 400,
    lineHeight: '2rem',
  },
  topSpacer: {
    marginTop: '2rem',
  },
  image: {
    width: '100%',
  },
  imageContainer: {
    width: '80%',
    margin: 'auto',
  },
  smallImage: {
    width: '20rem',
    height: '15rem',
    aspectRatio: 1,
    margin: 'auto',
    objectFit: 'cover',
  },
  smallText: {
    width: '80%',
    margin: '1rem auto',
    // fontSize: '1.1rem',
    lineHeight: '2rem',
  },
  infoContainer: {
    margin: '1.5rem auto',
    textAlign: 'center',
  },
  map: {
    width: '100%',
    height: '15rem',
    allowfullscreen: '',
    border: '1px solid red',
  },
  padding: {
    padding: '2rem',
  },
};

const AboutUsScreen = () => {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  let unused = window.onbeforeunload();
  const language = English;
  return (
    <>
      <Menu3 />
      <Container>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'>
          {/* About Us */}
          <Typography variant='h5' color='textSecondary'>
            About Us
          </Typography>
          <hr style={styles.divider} />
          <Grid style={styles.padding}>
            <Typography variant='body1' style={styles.paragraph}>
              {language.AboutUs}
            </Typography>
            <Typography variant='body1' style={styles.paragraph}>
              {language.AboutUs2}
            </Typography>
            <Typography variant='body1' style={styles.paragraph}>
              {language.AboutUs3}
            </Typography>
          </Grid>
          {/* Proudly Canadian */}
          <Typography variant='h6' color='textSecondary'>
            Shading Solutions Simplified!
          </Typography>
          <hr style={styles.divider} />
          <div style={styles.imageContainer}>
            <img
              style={styles.image}
              src={'/images/lifestyle/aboutUs.jpg'}
              alt='shade'
            />
          </div>
          <Grid style={styles.padding}>
            <Typography
              variant='body1'
              color='textSecondary'
              style={styles.paragraph}>
              {language.Canadian}
            </Typography>
          </Grid>
          <hr style={styles.divider} />

          {/* Local */}
          <Grid
            container
            direction='row-reverse'
            justifyContent='center'
            alignItems='center'
            style={styles.infoContainer}>
            <Grid item xs={12} sm={6}>
              <Typography variant='h6'>Local</Typography>
              <Typography
                variant='body1'
                color='textSecondary'
                style={styles.smallText}>
                {language.Local}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <img
                style={styles.smallImage}
                src='/images/lifestyle/local.jpg'
                alt='shade'
              />
            </Grid>
          </Grid>
          {/* Innovation */}
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            style={styles.infoContainer}>
            <Grid item xs={12} sm={6}>
              <Typography variant='h6'>Innovation</Typography>
              <Typography
                variant='body1'
                color='textSecondary'
                style={styles.smallText}>
                {language.Innovation}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <img
                style={styles.smallImage}
                src='/images/lifestyle/innovation.jpg'
                alt='shade'
              />
            </Grid>
          </Grid>
          <hr style={styles.divider} />

          <Grid
            container
            direction='row-reverse'
            justifyContent='center'
            alignItems='center'
            style={styles.infoContainer}>
            <Grid item xs={12} sm={6}>
              <Typography variant='h6'>Visit Our Showroom</Typography>
              <Typography
                variant='body1'
                color='textSecondary'
                style={styles.smallText}>
                Here we have on display all of our products and offering. Have
                one of our sales representatives walk you through all the steps
                necessary for ordering a shade.
              </Typography>
              <Typography variant='body1'>
                Phone : (416) 285-6001{''}
              </Typography>
              <Typography variant='body1'>
                Email : info@offcutshades.com
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <iframe
                style={styles.map}
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2883.798940277393!2d-79.31543274872995!3d43.714727279016856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cd1093170493%3A0x49fe692c4ded4364!2sOff%20Cut%20Shades!5e0!3m2!1sen!2sca!4v1579535251248!5m2!1sen!2sca'></iframe>
            </Grid>
          </Grid>
        </Grid>
        {/* <hr style={styles.divider} /> */}
      </Container>
    </>
  );
};

export default AboutUsScreen;
