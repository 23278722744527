/** @format */

import { React, useState } from 'react';
import Menu3 from '../components/Menu3';
import { useEffect, useRef } from 'react';
import { Container, Grid, Hidden, Snackbar } from '@material-ui/core';
import CustomizeDetails from '../components/CustomizeDetails';
import CustomizeFabric from '../components/CustomizeFabric';
import CustomizeBottomBar from '../components/CustomizeBottomBar';
import { useSelector } from 'react-redux';
import CustomizeDrive from '../components/CustomizeDrive';
import CustomizeValance from '../components/CustomizeValance';
import { Alert, AlertTitle } from '@material-ui/lab';
import CustomizeSummary from '../components/CustomizeSummary';
import LoadingOverlay from 'react-loading-overlay';

const styles = {
  msrpBar: {
    background: '#ebebeb',
    width: '100%',
  },

  disabledText: {
    color: '#9b9b9b',
    borderBottom: '1px solid #9b9b9b;',
    fontSize: '.8rem',
    // fontFamily: 'Lato',
    cursor: 'pointer',
  },
  heavyText: {
    fontWeight: 400,
  },
  heavyText2: {
    fontWeight: 400,
    fontSize: '1.5rem',
    textAlign: 'center',
    marginTop: '4rem',
  },
  panel: {
    background: '#e3e3e3d9',
    padding: '.5rem',
    marginBottom: '.5rem',
    textAlign: 'center',
    cursor: 'pointer',
  },
  priceText: {
    fontSize: '1rem',
    margin: '.5rem 0',
  },
  hide: {
    visibility: 'hidden',
  },
  atcButton: {
    background: '#ebebeb',
    minWidth: '12rem',
    marginRight: '1rem',
    marginTop: '1rem',
  },
  divider: {
    width: '100%',
    margin: '1rem auto',
  },
  darkText: {
    color: '#000',
    borderBottom: '1px solid #000;',
    fontSize: '0.8rem',
    // fontFamily: 'Lato',
  },
  headText: {
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'uppercase',
    padding: '.25rem 0',
    textAlign: 'left',
  },
  msrpTag: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'Capitalize',
  },
  ourTag: {
    textAlign: 'right',
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'Capitalize',
    color: '#E61610',
    whiteSpace: 'break-spaces',
  },
  removeButton: {
    border: 'none',
  },
  imageContainer: {
    width: '75%',
    margin: 'auto',
  },
  image: {
    width: '100%',
  },
  holder: {
    width: '100%',
  },
  renderWrapper: {
    flex: '50',
    height: '50vh',
  },
};

const Customise = () => {
  var prod = useSelector((state) => state.customize.customizeProduct);
  var { productInfo } = prod;
  var { optionInfo } = prod;
  var invalid = productInfo.invalid;

  const { userInfo } = useSelector((state) => state.userLogin);

  var [totalPrice, setTotalPrice] = useState(optionInfo.OUR_PRICE);
  var fabricPrice = useRef(0);

  var [renderFabric, setRenderFabric] = useState(
    productInfo.Fabric + ' ' + productInfo.SG_Color,
  );
  var tempHem = null;
  if (
    productInfo.Bottom_Bar.includes('Wrapped') &&
    productInfo.Bottom_Bar.includes('Accubar')
  )
    tempHem = 'wab';
  else if (productInfo.Bottom_Bar.includes('Accubar')) tempHem = 'ab';
  else if (productInfo.Bottom_Bar.includes('Plain Hem')) tempHem = 'ph';
  else if (productInfo.Bottom_Bar.includes('Illusion')) tempHem = 'ill';
  else if (productInfo.Bottom_Bar.includes('Interlude')) tempHem = 'int';
  tempHem =
    tempHem +
    '/' +
    (productInfo.Bottom_Bar == 'Plain Hem'
      ? 'standard'
      : productInfo.Bottom_Bar_Color);
  var [renderHem, setRenderHem] = useState(tempHem);

  const [disabled, setDisabled] = useState(true);

  const valanceRender = (name, color) => {
    switch (name.toLowerCase()) {
      case 'sb compact curved cassette':
        name = 'ccc';
        break;
      case 'open roll':
        name = 'or';
        break;
      case '3" fascia':
        name = '3f';
        break;
      case '4" fascia':
        name = '4f';
        break;
      case 'sb compact curved casette':
      case 'curved cassette s':
        name = 'd8';
        break;
      case 'curved cassette m':
        name = 'd12';
        break;
      case 'curved cassette l':
        name = 'd16';
        break;
      case 'pvc valance':
        name = 'pvc';
        break;
      case 'sb 3" fascia':
        name = '3f';
        break;
      case 'sb 4" fascia':
      case 'sb 4" wrapped fascia':
        name = '4f';
        break;
      case 'sb curved cassette':
      case 'sb wrapped curved cassette':
        name = 'd12';
        break;
      default:
        name = 'or';
        break;
    }
    return 'render/valance/' + name + '/' + color + '.png';
  };

  var [renderValance, setRenderValance] = useState(
    valanceRender(productInfo.Valance, productInfo.Valance_Color),
  );

  const displayDetails = () => {
    page.current = 1;
    var t1 = document.getElementById('t1');
    t1.style = styles.darkText;

    var toChange = document.getElementById('details');
    toChange.style.display = 'block';
  };

  var priceToDisplay = (totalPrice - totalPrice * productInfo.Discount).toFixed(
    2,
  );
  const saving = (totalPrice - priceToDisplay).toFixed(2);

  useEffect(() => {
    displayDetails();

    setTimeout(() => {
      setDisabled(false);
    }, 4000);
  }, []);

  // Variables for functions
  var page = useRef(0);

  var [switchPage, setSwitchPage] = useState(0);

  useEffect(() => {
    if (invalid) {
      displayDetails();

      for (let i = 2; i < 7; i++) {
        var toHide = document.getElementById('t' + i);

        toHide.style.color = '#9b9b9b';
        toHide.style.fontSize = '0.8rem';
      }
    }
  }, [invalid, page.current]);

  const changePage = (pageNo) => {
    if (pageNo == page.current || disabled) return;
    setSwitchPage(page.current);
    console.log(page.current);
    console.log(pageNo);

    switch (pageNo) {
      case 1:
        page.current = 1;
        break;
      case 2:
        page.current = 2;
        break;
      case 3:
        page.current = 3;
        break;
      case 4:
        page.current = 4;
        break;
      case 5:
        page.current = 5;
        break;
      case 6:
        page.current = 6;
        break;
      default:
        page.current = 1;
        break;
    }

    console.log(page.current);
    console.log(switchPage);
  };

  return (
    <>
      <Menu3 />
      <Container>
        <LoadingOverlay
          active={disabled}
          spinner
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'rgba(255, 255, 255, 0.5)',
              display: page.current == 1 ? 'block' : 'none',
            }),
            spinner: (base) => ({
              ...base,
              width: '100px',
              '& svg circle': {
                stroke: '#ef4036',
                display: page.current == 1 ? 'block' : 'none',
              },
            }),
          }}>
          {/* <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            style={styles.panel}
            spacing={0}>
            <Grid
              item
              sm={2}
              id='t1'
              style={styles.disabledText}
              onClick={() => {
                changePage(1);
              }}>
              <p>1. Details</p>
            </Grid>
           
            <Grid
              item
              md={2}
              id='t2'
              style={styles.disabledText}
              onClick={() => {
                changePage(2);
              }}>
              <p>2.Fabric</p>
            </Grid>
      
           
            <Grid
              item
              md={2}
              id='t3'
              style={styles.disabledText}
              onClick={() => {
                changePage(3);
              }}>
              <p>3. Bottom Bar</p>
            </Grid>
           
           
            <Grid
              item
              md={2}
              id='t4'
              style={styles.disabledText}
              onClick={() => {
                changePage(4);
              }}>
              <p>4. Drive</p>
            </Grid>
           
           
            <Grid
              item
              md={2}
              id='t5'
              style={styles.disabledText}
              onClick={() => {
                changePage(5);
              }}>
              <p>5. Valance</p>
            </Grid>
         
           
            <Grid
              item
              md={2}
              id='t6'
              style={styles.disabledText}
              onClick={() => {
                changePage(6);
              }}>
              <p>6. Summary</p>
            </Grid>

         
          </Grid> */}
          
          <div className='stepper-wrapper '>
            <div
              id='t1'
              className={
                page.current == 1
                  ? 'stepper-item active'
                  : page.current > 1
                  ? 'stepper-item completed'
                  : 'stepper-item active'
              }
              onClick={() => {
                changePage(1);
              }} style={styles.disabledText}>
              <div className='step-counter'>1</div>
              <div className='step-name'>Details</div>
            </div>
            <div
              id='t2'
              className={
                page.current == 2
                  ? 'stepper-item active'
                  : page.current > 2
                  ? 'stepper-item completed'
                  : 'stepper-item active'
              }
              onClick={() => {
                changePage(2);
              }} style={styles.disabledText}>
              <div className='step-counter'>2</div>
              <div className='step-name'>Fabric</div>
            </div>
            <div
              id='t3'
              className={
                page.current == 3
                  ? 'stepper-item active'
                  : page.current > 3
                  ? 'stepper-item completed'
                  : 'stepper-item active'
              }
              onClick={() => {
                changePage(3);
              }} style={styles.disabledText}>
              <div className='step-counter'>3</div>
              <div className='step-name'>Bottom Bar</div>
            </div>
            <div
              id='t4'
              className={
                page.current == 4
                  ? 'stepper-item active'
                  : page.current > 4
                  ? 'stepper-item completed'
                  : 'stepper-item active'
              }
              onClick={() => {
                changePage(4);
              }} style={styles.disabledText}>
              <div className='step-counter'>4</div>
              <div className='step-name'>Control</div>
            </div>
            <div
              id='t5'
              className={
                page.current == 5
                  ? 'stepper-item active'
                  : page.current > 5
                  ? 'stepper-item completed'
                  : 'stepper-item active'
              }
              onClick={() => {
                changePage(5);
              }} style={styles.disabledText}>
              <div className='step-counter'>5</div>
              <div className='step-name'>Valance</div>
            </div>
            <div
              id='t6'
              className={
                page.current == 6
                  ? 'stepper-item active'
                  : page.current > 6
                  ? 'stepper-item completed'
                  : 'stepper-item active'
              }
              onClick={() => {
                changePage(6);
              }} style={styles.disabledText}>
              <div className='step-counter'>6</div>
              <div className='step-name'>Summary</div>
            </div>
          </div>
          <Grid container direction='row' justifyContent='center'>
            {/* Left Image */}
            <Grid item xs={12} sm={6}>
              <Grid style={styles.imageContainer}>
                <div class='render-wrapper'>
                  <div id='cContainer'>
                    <img
                      id='render-bg'
                      style={styles.holder}
                      src='/images/render/render_base.png'
                    />
                    <img
                      id='render-fab'
                      className='holder'
                      style={styles.holder}
                      // src='/images/render/rustica opaque black/fabric.png'
                      src={
                        '/images/render/' +
                        renderFabric.toLowerCase() +
                        '/fabric.png'
                      }
                    />
                    <img
                      id='render-val'
                      style={styles.holder}
                      // src='/images/render/option/valance/or/or.png'
                      src={'/images/' + renderValance.toLowerCase()}
                    />
                    <img
                      id='render-val-insert'
                      style={styles.holder}
                      // src='/images/render/empty.png'
                      src={'/images/' + renderValance.toLowerCase()}
                    />
                    <img
                      id='render-hem'
                      style={styles.holder}
                      // src='/images/render/option/hem/ph.png'
                      src={
                        '/images/render/hem/' + renderHem.toLowerCase() + '.png'
                      }
                    />
                    <img
                      id='render-hem-insert'
                      style={styles.holder}
                      // src='/images/render/empty.png'
                      src={
                        '/images/render/hem/' + renderHem.toLowerCase() + '.png'
                      }
                    />
                  </div>
                </div>

                <div className='productTotalsInnerWrap'>
                  <div id='productTotals' className=''>
                    <div className='firstProductSection'>
                      <div className='totalLine'>
                        <label className='productPriceInfo'>Shade Price:</label>
                        <div
                          className='total'
                          style={{ textDecoration: 'line-through' }}>
                          ${totalPrice}
                        </div>
                      </div>
                      <div className='totalLine totalLineRed'>
                        <label className='productPriceInfo'>
                          You Save 30%:
                        </label>
                        <div className='total'>${saving}</div>
                      </div>
                      <div className='totalLine'>
                        <label className='productPriceInfo'>Shipping:</label>
                        <div className='total'>FREE</div>
                      </div>
                      <div
                        className='totalLine sale'
                        style={{
                          paddingBottom: 0,
                          marginBottom: 15,
                          borderTop: '1px solid #ccc',
                        }}>
                        <label className='totalLine totalLineRed'>
                          Sale Price:
                        </label>
                        <div className='total'>${priceToDisplay}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* Details Component */}
              <CustomizeDetails
                page={page}
                totalPrice={totalPrice}
                setTotalPrice={setTotalPrice}
                productInfo={productInfo}
                optionInfo={optionInfo}
                switchPage={switchPage}
                setSwitchPage={setSwitchPage}
                fabricPrice={fabricPrice}
                setDisabled={setDisabled}
              />
              {/* Fabric Component */}
              <CustomizeFabric
                page={page}
                totalPrice={totalPrice}
                setTotalPrice={setTotalPrice}
                productInfo={productInfo}
                optionInfo={optionInfo}
                switchPage={switchPage}
                setSwitchPage={setSwitchPage}
                fabricPrice={fabricPrice}
                renderFabric={renderFabric}
                setRenderFabric={setRenderFabric}
                setDisabled={setDisabled}
              />
              {/* Bottom Bar Component */}
              <CustomizeBottomBar
                page={page}
                totalPrice={totalPrice}
                setTotalPrice={setTotalPrice}
                productInfo={productInfo}
                optionInfo={optionInfo}
                switchPage={switchPage}
                setSwitchPage={setSwitchPage}
                renderHem={renderHem}
                setRenderHem={setRenderHem}
                setDisabled={setDisabled}
              />
              {/* Drive Component */}
              <CustomizeDrive
                page={page}
                totalPrice={totalPrice}
                setTotalPrice={setTotalPrice}
                productInfo={productInfo}
                optionInfo={optionInfo}
                switchPage={switchPage}
                setSwitchPage={setSwitchPage}
                fabricPrice={fabricPrice}
                setDisabled={setDisabled}
              />
              {/* Valance Component */}
              <CustomizeValance
                page={page}
                totalPrice={totalPrice}
                setTotalPrice={setTotalPrice}
                productInfo={productInfo}
                optionInfo={optionInfo}
                switchPage={switchPage}
                setSwitchPage={setSwitchPage}
                fabricPrice={fabricPrice}
                setRenderValance={setRenderValance}
                setDisabled={setDisabled}
              />
              {/* Summary Component */}
              <CustomizeSummary
                page={page}
                totalPrice={totalPrice}
                productInfo={productInfo}
                optionInfo={optionInfo}
                switchPage={switchPage}
                setSwitchPage={setSwitchPage}
                userInfo={userInfo}
                setDisabled={setDisabled}
              />
            </Grid>
          </Grid>
          <Snackbar open={invalid} autoHideDuration={6000}>
            <Alert severity='error'>
              <AlertTitle>
                An error has occured while retreiving the data. The values are
                not compatible. Click 'Next' after changing values.{' '}
              </AlertTitle>
            </Alert>
          </Snackbar>

          <div style={styles.divider} />
        </LoadingOverlay>
      </Container>
    </>
  );
};
export default Customise;
