/** @format */

import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { English } from '../../localization/strings';
import RollerPDF from '../../pdf/Open Roll.pdf';
import CcPDF from '../../pdf/Curved Cassettes.pdf';
import Fascia from '../../pdf/Fascia.pdf';
import ZeroGravity from '../../pdf/Zero Gravity OR SF.pdf';
import Aria from '../../pdf/Aria.pdf';
import Neo from '../../pdf/Neo.pdf';
import SGPDF from '../../pdf/OCS - Specifications & Programming - OCS Rechargeable Motors.pdf';
import Menu3 from '../../components/Menu3';

// import pic1 from './openroll.png';

const styles = {
  heavyText: {
    fontWeight: 400,
    textAlign: 'center',
  },
  subText: {
    textAlign: 'center',
    fontWeight: 300,
    width: '80%',
    margin: '1rem auto',
  },
  listStyle: {
    listStyle: 'none',
    fontSize: '1.5rem',
    fontWeight: '300',
    padding: 0,
    margin: '1.5rem auto',
  },
  itemSpacer: {
    margin: '1.25rem',
    textAlign: 'center',
    fontSize: '1.2rem',
  },
  divider: {
    width: '100%',
    borderTop: '1px solid black',
    marginTop: '1rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  button: {
    background: '#ebebeb',
    minWidth: '10rem',
    margin: '1rem',
  },

  card: {
    maxWidth: 300,
    margin: 'auto',
    marginBottom: '2rem',
    transition: '0.3s',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.5)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
      // backgroundColor: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
    },
  },

  media: {
    width: '10rem',
    height: '10rem',
    alignItems: 'center',
    margin: '1rem',
  },
  content: {
    textAlign: 'center',
  },
  heading: {
    fontWeight: 'bold',
  },
  subheading: {
    lineHeight: 1.8,
  },
  avatar: {
    display: 'inline-block',
    border: '2px solid white',
  },
  imageContainer: {
    width: '70%',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    // marginLeft: '6rem',
  },
  video: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    border: '1px solid grey',
  },
  heavyText1: {
    fontWeight: 400,
    textAlign: 'center',
    marginTop: '2rem',
  },
  align: {
    alignSelf: 'center',
    textAlign: 'center',
  },
};

const InstallationScreen = () => {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  let unused = window.onbeforeunload();
  const language = English;

  const cardDetails = [
    {
      type: 'Open Roll-Reverse Roll',
      desc: 'Open roll shades offer a pleasing minimalist design. Reverse roll conceals the tube and prevents the shade from contacting window cranks and other obstacles',
      image: '/images/in/open roll - reverse roll.png',
      pdf: RollerPDF,
    },
    {
      type: 'Fascia 3" & 4"',
      desc: 'Conceals roller and components for a very clean and contemporary appearance. Universal bracket suits all mounting conditions.',
      image: '/images/in/fascia 3inch.png',
      pdf: Fascia,
    },
    {
      type: 'Aria',
      desc: 'The Aria Chainless Shade system is a simple and contemporary solution for smaller shades. Available in White and Grey, chosen to compliment the fabric selected',
      image: '/images/in/aria.png',
      pdf: Aria,
    },
    {
      type: 'Curved Cassette',
      desc: 'Two headrail sizes accommodate greater range of drop length possibilities and use of thicker fabrics. Fully assembled design makes for quick and easy installation.',
      image: '/images/in/wrapped curved cassette.png',
      pdf: CcPDF,
    },
    {
      type: 'Rechargeable Motor',
      desc: 'Rechargeable motorization line features robust motors with bi-directional communication. Rechargeable motors make automating your shades simple and affordable.',
      image: '/images/in/motor.png',
      pdf: SGPDF,
    },
  ];

  return (
    <div>
     <Menu3/>
      <div class='container '>
        <div class='p-4 ' style={styles.align}>
          <img
            src='/images/newHome/Installation-Guides-80x80.png'
            class='img-fluid rounded-start'
            alt='...'
          />
        </div>
        <h4 class='my-2 text-center fw-bolder'>Installation Guides</h4>
        
        <div class=' p-4'>
          <p class='lead my-2 text-center'>
            Our easy-to-follow instructions will walk you through the
            installation process step-by-step. In most cases, you will only need
            4 screws (included) and a screwdriver to install your new shades.
            You can get them installed in a few minutes. Additionally, you can
            always count on us if you have any questions during the installation
            process.
          </p>
        </div>
        <Grid
          container
          spacing={2}
          direction='row'
        
          alignItems='flex-start'>
          {cardDetails.map((cardDetail) => (
            <Card style={styles.card}>
              <CardMedia style={styles.media}>
                <img
                  src={cardDetail.image}
                  alt={cardDetail.type}
                  style={styles.media}
                />
              </CardMedia>
              <CardContent style={styles.content}>
                <Typography variant='h6' gutterBottom>
                  {cardDetail.type}
                </Typography>
                {/* <Grid>
                <Typography variant='ception'>{cardDetail.desc}</Typography>
              </Grid> */}
                <Divider light />
                <Button
                  href={cardDetail.pdf}
                  target='_blank'
                  style={styles.button}>
                  Download PDF
                </Button>
              </CardContent>
            </Card>
          ))}
        </Grid>
        <h4 class='my-2 text-center fw-bolder'>Wallpaper Installation</h4>
        <hr style={styles.divider} />
        <div class=' p-4'>
          <p class='lead my-2 text-center'>
            You can hire an expert installer, but it's easy enough to put up our
            peel and stick wallpaper yourself. Just make sure your walls are
            clean and smooth then follow the instructions in the video below.
          </p>
          <Grid style={styles.imageContainer}>
            <video controls muted style={styles.video}>
              <source
                src='https://filedn.com/lkm5O5AzyMUQoB4pAxPAElH/OCS/installation/OCS%20Wallpaper%20Installation.mp4'
                type='video/mp4'></source>
            </video>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default InstallationScreen;
