/** @format */

import React, { useEffect } from 'react';
import {
  Button,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { customizeProduct } from '../actions/blindActions';
import { Loader } from './Loader';
import { calculateTotalPrice } from '../actions/blindActions';
import InfoIcon from '@mui/icons-material/Info';
import './menu.css';
import { Alert, AlertTitle } from '@material-ui/lab';
import ImageZoom from './ImageZoom';

const CustomizeValance = ({
  page,
  productInfo,
  optionInfo,
  totalPrice,
  setTotalPrice,
  switchPage,
  setSwitchPage,
  fabricPrice,
  setRenderValance,
}) => {
  var dispatch = useDispatch();

  var [valance, setValance] = useState(productInfo.Valance);
  var [valanceColor, setValanceColor] = useState(productInfo.Valance_Color);

  var valanceArray = productInfo.valanceArray;
  var [lChannel, setLChannel] = useState(productInfo.L_Channel);
  var [selectedRollType, setSelectedRollType] = useState(productInfo.Roll_Type);
  var [selectedWrapType, setSelectedWrapType] = useState(productInfo.Wrap_Type);
  var [channelPrice, setChannelPrice] = useState(null);
  const [data, setData] = useState(null);

  var [errorToast, setErrorToast] = useState(false);

  const shade = productInfo.Type;

  const styles = {
    divider: {
      width: '100%',
      margin: '1rem auto',
    },
    leftHeader: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    items: {
      margin: '1rem',
      padding: '2rem',
    },
    image: {
      width: '150px',
      height: '150px',
      marginBottom: '2rem',
      objectFit: 'cover',
    },
    atcButton: {
      // background: '#ebebeb',
      minWidth: '10rem',
      marginRight: '1rem',
      marginTop: '1rem',
    },
    sgBoxHemBox: {
      display: 'inline-block',
      width: '40%',
      borderRadius: '10%',
      transition: 'transform .5s',
      position: 'relative',
      cursor: 'pointer',
      margin: '0.5em 1em',
    },
    sgBoxRight: {
      textAlign: 'right',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    checkText: {
      margin: 'auto',
      fontSize: '1em',
      textTransform: 'capitalize',
      width: '70%',
      paddingBottom: '0.2em',
      display: 'inherit',
    },
    sgBoxLeft: {
      textAlign: 'left',
      display: 'inline-block',
      width: '50%',
      verticalAlign: 'middle',
    },
    sgBoxImageHem: {
      width: '100%',
      borderRadius: '10%',
      transition: 'transform .5s',
      position: 'relative',
      zIndex: 0,
    },
    colorLabel: {
      display: 'inline-block',
      width: '5em',
      height: '1.3em',
      textOverflow: 'clip',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      verticalAlign: 'sub',
      border: '#3f3f3f 1px solid',
      borderRadius: '3px',
      boxSizing: 'border-box',
      padding: '0',
    },
    colorRadio: {
      display: 'inline-block',
      fontFamily: 'Neue_55',
      fontSize: '12pt',
      lineHeight: 1.15,
      margin: '0 10px',
      overflow: 'visible',
      border: 'none',
      maxWidth: '300px',
      height: '30px',
      fontStyle: 'italic',
      paddingLeft: '1%',
      border: 'none',
      width: 'auto',
      height: 'auto',
      margin: '0 10px',
      fontSize: '11px',
      fontFamily: 'Neue_55',
      fontStyle: 'italic',
      paddingLeft: '1%',
    },
  };

  const handleValance = (id, isWrapValance) => {
    console.log('id ' + id);

    setValance(id);
    valance = id;

    if (valance == 'Open Roll') setValanceColor('-');

    if (isWrapValance && selectedWrapType == 'Yes' && !id.includes('Wrapped'))
      if (id.includes('3"')) {
        getPrice(id.replace('SB 3"', 'SB 3" Wrapped'));
        valanceRender(id.replace('SB 3"', 'SB 3" Wrapped'), valanceColor);
      } else if (id.includes('4"')) {
        getPrice(id.replace('SB 4"', 'SB 4" Wrapped'));
        valanceRender(id.replace('SB 4"', 'SB 4" Wrapped'), valanceColor);
      } else {
        getPrice(id.replace('SB', 'SB Wrapped'));
        valanceRender(id.replace('SB', 'SB Wrapped'), valanceColor);
      }
    else {
      getPrice(id);
      valanceRender(id, valanceColor);
    }
  };

  const valanceRender = (name, color) => {
    console.log('name ' + name);

    switch (name.toLowerCase()) {
      case 'sb compact curved cassette':
        name = 'ccc';
        break;
      case 'open roll':
        name = 'or';
        break;
      case '3" fascia':
        name = '3f';
        break;
      case '4" fascia':
        name = '4f';
        break;
      case 'sb compact curved casette':
      case 'curved cassette s':
        name = 'd8';
        break;
      case 'curved cassette m':
        name = 'd12';
        break;
      case 'curved cassette l':
        name = 'd16';
        break;
      case 'pvc valance':
        name = 'pvc';
        break;
      case 'sb 3" fascia':
        name = '3f';
        break;
      case 'sb 4" fascia':
      case 'sb 4" wrapped fascia':
        name = '4f';
        break;
      case 'sb curved cassette':
      case 'sb wrapped curved cassette':
        name = 'd12';
        break;
      default:
        name = 'or';
        break;
    }

    console.log('name ' + name);
    setRenderValance('render/valance/' + name + '/' + color + '.png');
  };

  useEffect(() => {
    console.log('fetch ' + valanceArray);
    if (valanceArray != null) {
      let temp = [];
      temp.push(valanceArray[0].LChannel);
      temp.push(valanceArray[0].LBChannel);

      setChannelPrice(temp);
    }
    showValanceOptions();
  }, [
    valanceArray,
    valance,
    valanceColor,
    productInfo.Operation,
    productInfo.Valance,
    productInfo.L_Channel,
    selectedRollType,
    selectedWrapType,
  ]);

  const handleValanceColor = (e) => {
    valanceColor = e.target.value;
    console.log('valanceColor ' + valanceColor);

    setValanceColor(e.target.value);
    if (errorToast) {
      setErrorToast(false);
    }
    valanceRender(valance, e.target.value);
  };

  const handleLChannel = (e) => {
    lChannel = e.target.value;
    setLChannel(e.target.value);
    getPrice(valance);
  };

  const forwardPage = () => {
    if (!valanceColor || (valanceColor == '-' && valance != 'Open Roll')) {
      setErrorToast(true);
      return;
    }
    page.current = 6;
    dispatchDetails();
    var toChange = document.getElementById('valance');
    toChange.style.display = 'none';
    var t5 = document.getElementById('t5');

    t5.style.color = '#9b9b9b';
    t5.style.fontSize = '0.8rem';

    var t6 = document.getElementById('t6');
    t6.style = styles.darkText;

    toChange = document.getElementById('summary');
    toChange.style.display = 'block';
    return;
  };

  const previousPage = () => {
    page.current = 4;
    dispatchDetails();
    var toChange = document.getElementById('valance');
    toChange.style.display = 'none';
    var t5 = document.getElementById('t5');

    t5.style.color = '#9b9b9b';
    t5.style.fontSize = '0.8rem';

    var t4 = document.getElementById('t4');
    t4.style = styles.darkText;

    toChange = document.getElementById('drive');
    toChange.style.display = 'block';
  };

  const dispatchDetails = () => {
    if (selectedWrapType == 'Yes' && !valance.includes('Wrapped')) {
      if (valance.includes('3"'))
        valance = valance.replace('SB 3"', 'SB 3" Wrapped');
      else if (valance.includes('4"'))
        valance = valance.replace('SB 4"', 'SB 4" Wrapped');
      else valance = valance.replace('SB', 'SB Wrapped');
    }

    productInfo.Valance = valance;
    productInfo.Valance_Color = valanceColor;
    productInfo.L_Channel = lChannel;
    productInfo.Roll_Type = selectedRollType;
    productInfo.Wrap_Type = selectedWrapType;
    productInfo.Program = 'Customized ' + productInfo.Type + ' Shade';
    let temp = totalPrice.toString().split('\n')[0];
    console.log(temp);

    optionInfo.OUR_PRICE = +temp;
    productInfo.series = productInfo.SG_Color;
    dispatch(
      customizeProduct({
        productInfo: {
          ...productInfo,
        },
        optionInfo: {
          ...optionInfo,
        },
      }),
    );
  };

  useEffect(() => {
    if (switchPage && page.current == 5) {
      var t5 = document.getElementById('t5');
      t5.style = styles.darkText;

      var toHide = document.getElementById('t' + switchPage);

      toHide.style.color = '#9b9b9b';
      toHide.style.fontSize = '0.8rem';
      setSwitchPage(0);
    }
  }, [switchPage]);

  const getPrice = (valance) => {
    if (page.current != 5) return;
    let width = optionInfo.widthWholeFrac
      ? parseFloat(optionInfo.widthWhole) +
        parseFloat(optionInfo.widthWholeFrac)
      : optionInfo.widthWhole;
    let length = optionInfo.lengthWholeFrac
      ? parseFloat(optionInfo.lengthWhole) +
        parseFloat(optionInfo.lengthWholeFrac)
      : optionInfo.lengthWhole;

    if (length % 1 !== 0) {
      length = eval(length);
    }
    if (width % 1 !== 0) {
      width = eval(width);
    }

    let shade = productInfo.Type + ' Shade';
    let priceGroup = productInfo.Price_Group;
    let drive = productInfo.Operation;
    let channel = lChannel;
    console.log('channel ' + channel);
    console.log('valance ' + valance);
    let quantity = optionInfo.quantity;

    calculateTotalPrice(
      length,
      width,
      shade,
      priceGroup,
      drive,
      valance,
      channel,
    ).then((tp) => {
      if (tp.hasOwnProperty('type')) {
        console.log(tp);
        return;
      }
      console.log(quantity);
      let temp = quantity * tp.totalPrice;

      temp = Math.round(temp * 100) / 100;
      totalPrice = temp;
      // if (fabricPrice.current && productInfo.Type != 'Sheer')
      //   temp += ` \n(Extra $${fabricPrice.current} For Selected Fabric)`;
      // if (drive == 'Motor') temp += ` \n(Extra $${tp.drivePrice} For Motor)`;
      // if (drive == 'Zero Gravity')
      //   temp += ` \n(Extra $${tp.drivePrice} For Zero Gravity)`;
      // if (tp.valancePrice) temp += ` \n(Extra $${tp.valancePrice} For Valance)`;
      // if (lChannel != 'None')
      //   temp += `\n(Extra $${tp.channelPrice} For L-Channel)`;
      setTotalPrice(temp);
      console.log(totalPrice);
    });
  };

  const showValanceOptions = () => {
    if (valanceArray != null) {
      if (valanceColor == 'Dark Bronze') valanceColor = 'Anodized';

      if (valance.includes('inch')) {
        valance = valance.replace('inch', '"');
        let temp = valance.split(' ');
        valance = temp[1] + ' ' + temp[0];
      }
      // Remove the ones that have 'wrapped' in it
      valanceArray = valanceArray.filter((index) => {
        return !index.valance.includes('Wrapped');
      });

      // Check if valance in valanceArray has valance, if not then set it to first index
      if (
        !valanceArray.some(
          (index) => index.valance == valance.replace(' Wrapped', ''),
        )
      ) {
        setValance(valanceArray[0].valance);
      }
      setData(valanceArray);
    }
  };

  return (
    <>
      {' '}
      {/* 5. Valance */}
      <Grid
        container
        id='valance'
        visible='none'
        direction='row'
        style={page.current == 5 ? { display: 'block' } : { display: 'none' }}
        justifyContent='space-around'
        alignItems='flex-center'
        spacing={1}
        onHide={switchPage && dispatchDetails()}>
        <Grid>
          {data && (
            <Grid>
              <p style={styles.leftHeader}>5. Select Valance</p>
            <hr className='divider' />

              <Grid
                container
                direction='row'
                justifyContent='flex-start'
                alignItems='center'>
                {data ? (
                  data.map((index) => {
                    if (
                      index.valance.includes('SB') &&
                      !valance.includes('SB')
                    ) {
                      valance = 'SB ' + valance;
                    }
                    if (
                      !index.valance.includes('SB') &&
                      valance.includes('SB')
                    ) {
                      valance = valance.replace('SB ', '');
                    }
                    valance = valance.replace(' - Reverse Roll', '');
                    return (
                      <>
                        <div
                          className={
                            index.valance.toLowerCase() ==
                            valance
                              .toLowerCase()
                              .replace('sb open roll', 'open roll')
                              .replace(' wrapped', '')
                              ? 'featureOption selected optionsBoxMainValance'
                              : 'featureOption optionsBoxMainValance'
                          }
                          onClick={() => {
                            handleValance(
                              index.valance,
                              index?.wrapPrice ? true : false,
                            );
                          }}>
                          {index.valance.toLowerCase() ==
                            valance
                              .toLowerCase()
                              .replace('sb open roll', 'open roll') && (
                            <span
                              className='featureOptioncheckmark'
                              style={{ display: 'block' }}></span>
                          )}
                          <div
                            className='option feature optionFeatures'
                            style={{ height: '132.406px' }}>
                            <div
                              className='image optionsFeatures'
                              style={{
                                backgroundImage: `url(${encodeURI(index.img)})`,
                              }}>
                             <ImageZoom imageUrl={encodeURI(index.img)} />
                              <div className='overlay'></div>
                            </div>
                          </div>

                          <div className='featureText withMediaIcons'>
                            <div className='featureoptionradio' style={{}}>
                              <div
                                className='customRadio radioFeatureButton'
                                style={{ float: 'left', left: '-2px' }}>
                                <span className='radio selected'>
                                  <span className='checked'></span>
                                </span>
                                <input
                                  defaultChecked='checked'
                                  groupname='Feature1320'
                                  id='SelectedProductFeatureOptions3743'
                                  name='AvailableFeatures[9].FeatureOptions[2].Selected'
                                  type='checkbox'
                                  defaultValue='true'
                                />
                                <input
                                  name='AvailableFeatures[9].FeatureOptions[2].Selected'
                                  type='hidden'
                                  defaultValue='false'
                                />
                                <input
                                  id='AvailableFeatures_9__FeatureOptions_2__OriginallySelected'
                                  name='AvailableFeatures[9].FeatureOptions[2].OriginallySelected'
                                  type='hidden'
                                  defaultValue='True'
                                />
                              </div>
                              <div style={{}} className=' featureOptionNamelbl'>
                                <label className='featureOptionName'>
                                  {index.valance}
                                  <br />
                                  <span className='dynamicPrice'>
                                    +${index.price}
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div style={{ float: 'right', paddingTop: 6 }}>
                              {/* <Tooltip
                    title={
                      <>
                        <span style={styles.spanInfo}>
                          Tight : 1/8” deducated from Width.
                        </span>
                        <br></br>
                        <span style={styles.spanInfo}>
                          Finish : Entire shade made to given width.
                        </span>
                        <br></br>
                        <span style={styles.spanInfo}>
                          Cloth : Fabric made to given width.
                        </span>
                      </>
                    }
                    color='disabled'>
                    <InfoIcon />
                  </Tooltip> */}
                            </div>

                            {index.valance != 'Open Roll' && (
                              <div className='subFeature horizontalFeature'>
                                <div
                                  className='subTitle featuresTitle'
                                  data-featuretitle='Valance Colour'>
                                  <div className='featureName'>
                                    <span className='feature_name'>
                                      Valance Colour
                                    </span>
                                    <div
                                      style={{
                                        float: 'right',
                                        position: 'relative',
                                      }}></div>

                                    <select
                                      className='dd ddcommon borderRadius'
                                      value={
                                        index.valance.toLowerCase() ==
                                        valance
                                          .toLowerCase()
                                          .replace('sb open roll', 'open roll')
                                          .replace(' wrapped', '')
                                          ? valanceColor
                                          : ''
                                      }
                                      name='valance-options'
                                      onChange={(e) => {
                                        handleValanceColor(e);
                                      }}
                                      tabIndex={-1}>
                                      <option className='ddlabel' value=''>
                                        --Select--
                                      </option>
                                      {index.color != '-' ? (
                                        index.color.map((colour) => {
                                          return (
                                            <option
                                              className='ddlabel'
                                              value={colour}
                                              style={{
                                                backgroundColor:
                                                  colour == 'Anodized'
                                                    ? '#d3d2ce'
                                                    : colour == 'Brown'
                                                    ? '#665339'
                                                    : colour,
                                                color:
                                                  colour == 'Black'
                                                    ? 'white'
                                                    : colour == 'Brown'
                                                    ? 'white'
                                                    : 'black',
                                              }}>
                                              {colour}
                                            </option>
                                          );
                                        })
                                      ) : (
                                        <option
                                          className='ddlabel'
                                          id={index.valance}
                                          value='-'>
                                          -
                                        </option>
                                      )}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            )}

                            {index.roll_supported && (
                              <div className='subFeature horizontalFeature'>
                                <div
                                  className='subTitle featuresTitle'
                                  data-featuretitle='Valance Colour'>
                                  <div className='featureName'>
                                    <span className='feature_name'>
                                      Roll Type
                                    </span>
                                    <div
                                      style={{
                                        float: 'right',
                                        position: 'relative',
                                      }}></div>

                                    <select
                                      className='dd ddcommon borderRadius'
                                      value={
                                        index.valance.toLowerCase() ==
                                        valance
                                          .toLowerCase()
                                          .replace('sb open roll', 'open roll')
                                          .replace(' wrapped', '')
                                          ? selectedRollType
                                          : ''
                                      }
                                      name='valance-options'
                                      onChange={(e) => {
                                        setSelectedRollType(e.target.value);
                                      }}
                                      tabIndex={-1}>
                                      <option
                                        className='ddlabel'
                                        value='Regular'>
                                        Regular
                                      </option>
                                      <option
                                        className='ddlabel'
                                        value='Reverse'>
                                        Reverse
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            )}
                            {(index.valance.includes('Compact Curved') ||
                              index.valance.includes('Curved') ||
                              index.valance.includes('4" Fascia')) && (
                              <div className='subFeature horizontalFeature'>
                                <div className='subTitle featuresTitle'>
                                  <div className='featureName'>
                                    <span className='dynamicPrice'>
                                    Fabric Wrapped::{' '}
                                      <span className='dynamicPrice'>
                                        ${index.wrapPrice}
                                      </span>
                                    </span>
                                    <div
                                      style={{
                                        float: 'right',
                                        position: 'relative',
                                      }}></div>

                                    <select
                                      className='dd ddcommon borderRadius'
                                      value={
                                        index.valance.toLowerCase() ==
                                        valance
                                          .toLowerCase()
                                          .replace('sb open roll', 'open roll')
                                          .replace(' wrapped', '')
                                          ? selectedWrapType
                                          : ''
                                      }
                                      name='valance-options'
                                      onChange={(e) => {
                                        setSelectedWrapType(e.target.value);
                                      }}
                                      tabIndex={-1}>
                                      <option className='ddlabel' value='No'>
                                        No
                                      </option>
                                      <option className='ddlabel' value='Yes'>
                                        Yes
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <Loader />
                )}
              </Grid>
            </Grid>
          )}
        </Grid>

        {/* Dropdown for L Channel only if shade is banded */}
        {shade == 'Roller' && (
          <>
            <hr className='divider' />
            <Typography variant='h6'>
              Select Your L Channels (Optional):
            </Typography>
            {/* Drop down */}

            {/* <Select
                labelId='l-channel-options'
                id='l-channel-options'
                value={lChannel}
                name='l-channel-options'
                onChange={(e) => {
                  handleLChannel(e);
                }}
                label='L Channel'>
                <MenuItem value='None'>None</MenuItem>
                <MenuItem value='L Channels'>L Channels</MenuItem>
                <MenuItem value='L Channels & Bottom Channels'>
                  L Channels & Bottom Channels
                </MenuItem>
              </Select> */}

            <div
              className={
                lChannel == 'None'
                  ? 'featureOption selected optionsBoxMain'
                  : 'featureOption optionsBoxMain'
              }
              onClick={() => {
                setLChannel('None');
                lChannel = 'None';
                getPrice(valance);
              }}>
              {lChannel == 'None' && (
                <span
                  className='featureOptioncheckmark'
                  style={{ display: 'block' }}></span>
              )}

              <div
                className='option feature optionFeatures'
                style={{ height: '132.406px' }}>
                <div
                  className='image optionsFeatures'
                  style={{
                    backgroundImage: `url(${encodeURI(
                      '/images/diagram/lc/none.png',
                    )})`,
                  }}></div>
              </div>

              <div className='featureText withMediaIcons'>
                <div className='featureoptionradio' style={{}}>
                  <div
                    className='customRadio radioFeatureButton'
                    style={{ float: 'left', left: '-2px' }}>
                    <span className='radio selected'>
                      <span className='checked'></span>
                    </span>
                    <input
                      defaultChecked='checked'
                      groupname='Feature1320'
                      id='SelectedProductFeatureOptions3743'
                      name='AvailableFeatures[9].FeatureOptions[2].Selected'
                      type='checkbox'
                      defaultValue='true'
                    />
                    <input
                      name='AvailableFeatures[9].FeatureOptions[2].Selected'
                      type='hidden'
                      defaultValue='false'
                    />
                    <input
                      id='AvailableFeatures_9__FeatureOptions_2__OriginallySelected'
                      name='AvailableFeatures[9].FeatureOptions[2].OriginallySelected'
                      type='hidden'
                      defaultValue='True'
                    />
                  </div>
                  <div style={{}} className='featureOptionNamelbl'>
                    <label
                      htmlFor='SelectedProductFeatureOptions3743'
                      className='featureOptionName'
                      data-featuretitle='Curved Cassette Valance'>
                      None
                      <br />
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                lChannel == 'L Channels'
                  ? 'featureOption selected optionsBoxMain'
                  : 'featureOption optionsBoxMain'
              }
              onClick={() => {
                setLChannel('L Channels');
                lChannel = 'L Channels';
                getPrice(valance);
              }}>
              {lChannel == 'L Channels' && (
                <span
                  className='featureOptioncheckmark'
                  style={{ display: 'block' }}></span>
              )}

              <div
                className='option feature optionFeatures'
                style={{ height: '132.406px' }}>
                <div
                  className='image optionsFeatures'
                  style={{
                    backgroundImage: `url(${encodeURI(
                      '/images/diagram/lc/l_side_channels.png',
                    )})`,
                  }}></div>
              </div>

              <div className='featureText withMediaIcons'>
                <div className='featureoptionradio' style={{}}>
                  <div
                    className='customRadio radioFeatureButton'
                    style={{ float: 'left', left: '-2px' }}>
                    <span className='radio selected'>
                      <span className='checked'></span>
                    </span>
                    <input
                      defaultChecked='checked'
                      groupname='Feature1320'
                      id='SelectedProductFeatureOptions3743'
                      name='AvailableFeatures[9].FeatureOptions[2].Selected'
                      type='checkbox'
                      defaultValue='true'
                    />
                    <input
                      name='AvailableFeatures[9].FeatureOptions[2].Selected'
                      type='hidden'
                      defaultValue='false'
                    />
                    <input
                      id='AvailableFeatures_9__FeatureOptions_2__OriginallySelected'
                      name='AvailableFeatures[9].FeatureOptions[2].OriginallySelected'
                      type='hidden'
                      defaultValue='True'
                    />
                  </div>
                  <div style={{}} className='featureOptionNamelbl'>
                    <label
                      htmlFor='SelectedProductFeatureOptions3743'
                      className='featureOptionName'
                      data-featuretitle='Curved Cassette Valance'>
                      L Channels
                      <br />
                      <span className='dynamicPrice'>
                        ${channelPrice != null && channelPrice[0]}
                      </span>
                    </label>
                  </div>
                </div>
                <div style={{ float: 'right', paddingTop: 6 }}>
                
                </div>
              </div>
            </div>

            <div
              className={
                lChannel == 'L Channels & Bottom Channels'
                  ? 'featureOption selected optionsBoxMain'
                  : 'featureOption optionsBoxMain'
              }
              onClick={() => {
                setLChannel('L Channels & Bottom Channels');
                lChannel = 'L Channels & Bottom Channels';
                getPrice(valance);
              }}>
              {lChannel == 'L Channels & Bottom Channels' && (
                <span
                  className='featureOptioncheckmark'
                  style={{ display: 'block' }}></span>
              )}

              <div
                className='option feature optionFeatures'
                style={{ height: '132.406px' }}>
                <div
                  className='image optionsFeatures'
                  style={{
                    backgroundImage: `url(${encodeURI(
                      '/images/diagram/lc/l_side_bottom_channels.png',
                    )})`,
                  }}></div>
              </div>

              <div className='featureText withMediaIcons'>
                <div className='featureoptionradio' style={{}}>
                  <div
                    className='customRadio radioFeatureButton'
                    style={{ float: 'left', left: '-2px' }}>
                    <span className='radio selected'>
                      <span className='checked'></span>
                    </span>
                    <input
                      defaultChecked='checked'
                      groupname='Feature1320'
                      id='SelectedProductFeatureOptions3743'
                      name='AvailableFeatures[9].FeatureOptions[2].Selected'
                      type='checkbox'
                      defaultValue='true'
                    />
                    <input
                      name='AvailableFeatures[9].FeatureOptions[2].Selected'
                      type='hidden'
                      defaultValue='false'
                    />
                    <input
                      id='AvailableFeatures_9__FeatureOptions_2__OriginallySelected'
                      name='AvailableFeatures[9].FeatureOptions[2].OriginallySelected'
                      type='hidden'
                      defaultValue='True'
                    />
                  </div>
                  <div style={{}} className='featureOptionNamelbl'>
                    <label
                      htmlFor='SelectedProductFeatureOptions3743'
                      className='featureOptionName'
                      data-featuretitle='Curved Cassette Valance'>
                      L Channels & Bottom Channels
                      <br />
                      <span className='dynamicPrice'>
                        ${channelPrice != null && channelPrice[1]}
                      </span>
                    </label>
                  </div>
                </div>
                <div style={{ float: 'right', paddingTop: 6 }}>
                
                </div>
              </div>
            </div>
          </>
        )}
        <Snackbar open={errorToast}>
          <Alert severity='error'>
            <AlertTitle>Please select a valance color.</AlertTitle>
          </Alert>
        </Snackbar>
        <Grid container direction='row'>
          <Grid item xs={6}>
            <Button id='back' onClick={previousPage} style={styles.atcButton}>
              Back
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button  className='redButton'
              style={{
                margin: '.5rem 0rem',
                width: '10rem',
                backgroundColor: '#ef4036',
                color: '#fff',
                marginTop: '1rem',
              }} onClick={forwardPage}>
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomizeValance;
